import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from "react-redux";
import { addTerm, updateTerm } from '../../services/terms.service';
import { Formik, Form } from "formik";
import TextField from '../../components/common/formInput/TextField';
import { failed, success } from "../../components/common/Toastify";
import Editor from '../../components/common/formInput/Editor';
import SearchSelectBox from '../../components/common/formInput/SearchSelectBox';
import { _status } from '../../components/common/utils/message';

const TermForm = ({ handleClose, show, formState }) => {

    const dispatch = useDispatch();

    const _submitData = (data) => {
        try {
            addTerm(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleClose();
                    success(result.message)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };


    const _updateData = async (data) => {
        try {
            updateTerm(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleClose();
                    success(result.message);

                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    return (
        <div className='dashboard-inner'>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{formState._id ? "Edit Term" : "Add New Term"}</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={formState}
                    enableReinitialize
                    onSubmit={(values) => formState._id ? _updateData(values) : _submitData(values)}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className="f-row alignTop">
                                    <div className="f-50">
                                        <TextField
                                            label="Title"
                                            name="title"
                                            value={formik?.values?.title}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>
                                    <div className="f-50">
                                        <SearchSelectBox
                                            label="Status"
                                            name="status"
                                            options={_status}
                                            value={formik?.values?.status}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="f-row">
                                    <div className="f-100">
                                        <Editor
                                            label="Description"
                                            name="detail"
                                            value={formik?.values?.detail}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li><button className="btn btn-primary">{formState._id ? "Edit" : "Add"}</button></li>
                                    <li><button type='button' className="btn btn-grey" onClick={handleClose}>Cancel</button></li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default TermForm

