import React from 'react';
import NoDataFound from '../../../components/common/NoDataFound';
const Maps = () => {
    return <>
    <div className='dashboard-inner'>
            Map 
            <div><NoDataFound/></div>
    </div></>
}
export default Maps;
