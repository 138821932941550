import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import AddInvoice from '../AddInvoice';
import InvoiceDetails from '../InvoiceDetails';

const InvoiceRoutes = [
    {
        path: `/add-invoice`,
        layout: DefaultLayout,
        component: <  AddInvoice />
    },
    {
        path: `/invoice-list`,
        layout: DefaultLayout,
        component: <  InvoiceDetails />
    },
]

export default InvoiceRoutes;