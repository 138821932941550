import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from "formik";
import TextField from '../../../components/common/formInput/TextField';
import { useDispatch } from "react-redux";
import { success, failed } from "../../../components/common/Toastify";
import SearchSelectBox from '../../../components/common/formInput/SearchSelectBox';
import TextArea from '../../../components/common/formInput/TextArea';
import CategoryManagementService from '../../../services/category.service';
import { useParams } from 'react-router-dom';

const AddSubCategory = ({ show, handleClose, subCategory }) => {

    const params = useParams();
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
        name: subCategory.name || ""
    });
    const category_id = params.category_id;


    const _submitData = (data) => {
        const payload = {
            name: data.name,
            category_id: category_id
        }

        CategoryManagementService.addSubCategory(payload, (data) => {

            if (data.status === "SUCCESS" && data.code === 200) {
                success(data.message);
                handleClose();
            }
            else {
                failed(data.message);
            }
        }, dispatch);



    }
    const _editData = (data) => {

        const payload = { _id: subCategory.id, name: data.name, category_id }
        CategoryManagementService.updateCategory(payload, (data) => {
            if (data.status === "SUCCESS" && data.code === 200) {
                success(data.message);
                handleClose();
            }
            else {
                failed(data.message);
            }
        }, dispatch);



    }
    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="custom-inner-header">
                        <Modal.Title>{subCategory.id ? "Edit Sub Category" : "Add Sub Category"}</Modal.Title>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={formState}
                    enableReinitialize
                    onSubmit={(values) => {
                        subCategory.id ? _editData(values) : _submitData(values);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className='f-row'>
                                    <div className='f-50'>
                                        <TextField
                                            label="Sub Category Name"
                                            name="name"
                                            value={formik?.values?.name}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li>
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </li>
                                    <li>
                                        <button className="btn btn-grey" type="button" onClick={handleClose}>Cancel</button>
                                    </li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default AddSubCategory