import React from "react";
import moment from 'moment';

const EstimatorAssistant = ({ estimatorDetails }) => {
    return (
        <>
            <div className="dashboard-table responsive-table vr-table">
                {estimatorDetails?.length !== 0 ?
                    (<table>
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Description</th>
                                <th>CreatedAt</th>
                            </tr>
                        </thead>
                        <tbody>
                            {estimatorDetails?.map((item) => (
                                <tr>
                                    <td>{item.title}</td>
                                    <td>{item.description}</td>
                                    <td>{moment(item?.createdAt).format('DD/MM/YYYY')}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>)
                    :
                    (<h5 className="d-flex align-items-center justify-content-center">No data found!</h5>)}
            </div>
        </>
    );
}

export default EstimatorAssistant;