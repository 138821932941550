import React from 'react'
import moment from 'moment';
import { Link } from 'react-router-dom'
import Icons from '../../../assets/images'
import _ from 'underscore'
import { API_BASEURL } from '../../../environment'

const GridView = ({ resPayload }) => {
    return (
        <div>
    
            <ul className='projects-list'>
                {resPayload && resPayload?.docs?.map((item, ind) => (
                    <li key={ind}>
                        <div className='project'>
                            <span className='project-img'>
                                <Link to={`/contractor-project-view/${item._id}`}>
                                     <img src={item?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                                </Link>
                            </span>
                            <div className='project-details'>
                                <div className='project-header'>
                                    <div className='project-header-left'>
                                        <h5 className='project-name'>{item?.project_title}</h5>
                                    </div>
                                    <div className='project-header-right'>
                                        <span className='project-date'>{moment(item?.createdAt).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                               {/* <div className='project-body'>
                                    <p>{item.notes}</p>
                                    <div className='project-sub-details'>
                                        <div className='project-sub-left'>
                                            <h5>Bid Fee : $150 USD</h5>
                                        </div>
                                        <div className='project-sub-right'>
                                            <h5>Winning bid Fee : $254 USD</h5>
                                        </div>
                                    </div>
                                </div>
                */}
                <div className='project-body'>
                <p>Square footage : {item.square_footage }</p>
                                    </div>
                                    
                <div className='project-body'>

                <p>Status : {item.project_status
                }</p>
                                   
                                    </div>
                                <div className='project-footer'>
                                    <p className='project-location'>{item?.buildingId?.city},{item?.buildingId?.state}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default GridView