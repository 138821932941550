import React from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LoaderUI from "../loader/loader";
import HomeHeader from "../../../features/homePages/common/HomeHeader";
import HomeFooter from "../../../features/homePages/common/HomeFooter";

const HomeLayout = ({ children }) => {
  //Responsive menu toggle
  const [toggle, setToggle] = React.useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  let isLoading = useSelector(state => state.loader.isLoading);
  return (
    <main className={`${toggle ? 'toggled' : ''} main`}>   
      <HomeHeader toggle={handleToggle}/>
      <span className='overlay' onClick={handleToggle}></span>
      <ToastContainer />
        <div className="home">
          {isLoading && <LoaderUI
            loader={isLoading}
            overlay={true}
            overlayRadius='rounded-10'
            FullWindow={true}
            color="danger"
            animation="border"
            customSize="lg"
          />}
          {children}
        </div>
      <HomeFooter />
    </main>
  );
};

export default HomeLayout;
