import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import DocumentsList from '../DocumentsList';

const MaterialRoutes = [
    {
        path: `/documents-list`,
        layout: DefaultLayout,
        component: <  DocumentsList />
    }
]

export default MaterialRoutes;