import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';
import ReactPaginate from 'react-paginate';
import SelectBox from "../../components/common/formInput/SelectBox";

const PaymentManagement = () => {
    return (
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <div className="custom-seachbox">
                        <input type="search" placeholder="Search" />
                        <button className="btn btn-primary btn-search" >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <div className="sortby">
                                <span>State : </span>
                                <div className="nocolor-dropdown">
                                    <select>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="sortby">
                                <span>Sort by : </span>
                                <div className="nocolor-dropdown">
                                    <select>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <h4 className='dashboard-heading p-b-30'>Payment Management</h4>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Opportunities Payment</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className='responsive-table'>
                        <table className='table-odd-even'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-filters">
                        <div className="t-f-cell">
                            <div className="records-filter">
                                <span>Show</span>
                                <div className="grey-dropdown bg-white">
                                    <SelectBox />
                                </div>
                                <span>Entries</span>
                            </div>
                        </div>
                        <div className="t-f-cell">
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel={'Next >'}
                                previousLabel={'< Back'}
                                renderOnZeroPageCount={null}
                                containerClassName={'custom-pagination '}
                                pageClassName={'btn-page'}
                                pageLinkClassName={'btn-page'}
                                previousClassName={'btn-page'}
                                previousLinkClassName={'btn-previous'}
                                nextClassName={'btn-page'}
                                nextLinkClassName={'btn-next'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Winning Bid Payment</h5>
                    <div className='d-card-actions'>
                        <ul className="dashboard-sub-filters">
                            <li>
                                <div className="sortby">
                                    <span>State : </span>
                                    <div className="nocolor-dropdown">
                                        <select>
                                            <option>California</option>
                                            <option>California</option>
                                            <option>California</option>
                                            <option>California</option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="sortby">
                                    <span>Sort by : </span>
                                    <div className="nocolor-dropdown">
                                        <select>
                                            <option>Active</option>
                                            <option>Inactive</option>
                                        </select>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className='responsive-table'>
                        <table className='table-odd-even'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='payments-details'>
                                            <span className='payment-client'>
                                                <img src={Icons.building} alt="" />
                                            </span>
                                            <h4>South Mountain Crossing [rpc102494-002]</h4>
                                            <h5>$150.00 USD</h5>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing p-b-20'>14/05/2022</h5>
                                        <h5 className='bid-closing'>Paid By : Cameron Williamson</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-filters">
                        <div className="t-f-cell">
                            <div className="records-filter">
                                <span>Show</span>
                                <div className="grey-dropdown bg-white">
                                    <SelectBox />
                                </div>
                                <span>Entries</span>
                            </div>
                        </div>
                        <div className="t-f-cell">
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel={'Next >'}
                                previousLabel={'< Back'}
                                renderOnZeroPageCount={null}
                                containerClassName={'custom-pagination '}
                                pageClassName={'btn-page'}
                                pageLinkClassName={'btn-page'}
                                previousClassName={'btn-page'}
                                previousLinkClassName={'btn-previous'}
                                nextClassName={'btn-page'}
                                nextLinkClassName={'btn-next'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentManagement

