import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { useNavigate } from "react-router-dom";
import { listManufacturer } from "../../services/manufacturer";
import Accordion from 'react-bootstrap/Accordion'
import { useDispatch, useSelector } from "react-redux";
import { pagination, PaginationOption } from "../../components/common/utils/message";
import { API_BASEURL } from "../../environment";
import images from '../../assets/images/defaultimage.png'
const ManufacturerView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();
    const [searchKey, setSearchKey] = useState("");

    const [perPage, setPerPage] = useState(pagination.limit);

    useEffect(() => {
        listManufacturer({ page: pagination.page }, (data) => { setResPayload(data) }, dispatch);
    }, [dispatch]);

    const general = useSelector((state) => state.general)
    const get_manufactrer = resPayload && resPayload.docs.find((item) => item._id === general.param)

    return (
        <div className='dashboard-inner'>
            <div className='d-h-wrap'>
            </div>
            <h4 className='dashboard-heading p-b-15'>View Manufacturer</h4>
            <div className="owner">
                <div className="owner-left">
                    <span className="owner-img">
                        <img src={get_manufactrer?.company_logo ? `${API_BASEURL}/${get_manufactrer.company_logo}` : images} alt="" />
                    </span>
                    <h4>{get_manufactrer?.first_name} {get_manufactrer?.last_name}</h4>
                    <p>{get_manufactrer?.email}</p>
                </div>
                <div className="owner-right">
                    <h5>Location: {get_manufactrer?.street_address} {get_manufactrer?.city} {get_manufactrer?.state}</h5>
                </div>
            </div>
        </div>
    );
};

export default ManufacturerView;
