import React from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LoaderUI from "../loader/loader";
import OwnerHomeHeader from "../../../features/ownerInterface/common/Header";
import OwnerHomeFooter from "../../../features/ownerInterface/common/Footer";
import { useNavigate } from "react-router-dom";

const OwnerInterfaceLayout = ({ children }) => {
    //Responsive menu toggle
    const [toggle, setToggle] = React.useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    const navigate = useNavigate();
    let loginUser = useSelector(state => state.login?.user?.data?.user)
    if (loginUser.role !== "OWNER")
      navigate('/dashboard')

    let isLoading = useSelector(state => state.loader.isLoading);
    return (
        <main className={`${toggle ? 'toggled' : ''} main`}>
            <OwnerHomeHeader toggle={handleToggle} />
            <span className='overlay' onClick={handleToggle}></span>
            <ToastContainer />
            <div className="v-wrapper">
                <div className='container'>
                    {isLoading && <LoaderUI
                        loader={isLoading}
                        overlay={true}
                        overlayRadius='rounded-10'
                        FullWindow={true}
                        color="danger"
                        animation="border"
                        customSize="lg"
                    />}
                    {children}
                </div>
            </div>
            <OwnerHomeFooter />
        </main>
    );
};

export default OwnerInterfaceLayout;
