import React from 'react'
import { ErrorMessage, useField } from "formik";
import Select from "react-select";

const SearchSelectBox = ({ label, required, viewOnly = false, ...props }) => {
    const [field, meta, helpers] = useField(props);

    //Add your search logic here.
    // const customFilter = (option, searchText) => option?.data?.label?.toLowerCase().startsWith(searchText.toLowerCase()) 
      
    return (
        <div className="form-group">
            <label className="f-label" htmlFor={field.name}>{label}{required ? <span className="error">*</span> : ""}</label>
            <div className='f-field'>
                <div className='cm-dropdown'>
                    <Select
                        className={`pro-input-two pro-select-two-new ${meta.touched && meta.error && 'is-invalid'}`}
                        options={props.options}
                        name={props.name}
                        placeholder={props.placeholder}
                        value={
                            props.options
                                ? props.options.find((option) => option.value === field.value)
                                : ""
                        }
                        onChange={(option) => {
                            props?.changeHandle?.(option.value);
                            helpers?.setValue(option.value);
                        }}
                        isDisabled={viewOnly}
                        filterOption={props.filterOption}
                    />
                    <ErrorMessage component="div" name={field.name} className="error" />
                </div>
            </div>
        </div>
    );
};

export default SearchSelectBox;