import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteEstimate, listEstimator, updateProjectEstimator } from '../../services/estimator';
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { failed, success } from '../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";
import ReactPaginate from 'react-paginate';
import SelectBox from '../../components/common/formInput/SelectBox';
import BidForm from './BidForm';
import Icons from '../../assets/images';
import _ from 'underscore';
import {   viewOneProject } from '../../services/project';
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import { listBidForm, addBidForm, updateBidForm, getSumbitBidAdmin, listMasterBidForm, createBidForm } from '../../services/project';



const CreateBidForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const _projectId = queryString.parse(location.search);

    const [modalShow, setModalShow] = useState(false);
    const [cloneEstimator, setCloneEstimator] = useState("");
    const handleClose = () => setModalShow(false)

    const [resPayload, setResPayload] = useState();
    const [projectName, setProjectName] = useState();

    const totalPages = resPayload && resPayload.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    let [estimatorData, setEstimatorData] = useState([]);
    let [isRightCheck, setIsRightCheck] = useState([]);
    let [isLeftCheck, setIsLeftCheck] = useState([]);

    const [formValues, setFormValues] = useState(
        [
            {
                item: "",
                default_value: 0,
                allowed_in_words: false,
                is_master_lineItem: true,
                master_lineItem_id: ""
            }
        ]
    );

    useEffect(() => {
        _getListing();
        //getting Estimator data from project by project_id
        viewOneProject(_projectId, (data) => {
            setEstimatorData(data?.associatedEstimatorAssistance);
            setProjectName(data?.project_title)
        })
    }, [dispatch]);

    useEffect(() => {
        _getListing();
    }, [estimatorData])

    /**
     * @description get all the faq db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {
        let ignoreRecords = estimatorData?.map((item) => item._id)
        listMasterBidForm({ page, limit, ignoreRecords }, (data) => { setResPayload(data) }, dispatch)
    }

    /**
    * @description Function For delete faq.
    * @param {id} id 
    */
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            deleteEstimate(id, async res => {
                if (res.status === "SUCCESS" && res.code === 200) {
                    _getListing();
                    success(res.message);
                    await alert(message.EstimateAssistanceDeleted);
                }
            })
        }
    }

    //Left checkbox handlecheck.............
    const handleChangeCheck = (id, e) => {
        let checkedData = JSON.parse(JSON.stringify(isLeftCheck))
        if (!e.target.checked) {
            let index = checkedData.findIndex(item => item === id)
            if (index !== -1)
                checkedData.splice(index, 1)
            setIsLeftCheck([...checkedData])
        } else
            setIsLeftCheck([...checkedData, String(id)])
    }

    //Right checkbox handlecheck.............
    const _handleCheck = (id, e) => {
        let checkValue = JSON.parse(JSON.stringify(isRightCheck))
        if (!e.target.checked) {
            let index = checkValue.findIndex(item => item === id)
            if (index !== -1)
                checkValue.splice(index, 1)
            setIsRightCheck([...checkValue])
        } else
            setIsRightCheck([...checkValue, String(id)])
    }

    //For Right side remove data....
    const _handleArrowDown = () => {
        let checkData = estimatorData.filter(e => {
            if (!isRightCheck.includes(e._id))
                return e
        })
        setEstimatorData([...checkData])
    }
   
    // on data move left to right
    const _handleArrowUp = async () => {
        if (_projectId.id) {
            let rightEstimates = resPayload.docs
            await rightEstimates?.forEach(item => {
                if (isLeftCheck.includes(item._id)) {
                    estimatorData.push(item)
                }
            })
            setIsLeftCheck([])
            var output = rightEstimates.filter((el) => {
                if (!isLeftCheck.includes(el._id)) {
                    return el
                }
            })
            setResPayload({ ...resPayload, docs: output })
        } else {
            failed("Project is not associated! Select a project first!.")
        }
    }

    //update description handle change
    const _handleChange = (item, e, attribute) => {
        for (var i = 0; i < estimatorData.length; i++) {
            if (estimatorData[i]._id === item._id) {
                estimatorData[i][attribute] = e.target.value
            }
        }
        setEstimatorData([...estimatorData])
    }

    //update description textare
    const updateInput = (item, className) => {
        return (
            <div className='f-field' style={{ border: '0.5px solid #ccc' }}>
                <textarea
                    className={className}
                    type="text"
                    value={className === "f-area" ?
                        item.description :
                        item.title
                    }
                    onChange={(e) => { _handleChange(item, e, className === "f-area" ? "description" : "title") }} />
            </div>
        )
    }

    /**
    * @description Function For Update Estimator.
    * @param {id} id 
    */
    const _updateData = async () => {
        if (_projectId.id) {
           
            let itemArray = [];
            estimatorData.forEach(element => {
                let tempJson = {
                    item: element.item,
                    default_value: 0,
                    allowed_in_words: false,
                    is_master_lineItem: true,
                    master_lineItem_id: element._id
                }
                itemArray.push(tempJson);


            });
            

            let resData = {
                "project_id": _projectId.id,
                "line_items": itemArray
            }
            

            try {
                createBidForm(resData, result => {
                    if (result.status === "SUCCESS" && result.code === 200) {
                        success(result.message)
                        navigate(`/project-view/${ _projectId.id}`)
                        
                    } else if (result.status === "ERROR" && result.code === 403) {
                        failed(result.message)
                    }
                }, dispatch)
            } catch (e) {
                failed(e)
            }
        } else {
            failed("Project is not associated! Select a project first!.")
        }
    }

 
    
    /**
    * @description /Function is on Page Change.
    * @param {param} param 
    */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listMasterBidForm({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };

    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listMasterBidForm({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }
   
    return (
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Create Bid Form</h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters dashboard-sm-sub-filters">
                       
                        <li>
                            <button className="btn btn-blue btn-min-width-200" onClick={() => { setModalShow(true); setCloneEstimator("") }} >Add New Line Items</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    {/* <h6 className='text-center'>Master Estimator Assistance</h6> */}
                </div>
                <div className="d-h-r">
                    {projectName ? <h6>Project Name:<span> {projectName}</span></h6> :
                        <h6 className='text-center'>Assigned Line Items</h6>}
                </div>
            </div>
            <div className='bid-container'>
                <div className="dashboard-table">
                    <h3 className="text-center mb-2"> Master Bid Line Items List </h3>
                    <div className="responsive-table">
                        <table className='table-update dark-header'>
                            <thead>
                                <tr>
                                    <th className="text-center">Select</th>
                                    <th>
                                        <div className='master-estimate'>
                                            <h4 style={{ color: '#fff' }}>Line Item</h4>
                                            <h6 style={{ color: '#fff' }}>Description</h6>
                                        </div>
                                    </th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resPayload && resPayload?.docs
                                    .map((dataItem, index) => (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <label className='checkbox nolabel' htmlFor={`check004${index}`}>
                                                    <input type="checkbox"
                                                        checked={isLeftCheck.includes(dataItem._id)}
                                                        onClick={(e) => { handleChangeCheck(dataItem._id, e) }}
                                                        id={`check004${index}`}
                                                    />
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td>
                                                <div className='master-estimate'>
                                                    <h4>{dataItem.item}</h4>
                                                    <h6>{dataItem.status}</h6>
                                                </div>
                                            </td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="table-filters">
                    <div className="t-f-cell">
                        <div className="records-filter">
                            <span>Show</span>
                            <div className="grey-dropdown bg-white">
                                <SelectBox
                                    options={PaginationOption}
                                    val={limit}
                                    handleVal={(e) => setPagination(e)}
                                />
                            </div>
                            <span>Entries</span>
                        </div>
                    </div>
                    {totalPages > 1 ? (
                        <div className="t-f-cell">
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel={'Next >'}
                                onPageChange={handlePageChange}
                                pageCount={totalPages}
                                previousLabel={'< Back'}
                                renderOnZeroPageCount={null}
                                containerClassName={'custom-pagination'}
                                pageClassName={'btn-page'}
                                pageLinkClassName={'btn-page'}
                                previousClassName={'btn-page'}
                                previousLinkClassName={'btn-previous'}
                                nextClassName={'btn-page'}
                                nextLinkClassName={'btn-next'}
                                activeClassName={'active'}
                            />
                        </div>
                    ) : null}
                </div>
                <div className='estimator-arrow' style={{ padding: '20px 0' }}>
                    <button className="btn btn-blue" onClick={() => _handleArrowUp()} style={{ marginRight: '10px' }}> Add To Project</button>
                    <button className="btn btn-red" onClick={() => _handleArrowDown()}> Remove From Project </button>
                </div>

                <div className="dashboard-table">
                    <h3 className="text-center mb-2"> Project Bid Line Items List </h3>
                    <div className="responsive-table">
                        <table className='table-update dark-header'>
                            <thead>
                                <tr>
                                    <th className="text-center">Select</th>
                                    <th>Line Item Description</th>
                                    <th style={{ width: 'auto' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estimatorData?.map((itemData, index) => (
                                    <tr key={index}>
                                        <td className="text-center">
                                            <label className='checkbox nolabel' htmlFor={`check005${index + 1}`}>
                                                <input type="checkbox" id={`check005${index + 1}`}
                                                    checked={isRightCheck.includes(itemData._id)}
                                                    onClick={(e) => _handleCheck(itemData._id, e)} />
                                                <span></span>
                                            </label>

                                        </td>
                                        <td>{(itemData.item)}</td>

                                        <td></td>

                                    </tr>
                                ))}
                                {estimatorData === undefined || estimatorData?.length === 0 ? (<tr><td colSpan={3} className="text-center"> No data found! </td> </tr>) : null}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="d-h-wrap">
                    <div className="d-h-l">

                    </div>
                    <div className="d-h-r  mt-2">
                        <ul className="dashboard-sub-filters dashboard-sm-sub-filters">
                            <li></li>
                            <li>
                                <button className="btn btn-blue" type='submit' onClick={(e) => { _updateData(); }}>Submit</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <BidForm
                show={modalShow}
                handleClose={handleClose}
                cloneEstimator={cloneEstimator}
                handleModal={() => { _getListing(); setCloneEstimator("") }}
            />
        </div>
    )

};

export default CreateBidForm;
