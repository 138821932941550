import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import PrivilegeList from '../PrivilegeList';
import PrivilegeView from '../PrivilegeView';

const PrivilegeRoutes = [
    {
        path: `/privilege-list`,
        layout: DefaultLayout,
        component: <  PrivilegeList />
    },
    {
        path: `/privilege-view`,
        layout: DefaultLayout,
        component: <  PrivilegeView />
    }
]

export default PrivilegeRoutes;