import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';

const AddInvoice = () => {
    return (
        <div className='dashboard-inner'>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Invoice</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="form-content">
                        <div className='f-row'>
                            <div className='f-33'>
                                <label className='f-label'>Invoice No.</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                            <div className='f-33'>
                                <label className='f-label'>Date</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                            <div className='f-33'>
                                <label className='f-label'>Payment Due</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                        </div>
                        <div className='f-row'>
                            <div className='f-33'>
                                <label className='f-label'>Receiver Name</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                            <div className='f-33'>
                                <label className='f-label'>Receiver Email Address</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                            <div className='f-33'>
                                <label className='f-label'>Email Address</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Item Description</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="form-content">
                        <div className='f-row'>
                            <div className='f-40'>
                                <label className='f-label'>Item Name</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                            <div className='f-60'>
                                <div className='f-row'>
                                    <div className='f-33'>
                                        <label className='f-label'>Price</label>
                                        <div className='f-field'>
                                            <input className='f-input' />
                                        </div>
                                    </div>
                                    <div className='f-33'>
                                        <label className='f-label'>Quantity</label>
                                        <div className='f-field'>
                                            <input className='f-input' />
                                        </div>
                                    </div>
                                    <div className='f-33'>
                                        <label className='f-label'>Subtotal ($)</label>
                                        <div className='f-field'>
                                            <input className='f-input' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='f-row'>
                            <div className='f-40'>
                                <label className='f-label'>Item Name</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                            <div className='f-60'>
                                <div className='f-row'>
                                    <div className='f-33'>
                                        <label className='f-label'>Price</label>
                                        <div className='f-field'>
                                            <input className='f-input' />
                                        </div>
                                    </div>
                                    <div className='f-33'>
                                        <label className='f-label'>Quantity</label>
                                        <div className='f-field'>
                                            <input className='f-input' />
                                        </div>
                                    </div>
                                    <div className='f-33'>
                                        <label className='f-label'>Subtotal ($)</label>
                                        <div className='f-field'>
                                            <input className='f-input' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='f-row'>
                            <div className="f-100">
                                <div className='f-field'>
                                    <button className='btn btn-transparent'><span className='btn-icon'>+</span>Add New Bid</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-card-bottom">
                <div className="d-card-bottom-cell">
                    <button className="btn btn-blue" type="submit">Submit Invoice</button>
                </div>
                <div className="d-card-bottom-cell">
                    <button className="btn btn-grey" type="button" >Save Invoice</button>
                </div>
                <div className="d-card-bottom-cell">
                    <button className="btn btn-grey" type="button" >Download Invoice</button>
                </div>
            </div>
        </div>
    )
}

export default AddInvoice

