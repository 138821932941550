import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Icons from '../../../assets/images'
import moment from 'moment';

const ListView = ({ resPayload, handleDelete, handleSorting }) => {

    const navigate = useNavigate();

    return (
        <div className="dashboard-table">
            <div className="responsive-table">
                <table>
                    <thead>
                        <tr>
                            <th>Serial <br /> No. </th>
                            <th><span className="sort-icon">Inspector name<i class="bi bi-arrow-up" onClick={() => handleSorting("inspector_name", 1)}></i><i class="bi bi-arrow-down" onClick={() => handleSorting("inspector_name", -1)}></i></span></th>
                            <th>Project Name</th>
                            <th>Created Date</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resPayload && resPayload.docs
                            .map((item, index) => (
                                
                                <tr key={index}>
                                    {item.projectId != null || item.projectId != undefined ? 
                                    <>
                                    <td>{index + 1}</td> 
                                    <td>{item.inspector_name}</td>
                                    <td>{item.projectId.project_title}</td>
                                    <td>{moment(item.date).format('DD/MM/YYYY')}</td>
                                    <td className="text-center">
                                        <span className="sort-icon">
                                            <button className="dashboard-action" title="View" onClick={() => navigate(`/inspection-view/${item._id}`)}>
                                                <img src={Icons.eye} alt="" />
                                            </button>
                                            <Link className="dashboard-action" to={`/inspection-form?id=${item._id}`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete" onClick={() => handleDelete(item._id)}>
                                                <img src={Icons.bin} alt="" />
                                            </button>
                                        </span>
                                    </td>
                                    </>     
                               : '' }
                               </tr> 
                            ))}
                    </tbody>
                </table>
                {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No data found!</h5>) : null}
            </div>
        </div>
    )
}

export default ListView