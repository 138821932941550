import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import CreateManufacturer from "../ManufacturerForm";
import AllManufacturer from '../ManufacturerList';
import ManufacturerView from '../ManufacturerView';


const ManufacturerRoutes = [
    {
        path: `/add-manufacturer`,
        layout: DefaultLayout,
        component: <  CreateManufacturer />
    },
    {
        path: `/list-manufacturer`,
        layout: DefaultLayout,
        component: <  AllManufacturer />
    },
    {
        path: `/manufacturer-view/:id`,
        layout: DefaultLayout,
        component: <  ManufacturerView />
    },
   
]

export default ManufacturerRoutes;