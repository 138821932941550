import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all newRoofType data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listNewRoofType = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/newrooftype/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description add new newRoofType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addNewRoofType = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/newrooftype/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description update newRoofType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateNewRoofType = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/rpc/newroofType/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description Soft Delete newRoofType in db
 * @param {*} id 
 * @param {*} callback 
 */
export const deleteNewRoofType = async (id, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.delete(`${apiUrl}${PORT}/rpc/newrooftype/delete/${id}`, option)
                .then((response) => {
                    callback(response.data)
                }).catch((error) => {
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const updateNewRoofTypeStatus = async (id, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/newrooftype/update/status`,id , option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const viewOneNewRoofType = async (param, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/rpc/newrooftype/view?${queryString.stringify(param)}`, option).then((response) => {
                callback(response.data.data)
            }).catch((error) => {
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
  };