import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all materialType data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listWindUplift = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/windUplift/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description add new MaterialType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addWindUplift = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/windUplift/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description update MaterialType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateWindUplift = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/rpc/windUplift/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description Soft Delete MaterialType in db
 * @param {*} id 
 * @param {*} callback 
 */
export const DeleteWindUplift = async (id, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.delete(`${apiUrl}${PORT}/rpc/windUplift/delete/${id}`, option)
                .then((response) => {
                    callback(response.data)
                }).catch((error) => {
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const viewOneWindLift = async (param, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/rpc/windUplift/view?${queryString.stringify(param)}`, option).then((response) => {
                callback(response.data.data)
            }).catch((error) => {
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};


export const updateWindUpliftStatus = async (id, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/windUplift/update/status`,id , option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
// export const updateWindUpliftStatus = async (data, callback,dispatch) => {
//     try {
//         let token = localStorage.getItem('authentication')
//         if (token) {
//             let option = {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             }
//             dispatch(loadingAction(true))
//             axios.post(`${apiUrl}${PORT}/rpc/windUplift/update/status/?${queryString.stringify(data)}`, option)
//                 .then((response) => {
//                     console.log(response,"response id");
//                     dispatch(loadingAction(false))
//                     callback(response.data)
//                 }).catch((error) => {
//                     dispatch(loadingAction(false))
//                     callback(false)
//                 });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };