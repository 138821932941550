import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { rpcSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import { options, message, _status } from "../../components/common/utils/message";
import { confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { addRpc, getRPC, updateRpc } from "../../services/rpc";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { Get_Countries, Get_State } from "../../services/owner";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import { listModules } from "../../services/leftMenu";
import { UploadLogoField } from "../../components/common/formInput/UploadLogoField";
import Breadcrumb from "../../components/common/Breadcrumb";

const initialFormState = {
    _id: "",
    first_name: "",
    last_name: "",
    company_name: "",
    city: "",
    mobile_no: "",
    state: "",
    country: "United States",
    zip_code: "",
    address1: "",
    address2: "",
    email: "",
    status: true,
    company_logo: ""
};

const CreateRPC = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const rpc_id = queryString.parse(location.search);
    const [rpcManager, setRpcManager] = useState({ managerData: { ...initialFormState }, userModules: [] });

    const [states, setStates] = useState([]);
    const [resCountries, setResCountries] = useState();
    const [modules, setModules] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        Get_Countries(result => {
            if (result.data) {
                const statesDropDownData = result.data && result.data.map((item) => ({ value: item.name, label: item.name }));
                setResCountries(statesDropDownData);
            }
        })
        

        if (rpc_id) {
            getRPC({ id: rpc_id.id }, (result) => {
                if (result.user) {
                    const get_rpc = { ...result.user };
                    const rpcManagerData = {
                        _id: get_rpc ? get_rpc._id : "",
                        first_name: get_rpc ? get_rpc.first_name : "",
                        last_name: get_rpc ? get_rpc.last_name : "",
                        company_name: get_rpc ? get_rpc.company_name : "",
                        city: get_rpc ? get_rpc.city : "",
                        mobile_no: get_rpc ? get_rpc.mobile_no : "",
                        state: get_rpc ? get_rpc.state : "",
                        country: get_rpc ? get_rpc.country : "",
                        zip_code: get_rpc ? get_rpc.zip_code : "",
                        address1: get_rpc ? get_rpc.address1 : "",
                        address2: get_rpc ? get_rpc.address2 : "",
                        email: get_rpc ? get_rpc.email : "",
                        status: get_rpc ? get_rpc.status : true,
                        company_logo: get_rpc ? get_rpc.company_logo : ""
                    };
                    setRpcManager({ managerData: rpcManagerData, userModules: [...result.usermodules.module] });
                    Get_State({ country: get_rpc.country }, result => {
                        if (result?.data?.states) {
                            setStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                        }
                    }, dispatch)
                }
            }, dispatch)
        }

        listModules({ page: 1, sort: "createdAt", order: 1 }, (data) => {
            if (Array.isArray(data.docs)) {
                const allModules = [...data.docs];
                allModules.push({
                    name: "Application Management",
                    childrens: []
                })
                setModules(allModules)
            }
        }, dispatch);
    }, []);

    

        useEffect(() => {
            Get_State({ country: "United States" }, result => {
                if (result.data) {
                    const citiesDropDwownData = result?.data?.states?.map((item) => ({ value: item.name, label: item.name }));
                    setStates(citiesDropDwownData);
                }
            }, dispatch)
          }, []);

    useEffect(() => {
        setSelectAll(isAllModulesSelected(rpcManager.userModules, modules))
    }, [modules, rpcManager])

    /**
     * @description Function is for add Rpc.
     * @param {object} data 
     */
    const _submitData = (data) => {
        let moduleArray = [...rpcManager.userModules]
        let form = new FormData();
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        if (moduleArray) {
            for (let i = 0; i < moduleArray.length; i++) {
                form.append("module[]", moduleArray[i]);
            }
        }

        // let payload = {
        //     ...form,
        //     module: [...rpcManager.userModules]
        // }
        try {
            addRpc(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/list-rpc")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update Owner with building.
     * @param {object} data 
     */
    const _updateData = async (data) => {
        let moduleArray = [...rpcManager.userModules]
        let form = new FormData();
        form.append('_id', data._id)
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        if (moduleArray) {
            //form.append('module', JSON.stringify(moduleArray));

            for (let i = 0; i < moduleArray.length; i++) {
                form.append("module[]", moduleArray[i]);
            }
        }
        // let payload = {
        //     ...form,
        //     module: [...rpcManager.userModules]
        // }
        try {
            updateRpc(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/list-rpc")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.canceledRpcForm, options);
        if (result) {
            navigate("/list-rpc");
        }
    };

    const handleChangeCheck = (name, child) => {
        if (!child) {
            const permissiosAllowed = [name];

            const selectedModuleIndex = modules.findIndex(e => e.name === name);
            if (modules[selectedModuleIndex].childrens.length > 0) {
                modules[selectedModuleIndex].childrens.forEach(element => {
                    permissiosAllowed.push(element.name)
                });
            }
            const rpcManagerState = { ...rpcManager };
            if (rpcManagerState.userModules.includes(name)) {
                const upadtedModules = rpcManagerState.userModules.filter(e => !permissiosAllowed.includes(e));
                rpcManagerState.userModules = [...upadtedModules];
            } else { rpcManagerState.userModules.push(...permissiosAllowed); }
            setRpcManager(rpcManagerState);
        } else {
            const permissiosAllowed = [name, child];
            const rpcManagerState = { ...rpcManager };
            if (rpcManagerState.userModules.includes(name) && rpcManagerState.userModules.includes(child)) {
                const upadtedModules = rpcManagerState.userModules.filter(e => e !== child);
                rpcManagerState.userModules = [...upadtedModules];
            }
            else if (rpcManagerState.userModules.includes(name)) { rpcManagerState.userModules.push(child) }
            else { rpcManagerState.userModules.push(...permissiosAllowed); }
            setRpcManager(rpcManagerState);
        }

    }


    const handleSelectUnSelectAll = () => {
        const rpcManagerState = { ...rpcManager };
        const newPermissions = [];

        if (selectAll) {
            rpcManagerState.userModules = []
        } else {
            modules.forEach(e => {
                newPermissions.push(e.name);
                if (e.childrens.length > 0) {
                    const childPermissions = e.childrens.map(e => e.name);
                    newPermissions.push(...childPermissions)
                }
            })
            newPermissions.push("Application Management")
            rpcManagerState.userModules = [...newPermissions];
        }
        setRpcManager(rpcManagerState);
        setSelectAll(!selectAll);

    }

    const isAllModulesSelected = (selectedModules = [], availableModules = []) => {
        const newPermissions = [];
        availableModules.forEach(e => {
            newPermissions.push(e.name);
            if (e.childrens.length > 0) {
                const childPermissions = e.childrens.map(e => e.name);
                newPermissions.push(...childPermissions)
            }
        })
        newPermissions.push("Application Management")
        return selectedModules.length === newPermissions.length;
    }

    return (
        <div className="dashboard-inner p-b-0">
        <Breadcrumb/>
            <Formik
                initialValues={rpcManager.managerData}
                enableReinitialize
                validationSchema={rpcSchema}
                onSubmit={(values) => rpc_id.id ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">{rpc_id.id ? "Edit RPC Manager" : "Add RPC Manager"}</h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="First Name"
                                                placeholder="First Name"
                                                name="first_name"
                                                value={formik?.values?.first_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Last Name"
                                                placeholder="Last Name"
                                                name="last_name"
                                                value={formik?.values?.last_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Email Address"
                                                placeholder="Email Address"
                                                name="email"
                                                value={formik?.values?.email}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Phone"
                                                placeholder="Mobile Number"
                                                name="mobile_no"
                                                value={formik?.values?.mobile_no}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Country"
                                                placeholder="Select Country"
                                                name="country"
                                                value={formik?.values?.country}
                                                onChange={formik.handleChange}
                                                changeHandle={(val) => {
                                                    Get_State({ country: val }, result => {
                                                        if (result.data) {
                                                            const citiesDropDwownData = result?.data?.states?.map((item) => ({ value: item.name, label: item.name }));
                                                            setStates(citiesDropDwownData);
                                                        }
                                                    }, dispatch)
                                                }}
                                                required={true}
                                                options={resCountries}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="State"
                                                placeholder="Select States"
                                                name="state"
                                                value={formik?.values?.state}
                                                onChange={formik.handleChange}
                                                required={true}
                                                options={states}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Address Line 1"
                                                name="address1"
                                                placeholder="Address Line 1"
                                                value={formik?.values?.address1}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Address Line 2"
                                                name="address2"
                                                placeholder="Address Line 2"
                                                value={formik?.values?.address2}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="City"
                                                name="city"
                                                placeholder="City"
                                                value={formik?.values?.city}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Zip Code"
                                                placeholder="Zip Code"
                                                name="zip_code"
                                                value={formik?.values?.zip_code}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Company Name"
                                                placeholder="Company Name"
                                                name="company_name"
                                                value={formik?.values?.company_name}
                                                onChange={formik.handleChange}
                                                
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Status"
                                                name="status"
                                                options={_status}
                                                value={formik?.values?.status}
                                                onChange={formik.handleChange}
                                               
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <UploadLogoField
                                                label="Upload Logo"
                                                name="company_logo"
                                                id="file002"
                                                profileDetails={formik?.values?.company_logo}
                                                isrequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">Roles and Permission </h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-25">
                                            <div className="checkbox-row">
                                                <div className="checkbox-wrap">
                                                    <label className="checkbox" htmlFor={`selectAlloption`} onClick={handleSelectUnSelectAll}>
                                                        <input type="checkbox"
                                                            checked={selectAll}
                                                        />
                                                        <span className="main-head">Select All</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        {modules && modules.map((item, inx) => {
                                            return (
                                                <div className="f-25">
                                                    <div className="checkbox-row">
                                                        <div className="checkbox-wrap">
                                                            <label className="checkbox" >
                                                                <input type="checkbox"
                                                                    checked={rpcManager.userModules.includes(item.name)}
                                                                    onChange={(e) => handleChangeCheck(item.name)}
                                                                    id={`option0002${inx}`}
                                                                />
                                                                <span className="sub-head">{item.name}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="checkbox-row">
                                                        <div className="checkbox-wrap">
                                                            {item.childrens && item.childrens.map(child => {
                                                                return (<label className="checkbox"  >
                                                                    <input type="checkbox"
                                                                        checked={rpcManager.userModules.includes(child.name)}
                                                                        onChange={(e) => handleChangeCheck(item.name, child.name)}
                                                                        id={`option0005${inx}`}
                                                                    />
                                                                    <span>{child.name}</span>
                                                                </label>)
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{rpc_id.id ? "Edit RPC Manager" : "Add RPC Manager"}</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" onClick={onCancel} type="button">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
};

export default CreateRPC;
