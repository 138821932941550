import React from 'react'
import { Link } from 'react-router-dom'

const UnauthorisedAccess = () => {
  return (
    <div className='page-not-found'>
      <div className="card">
        <h3><span>403</span></h3>
        <h2>Unauthorised Access</h2>
        <Link to={{ pathname: `/dashboard` }}><button className='btn btn-primary'>Go To Home</button></Link>
      </div>
    </div>
  )
}

export default UnauthorisedAccess