import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';

const EmailBidderView = () => {
    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Email Bidders</h4>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Project Details</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="f-row">
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Project Name</h6>
                                <p className="desc-info-detail">Amet minim mollit</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Date</h6>
                                <p className="desc-info-detail">14/05/2022</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Roofing Area</h6>
                                <p className="desc-info-detail">5000 Square Feet</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Price</h6>
                                <p className="desc-info-detail">$5999 USD</p>
                            </div>
                        </div>
                    </div>
                    <div className="desc-info">
                        <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
                    </div>
                </div>
            </div>
            <div className='d-card m-b-25'>
                <div className='d-card-body p-b-0'>
                    <div className="form-content">
                        <div className='f-row'>
                            <div className='f-100'>
                                <label className='f-label'>Compose  Email</label>
                                <div className='f-field'>
                                    <textarea className='f-area'></textarea>
                                </div>
                            </div>
                        </div>
                        <div className='f-row'>
                            <div className='f-33'>
                                <label className='f-label'>Attach Documents</label>
                                <div className='f-field'>
                                    <div className='draggable-wrap'>
                                        <label className='draggable-box' htmlFor='file001'>
                                            <input type="file" id='file001' />
                                            <span className='file-icon'>
                                                <img src={Icons.file} alt="" />
                                            </span>
                                            <h4>Drag file(s) here to upload</h4>
                                            <p>Alternatively, you can select file by</p>
                                            <h6>Clicking here.</h6>
                                        </label>
                                    </div>
                                </div>
                            </div></div>
                    </div>
                </div>
            </div>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Email Bidders</h5>
                    <div className='d-card-actions'>
                        <button className="btn btn-blue">Select All</button>
                    </div>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className='bidders-list-wrap'>
                        <ul className="contractor-list">
                            <li>
                                <div className="contractor-check">
                                    <label className='checkbox nolabel radio-checkbox' htmlFor="bidder01">
                                        <input type="checkbox" id="bidder01" />
                                        <span></span>
                                    </label>
                                </div>
                                <div className="contractor-details">
                                    <div className='bid-details'>
                                        <span className='bid-icon'>
                                            <img src={Icons.Owner} alt='' />
                                        </span>
                                        <h4>Ronald Richards</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="contractor-check">
                                    <label className='checkbox nolabel radio-checkbox' htmlFor="bidder02">
                                        <input type="checkbox" id="bidder02" />
                                        <span></span>
                                    </label>
                                </div>
                                <div className="contractor-details">
                                    <div className='bid-details'>
                                        <span className='bid-icon'>
                                            <img src={Icons.Owner} alt='' />
                                        </span>
                                        <h4>Ronald Richards</h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-card-bottom">
                <div className="d-card-bottom-cell">
                    <button className="btn btn-blue" type="submit">Send</button>
                </div>
                <div className="d-card-bottom-cell">
                    <button className="btn btn-grey" type="button" >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default EmailBidderView

