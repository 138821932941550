import React from "react";
import Icons from "../../../assets/images";
import { API_BASEURL } from "../../../environment";
import ProjectDescription from "./ProjectDescription";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import { contRoofPhotos } from "../services/contractor";
import NoDataFound from "../../../components/common/NoDataFound";

const RoofPhotos = () => {
    const [projectData, setProjectData] = useState([]);
    let project_id = useParams();
    let type;

    useEffect(() => {
        contRoofPhotos(project_id,type="images",(data) => {
           
            let newData = JSON.parse(JSON.stringify(data))
          setProjectData(newData);
         
        })
        
      }, [])
    
    return (
        
        <>
        <div className='dashboard-inner'>
       
        <ContractorProjectDescription/>
        </div>
            {
                projectData?.length > 0 ?
                    <>
                        <h1 className="d-card-heading d-card-sub-heading"> <h2>Roof Photos </h2></h1>
                        <ul className="sample-gallery">
                            {projectData?.map((data, inx) => {
                            
                                return (
                                    data?.type === "images" &&
                                    <li key={inx}>
                                        <span>
                                            <img src={`${API_BASEURL}/${data?.path}` || (Icons.projectBigImg)} alt='' />
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                        
                    </>
                    :
                    <h5 className="d-flex align-items-center justify-content-center"><div><NoDataFound/></div></h5>
            }
        </>
    );
}
export default RoofPhotos;