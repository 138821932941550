import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import RvalueList from '../RvalueList';
import RvalueView from '../RvalueView';
import RvalueForm from '../RvalueForm'

const RvalueRoutes = [
    {
        path: `/rvalue-list`,
        layout: DefaultLayout,
        component: <  RvalueList />
    },
    {
        path: `/rvalue-form`,
        layout: DefaultLayout,
        component: <  RvalueForm />
    },
    {
        path: `/rvalue-view/:id`,
        layout: DefaultLayout,
        component: <  RvalueView />
    }
    
    
]

export default RvalueRoutes ;