import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Icons from '../../assets/images/index'
import { API_BASEURL } from '../../environment';
import { list_Projects } from './services/owner';
import _ from 'underscore';
import moment from 'moment';
import { Chart } from "react-google-charts";

const OwnerDashboard = () => {

    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();

    const [openProjects, setOpenProjects] = useState();
    const [closeProjects, setCloseProjects] = useState();

    const data = [
        ["Projects", "Total", "Closed", "Open",],
        ["Status", resPayload?.totalDocs, closeProjects?.totalDocs,openProjects?.totalDocs]
    ];
    console.log(resPayload)
  
    useEffect(() => {
        list_Projects({}, (data) => { setResPayload(data) }, dispatch);
        list_Projects({ fields: "project_status", filter: "open" }, (data) => { setOpenProjects(data) }, dispatch);
        list_Projects({ fields: "project_status", filter: "close" }, (data) => { setCloseProjects(data) }, dispatch);
    }, [dispatch]);

    return (
        <div className="dashboard-cards-row">
            <div className="d-card-col-68">
                <div className='d-card'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>New Projects</h5>
                        <div className='d-card-actions'>
                            <Link to="/" className='btn btn-transparent'>View All</Link>
                        </div>
                    </div>
                    <div className='d-card-body'>
                        {resPayload?.docs?.map((item, inx) => (
                            <ul className='projects-list owners-projects-list'>
                                <li>
                                    <div className='project'>
                                        <span className='project-img'>
                                            <img src={`${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` || (Icons.projectBigImg)} alt="" />
                                        </span>
                                        <div className='project-details'>
                                            <div className='project-header'>
                                                <div className='project-header-left'>
                                                    <h5 className='project-name'>{item.project_title}</h5>
                                                </div>
                                                <div className='project-header-right'>
                                                    <span className='project-date'>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                            <div className='project-body'>
                                                <p>{item.notes}</p>
                                                <div className='project-sub-details r3'>
                                                    <div className='project-sub-left project-sub-cell'>
                                                        <h5 className="p-wallet">{item.square_footage} <span><sup>S</sup>/<sub>F</sub></span></h5>
                                                    </div>
                                                    <div className="project-sub-cell">
                                                        <p className='project-location'>{item?.buildingId?.country} ,{item?.buildingId?.state}</p>
                                                    </div>
                                                    <div className='project-sub-right project-sub-cell'>
                                                    {/* Start/smartdata/staticData*/}
                                                    
                                                        <h5 className="p-dollar">254 USD</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
            <div className='d-card-col-32'>
                <ul className='dashboard-tiles dashboard-tiles-full'>
                    <li>
                        <Link to="/" className='d-tile light-blue'>
                            <div className='d-tile-header'>
                                <div className='d-t-left'>
                                    <h4>{resPayload?.totalDocs}</h4>
                                    <p>Current Projects</p>
                                </div>
                                <div className='d-t-right'>
                                    <span className='tile-icon'>
                                        <img src={Icons.rocket} alt='' />
                                    </span>
                                </div>
                            </div>
                            <div className='d-tile-footer'>
                                <h5>100 <span>YTD Projects</span></h5>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link to="/" className='d-tile light-red'>
                            <div className='d-tile-header'>
                                <div className='d-t-left'>
                                    <h4>50</h4>

                                    {/* END/smartdata/staticData*/}

                                    <p>Active New Bids</p>
                                </div>
                                <div className='d-t-right'>
                                    <span className='tile-icon'>
                                        <img src={Icons.hammer} alt='' />
                                    </span>
                                </div>
                            </div>
                        </Link>
                    </li>
                </ul>
                <div className='d-card m-b-30'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>Projects</h5>
                        {/* <div className='d-card-actions'>
                            <div className='grey-dropdown'>
                                <select>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                    <option>California</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                    <div className='d-card-body'>
                        <div className='graph'>
                            <Chart
                                chartType="Bar"
                                width="100%"
                                height="400px"
                                data={data}
                                legendToggle
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OwnerDashboard;