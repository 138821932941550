import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { Link, useParams } from "react-router-dom";
import { listContractors, listBids } from "../../services/contractor";
import { useDispatch, useSelector } from "react-redux";
import _ from 'underscore';
import ReactPaginate from 'react-paginate';
import SelectBox from "../../components/common/formInput/SelectBox";
import { pagination, PaginationOption } from "../../components/common/utils/message";
import { API_BASEURL } from '../../environment';

const PrivilegeView = () => {

    const dispatch = useDispatch();
    const constID = useParams()
    const contractor_id = constID.id;

    const [resPayload, setResPayload] = useState();
    const [contractorRes, setContractorRes] = useState();
    const [searchKey, setSearchKey] = useState("");

    const totalPages = contractorRes && contractorRes.totalPages;
    const [perPage, setPerPage] = useState(pagination.limit);

    useEffect(() => {
        listContractors({ page: pagination.page }, (data) => { setResPayload(data) }, dispatch);
        listBids(contractor_id, (data) => {
            setContractorRes(data)
        }, dispatch);
    }, [dispatch]);

    /**
   * @description Function for show in dropdown entries with page option.
   * @param {param} displayPerPage 
   */
    const setPagination = async (displayPerPage) => {
        // setPerPage(displayPerPage);
        // await listBuildings({ limit: displayPerPage }, response => {
        //     setBuildingRes(response);
        // }, dispatch)
    }

    /**
   * @description /Function is on Page Change.
   * @param {param} param 
   */
    const handlePageChange = async (param) => {
        // let currentPage = param.selected + 1;
        // await listBuildings({ page: currentPage }, response => {
        //     setBuildingRes(response);
        // }, dispatch)
    };

    const general = useSelector((state) => state.general)
    const get_owner = resPayload && resPayload.docs.find((item) => item._id === general.param)

    return (
        <div className='dashboard-inner'>
            <div className='d-h-wrap'>
                <div className='d-h-l'>
                    <div className="custom-seachbox">
                        <input type="search" placeholder="Search" />
                        <button className="btn btn-primary btn-search">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='d-h-r'>
                    <ul className='dashboard-sub-filters'>
                        <li>
                            <div className='sortby'>
                                <span>State : </span>
                                <div className='nocolor-dropdown'>
                                    <select>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='sortby'>
                                <span>Sort by : </span>
                                <div className='nocolor-dropdown'>
                                    <select>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <h4 className='dashboard-heading p-b-15'>Viewing Privileges</h4>
            <div className="owner">
                <div className="owner-left">
                    <span className="owner-img">
                        <img src={Icons.Owner} alt="" />
                    </span>
                    <h4>Ronald Richards</h4>
                    <p>ronald.richards@example.com</p>
                </div>
                <div className="owner-right text-right">
                    <div className='inline-actions'>
                        <h5 className="m-r-15">Location: California</h5>
                        <label htmlFor="001" className='toggle-switch'>
                            <input type="checkbox" id="001"
                                name={`status`}
                                value=""
                                defaultChecked="checked" />
                            <h6 className='deactivate'>Active</h6>
                            <h6 className='activate'>Inactive</h6>
                            <span className='toggle-bar'></span>
                        </label>
                    </div>
                </div>
            </div>
            <div className="d-card m-b-25">
                <div className="d-card-header ">
                    <h5 className="d-card-heading sm-heading p-b-0">Roles and Permission </h5>
                </div>
                <div className="d-card-body p-b-0">
                    <div className="form-content">
                        <div className="f-row">
                            <div className="f-25">
                                <div className="checkbox-row">
                                    <div className="checkbox-wrap">
                                        <label className="checkbox" htmlFor="option0001" >
                                            <input type="checkbox" id="option0001" />
                                            <span>Non deserunt ullamco est sit aliqu</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="f-25">
                                <div className="checkbox-row">
                                    <div className="checkbox-wrap">
                                        <label className="checkbox" htmlFor="option0002" >
                                            <input type="checkbox" id="option0002" />
                                            <span>Non deserunt ullamco est sit aliqu</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="f-25">
                                <div className="checkbox-row">
                                    <div className="checkbox-wrap">
                                        <label className="checkbox" htmlFor="option0003" >
                                            <input type="checkbox" id="option0003" />
                                            <span>Deserunt ullamco est sit aliqu</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="f-25">
                                <div className="checkbox-row">
                                    <div className="checkbox-wrap">
                                        <label className="checkbox" htmlFor="option0004" >
                                            <input type="checkbox" id="option0004" />
                                            <span>Amet minim mollit</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="f-25">
                                <div className="checkbox-row">
                                    <div className="checkbox-wrap">
                                        <label className="checkbox" htmlFor="option0005" >
                                            <input type="checkbox" id="option0005" />
                                            <span>Deserunt ullamco est sit aliqu</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="f-25">
                                <div className="checkbox-row">
                                    <div className="checkbox-wrap">
                                        <label className="checkbox" htmlFor="option0006" >
                                            <input type="checkbox" id="option0006" />
                                            <span>Consequat duis enim velit mollit</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default PrivilegeView;
