import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
    return (
        <div className='page-not-found'>
            <div className="card">
                <h3><span>404</span> Page Not Found</h3>
                <h2><span>Oops!!!</span> You are looking for something that doesn't actually exist.</h2>
                <Link to={{ pathname: `/dashboard` }}><button className='btn btn-primary'>Go To Home</button></Link>
            </div>
        </div>
    )
}

export default PageNotFound