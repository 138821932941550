import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import PaymentList from '../PaymentList';

const PaymentsRoutes = [
    {
        path: `/payment-list`,
        layout: DefaultLayout,
        component: <  PaymentList />
    }
]

export default PaymentsRoutes ;