import React from 'react';
import { useSelector } from "react-redux";
import ContractorInterfaceLayout from '../../../components/common/layout/ContractorInterfaceLayout';
import AboutUs from '../common/AboutUs';
import ContractorDashboard from '../ContractorDashboard';
import ContractorFaq from '../common/Faq';
import ContractorProfileEdit from '../common/ProfileEdit';
import ContractorProfileView from '../common/ProfileView';
import ContractorProjects from '../ContractorProjects';
import ContractorProjectView from '../ContractorProjectView';
import ContractorTermsAndConditions from '../common/ContractorTermsAndConditions';
import Maps from '../common/Map';
import PurchasedOppourtunity from '../common/PurchasedOppoutunity';
import ClosedOppourtunity from '../common/ClosedOppourtunity';
import WonBids from '../common/WonBids';
import BidForm from '../common/BidForm';
import Settings from '../common/Setting';
import Notification from '../common/Notifications';
import Invitations from '../common/Invitations';
import Payment from '../common/Payment';
import MyNotification from '../component/MyNotification';
import ProjectDescription from '../component/ProjectDescription';
import RoofPhotos from '../component/RoofPhotos';
import EstimatorAssistance from '../component/EstimatorAssistance';
import ProjectNotes from '../component/ProjectNote';
import DrawingDetails from '../component/DrawingDetails';
import Specifications from '../component/Specifications';
import Contract from '../component/Contract';
import Insurance from '../component/Insurance';
import QuestionAns from '../component/QuestionAns';
import BidSubmit from '../component/BidSubmit';
import RoofDrawings from '../component/RoofDrawings';
import ViewChange from '../component/ViewChangeBid'
import ViewChangeBid from '../component/ViewChangeBid';
import SucessPage from '../common/Sucess';
import CancelPage from '../common/CancelPage';



const ContractorInterfaceRoute = [
    {
        path: `/about-us`,
        layout: ContractorInterfaceLayout,
        component: <  AboutUs />
    },
    {
        path: `/contractor/dashboard`,
        layout: ContractorInterfaceLayout,
        component: <  ContractorDashboard />
    },
    {
        path: `/contractor-faq`,
        layout: ContractorInterfaceLayout,
        component: <  ContractorFaq />
    },
    {
        path: `/contractor-projects`,
        layout: ContractorInterfaceLayout,
        component: <  ContractorProjects />
    },
    {
        path: `/contractor-terms-conditions`,
        layout: ContractorInterfaceLayout,
        component: < ContractorTermsAndConditions />
    },
    {
        path: `/contractor-profile`,
        layout: ContractorInterfaceLayout,
        component: <  ContractorProfileView />
    },
    {
        path: `/contractor-profile-edit`,
        layout: ContractorInterfaceLayout,
        component: <  ContractorProfileEdit />
    },
    {
        path: `/contractor-project-view/:id`,
        layout: ContractorInterfaceLayout,
        component: <  ContractorProjectView />
    },
    {
        path: `/contractor-map`,
        layout: ContractorInterfaceLayout,
        component: <  Maps />
    },
    {
        path: `/contractor/sucess/payment`,
        layout: ContractorInterfaceLayout,
        component: <  SucessPage />
    },
    {
        path: `/contractor/failed/payment`,
        layout: ContractorInterfaceLayout,
        component: <  CancelPage />
    },
    {
        path: `/contractor-purchased-oppourtunity`,
        layout: ContractorInterfaceLayout,
        component: <PurchasedOppourtunity />
    },
    {
        path: `/contractor-closed-oppourtunity`,
        layout: ContractorInterfaceLayout,
        component: <ClosedOppourtunity />

    },
    {
        path: `/contractor-won-bids`,
        layout: ContractorInterfaceLayout,
        component: <WonBids />
    },
    {
        path: `/contractor-bid-form`,
        layout: ContractorInterfaceLayout,
        component: <BidForm />
    },
    {
        path: `/contractor-settings`,
        layout: ContractorInterfaceLayout,
        component: <Settings />
    },
    {
        path: `/contractor-notifications`,
        layout: ContractorInterfaceLayout,
        component: <Notification />
    },
    {
        path: `/contractor-notification`,
        layout: ContractorInterfaceLayout,
        component: <MyNotification />
    },
    {
        path: `/contractor-invitations`,
        layout: ContractorInterfaceLayout,
        component: <Invitations />
    },
    {
        path: `/contractor-payment`,
        layout: ContractorInterfaceLayout,
        component: <Payment />
    },
    {
        path: `/project/description/:id`,
        layout: ContractorInterfaceLayout,
        component: <ProjectDescription />
    },
    {
        path: `/Roof/photos/:id`,
        layout: ContractorInterfaceLayout,
        component: <RoofPhotos />
    },
    {
        path: `/Roof/Drawings/:id`,
        layout: ContractorInterfaceLayout,
        component: <RoofDrawings />
    },
    {
        path: `/Estimator/Assistance/:id`,
        layout: ContractorInterfaceLayout,
        component: <EstimatorAssistance />
    },
    {
        path: `/Project/Notes/:id`,
        layout: ContractorInterfaceLayout,
        component: <ProjectNotes />
    },
    {
        path: `/Drawing/Details/:id`,
        layout: ContractorInterfaceLayout,
        component: <DrawingDetails />
    },
    {
        path: `/specifications/:id`,
        layout: ContractorInterfaceLayout,
        component: <Specifications />
    },
    {
        path: `/contract/:id`,
        layout: ContractorInterfaceLayout,
        component: <Contract />
    },
    {
        path: `/insurance/:id`,
        layout: ContractorInterfaceLayout,
        component: <Insurance />
    },
    {
        path: `/Question/Answer/:id`,
        layout: ContractorInterfaceLayout,
        component: <QuestionAns />
    },

    {
        path: `/Bid/Submit/:id`,
        layout: ContractorInterfaceLayout,
        component: <BidSubmit />
    },

  {
        path: `/View/Change/Bid/:id`,
        layout: ContractorInterfaceLayout,
        component: <ViewChangeBid />
    }
    


]

export default ContractorInterfaceRoute;