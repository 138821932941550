import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import BidForm from '../BidForm';
import BidList from '../BidList';

const BidRoutes = [
    {
        path: `/list-bid`,
        layout: DefaultLayout,
        component: < BidList />
    },
    {
        path: `/bid-form`,
        layout: DefaultLayout,
        component: < BidForm />
    }
]

export default BidRoutes;