import React from 'react';
import OwnerInterfaceLayout from '../../../components/common/layout/OwnerInterfaceLayout';
import AboutUs from '../common/AboutUs';
import OwnerDashboard from '../OwnerDashboard';
import OwnerFaq from '../common/Faq';
import OwnerProfileEdit from '../common/ProfileEdit';
import OwnerProfileView from '../common/ProfileView';
import OwnerProjects from '../OwnerProjects';
import OwnerProjectView from '../OwnerProjectView';
import TermsAndConditions from '../common/TermsAndConditions';
import OwnerWarranty from '../OwnerWarranty';

const OwnerInterfaceRoute = [
    {
        path: `/about-us`,
        layout: OwnerInterfaceLayout,
        component: <  AboutUs />
    },
    {
        path: `/owner-dashboard`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerDashboard />
    },
    {
        path: `/owner-faq`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerFaq />
    },
    {
        path: `/owner-projects`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerProjects />
    },
    {
        path: `/terms-and-conditions`,
        layout: OwnerInterfaceLayout,
        component: <  TermsAndConditions />
    },
    {
        path: `/owner-warranty`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerWarranty />
    },
    {
        path: `/owner-profile`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerProfileView />
    },
    {
        path: `/owner-profile-edit`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerProfileEdit />
    },
    {
        path: `/owner-project-view`,
        layout: OwnerInterfaceLayout,
        component: <  OwnerProjectView />
    }
]

export default OwnerInterfaceRoute;