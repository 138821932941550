import React, { useEffect, useState } from 'react';
import Icons from '../../assets/images/index';
import { useDispatch } from "react-redux";
import { listTerms, deleteTerm } from '../../services/terms.service';
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { success } from '../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";
import TermForm from './TermForm';
import ReactPaginate from 'react-paginate';
import SelectBox from '../../components/common/formInput/SelectBox';

const TermList = () => {

    const [modalShow, setModalShow] = useState(false);

    const dispatch = useDispatch();
    const [resPayload, setResPayload] = useState();
    const [editState, setEditState] = useState({});

    const totalPages = resPayload && resPayload.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)
    const handleClose = () => { _getListing(); setEditState({}); setModalShow(false) }
    const handleShow = (data) => { setEditState(data); setModalShow(true) };

    useEffect(() => {
        _getListing();
    }, [dispatch]);


    const _getListing = () => {
        listTerms({ page, limit }, (data) => {
            setResPayload(data)
        }, dispatch)
    }


    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            deleteTerm(id, async res => {
                if (res.status === "SUCCESS" && res.code === 200) {
                    _getListing();
                    success(res.message);
                    await alert(message.ContentManagementDeleted);
                }
            })
        }
    }


    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listTerms({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };


    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listTerms({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }

    return (
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Terms Management</h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <button className="btn btn-blue" onClick={() => { setModalShow(true) }}>Add New Term</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Terms</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <ul className='faq-list'>
                        {resPayload && resPayload.docs
                            .map((item, index) => (
                                <li>
                                    <div className='faq-item'>
                                        <h4>{index + 1}. {item.title}</h4>
                                        <p dangerouslySetInnerHTML={{ __html: item.detail }} />
                                        <div className='quick-actions'>
                                            <span className={item.status === true ? "text-success" : "text-danger"}>{item.status === true ? "Active" : "Inactive"}</span>
                                            <button className="dashboard-action" title="Edit" onClick={(e) => handleShow(item)}>
                                                <img src={Icons.edit} alt="" />
                                            </button>
                                            <button className="dashboard-action" title="Delete" onClick={() => _handleDelete(item._id)}>
                                                <img src={Icons.bin} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            <div className="table-filters">
                <div className="t-f-cell">
                    <div className="records-filter">
                        <span>Show</span>
                        <div className="grey-dropdown bg-white">
                            <SelectBox
                                options={PaginationOption}
                                val={limit}
                                handleVal={(e) => setPagination(e)}
                            />
                        </div>
                        <span>Entries</span>
                    </div>
                </div>
                {totalPages > 1 ? (
                    <div className="t-f-cell">
                        <ReactPaginate
                            breakLabel={"..."}
                            nextLabel={'Next >'}
                            onPageChange={handlePageChange}
                            pageCount={totalPages}
                            previousLabel={'< Back'}
                            renderOnZeroPageCount={null}
                            containerClassName={'custom-pagination'}
                            pageClassName={'btn-page'}
                            pageLinkClassName={'btn-page'}
                            previousClassName={'btn-page'}
                            previousLinkClassName={'btn-previous'}
                            nextClassName={'btn-page'}
                            nextLinkClassName={'btn-next'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}

            </div>
            {editState && <TermForm
                show={modalShow}
                handleClose={handleClose}
                formState={editState}
            />}
        </div>
    )
}

export default TermList

