import React from "react";
import { forgotSchema } from "../../components/common/validations/Validation";
import { Formik, Form } from "formik";
import TextInput from "./common/TextInput";
import AuthService from "../../services/auth.service";
import { success, failed } from "../../components/common/Toastify";
import { useNavigate } from "react-router-dom";


const ForgotPassword = () => {
  const navigate = useNavigate();
  
  const forgotPasshandler = async (params) => {
    try {
      const resetResponse = await AuthService.forgotPassword(params);
      if (resetResponse.status === "SUCCESS" && resetResponse.code === 200) {
        success(resetResponse.message);
        navigate("/login");
      } else {
        failed(resetResponse.message);
      }
    } catch (e) {
      failed(e);
    }
  };

  return (

    <div className="login-left-wrapper">
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={forgotSchema}
        onSubmit={(values) => {
          forgotPasshandler(values);
        }}
      >
        <Form>
          <div className="login-box">
            <h4 className="form-heading">Forgot Password</h4>
            <p className="form-desc">
              Enter your email to acces your account.
            </p>
            <TextInput
              label="Email"
              name="email"
              type="email"
              required={true}
            />
            <div className="form-row">
              <div className="w100">
                <div className="form-action">
                  <button className="btn-square btn-blue" type="submit">
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ForgotPassword;
