
//For local setup
const local = {
  apiUrl: "http://localhost:",
  // encryptionkey: "",
  // PORT: '9121/api/v1',
  // API_BASEURL: "http://localhost:9121",
  // SOCKET_URL: "ws://localhost:9121"
};

// For Staging server
const staging = {
  // apiUrl: "http://54.190.192.105:",
  // encryptionkey: "",
  // PORT: '9121/api/v1',
  // API_BASEURL: "http://54.190.192.105:9121",
  // SOCKET_URL: "ws://54.190.192.105:9121"
};

//For Client Server
const dev = {
  apiUrl: "https://roofingprojects.bid:",
  encryptionkey: "",
  PORT: '30004/api/v1',
  API_BASEURL: "https://roofingprojects.bid:30004/",
  SOCKET_URL: "wss://roofingprojects.bid:30004"

};

console.log("process.env.REACT_APP_ENV :", process.env.REACT_APP_ENV);

if (process.env.REACT_APP_ENV === "local") module.exports = local;
else if (process.env.REACT_APP_ENV === "dev") module.exports = dev;
else if (process.env.REACT_APP_ENV === "staging") module.exports = staging;
 // else module.exports = staging;
//  else module.exports = dev;
// else module.exports = local;

else module.exports = dev;

