import axios from "axios";
import { apiUrl, PORT } from "../environment";
import { loadingAction } from "../components/common/loader/LoaderSlice";

const login = (data) => {
  return axios.post(`${apiUrl}${PORT}/login`, data).then((response) => {
    if (response.data.data) {
      localStorage.setItem("authentication", response.data.data.token);
    }
    return response.data;
  });
};

const logout = () => {
  localStorage.clear();
};


const forgotPassword = async (email) => {
  try {
    const response = await axios.post(`${apiUrl}${PORT}/forgetPassword`, email);
    return response.data;
  } catch (e) {
    return e;
  }

};

const resetPassword = (email) => {
  return axios
    .post(`${apiUrl}${PORT}/reset/password`, email)
    .then((response) => {
      return response.data;
    });
};

const verifyToken = (email, token) => {
  return axios
    .post(`${apiUrl}${PORT}/verify/token`, { email, token })
    .then((response) => {
      return response.data;
    });
};

/**
 * @description registration in db.
 * @param {*} data 
 * @param {*} callback 
 */

export const registration = (data, callback, dispatch) => {
  try {
    dispatch(loadingAction(true))
    return axios.post(`${apiUrl}${PORT}/signup`, data).then((response) => {
      dispatch(loadingAction(false))
      callback(response.data);
    })
      .catch((error) => {
        dispatch(loadingAction(false))
        callback(false);
      });
  }
  catch (err) {
    console.log(err);
  }
};

/**
 * @description contactUs in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const contact_us = (data, callback, dispatch) => {
  try {
    dispatch(loadingAction(true))
    return axios.post(`${apiUrl}${PORT}/contact-us`, data).then((response) => {
      dispatch(loadingAction(false))
      callback(response.data);
    })
      .catch((error) => {
        dispatch(loadingAction(false))
        callback(false);
      });
  }
  catch (err) {
    console.log(err);
  }
};




export const changePassword = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/user/password/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(true)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
* @description  owner's Profile view.
* @param {*} param 
* @param {*} callback 
*/
export const get_profile = async (callback, dispatch) => {
  try {
      let token = localStorage.getItem("authentication");
      if (token) {
          let option = {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          };
          dispatch(loadingAction(true))
          axios.get(`${apiUrl}${PORT}/user/profile/view`, option)
              .then((response) => {
                  dispatch(loadingAction(false))
                  callback(response.data.data);
              })
              .catch((error) => {
                  dispatch(loadingAction(false))
                  callback(false);
              });
      }
  } catch (err) {
      console.log(err);
  }
};


/**
* @description  owner's Profile view.
* @param {*} param 
* @param {*} callback 
*/
export const edit_profile = async (param, callback, dispatch) => {
  try {
      let token = localStorage.getItem("authentication");
      if (token) {
          let option = {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          };
          dispatch(loadingAction(true))
          axios.put(`${apiUrl}${PORT}/user/profile/update`, param, option)
              .then((response) => {
                  dispatch(loadingAction(false))
                  callback(response.data);
              })
              .catch((error) => {
                  dispatch(loadingAction(false))
                  callback(false);
              });
      }
  } catch (err) {
      console.log(err);
  }
};


const AuthService = {
  login,
  logout,
  forgotPassword,
  verifyToken,
  resetPassword,
  changePassword
};

export default AuthService;
