import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewOneBuilding } from "../../services/building";
import moment from 'moment';
import _ from 'underscore';
import { setParamFirst, setParamSecond } from "../../components/common/general/GeneralSlice";

const BuildingView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [buildingData, setBuildingData] = useState();

    let building_id = useParams();
    console.log( building_id,"abc>>>>>>>>>>>>>>>>>>>>" )
    const generalData = useSelector((state) => state.general)

    useEffect(() => {
        viewOneBuilding(building_id, (data) => { setBuildingData(data) }, dispatch)
    }, [dispatch]);
console.log('setBuildingData ==== 23 ', buildingData)
    return (
        <div className='dashboard-inner'>
            <div className='d-h-wrap'>
                <div className='d-h-l'>
                    <h4 className='dashboard-heading'>View Building</h4>
                </div>

            </div>
            <div className='accordians'>
                <div className='d-card m-b-25'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>Building Details</h5>
                    </div>
                    <div className='d-card-body p-b-0'>
                        <div className="f-row">
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Building Name</h6>
                                    <p className="desc-info-detail">{buildingData?.name}</p>
                                </div>
                            </div>
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Created Date</h6>
                                    <p className="desc-info-detail">{moment(buildingData?.createdAt).format('DD/MM/YYYY')}</p>
                                </div>
                            </div>
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Roofing Area</h6>
                                    <p className="desc-info-detail">{buildingData?.actual_size}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-h-wrap'>
                    <div className='d-h-l'>
                        <h4 className='d-card-heading'>Projects</h4>
                    </div>
                    <div className='d-h-r'>
                        <ul className='dashboard-sub-filters'>
                            {/* <li>
                                <button className="btn btn-blue" onClick={() => { navigate('create/project/form'); dispatch(setParamFirst(generalData.param)); dispatch(setParamSecond(generalData.param2)); }}>Add Projects</button>
                            </li> */}
                             <li>
                            <Link to={`/create/project/form/${ building_id.id}`} className="btn btn-blue">Add Project</Link>
                        </li>
                            <li>
                                <Link to="/building-list" className="btn btn-grey">Cancel</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='d-card m-b-25'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>Building Projects</h5>
                    </div>
                    <div className='d-card-body p-b-0'>
                        <div className='responsive-table'>
                            <table className='table-odd-even'>
                                <tbody>
                                    {buildingData?.projects?.map((item, inx) => (
                                        <tr key={inx}>
                                            <td>
                                                <div className='p-2'>
                                                    <p>Project Title</p>
                                                    <h4>{item?.project_title}</h4>
                                                </div>
                                            </td>
                                            <td>
                                                <button className="btn-edit" onClick={(e) => navigate(`/project-form?id=${item._id}`)}>
                                                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.8789 3.40479H3.87891C3.34847 3.40479 2.83977 3.6155 2.46469 3.99057C2.08962 4.36564 1.87891 4.87435 1.87891 5.40479V19.4048C1.87891 19.9352 2.08962 20.4439 2.46469 20.819C2.83977 21.1941 3.34847 21.4048 3.87891 21.4048H17.8789C18.4093 21.4048 18.918 21.1941 19.2931 20.819C19.6682 20.4439 19.8789 19.9352 19.8789 19.4048V12.4048" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M18.3789 1.90501C18.7767 1.50719 19.3163 1.28369 19.8789 1.28369C20.4415 1.28369 20.9811 1.50719 21.3789 1.90501C21.7767 2.30284 22.0002 2.8424 22.0002 3.40501C22.0002 3.96762 21.7767 4.50719 21.3789 4.90501L11.8789 14.405L7.87891 15.405L8.87891 11.405L18.3789 1.90501Z" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {buildingData?.projects?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Project found!</h5>) : null}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuildingView;
