import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Icons from "../../assets/images/index";

export const success = (msg) =>
    toast.success(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
export const failed = (msg) =>
    toast.error(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });

export const notify = (msg) =>
    toast.info(msg, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        icon: <img src={Icons.notification} alt="" />
    });
