import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icons from '../../assets/images/index'
import { pagination, PaginationOption } from '../../components/common/utils/message';
import { API_BASEURL } from '../../environment';
import { list_Projects } from './services/owner';
import ReactPaginate from 'react-paginate';
import _ from 'underscore';
import moment from 'moment';
import SelectBox from '../../components/common/formInput/SelectBox';
import { setParamFirst } from '../../components/common/general/GeneralSlice';

const OwnerProjects = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [openProjects, setOpenProjects] = useState();
    const [closeProjects, setCloseProjects] = useState();

    const totalPages = openProjects && openProjects.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    const [tab, setTab] = useState("openProjects");

    useEffect(() => {
        _listProjects();
    }, [dispatch]);

    /**
     * @description List of all owner's open & close projects .
     */
    const _listProjects = () => {
        list_Projects({ fields: "project_status", filter: "open" }, (data) => { setOpenProjects(data) }, dispatch);
        list_Projects({ fields: "project_status", filter: "close" }, (data) => { setCloseProjects(data) }, dispatch);
    }

    /**
    * @description /Function is on Page Change.
    * @param {param} param 
    */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await list_Projects({ page: currentPage, limit }, response => {
            setOpenProjects(response);
        }, dispatch)
    };

    /**
    * @description Function for show in dropdown entries with page option.
    * @param {param} displayPerPage 
    */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await list_Projects({ limit: displayPerPage, page }, response => {
            setOpenProjects(response);
        }, dispatch)
    }

    return (
        <>
            <div className="ds-header m-b-30">
                <div className="ds-cell">
                    <h4 className='dashboard-title'>Projects</h4>
                </div>
                <div className="ds-cell">
                    <ul className='projects-tabs'>
                        <li>
                            <button className={`tab-item ${tab === 'openProjects' ? 'active' : ''}`} onClick={() => setTab("openProjects")}>Open Projects</button>
                        </li>
                        <li>
                            <button className={`tab-item ${tab === 'closeProjects' ? 'active' : ''}`} onClick={() => setTab("closeProjects")}>Closed Projects</button>
                        </li>
                    </ul>
                </div>
            </div>
            {tab === 'openProjects' &&
                <ul className='projects-list'>
                    {openProjects?.docs?.map((item, ind) => (
                        <li key={ind}>
                            <div className='project'>
                                <span className='project-img'>
                                    <a onClick={() => { navigate('/owner-project-view'); dispatch(setParamFirst(item._id)) }}>
                                        <img src={item?.media.length > 0 ? `${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                                    </a>
                                </span>
                                <div className='project-details'>
                                    <div className='project-header'>
                                        <div className='project-header-left'>
                                            <h5 className='project-name'>{item.project_title}</h5>
                                        </div>
                                        <div className='project-header-right'>
                                            <span className='project-date'>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                    <div className='project-body'>
                                        <p>{item.notes}</p>
                                        <div className='project-sub-details r3'>
                                            <div className='project-sub-left project-sub-cell'>
                                                <h5 className="p-wallet">{item.square_footage} <span><sup>S</sup>/<sub>F</sub></span></h5>
                                            </div>
                                            <div className="project-sub-cell">
                                                <p className='project-location'>{item?.buildingId?.country} ,{item?.buildingId?.state}</p>
                                            </div>
                                            <div className='project-sub-right project-sub-cell'>
                                            {/*Smartdata/staticData*/}
                                                <h5 className="p-dollar">254 USD</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                    {openProjects?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Open Project found!</h5>) : null}
                </ul>
            }
            {tab === 'closeProjects' &&
                <ul className='projects-list'>
                    {closeProjects?.docs?.map((item, ind) => (
                        <li key={ind}>
                            <div className='project'>
                                <span className='project-img'>
                                    <a href='/owner-project-view'>
                                        <img src={item?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                                    </a>
                                </span>
                                <div className='project-details'>
                                    <div className='project-header'>
                                        <div className='project-header-left'>
                                            <h5 className='project-name'>{item.project_title}</h5>
                                        </div>
                                        <div className='project-header-right'>
                                            <span className='project-date'>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                    <div className='project-body'>
                                        <p>{item.notes}</p>
                                        <div className='project-sub-details r3'>
                                            <div className='project-sub-left project-sub-cell'>
                                                <h5 className="p-wallet"> {item.square_footage}<span><sup>S</sup>/<sub>F</sub></span></h5>
                                            </div>
                                            <div className="project-sub-cell">
                                                <p className='project-location'>{item?.buildingId?.country} ,{item?.buildingId?.state}</p>
                                            </div>
                                            <div className='project-sub-right project-sub-cell'>
                                            {/* Smartdata/staticData*/}
                                                <h5 className="p-dollar">254 USD</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                    {closeProjects?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Closed Project yet!</h5>) : null}
                </ul>
            }
            <div className="table-filters">
                <div className="t-f-cell">
                    <div className="records-filter">
                        <span>Show</span>
                        <div className="grey-dropdown bg-white">
                            <SelectBox
                                options={PaginationOption}
                                val={limit}
                                handleVal={(e) => setPagination(e)}
                            />
                        </div>
                        <span>Entries</span>
                    </div>
                </div>
                {totalPages > 1 ? (
                    <div className="t-f-cell">
                        <ReactPaginate
                            breakLabel={"..."}
                            nextLabel={'Next >'}
                            onPageChange={handlePageChange}
                            pageCount={totalPages}
                            previousLabel={'< Back'}
                            renderOnZeroPageCount={null}
                            containerClassName={'custom-pagination '}
                            pageClassName={'btn-page'}
                            pageLinkClassName={'btn-page'}
                            previousClassName={'btn-page'}
                            previousLinkClassName={'btn-previous'}
                            nextClassName={'btn-page'}
                            nextLinkClassName={'btn-next'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}
            </div>
        </>
    )
}

export default OwnerProjects;