import React from 'react'
import moment from 'moment';
import { Link,useNavigate } from 'react-router-dom'
import Icons from '../../../assets/images'
import _ from 'underscore'
import { API_BASEURL } from '../../../environment'
import { message,options } from '../../../components/common/utils/message';
import { DeleteProject } from '../../../services/project';
import { success } from '../../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";

const GridView = ({ resPayload}) => {
    const navigate = useNavigate();
    
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
          DeleteProject(id, async res => {
            if (res.status === "SUCCESS" && res.code === 200) {
              success(res.message);
            //   navigate("/project-list")
              await alert(message.ProjectDeleted);
            }
          })
        }
      }
    return (
        <div>
            <ul className='projects-list'>
                {resPayload && resPayload.docs.map((item, ind) => (
                    <li key={ind}>
                        <div className='project'>
                            <span className='project-img'>
                                <Link to={`/project-view/${item._id}`}> <img src={item?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" /></Link>
                            </span>
                            <div className='project-details'>
                                <div className='project-header'>
                                    <div className='project-header-left'>
                                        <h5 className='project-name'>{item?.project_title}</h5>
                                    </div>
                                    <div className='project-header-right'>
                                        <span className='project-date'>{moment(item?.createdAt).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                                <div className='project-body'>
                                <p>Square footage : {item.square_footage }</p>
                                                    </div>
                                {/* <div className='project-body'>
                                <p>{item.notes}</p>
                                <div className='project-sub-details'>
                                    <div className='project-sub-left'>
                                        <h5>Bid Fee : $150 USD</h5>
                                    </div>
                                    <div className='project-sub-right'>
                                        <h5>Winning bid Fee : $254 USD</h5>
                                    </div>
                                </div>
                            </div>
            */}
            <div className='project-body'> { console.log(' item ------- 42 -->', item.project_status)}
            <div className='project-body'> <p>Status : <b></b>{item.project_status }</p> </div>
                                </div>
                                <div className='project-footer'>
                                    <p className='project-location'>{item?.buildingId?.city},{item?.buildingId?.state}</p>
                                </div>
                                <span className="sort-icon">
                                            <button className="dashboard-action" title="View" onClick={() => navigate(`/project-view/${item._id}`)}>
                                                <img src={Icons.eye} alt="" />
                                            </button>
                                            <Link className="dashboard-action" to={`/project-form?id=${item._id}`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete" onClick={() => _handleDelete(item._id)}>
                                                <img src={Icons.bin} alt="" />
                                            </button>
                                        </span>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
          
        </div>
    )
}

export default GridView