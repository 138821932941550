import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all RValue data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listEstimator = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/estimator/get/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description list for all RValue data.
 * @param {*} param 
 * @param {*} callback 
 */
 export const viewEstimator = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/estimator/get?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description add new RValue in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addEstimate = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/estimator/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description update RValue in db.
 * @param {*} data 
 * @param {*} callback 
 */
 export const updateProjectEstimator = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/admin/project/addUpdateEstimator`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description update RValue in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateEstimator = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/rpc/estimator/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description Soft Delete RValue in db
 * @param {*} id 
 * @param {*} callback 
 */
export const deleteEstimate= async (id, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.delete(`${apiUrl}${PORT}/rpc/estimator/delete/${id}`, option)
                .then((response) => {
                    callback(response.data)
                }).catch((error) => {
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

