import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import AllOwners from '../OwnerList';
import CreateOwner from '../CreateOwner';
import OwnerView from '../OwnerView';
import CreateBuilding from '../BuildingForm';

const OwnerRoutes = [
    {
        path: `/add-owner`,
        layout: DefaultLayout,
        component: <  CreateOwner />
    },
    {
        path: `/list-owner`,
        layout: DefaultLayout,
        component: <  AllOwners />
    },
    {
        path: `/owner-view`,
        layout: DefaultLayout,
        component: <  OwnerView />
    },
    {
        path: `/add-building`,
        layout: DefaultLayout,
        component: <  CreateBuilding />
    }
]

export default OwnerRoutes;