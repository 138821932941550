import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

export const addCategory = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/admin/project/category/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const addSubCategory = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/admin/project/subcategory/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const getAllCategory = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/project/category/view/all`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateCategory = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/admin/project/category/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const deleteCategory = async (categoryId, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.delete(`${apiUrl}${PORT}/admin/project/category/delete/${categoryId}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};
const getAllSubCategoryByCategory = async (categoryId, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/project/subcategory/${categoryId}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const deleteSubCategory = async (subCategoryId, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.delete(`${apiUrl}${PORT}/admin/project/subcategory/delete/${subCategoryId}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};
const getAllSubCategory = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/project/subcategory/view/all`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};



const CategoryManagementService = {
    addCategory,
    addSubCategory,
    getAllCategory,
    deleteCategory,
    updateCategory,
    getAllSubCategoryByCategory,
    deleteSubCategory,
    getAllSubCategory
};

export default CategoryManagementService;