import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import FaqForm from '../FaqForm';
import FaqList from '../FaqList';
import JobBook from '../JobBook';

const FaqRoutes = [
    {
        path: `/job-book`,
        layout: DefaultLayout,
        component: < JobBook />
    },
    {
        path: `/faq-list`,
        layout: DefaultLayout,
        component: < FaqList />
    },
    {
        path: `/faq-form`,
        layout: DefaultLayout,
        component: < FaqForm />
    }
]

export default FaqRoutes;