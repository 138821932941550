import ProjectDescription from "./ProjectDescription";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
import { contSpecifications } from "../services/contractor";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import { API_BASEURL } from "../../../environment";
import Icons from "../../../assets/images";
import NoDataFound from "../../../components/common/NoDataFound";
const Specifications = () => {
    const [projectData, setProjectData] = useState([]);
    let project_id = useParams();
    let type;

    useEffect(() => {
        contSpecifications(project_id,type="specifications",(data) => {
           
           
            let newData = JSON.parse(JSON.stringify(data))
          setProjectData(newData);
         
         
        })
        
      }, [])
    return( 
        <>

        <div className='dashboard-inner'>
        <h2>Specifications</h2>
    <ContractorProjectDescription/>
    <div>
    </div>
    {
        projectData?.length > 0 ?
            <>
                <h1 className="d-card-heading d-card-sub-heading">Specifications</h1>
                <ul className="sample-gallery">
                    {projectData?.map((data, inx) => {
                       
                        return (
                            data?.type === "specifications" &&
                            <li key={inx}>
                                <span>
                                    <img src={`${API_BASEURL}/${data?.path}` || (Icons.projectBigImg)} alt='' />
                                </span>
                            </li>
                        )
                    })}
                </ul>
                
            </>
            :
            <h5 className="d-flex align-items-center justify-content-center"><div><NoDataFound/></div></h5>
    }
    
    </div>
    </>
    )
}
export default Specifications;