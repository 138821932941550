import React from 'react';
import { Link } from 'react-router-dom';
import { transactionDetails } from '../services/contractor';
import { useDispatch } from 'react-redux';
import { setProjectID } from '../../../components/common/general/GeneralSlice';
import { useParams } from 'react-router-dom';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useState, useEffect } from 'react';
import '../../contractorInterface/common/Sucess.css';
import { pdfFromReact } from "generate-pdf-from-react-html";
import moment from 'moment';

const SucessPage = () => {

  const [showResults, setShowResults] = React.useState(false)
  const showpdfblock = () => setShowResults(true)
  const [show, setShow] = useState([]);
  const [data, setData] = useState([]);
  let project_id = useParams();
  const [projectData, setprojectData] = useState([]);

  const dispatch = useDispatch();
  dispatch(setProjectID(project_id));




  useEffect(() => {
    handleShow();
  }, []);

  const handleShow = () => {


    let session_id = JSON.parse(sessionStorage.getItem('session_id'))
    let project_id = JSON.parse(sessionStorage.getItem('project_id'))
    let reqData = {
      project_id: project_id,
      session_id: session_id
    }

    transactionDetails(reqData, (data) => {
      setprojectData(data)
     
    }, dispatch)
  }




 
  return <>
 
    <div className='dashboard-inner'>
      <div className='page-not-found' style={{ height: "auto" }}>
        <div className="card" style={{ alignItems: "center" }}>
          <h1>Sucessfull</h1>
          <h2>Payment successfully done!</h2>
          <Link to={{ pathname: `/contractor/dashboard` }}><button className='btn btn-primary' onClick={handleShow}>Go to project</button></Link>
          <button className='btn btn-primary' onClick={showpdfblock} style={{ width: "fit-content", marginTop: "20px" }}> Print Recipt</button>
        </div>
      </div>
    </div>
    <div className="element-to-print">
    {showResults ?

      <div className='dashboard-inner'>
        <div className='page-not-found' style={{ height: "auto" }}>
          <div className='card'>
            <div style={{ color: "#333", height: "100%", width: "100%" }} height="100%" width="100%">
              <table cellspacing="0" style={{ borderCollapse: "collapse", padding: "40px", fontFamily: "'Barlow', sans-serif" }} width="100%">
                <tbody>
                  <tr>
                    <td width="5px" style={{ padding: "0" }}></td>
                    <td style={{ border: "1px solid #ccc", clear: "both", display: "block", margin: "0 auto", maxWidth: "600px", padding: "0" }}>
                      <table width="100%" style={{ borderBottom: "1px solid #eee", borderCollapse: "collapse", color: "#999" }}>
                        <tbody>
                          <tr>
                            <td width="50%" style={{ padding: "20px" }}><strong style={{ color: " #333", fontSize: "24px" }}>{projectData?.gateway_response?. amount_total}</strong> {projectData?.gateway_response?.payment_status}</td>
                            <td align="right" width="50%" style={{ padding: "20px" }}><p className="desc-info-detail">{moment(projectData?.createdAt).format('DD/MM/YYYY')}</p> <span className="il">Roofing.com</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td style={{ padding: "0" }}></td>
                    <td width="5px" style={{ padding: "0" }}></td>
                  </tr>
                  <tr>
                    <td width="5px" style={{ padding: "0" }}></td>
                    <td style={{ border: "1px solid #ccc", borderTop: "0", clear: "both", display: "block", margin: "0 auto", maxWidth: "600px", padding: "0" }}>
                      <table cellspacing="0" style={{ borderCollapse: "collapse", borderLeft: "1px solid #ccc", margin: " 0 auto", maxWidth: "600px" }}>
                        <tbody>
                          <tr>
                            <td valign="top" style={{ padding: "20px" }}>
                              <h3
                                style={{
                                  borderBottom: "1px solid #ccc",
                                  color: " #000",
                                  fontFamily: "'Barlow', sans-serif",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  lineHeight: "1.2",
                                  margin: "0",
                                  marginBottom: "15px",
                                  paddingBottom: "5px"
                                }}
                              >

                                Summary
                              </h3>
                              <table cellspacing="0" style={{ borderCollapse: "collapse", marginBottom: "40px" }}>
                                <tbody>
                                  <tr>
                                    <td style={{ padding: "5px 0" }}>transactionid:</td>
                                    <td align="right" style={{ padding: "5px 0" }}>{projectData?.transaction_id}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: "5px 0" }}>customer Name</td>
                                    <td align="right" style={{ padding: "5px 0" }}>{projectData?.gateway_response?.customer_details?.name}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: "5px 0" }}>customer Email</td>
                                    <td align="right" style={{ padding: "5px 0" }}>{projectData?.gateway_response?.customer_details?.email}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: "5px 0" }}>amount total</td>
                                    <td align="right" style={{ padding: "5px 0" }}>{projectData?.gateway_response?.amount_total}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ borderBottom: "2px solid #ccc", borderTop: "2px solid #ccc", fontWeight: "bold", padding: "5px 0" }}>Amount paid</td>
                                    <td align="right" style={{ borderBottom: "2px solid #ccc", borderTop: "2px solid #ccc", fontWeight: "bold", padding: "5px 0" }}>{projectData?.gateway_response?. amount_total}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td width="5px" style={{ padding: "0" }}></td>
                  </tr>
                </tbody>
              </table>
            </div>
           
          </div>
        </div>
      </div>

      : null}
</div>
<div class="d-flex justify-content-center">
<button className="btn btn-primary btn-sm" onClick={() =>
      pdfFromReact(".element-to-print", "My-file", "l", true, false) }> Download recipt</button>
      </div>

  </>
}
export default SucessPage;