import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

export const listBuildings = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/building/view/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const listAllBuildings = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/building/get/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const addBuilding = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': `multipart/form-data`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/building/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateBuilding = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': `multipart/form-data`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/rpc/building/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteBuilding = async (id, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.delete(`${apiUrl}${PORT}/rpc/building/delete/${id}`, option)
        .then((response) => {
          callback(response.data)
        }).catch((error) => {
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};


export const viewOneBuilding = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/building/view?${queryString.stringify(param)}`, option).then((response) => {
        dispatch(loadingAction(false))
        callback(response.data.data)
      }).catch((error) => {
        dispatch(loadingAction(false))
        callback(false)
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const viewOneBuildingId = async (id, callback, dispatch) => {  console.log( ' viewOneBuildingId 152 -- id ', id);
  try { 
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/building/view?${(id)}`, option).then((response) => {
        console.log( ' response response response 162', response);
        dispatch(loadingAction(false))
        callback(response.data.data)
      }).catch((error) => {
        dispatch(loadingAction(false))
        callback(false)
      });
    }
  } catch (err) {
    console.log(err);
  }
};