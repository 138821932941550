import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../assets/images/index'
import { setParamFirst } from '../../../components/common/general/GeneralSlice';
import { API_BASEURL } from '../../../environment';
import { profile_view } from '../services/contractor';
import ChangePassword from './ChangePassword';
import images from '../../../assets/images/defaultimage.png'

const ContractorProfileView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginUser, setLoginUser] = useState()

    useEffect(() => {
        profile_view((data) => { setLoginUser(data) }, dispatch)
    }, [])

    return (
        <>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Profile</h5>
                </div>
                <div className='d-card-body'>
                    <div className="owner-profile-wrapper">
                        <div className="u-p-l">
                            <div className="owner-profile-header">
                                <span className='owner-profile-img'>
                                    <img src={loginUser?.profile_pic ? `${API_BASEURL}/${loginUser?.profile_pic?.path}` :(images)} alt="" />
                                    {/* <label htmlFor='profile001'>
                                        <input type="file" id='profile001' />
                                        <span className='edit-profile'>
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="13" cy="13" r="12" fill="#27316F" stroke="white" stroke-width="2" />
                                                <path d="M15.0519 9.33424C15.1579 9.22827 15.2837 9.14421 15.4221 9.08687C15.5606 9.02952 15.709 9 15.8588 9C16.0087 9 16.1571 9.02952 16.2955 9.08687C16.434 9.14421 16.5598 9.22827 16.6658 9.33424C16.7717 9.4402 16.8558 9.56601 16.9131 9.70446C16.9705 9.84291 17 9.9913 17 10.1412C17 10.291 16.9705 10.4394 16.9131 10.5779C16.8558 10.7163 16.7717 10.8421 16.6658 10.9481L11.219 16.3948L9 17L9.60519 14.781L15.0519 9.33424Z" fill="#FEFEFF" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" />
                                            </svg>
                                        </span>
                                    </label> */}
                                </span>
                                <h4 className='owner-name'>{loginUser?.first_name}{loginUser?.last_name}</h4>
                                <div className="f-row">
                                    <div className="f-auto">
                                        <div className="desc-info p-b-5 p-t-5">
                                            <h6 className="desc-info-heading">Email Address</h6>
                                            <p className="desc-info-detail">{loginUser?.email}</p>
                                        </div>
                                    </div>
                                    <div className="f-auto">
                                        <div className="desc-info p-b-5 p-t-5">
                                            <h6 className="desc-info-heading">Phone Number</h6>
                                            <p className="desc-info-detail">{loginUser?.mobile_no}</p>
                                        </div>
                                    </div>
                                    <div className="f-auto">
                                        <div className="desc-info p-b-5 p-t-5">
                                            <h6 className="desc-info-heading">Address</h6>
                                            <p className="desc-info-detail">{loginUser?.country},{loginUser?.city},{loginUser?.state},{loginUser?.zip_code}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="u-p-r">
                            <div className="owner-profile-actions">
                                <button className='btn btn-blue btn-sm' onClick={() => { navigate('/contractor-profile-edit'); dispatch(setParamFirst(loginUser)) }}>Edit Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChangePassword />
        </>
    )
}

export default ContractorProfileView;