import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Icons from '../../assets/images/index';
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import AddApplicationMenu from './components/AddApplicationMenu';
import DeleteConfirmation from '../../components/common/DeleteConfirmation/DeleteConfirmation';
import ApplicationMenuService from '../../services/application.menu';
import CategoryManagementService from '../../services/category.service';
import { success, failed } from '../../components/common/Toastify';
import { listModules } from '../../services/leftMenu';
const LeftMenu = ({ changeToggle }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const stateData = useSelector((state) => state.login.user.data.modules);
    const role = useSelector((state) => state.login.user.data.user.role);
    const [permissions, setPermissions] = useState([]);
    const [modules, setModules] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [applicationMenu, setApplcationMenu] = useState([]);
    const [selectedMenu, setSelectedMenu] = useState(null);

    const [modalData, setModalData] = useState({
        title: null,
        menuId: null
    });

    const handleClose = () => {
        setOpenForm(false);
        setSelectedMenu(null);
        getApplicationMenus();
    }
    const handleShow = () => setOpenForm(true);
    const handleDeleteConfirmModalClose = () => {
        setModalData({ title: null, menuId: null });
        setDeleteConfirmation(false);
        getApplicationMenus();
    }
    const handleDeleteConfirmModalOpen = (title, menuId) => {
        setModalData({ title, menuId });
        setDeleteConfirmation(true);
    }

    useEffect(() => {
        getApplicationMenus();
    }, [])
    const getApplicationMenus = () => {
        ApplicationMenuService.getAllApplicationMenu((result) => {
            setApplcationMenu(result)
        }, dispatch)
    }
    useEffect(() => {
        if (stateData && stateData.length > 0) {
            setPermissions(stateData);
        }
        listModules({}, (data) => {
            const orderedData = data.docs.sort((a, b) => a.render_key - b.render_key);
            setModules(orderedData)
        }, dispatch)
    }, [stateData])

    const openEditModal = (data) => {
        setSelectedMenu(data)
        handleShow();
    }
    const confirmDelete = (menuId) => {
        ApplicationMenuService.deleteMenu(menuId, (result) => {
            if (result.status === "SUCCESS" && result.code === 200) {
                success(result.message);
                handleDeleteConfirmModalClose();
            }
            else {
                failed(result.message);
            }
        }, dispatch)
    }


    const handleToggle = (e) => {
        console.log('sidebar add class', e)
        var x = document.getElementsByID("dashboard");
        console.log('sidebar add class xxx', x[0])
        if (x.className === "dashboard") {
          x.className += " show";
          console.log('sidebar add class', x)
        } else {
          x.className = "dashboard";
        }
      }
      
      
    return (
        <div className='dashboard-sidebar'>
            <div className='dashboard-logo-wrap'>
                <Link to='/' className='dashboard-logo'>
                    <img src={Icons.logo} alt='' />
                </Link>
            </div>
            <ul className='sidebar-links'>
                {

                    modules && modules.map((module, i) => {
                        return (
                            module.childrens.length > 0 && (role === 'RPC_MANAGER' ? permissions.includes(module.name) : role === 'ADMIN') ?
                                <>
                                    <li>
                                        <div className='sidebar-dropdown' id={i + 1} onClick={() => Icons.toggleSubMenu(i + 1)}>

                                            <div className={`sidebar-item ${location?.pathname === `${module.link}` ? 'active' : ""}`} onClick={() => handleToggle()}>
                                                <span>
                                                    <svg width={module.icon.width} height={module.icon.height} viewBox={module.icon.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d={module.icon.d} fill="#7F87A4" />
                                                    </svg>
                                                </span>
                                                <h5>{module.name}</h5>
                                            </div>

                                            <ul className='sidebar-dropdown-list' >
                                                {module.name !== "Application Management" && module?.childrens?.map((child => {
                                                    return (
                                                        (role === 'RPC_MANAGER' ? permissions.includes(child.name) : role === 'ADMIN') ? (
                                                            <li onClick={() => { Icons.toggleChild(i + 1); }}>

                                                                <Link to={child.link} className={`sidebar-item ${location?.pathname === `${child.link}` ? 'activeChild' : ""}`} onClick={() => handleToggle()} >
                                                                    <span>
                                                                        <svg width={child.icon.width} height={child.icon.height} viewBox={child.icon.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path fillRule="evenodd" clipRule="evenodd" d={child.icon.d} fill="#7F87A4" />
                                                                        </svg>
                                                                    </span>
                                                                    <h5>{child.name}</h5>
                                                                </Link>
                                                            </li>) : null
                                                    )
                                                }))}

                                            </ul>
                                        </div>
                                    </li>
                                </>
                                :
                                (role === 'RPC_MANAGER' ? permissions.includes(module.name) : role === 'ADMIN') &&
                                <li onClick={() => changeToggle()}>
                                    <Link to={modules[0].link} className={`sidebar-item ${location?.pathname === `${module.link}` ? 'active' : ""}`}  onClick={() => handleToggle()}>
                                        <span>
                                            <svg width={module.icon.width} height={module.icon.height} viewBox={module.icon.viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d={module.icon.d} fill="#7F87A4" />
                                            </svg>
                                        </span>
                                        <h5>{module.name}</h5>
                                    </Link>
                                </li>
                        )
                    })


                }
                {((role === 'RPC_MANAGER') ? permissions.includes("Application Management") : role === 'ADMIN') &&
                    <div className='sidebar-dropdown' id={"app_manage"} onClick={() => Icons.toggleSubMenu("app_manage")}>
                        <div className={`sidebar-item ${location?.pathname === `${module.link}` ? 'active' : ""}`}  onClick={() => handleToggle()}>
                            <span>

                                <svg width={19} height={19} xmlns="http://www.w3.org/2000/svg" fill="#7F87A4" class="bi bi-window-desktop" viewBox="0 0 16 16">
                                    <path d="M3.5 11a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-9Z" />
                                    <path d="M2.375 1A2.366 2.366 0 0 0 0 3.357v9.286A2.366 2.366 0 0 0 2.375 15h11.25A2.366 2.366 0 0 0 16 12.643V3.357A2.366 2.366 0 0 0 13.625 1H2.375ZM1 3.357C1 2.612 1.611 2 2.375 2h11.25C14.389 2 15 2.612 15 3.357V4H1v-.643ZM1 5h14v7.643c0 .745-.611 1.357-1.375 1.357H2.375A1.366 1.366 0 0 1 1 12.643V5Z" />
                                </svg>
                            </span>
                            <h5>{"Application Management"}</h5>
                        </div>
                        <ul className='sidebar-dropdown-list' id={"app_manage"} onClick={() => Icons.toggleSubMenu("app_manage")}>
                            {modules.length > 0 && applicationMenu?.map(menu => {
                                return (<li className="sidebar-item" >
                                    <h5 onClick={() => openEditModal(menu)}>{menu.menu_title}</h5>
                                    <i >
                                        <img src={Icons.bin} alt='' onClick={() => handleDeleteConfirmModalOpen(menu.menu_title, menu._id)} />
                                    </i>
                                </li>
                                );
                            })}
                            {modules.length > 0 &&
                                <li onClick={handleShow} className='add-more-list'>
                                    <h5>Add more</h5>
                                    <span>
                                        <em className='bi bi-plus-square'></em>
                                    </span>
                                </li>
                            }
                        </ul>
                    </div>}

                {/* <li><a class="sidebar-item " href="/create-line-items"><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#7F87A4" class="bi bi-line" viewBox="0 0 16 16">
                        <path d="M8 0c4.411 0 8 2.912 8 6.492 0 1.433-.555 2.723-1.715 3.994-1.678 1.932-5.431 4.285-6.285 4.645-.83.35-.734-.197-.696-.413l.003-.018.114-.685c.027-.204.055-.521-.026-.723-.09-.223-.444-.339-.704-.395C2.846 12.39 0 9.701 0 6.492 0 2.912 3.59 0 8 0ZM5.022 7.686H3.497V4.918a.156.156 0 0 0-.155-.156H2.78a.156.156 0 0 0-.156.156v3.486c0 .041.017.08.044.107v.001l.002.002.002.002a.154.154 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157Zm.791-2.924a.156.156 0 0 0-.156.156v3.486c0 .086.07.155.156.155h.562c.086 0 .155-.07.155-.155V4.918a.156.156 0 0 0-.155-.156h-.562Zm3.863 0a.156.156 0 0 0-.156.156v2.07L7.923 4.832a.17.17 0 0 0-.013-.015v-.001a.139.139 0 0 0-.01-.01l-.003-.003a.092.092 0 0 0-.011-.009h-.001L7.88 4.79l-.003-.002a.029.029 0 0 0-.005-.003l-.008-.005h-.002l-.003-.002-.01-.004-.004-.002a.093.093 0 0 0-.01-.003h-.002l-.003-.001-.009-.002h-.006l-.003-.001h-.004l-.002-.001h-.574a.156.156 0 0 0-.156.155v3.486c0 .086.07.155.156.155h.56c.087 0 .157-.07.157-.155v-2.07l1.6 2.16a.154.154 0 0 0 .039.038l.001.001.01.006.004.002a.066.066 0 0 0 .008.004l.007.003.005.002a.168.168 0 0 0 .01.003h.003a.155.155 0 0 0 .04.006h.56c.087 0 .157-.07.157-.155V4.918a.156.156 0 0 0-.156-.156h-.561Zm3.815.717v-.56a.156.156 0 0 0-.155-.157h-2.242a.155.155 0 0 0-.108.044h-.001l-.001.002-.002.003a.155.155 0 0 0-.044.107v3.486c0 .041.017.08.044.107l.002.003.002.002a.155.155 0 0 0 .108.043h2.242c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156v-.56a.156.156 0 0 0-.155-.157H11.81v-.589h1.525c.086 0 .155-.07.155-.156Z" />
                    </svg>
                </span><h5>Create Line Items </h5></a></li>
               */}

                <li><a class="sidebar-item " href="/view-graph" onClick={() => handleToggle()}><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#7F87A4" class="bi bi-card-list" viewBox="0 0 16 16">
                        <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />
                        <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />
                    </svg>
                </span><h5>Graph Report </h5></a></li>

            </ul >
            {
                openForm &&
                <AddApplicationMenu
                    show={openForm}
                    handleClose={handleClose}
                    menu={selectedMenu}
                />
            }
            {deleteConfirmation && <DeleteConfirmation show={deleteConfirmation} handleClose={handleDeleteConfirmModalClose} modalData={modalData} deleteAction={confirmDelete} />}
        </div >
    )
}

export default LeftMenu;