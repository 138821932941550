import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';

const NotificationTemplates = () => {
    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Notifications</h4>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Predefined Templates</h5>
                    <div className='d-card-actions'>
                    <Link to="/add-template" className='btn btn-blue'>Add Templates</Link>
                        {/* <button className="btn btn-blue">Add Templates</button> */}
                    </div>
                </div>
                <div className='d-card-body p-b-0'>
                    <ul className='available-templates'>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 1</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 2</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 3</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 4</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 5</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 6</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 7</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 8</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" className='template'>
                                <h4 className='template-name'>Template 9</h4>
                                <p className='template-text'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet Amet minim mollit</p>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NotificationTemplates

