import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteEstimate, listEstimator, updateProjectEstimator } from '../../services/estimator';
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { failed, success } from '../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";
import ReactPaginate from 'react-paginate';
import SelectBox from '../../components/common/formInput/SelectBox';
import BidForm from './BidForm';
import Icons from '../../assets/images';
import _ from 'underscore';
import { viewOneProject } from '../../services/project';
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import { listBidForm, addBidForm, updateBidForm, getSumbitBidAdmin, listMasterBidForm, createBidForm,
    listBidFormData, listMasterProjectDescription } from '../../services/project';

import AddMasterDes from './AddMasterDes';



const DescriptionProjectForm = ({chooseMessageProjDesp}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const _projectId = queryString.parse(location.search);

    const [modalShow, setModalShow] = useState(false);
    const [cloneEstimator, setCloneEstimator] = useState("");
    const handleClose = () => setModalShow(false)

    const [resPayload, setResPayload] = useState();
    const [projectName, setProjectName] = useState();

    const totalPages = resPayload && resPayload.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    let [estimatorData, setEstimatorData] = useState();
    let [isRightCheck, setIsRightCheck] = useState([]);
    let [isLeftCheck, setIsLeftCheck] = useState([]);

    const [listDataItem, setListDataItem] = useState();

    const [formValues, setFormValues] = useState(
        [
            {
                item: "",
                default_value: 0,
                allowed_in_words: false,
                is_master_lineItem: true,
                master_lineItem_id: ""
            }
        ]
    );
    let strHref = window.location.href;
    let projectIdDD = (strHref.split("=")).slice(-1).pop();
    
console.log( ' _projectId --- _projectId 58 --- ', _projectId)

    useEffect(() => {
        _getListing();
        //getting Estimator data from project by project_id
        viewOneProject(_projectId, (data) => {
            setEstimatorData(data?.projectDescription);
            setListDataItem(data);
          //  setProjectName(data)
        })
    }, [dispatch]);

   console.log('setEstimatorData 70', estimatorData)
   console.log('setListDataItem 71', listDataItem)

    // useEffect(() => {
    //     listBidFormData(_projectId.id, (data) => { setListDataItem(data); setEstimatorData(data?.line_items); }, dispatch);
    // }, []);

  

   

    useEffect(() => {
        _getListing();
    }, [estimatorData])

    /**
     * @description get all the faq db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {
        let ignoreRecords = estimatorData?.map((item) => item._id)
       
        console.log('ignoreRecords 90 --- > ',ignoreRecords)
       //  debugger
        listMasterProjectDescription({ page, limit, ignoreRecords }, (data) => { setResPayload(data) }, dispatch)
    }
    
    // useEffect(() => {
    //     listBidFormData(_projectId.id, (data) => { setListDataItem(data) }, dispatch);
    // }, []);



    /**
    * @description Function For delete faq.
    * @param {id} id 
    */
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            deleteEstimate(id, async res => {
                if (res.status === "SUCCESS" && res.code === 200) {
                    _getListing();
                    success(res.message);
                    await alert(message.EstimateAssistanceDeleted);
                }
            })
        }
    }

console.log( ' listDataItem 115 -----> ', listDataItem)

    //Left checkbox handlecheck.............
    const handleChangeCheck = (id, e) => {
        let listDataItemId = listDataItem.projectDescription?.map((item) => item._id);

        console.log(' listDataItemId --- handleChangeCheck', listDataItemId);
       //  debugger;
        let checkedData = JSON.parse(JSON.stringify(isLeftCheck))
       
        // debugger;
        if (!e.target.checked) {
            let index = checkedData.findIndex(item => item === id)
            if (index !== -1)
                checkedData.splice(index, 1)
             setIsLeftCheck([...checkedData,listDataItemId])
        } else{
            setIsLeftCheck([...checkedData, String(id)])
            // setIsLeftCheck([...handleChangeCheck, String(id)])
        }
            
    }

    //Right checkbox handlecheck.............
    const _handleCheck = (id, e) => {
        let checkValue = JSON.parse(JSON.stringify(isRightCheck))
        console.log('checkValue-------',checkValue)
        console.log('id-------',id)
        console.log('e-------',e)
       // debugger
        if (!e.target.checked) {
            let index = checkValue.findIndex(item => item === id)
            if (index !== -1)
                checkValue.splice(index, 1)
            setIsRightCheck([...checkValue])
        } else
            setIsRightCheck([...checkValue, String(id)])
    }

    //For Right side remove data....
    const _handleArrowDown = () => {
        let checkData = estimatorData.filter(e => {
            if (!isRightCheck.includes(e._id))
                return e
        })
        console.log(' Arrow Down data ---- ',checkData)
        // debugger
        setEstimatorData([...checkData])
    }

    // on data move left to right
    const _handleArrowUp = async () => {
        if (_projectId.id) {
           //   let listDataItemId = listDataItem.line_items?.map((item) => item._id);
            let rightEstimates = resPayload
            console.log('  resPayload --- ', resPayload)
            console.log(' rightEstimates resPayload --- ', rightEstimates)
            // debugger
            await rightEstimates?.forEach(item => {
                console.log( 'item -------- 168 --' , item)
                if (isLeftCheck.includes(item._id)) {
                    estimatorData.push(item)
                }
            })
            
            setIsLeftCheck([])
            var output = rightEstimates.filter((el) => {
                if (!isLeftCheck.includes(el._id)) {
                    return el
                }
            })
           // setResPayload({ ...resPayload, docs: output })
           setResPayload( output )
            console.log(' output--- ', output)
            console.log(' resPayload--- ', resPayload)
           // debugger
            // need to delete ! from line number 176
        } else {
            failed("Project is not associated! Select a project first!.")
        }
    }

    //update description handle change
    const _handleChange = (item, e, attribute) => {
        for (var i = 0; i < estimatorData.length; i++) {
            if (estimatorData[i]._id === item._id) {
                estimatorData[i][attribute] = e.target.value
            }
        }
        setEstimatorData([...estimatorData])
    }

    //update description textare
    const updateInput = (item, className) => {
        return (
            <div className='f-field' style={{ border: '0.5px solid #ccc' }}>
                <textarea
                    className={className}
                    type="text"
                    value={className === "f-area" ?
                        item.description :
                        item.title
                    }
                    onChange={(e) => { _handleChange(item, e, className === "f-area" ? "description" : "title") }} />
            </div>
        )
    }

    /**
    * @description Function For Update Estimator.
    * @param {id} id 
    */
    const _updateData = async () => {
        if (_projectId.id) {

            let itemArray = [];
            // estimatorData.forEach(element => {
            //     let tempJson = {
            //         item: element.item,
            //         default_value: 0,
            //         allowed_in_words: null,
            //       //  is_master_lineItem: true,
            //        // master_lineItem_id: element._id
            //     }

            estimatorData.forEach(element => { console.log('element -- 240', element)
                let tempJson = {
                    _id: element._id,
                    title: element.title,
                    description: element.description,
                    is_master : true
                   
                  //  is_master_lineItem: true,
                   // master_lineItem_id: element._id
                }
                itemArray.push(tempJson);


            });

            console.log( ' itemArray ----> ', itemArray);

           // debugger
            // let resData = {
            //     "_id": listDataItem._id,
            //     "line_items": itemArray
            // }

            return chooseMessageProjDesp(itemArray);

            
            // try {
            //     updateBidForm(resData, result => {
            //         if (result.status === "SUCCESS" && result.code === 200) {
            //             success(result.message)
            //             navigate(`/project-view/${_projectId.id}`)

            //         } else if (result.status === "ERROR" && result.code === 403) {
            //             failed(result.message)
            //         }
            //     }, dispatch)
            // } catch (e) {
            //     failed(e)
            // }
        } else {
            failed("Project is not associated! Select a project first!.")
        }
    }



    /**
    * @description /Function is on Page Change.
    * @param {param} param 
    */
    // const handlePageChange = async (param) => {
    //     let currentPage = param.selected + 1;
    //     setPage(currentPage)
    //     await listMasterBidForm({ page: currentPage, limit }, response => {
    //         setResPayload(response);
    //     }, dispatch)
    // };

    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    // const setPagination = async (displayPerPage) => {
    //     setLimit(displayPerPage);
    //     await listMasterBidForm({ limit: displayPerPage, page }, response => {
    //         setResPayload(response);
    //     }, dispatch)
    // }  

    const onCancelData = async () => {
        const result = await confirm(message.cancelProjectForm, options);
        if (result) {
            // alert(message.canceled);
            navigate('/project-list')
        }
    }

  
    
    return (
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Update Project Description Form</h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters dashboard-sm-sub-filters">

                        <li>
                            {/* <button className="btn btn-blue btn-min-width-200" onClick={() => { setModalShow(true); setCloneEstimator("") }} >Add New Line Items</button> */}
                            { <button className="btn btn-blue btn-min-width-200" onClick={() => { setModalShow(true); setCloneEstimator("") }} >Add Descriptions</button> }
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    {/* <h6 className='text-center'>Master Estimator Assistance</h6> */}
                </div>
                <div className="d-h-r">
                    {/* {projectName ? <h6>Project Name:<span> {projectName}</span></h6> :
                        <h6 className='text-center'>Assigned Line Items</h6>} */}
                </div>
            </div>
            <div className='bid-container'>
                <div className="dashboard-table">
                    <h3 className="text-center mb-2"> Master Project Description List </h3>
                    <div className="responsive-table">
                        <table className='table-update dark-header'>
                            <thead>
                                <tr>
                                    <th className="text-center">Select</th>
                                    <th>
                                        <div className='master-estimate'>
                                            <h4 style={{ color: '#fff' }}>Line Item</h4>
                                            <h6 style={{ color: '#fff' }}>Description</h6>
                                        </div>
                                    </th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resPayload && resPayload?.map((dataItem, index) => (
                                        <tr key={index}>
                                            <td className="text-center"> 
                                                <label className='checkbox nolabel' htmlFor={`check004${index}`}>
                                                    <input type="checkbox"
                                                    //   defaultChecked={isLeftCheck.includes(dataItem._id)}
                                                      //  checked={listDataItem.line_items.master_lineItem_id.includes(index)}
                                                    // defaultChecked={listDataItem._id.includes(dataItem._id)}
                                                    checked  = {isLeftCheck.includes(dataItem._id) }
                                                        onClick={(e) => { handleChangeCheck(dataItem._id, e) }}
                                                        id={`check004${index}`}
                                                    />
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td> 
                                                <div className='master-estimate'>
                                                    <h4>{dataItem.title}</h4>
                                                    <h6>{dataItem.description}</h6>
                                                </div>
                                            </td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div className='estimator-arrow' style={{ padding: '20px 0' }}>
                    <button className="btn btn-blue" onClick={() => _handleArrowUp()} style={{ marginRight: '10px' }}> Add To Project</button>
                    <button className="btn btn-red" onClick={() => _handleArrowDown()}> Remove From Project </button>
                </div>

                <div className="dashboard-table">
                    <h3 className="text-center mb-2"> Project Descripton Items List </h3>
                    <div className="responsive-table">
                        <table className='table-update dark-header'>
                            <thead>
                                <tr>
                                    <th className="text-center">Select</th>
                                    <th>Line Item Description</th>
                                    <th style={{ width: 'auto' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                { estimatorData !=undefined ? estimatorData?.map((itemData, index) => (
                                    <tr key={index}>
                                        <td className="text-center">{console.log(' itemData 444.',itemData)}
                                            <label className='checkbox nolabel' htmlFor={`check005${index + 1}`}>
                                                <input type="checkbox" id={`check005${index + 1}`}
                                                    defaultChecked={isRightCheck.includes(itemData._id)}
                                                  //  checked={listDataItem._id.includes(index)}
                                                    onClick={(e) => _handleCheck(itemData._id, e)}    />
                                                <span></span>
                                            </label>

                                        </td>
                                        <td>
                                        <h4>{itemData.title}</h4>
                                                    <h6>{itemData.description}</h6>
                                        
                                        </td>

                                        <td></td>

                                    </tr>
                                )) : " "}
                                {estimatorData === undefined || estimatorData === null ? (<tr><td colSpan={3} className="text-center"> No data found! </td> </tr>) : null}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className="d-h-wrap">
                    <div className="d-h-l">

                    </div>
                    <div className="d-h-r  mt-2">
                        <ul className="dashboard-sub-filters dashboard-sm-sub-filters">
                            <li></li>
                            <li>
                                <button className="btn btn-blue" type='submit' onClick={(e) => { _updateData(); }}>Update</button>
                            </li>
                            <li>
                            <button className="btn btn-grey" type="button" onClick={onCancelData}>Cancel</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <BidForm
                show={modalShow}
                handleClose={handleClose}
                cloneEstimator={cloneEstimator}
                handleModal={() => { _getListing(); setCloneEstimator("") }}
            />

<AddMasterDes
                show={modalShow}
                handleClose={handleClose}
                cloneEstimator={cloneEstimator}
                handleModal={() => { _getListing(); setCloneEstimator("") }}
            />
        </div>
    )

};

export default DescriptionProjectForm;
