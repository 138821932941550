import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal'
import { get_all_q_n_a, add_answer } from "../../../services/rpc";
import moment from 'moment';
import { useDispatch } from "react-redux";
import { success, failed } from "../../../components/common/Toastify";

const AnswerQuestions = ({ projectId }) => {

    console.log('projectId --- ', projectId);
    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [qnAList, setQnAList] = useState([]);
    const [modalData, setModalData] = useState({
        qId: "",
        question: ""
    })
    const [answer, setAnswer] = useState("")
    useEffect(() => {
        const params = { id: projectId }
        get_all_q_n_a(projectId, (data) => { console.log('data --- in useeffect ', data)
            setQnAList(data)
        })
    }, [])

    const onModalOpen = (data) => {
        return () => {
            setModalData({ qId: data._id, question: data.question_text })
            setModalShow(true)
        }
    }

    const handleAnswerChange = (e) => {
        setAnswer(e.target.value)
        
    }



    const answerTheQuestion = () => {
        // const payLoad = {
        //     replyText: answer,
        //     id: projectId,
        //     qid: modalData.qId
        // }

        const payLoad = {
            answer_text: answer,            
            questionId : modalData.qId
        }


        console.log('payLoad payLoad -- ', payLoad)

        add_answer(payLoad, (result) => {

            if (result.status === "SUCCESS" && result.code === 200) {
                success(result.message);
            } else if (result.status === "ERROR" && result.code === 403) {
                failed(result.message)
            }

            setModalData({
                qId: "",
                question: ""
            })
            setAnswer("")
            setModalShow(false)
        }, dispatch)


    }


    return (
        <>
            <ul className="qa-list">
                {qnAList && qnAList.length > 0 && qnAList.map((data, index) => {
                    return (<li>
                        <div className="qa-wrap">
                            <h4><span>{`Q${index + 1}    `}</span>{data.question_text}</h4>
                            {data.answer_text ?  <p> Answer -  {data.answer_text} </p> :
                                <button className="btn btn-blue" onClick={onModalOpen(data)}>Answer</button>}

                            <div className="d-t">
                                <h5>{moment(data.createdAt).format('DD/MM/YYYY')}<br />{moment(data.createdAt).format('HH:MM:SS')}</h5>
                            </div>
                        </div>
                    </li>)
                })}
            </ul>
            {
                modalData &&
                <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Answer Questions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h6 className="desc-info-heading">Question</h6>
                        <div className="qa-wrap">
                            <h4><span>Q.</span>{modalData.question}</h4>
                        </div>
                        <div className="f-row">
                            <div className="f-100">
                                <h6 className="desc-info-heading">Answer</h6>
                                <div className="f-field m-b-0">
                                    <textarea className="f-area" placeholder="Answer" value={answer} onChange={handleAnswerChange}></textarea>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ul className="modal-actions">
                            <li>
                                <button className="btn btn-primary" onClick={() => answerTheQuestion()}>Answer</button>
                            </li>
                            <li>
                                <button className="btn btn-grey" onClick={() => setModalShow(false)}>Cancel</button>
                            </li>
                        </ul>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}
export default AnswerQuestions;