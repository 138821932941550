import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import MaterialTypeList from '../MaterialTypeList';
import MaterialTypeView from '../MaterialTypeView';
import MaterialTypeForm from '../MaterialTypeForm'

const MaterialRoutes = [
    {
        path: `/material-type-list`,
        layout: DefaultLayout,
        component: <  MaterialTypeList />
    },
    {
        path: `/material-type-view/:id`,
        layout: DefaultLayout,
        component: < MaterialTypeView />
    },
    {
        path: `/material-type-form`,
        layout: DefaultLayout,
        component: < MaterialTypeForm />
    }
]

export default MaterialRoutes;