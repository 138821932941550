import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from "react-redux";
import { addFaq, updateFaq, listOneFaq } from '../../services/faq';
import { Formik, Form } from "formik";
import TextField from '../../components/common/formInput/TextField';
import { useNavigate } from "react-router-dom";
import { failed, success } from "../../components/common/Toastify";
import { faqSchema } from '../../components/common/validations/Validation';
import Editor from '../../components/common/formInput/Editor';
import { _status } from '../../components/common/utils/message';
import SearchSelectBox from '../../components/common/formInput/SearchSelectBox';

const FaqForm = ({ handleClose, show, editFaq, handleModal }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();

    const [initialValue, setInitialValue] = useState({
        _id: "",
        faq_question: "",
        faq_answer: "",
        status: true
    });

    useEffect(() => {
        if (editFaq)
            listOneFaq({ _id: editFaq }, (data) => { setResPayload(data) }, dispatch)
        else
            setResPayload({})
    }, [editFaq]);

    useEffect(() => {
        setInitialValue({
            _id: resPayload?._id ? resPayload._id : "",
            faq_question: resPayload?.faq_question ? resPayload.faq_question : "",
            faq_answer: resPayload?.faq_answer ? resPayload.faq_answer : "",
            status: resPayload?.status ? resPayload.status : true
        })
    }, [resPayload]);

    /**
    * @description Function is for add Faq.
    * @param {object} data 
    */
    const _submitData = (data) => {
        try {
            addFaq(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleModal();
                    handleClose();
                    success(result.message)
                    navigate("/faq-list")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update Faq
     * @param {object} data 
     */
    const _updateData = async (data) => {
        try {
            updateFaq(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleModal();
                    handleClose();
                    success(result.message);
                    navigate("/faq-list")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    return (
        <div className='dashboard-inner'>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{resPayload?.faq_question ? "Edit FAQ’s" : "Add New FAQ’s"}</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValue}
                    enableReinitialize
                    validationSchema={faqSchema}
                    onSubmit={(values) => resPayload?.faq_question ? _updateData(values) : _submitData(values)}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className="f-row alignTop">
                                    <div className="f-50">
                                        <TextField
                                            label="Question"
                                            name="faq_question"
                                            value={formik?.values?.faq_question}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>
                                    <div className="f-50">
                                        <SearchSelectBox
                                            label="Status"
                                            name="status"
                                            options={_status}
                                            value={formik?.values?.status}
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </div>
                                <div className="f-row alignTop">
                                    <div className="f-100">
                                        <Editor
                                            label="Answer"
                                            name="faq_answer"
                                            value={formik?.values?.faq_answer}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li><button className="btn btn-primary">{resPayload?.faq_question ? "Edit" : "Add"}</button></li>
                                    <li><button type='button' className="btn btn-grey" onClick={handleClose}>Cancel</button></li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default FaqForm

