import React from 'react';
import Icons from '../../assets/images/index'
import Accordion from 'react-bootstrap/Accordion'
import OwlCarousel from 'react-owl-carousel2';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPageBySlug } from "../../services/cms.service";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { API_BASEURL } from "../../environment";
import { allTerms } from "../../services/terms.service"

const options = {
    items: 1,
    nav: false,
    rewind: true,
    autoplay: true
};

const events = {
    onDragged: function (event) { console.log(event) },
    onChanged: function (event) { console.log(event) }
};

const LibraryOfTerms = () => {

    const dispatch = useDispatch();
    const location = useLocation()

    const [pageData, setPageData] = useState(null)
    const [terms, setTerms] = useState(null);

    useEffect(() => {
        getPageBySlug(location.pathname, (data) => {
            setPageData(data)
        }, dispatch)
        allTerms({}, (data) => {
            setTerms(data.docs)
        }, dispatch)
    }, [])

    return (
        <>
            {pageData && <>
                <section className='hero-section'>
                    <OwlCarousel options={options} events={events} >
                        {pageData.banner_images.map(banner => {
                            return (<div className='hero-section-inner' style={{ backgroundImage: `url(${API_BASEURL}/${banner.path})` }}>
                                <div className='container'>
                                    <div className='hero-inner'>
                                        <h3 className='section-heading' dangerouslySetInnerHTML={{ __html: pageData.banner_heading }} />
                                        <p className='section-desc' dangerouslySetInnerHTML={{ __html: pageData.banner_description }} />
                                    </div>
                                </div>
                            </div>)
                        })}
                    </OwlCarousel>
                </section>
            </>}
            {terms && <section className='details-section'>
                <div className='inner-container'>
                    <div className='what-we-do-inner'>
                        <h4 className='section-heading dark text-center'>Library of Terms</h4>
                        <div className='accordians'>
                            <Accordion>
                                {terms.map((block, index) => (
                                    <Accordion.Item eventKey={index}>
                                        <Accordion.Header>
                                            <p dangerouslySetInnerHTML={{ __html: block.title }} />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <p className='terms-desc' dangerouslySetInnerHTML={{ __html: block.detail }} />
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section>}


        </>
    )
}

export default LibraryOfTerms;