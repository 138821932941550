import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import AllContractor from '../ContractorList';
import CreateContractor from '../ContractorForm';
import ContractorView from '../ContractorView';

const ContractorRoutes = [
    {
        path: `/add-contractor`,
        layout: DefaultLayout,
        component: <  CreateContractor />
    },
    {
        path: `/list-contractor`,
        layout: DefaultLayout,
        component: <  AllContractor />
    },
    {
        path: `/contractor-view/:id`,
        layout: DefaultLayout,
        component: <  ContractorView />
    }
]

export default ContractorRoutes;