import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';
import { viewOneNewRoofType } from '../../services/newRoofType';
import _ from 'underscore'; 
import { useDispatch } from "react-redux";

const NewRoofTypeView = () => {
    const dispatch = useDispatch();
    const [newRoofType, setNewRoofType] = useState()
    let roofType_id = useParams();

    useEffect(() => {
        viewOneNewRoofType(roofType_id, (data) => {
            setNewRoofType(data)
        })
    }, [dispatch])

  

    return (
        <div className='dashboard-inner'>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading'>New Roof Type</h5>
                    <div className='d-card-actions'>
                        <label htmlFor='toggleSwitch001' className='toggle-switch m-r-10'>
                            <input type="checkbox" id='toggleSwitch001' />
                            <h6 className='deactivate'>Inactive</h6>
                            <h6 className='activate'>Active</h6>
                            <span className='toggle-bar'></span>
                        </label>
                        <Link className="dashboard-action" to="/" title="Edit">
                            <img src={Icons.edit} alt="" height="20px" />
                        </Link>
                        <button className="dashboard-action" title="Delete">
                            <img src={Icons.bin} alt="" height="20px" />
                        </button>
                    </div>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="f-row">
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">New Roof Type</h6>
                                <p className="desc-info-detail">{newRoofType && newRoofType.roof_type_name}</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Price Per Sq Ft</h6>
                                <p className="desc-info-detail">${newRoofType && newRoofType.price} USD</p>
                            </div>
                        </div>
                    </div>
                    <div className="desc-info">
                        <p className="desc-info-detail">{newRoofType && newRoofType.description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewRoofTypeView

