import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import WindUpliftList from '../WindUpliftList';
import WindUpliftView from '../WindUpliftView';
import WindUpliftForm from '../WindUpliftForm'

const WindUpliftRoutes = [
    {
        path: `/wind-uplift-list`,
        layout: DefaultLayout,
        component: <  WindUpliftList />
    },
    {
        path: `/wind-uplift-view/:id`,
        layout: DefaultLayout,
        component: < WindUpliftView />
    },
    {
        path: `/wind-uplift-form`,
        layout: DefaultLayout,
        component: < WindUpliftForm />
    }
]

export default WindUpliftRoutes ;