import React from "react";
import { listContractorProjects } from "../../../services/project";
import _ from 'underscore';
import ReactPaginate from 'react-paginate';
import { message , options, pagination, ProjectPaginationOption, project_status} from "../../../components/common/utils/message";
import { useDispatch } from "react-redux";
import SelectBox from "../../../components/common/formInput/SelectBox";
import { Get_Countries,Get_State } from "../../../services/owner";
import { _status } from "../../../components/common/utils/message";
import { TabContent, TabPane, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import {useState,useEffect} from "react";
import { alert, confirm } from 'react-bootstrap-confirmation';
import {contClosedOppourtunity } from "../../../services/project";
import ListView from "../component/ListView";
import GridView from "../component/GridView";
import { wonProject } from "../services/contractor";
import NoDataFound from "../../../components/common/NoDataFound";

const WonBids = () => {
    const dispatch = useDispatch();
    let id;

    const [resPayload, setResPayload] = useState();

    const [states, setStates] = useState();
    const [countries, setCountries] = useState();
    const [country, setCountry] = useState("Afghanistan");

    const [searchKey, setSearchKey] = useState("");
    const [searchByState, setSearchByState] = useState();
    const [searchByProjectStatus, setSearchByProjectStatus] = useState();
    const [searchBystatus, setSearchByStatus] = useState();

    const totalPages = resPayload && resPayload.totalPages;

    const [limit, setLimit] = useState(pagination.projectLimit);
    const [page, setPage] = useState(pagination.page)
    const [sortBy, setSortBy] = useState();
    const [order, setOrder] = useState();

    const [currentActiveTab, setCurrentActiveTab] = useState('1');

    // Toggle active state for Tab
    const toggle = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
    }
   {/* const toggleB = tab => {
        if (currentActiveTab !== tab) setCurrentActiveTab(tab);
        bidden_Projects ({ page, limit, sort: sortBy, order: order }, (data) => {
            setResPayload(data)
        }, dispatch)
    }
*/}

    useEffect(() => {
        _getListing();
        Get_Countries(res => { setCountries(res.data) })
        _getState("Afghanistan");
    }, [dispatch]);

    const _getListing = () => {
        wonProject( { page, limit, sort: sortBy, order: order},(data) => {
            setResPayload(data)
        }, dispatch)
    }
    
    

    /**
    * @description Function for get state by selected country
    * @param {param} val country
    */
    const _getState = (val) => {
        Get_State({ country: val }, result => {
            setStates(result);
        }, dispatch)
    }

    /**
    * @description Function For delete project.
    * @param {id} id 
    */
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            // DeleteProject(id, async res => {
            //     if (res.status === "SUCCESS" && res.code === 200) {
            //         success(res.message);
            //         _getListing();
            //         setCurrentActiveTab("2")
            //         await alert(message.ProjectDeleted);
            //     }
            // })
        }
    }

    /**
     * @description /Function is on Page Change.
     * @param {param} param 
     */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await wonProject({ page: currentPage, limit, sort: sortBy, order: order }, response => {
            setResPayload(response);
        }, dispatch)
    };

    /**
   * @description Function for show in dropdown entries with page option.
   * @param {param} displayPerPage 
   */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await wonProject({ limit: displayPerPage, page, sort: sortBy, order: order }, response => {
            setResPayload(response);
        }, dispatch)
    }

    /**
    * @description Function for filter data with state fields.
    * @param {param} filterParam 
    */
    const onStateSearch = async (filterParam) => {
        setSearchByState(filterParam);
        await wonProject({ limit, page, fields: "state", filter: filterParam }, response => {
            setResPayload(response);
        }, dispatch)
    }

    /**
    * @description Function for filter data with state fields.
    * @param {param} filterParam 
    */
    const onProjectStatusSearch = async (filterParam) => {
        setSearchByProjectStatus(filterParam);
        await wonProject({ limit, page, fields: "project_status", filter: filterParam }, response => {
            setResPayload(response);
        }, dispatch)
    }

    /**
     * @description Function for filter data with status fields.
     * @param {param} filterParam 
     */
    const onStatusSearch = async (filterParam) => {
        setSearchByStatus(filterParam);
        let newParam = true;
        if (filterParam === 'Active') newParam = true
        if (filterParam === 'Inactive') newParam = false
        await wonProject({ limit, page, fields: "status", extraFilter: newParam }, response => {
            setResPayload(response);
        }, dispatch)
    }

    /**
    * @description Function for Search data with all fields.
    * @param {param} filterParam 
    */
    const onCombineSearch = async () => {
        await wonProject({ limit, page, fields: "project_title", filter: searchKey }, response => {
            setResPayload(response);
        }, dispatch)
    }

    const __handleSearch = async (val) => {
        if (val.trim() && val.trim().replace(/\./g, "")) {
            setSearchKey(val)
        } else {
            setSearchKey(val.trim().replace(/\./g, ""))
            await wonProject({ limit, page, fields: "project_title", filter: val }, response => {
                setResPayload(response);
            }, dispatch)
        }
    };

    const _handleSorting = async (sortby, order) => {
        setSortBy(sortby);
        setOrder(order)
        await wonProject({ limit: limit, page: page, sort: sortby, order: order }, response => {
            setResPayload(response);
        }, dispatch)
    }

    return (
        <>
    <div className='dashboard-inner p-b-0'>
        <div className="d-h-wrap">
            <div className="d-h-l">
                <div className="custom-seachbox">
                    <input type="search" placeholder="Search" value={searchKey} onChange={(e) => __handleSearch(e.target.value)} />
                    <button className="btn btn-primary btn-search" onClick={(e) => onCombineSearch(e)}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </button>
                </div>
            </div>
            <div className="d-h-r">
                <ul className="dashboard-sub-filters">
                    <li>
                        <div className="sortby">
                            <span>Country : </span>
                            <div className="nocolor-dropdown">
                                <SelectBox
                                    Placeholder="Select Country"
                                    options={countries}
                                    val={country}
                                    handleVal={(val) => {
                                        setCountry(val);
                                        _getState(val);
                                    }}
                                />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="sortby">
                            <span>State : </span>
                            <div className="nocolor-dropdown">
                                <SelectBox
                                    Placeholder="Select State"
                                    options={states?.data?.states?.map((item) => { return item.name })}
                                    val={searchByState}
                                    handleVal={(e) => onStateSearch(e)}
                                />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div className="d-h-wrap">
            <div className="d-h-l">
                <h4 className='dashboard-heading'>Won Bids</h4>
            </div>
            <div className="d-h-r">
                <ul className="dashboard-sub-filters">
                </ul>
            </div>
        </div>
        <div>       
        <TabContent activeTab={currentActiveTab}>
        <TabPane tabId="1">
            <Row>
                <Col sm="12">
                    <GridView
                        resPayload={resPayload}
                    />
                </Col>
            </Row>
        </TabPane>
        <TabPane tabId="2">
            <Row>
                <Col sm="12">
                    <ListView
                        resPayload={resPayload}
                        handleDelete={_handleDelete}
                        handleSorting={_handleSorting}
                    />
                </Col>
            </Row>
        </TabPane>
    </TabContent>
        </div>
        {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center"><div><NoDataFound/></div></h5>) : null}
        <div className="table-filters">
            <div className="t-f-cell">
                <div className="records-filter">
                    <span>Show</span>
                    <div className="grey-dropdown bg-white">
                        <SelectBox
                            options={ProjectPaginationOption}
                            val={limit}
                            handleVal={(e) => setPagination(e)}
                        />
                    </div>
                    <span>Entries</span>
                </div>
            </div>
            {totalPages > 1 ? (
                <div className="t-f-cell">
                    <ReactPaginate
                        breakLabel={"..."}
                        nextLabel={'Next >'}
                        onPageChange={handlePageChange}
                        pageCount={totalPages}
                        previousLabel={'< Back'}
                        renderOnZeroPageCount={null}
                        containerClassName={'custom-pagination '}
                        pageClassName={'btn-page'}
                        pageLinkClassName={'btn-page'}
                        previousClassName={'btn-page'}
                        previousLinkClassName={'btn-previous'}
                        nextClassName={'btn-page'}
                        nextLinkClassName={'btn-next'}
                        activeClassName={'active'}
                    />
                </div>
            ) : null}
        </div>
    </div>
    
</>

    )
}
export default WonBids;