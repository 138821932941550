import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteEstimate, listEstimator, updateProjectEstimator } from '../../services/estimator';
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { failed, success } from '../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";
import ReactPaginate from 'react-paginate';
import SelectBox from '../../components/common/formInput/SelectBox';
import EstimatorForm from '../estimatorAssistance/EstimatorForm';
import Icons from '../../assets/images';
import _ from 'underscore';
import { viewOneProject } from '../../services/project';
import queryString from 'query-string';
import { useLocation, useNavigate } from "react-router-dom";
import AddMasterDes from './AddMasterDes';
import { listBidForm, addBidForm, updateBidForm, getSumbitBidAdmin, 
    listMasterBidForm, createBidForm ,listMasterProjectDescription } from '../../services/project';



const CreateProDesForm = ({chooseMessageProjDesp}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const _projectId = queryString.parse(location.search); 

    const [modalShow, setModalShow] = useState(false);
    const [cloneEstimator, setCloneEstimator] = useState("");
    const handleClose = () => setModalShow(false)

    const [resPayload, setResPayload] = useState();
    const [projectName, setProjectName] = useState();

    const totalPages = resPayload && resPayload.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    let [estimatorData, setEstimatorData] = useState([]);
    let [isRightCheck, setIsRightCheck] = useState([]);
    let [isLeftCheck, setIsLeftCheck] = useState([]);
    const [isChecked, setIsChecked] = useState(true);
    const [resPayloadData, setResPayloadData] = useState();
    let [estimatorData1, setEstimatorData1] = useState([]);

    const [formValues, setFormValues] = useState(
        [
            {
                item: "",
                default_value: 0,
                allowed_in_words: false,
                is_master_lineItem: true,
                master_lineItem_id: ""
            }
        ]
    );
      
        
        useEffect(() => {
            if(_projectId.id){  
        _getListing();
      // getting Estimator data from project by project_id
        viewOneProject(_projectId, (data) => {
            setEstimatorData(data?.projectDescription);
            setProjectName(data?.project_title)
        })
    }},  [dispatch]);
   

    useEffect(() => {
        _getListing();
    }, [estimatorData])

    /**
     * @description get all the faq db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {
        let ignoreRecords = estimatorData?.map((item) => item._id)
        listMasterProjectDescription({ page, limit, ignoreRecords }, (data) => { setResPayload(data) }, dispatch)
    }

    /**
    * @description Function For delete faq.
    * @param {id} id 
    */
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            deleteEstimate(id, async res => {
                if (res.status === "SUCCESS" && res.code === 200) {
                    _getListing();
                    success(res.message);
                    await alert(message.EstimateAssistanceDeleted);
                }
            })
        }
    }

    //Left checkbox handlecheck.............
    const handleChangeCheck = (id, e) => {
        let checkedData = JSON.parse(JSON.stringify(isLeftCheck))
        if (!e.target.checked) {
            let index = checkedData.findIndex(item => item === id)
            if (index !== -1)
                checkedData.splice(index, 1)
            setIsLeftCheck([...checkedData])
        } else
            setIsLeftCheck([...checkedData, String(id)])
    }

    //Right checkbox handlecheck.............
    const _handleCheck = (id, e) => {
        let checkValue = JSON.parse(JSON.stringify(isRightCheck))
        if (!e.target.checked) {
            let index = checkValue.findIndex(item => item === id)
            if (index !== -1)
                checkValue.splice(index, 1)
            setIsRightCheck([...checkValue])
        } else
            setIsRightCheck([...checkValue, String(id)])
    }

    const _handleArrowDown = () => {
        let checkData = estimatorData.filter(e => {
            if (!isRightCheck.includes(e._id))
                return e
        })
        setEstimatorData([...checkData])
    }
   
    
    const _handleArrowUp = async () => {   
        
        let checkData1 = estimatorData.filter(e => {
            if (isRightCheck.includes(e._id))
                return e
        })
       // setEstimatorData1([...checkData1])


            let rightEstimates = resPayload
            await rightEstimates?.forEach(item => {
                if (isLeftCheck.includes(item._id)) {
                    estimatorData.push(item)
                }
            })
            setIsLeftCheck([])
            var output = rightEstimates.filter((el) => {
                if (!isLeftCheck.includes(el._id)) {
                    return el
                }
            })

            var outputData = rightEstimates.filter((el) => {
                if (isLeftCheck.includes(el._id)) {
                    return el
                }
            })

            // resPayloadData
            setResPayloadData({ ...resPayload, docs: outputData })

           

             
          
         
                
               
            let combineObj = {...outputData, ...checkData1 }

            let combinearr = [...outputData, ...checkData1 ]

          
            // typeof('checkData1 type',checkData1);
            // typeof('outputData type',outputData);
            // typeof('combineObj type',combineObj);

            // Object.prototype('Object.checkData1 type',checkData1);
            // Object.prototype('Object.combineObj type',combineObj);
            // Object.prototype('Object.outputData type',outputData);

           // debugger;
          // return chooseMessageProjDesp(resPayload.docs);
        //  return chooseMessageProjDesp(outputData);
       //   return chooseMessageProjDesp(combineObj); 
       return chooseMessageProjDesp(combinearr);
         

           
        
    }
   //  console.log(' resPayload --- --> 156', resPayload)
    //update description handle change
    const _handleChange = (item, e, attribute) => {
        for (var i = 0; i < estimatorData.length; i++) {
            if (estimatorData[i]._id === item._id) {
                estimatorData[i][attribute] = e.target.value
            }
        }
        setEstimatorData([...estimatorData])
    }

    //update description textare  some changes  
    // const updateInput = (item, className) => {
    //     return (
    //         <div className='f-field' style={{ border: '0.5px solid #ccc' }}>
    //             <textarea
    //                 className={className}
    //                 type="text"
    //                 value={className === "f-area" ?
    //                     item.description :
    //                     item.title
    //                 }
    //                 onChange={(e) => { _handleChange(item, e, className === "f-area" ? "description" : "title") }} />
    //         </div>
    //     )
    // }

    /**
    * @description Function For Update Estimator.
    * @param {id} id 
    */
    // const _updateData = async () => {
    //     if (_projectId.id) {
    //         let itemArray = [];
    //         estimatorData.forEach(element => {
    //             let tempJson = {
    //                 item: element.item,
    //                 default_value: 0,
    //                 allowed_in_words: false,
    //                 is_master_lineItem: true,
    //                 master_lineItem_id: element._id
    //             }
    //             itemArray.push(tempJson);


    //         });
    //       let resData = {
    //             "project_id": _projectId.id,
    //             "line_items": itemArray
    //         }
    //         try {
    //             createBidForm(resData, result => {
    //                 if (result.status === "SUCCESS" && result.code === 200) {
    //                     success(result.message)
    //                     navigate("project-view/"`${_projectId.id}`)
    //                 } else if (result.status === "ERROR" && result.code === 403) {
    //                     failed(result.message)
    //                 }
    //             }, dispatch)
    //         } catch (e) {
    //             failed(e)
    //         }
    //     } else {
    //         failed("Project is not associated! Select a project first!.")
    //     }
    // }

    /**
    * @description /Function is on Page Change.
    * @param {param} param 
    */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listMasterBidForm({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };

    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listMasterBidForm({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }
    const onCancelData = async () => {
        const result = await confirm(message.cancelProjectForm, options);
        if (result) {
            // alert(message.canceled);
            navigate('/project-list')
        }
    }
    return (
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Project Descriptions</h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters dashboard-sm-sub-filters">
                        <li>

                        </li>
                        <li>
                            { <button className="btn btn-blue btn-min-width-200" onClick={() => { setModalShow(true); setCloneEstimator("") }} >Add Descriptions</button> }
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    {/* <h6 className='text-center'>Master Estimator Assistance</h6> */}
                </div>
                <div className="d-h-r">
                    {projectName ? <h6>Project Name:<span> {projectName}</span></h6> :
                        <h6 className='text-center'></h6>}
                </div>
            </div>
            <div className='bid-container'>
                <div className="dashboard-table">
                    <h3 className="text-center mb-2"> Master Description List </h3>
                    <div className="responsive-table">
                        <table className='table-update dark-header'>
                            <thead>
                                <tr>
                                    <th className="text-center">Select</th>
                                    <th>
                                        <div className='master-estimate'>
                                            <h4 style={{ color: '#fff' }}>Line Item</h4>
                                            <h6 style={{ color: '#fff' }}>Description</h6>
                                        </div>
                                    </th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {resPayload && resPayload?.map((dataItem, index) => (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <label className='checkbox nolabel' htmlFor={`check004${index}`}>
                                                    <input type="checkbox"
                                                        checked={isLeftCheck.includes(dataItem._id)}
                                                        onClick={(e) => { handleChangeCheck(dataItem._id, e) }}
                                                        id={`check004${index}`}
                                                    />
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td> 
                                                <div className='master-estimate'>
                                                    <h4>{dataItem.title}</h4>
                                                    <h6>{dataItem.description}</h6>
                                                </div>
                                            </td>
                                            <td className="text-center">

                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                
                <div className='estimator-arrow' style={{ padding: '20px 0' }}>
                    
                    {/* <button className="btn btn-red" onClick={() => _handleArrowDown()}> Remove From Project </button> */}
                </div>

                <div className="dashboard-table">
                    <h3 className="text-center mb-2"> Project Description List </h3>
                    <div className="responsive-table">
                        <table className='table-update dark-header'>
                            <thead>
                                <tr>
                                    <th className="text-center">Select</th>
                                    <th>Line Item Description</th>
                                    <th style={{ width: 'auto' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estimatorData?.map((itemData, index) => (
                                    <tr key={index}>
                                        <td className="text-center"> 
                                           
                                            <label className='checkbox nolabel' >
                                                <input type="checkbox" id={`check005${index + 1}`}
                                                defaultChecked={isChecked}
                                                    checked={isRightCheck.includes(itemData._id)} 
                                                    onClick={(e) => _handleCheck(itemData._id, e)} />
                                                <span></span>
                                            </label> 

                                        </td>
                                        <td> <h4> {(itemData.title)} </h4> <h6> {(itemData.description)}</h6> </td>

                                        <td>
                                            <button className="btn btn-red" onClick={() => _handleArrowDown()}> Delete </button>

                                        </td>

                                    </tr>
                                ))}
                                {estimatorData === undefined || estimatorData?.length === 0 ? (<tr><td colSpan={3} className="text-center"> No data found! </td> </tr>) : null}
                            </tbody>
                        </table>

                    </div>
                </div>
                <div className='estimator-arrow' style={{ padding: '20px 0' }}>
                    <button className="btn btn-blue" onClick={() => _handleArrowUp()} style={{ marginRight: '10px' }}> Submit</button>
                    <button className="btn btn-grey" type="button" onClick={onCancelData}>Cancel</button>
                </div>
                <div className="d-h-wrap">
                    <div className="d-h-l">

                    </div>
                    <div className="d-h-r  mt-2">
                        <ul className="dashboard-sub-filters dashboard-sm-sub-filters">  
                            <li></li>
                            <li>
                                {/* <button className="btn btn-blue"  onClick={() => chooseMessageProjDesp(resPayload.docs) }>Submit</button> */}
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <AddMasterDes
                show={modalShow}
                handleClose={handleClose}
                cloneEstimator={cloneEstimator}
                handleModal={() => { _getListing(); setCloneEstimator("") }}
            />
        </div>
    )

};

export default CreateProDesForm;