import React from "react";
import Icons from "../../assets/images/index";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { useEffect } from "react";
import { getAllInvoices, downloadInvoice } from "../../services/rpc";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Invoice from "./component/InvoiceList";

const InvoiceDetails = () => {
    const dispatch = useDispatch();
    const [invoiceData, setInvoiceData] = useState([])

    useEffect(() => {
        getAllInvoices((result) => {
            setInvoiceData(result.data)
        }, dispatch)
    }, [dispatch])

    const downloadInvoiceAcion = (id) => {
        downloadInvoice({ bid_id: id }, (res) => {
        }, dispatch)
    }
    return (
        <div className='dashboard-inner'>
            <div className="d-h-l m-3">
                <h4 className='dashboard-heading'>Invoices</h4>
            </div>
            <div className="accordians">
                {invoiceData && invoiceData.map(e => {
                    return <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <div className="acc-header-inner">
                                    <div className="acc-h-cell">{e.bids[0].project[0].project_title}</div>

                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                {e.bids.map(x => {
                                    return <Invoice name={x._id} download={downloadInvoiceAcion} />
                                })}

                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                })}

            </div>
        </div >
    )
}

export default InvoiceDetails

