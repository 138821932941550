import React, { useState, useEffect } from "react";
import moment from 'moment';
import Icons from "../../assets/images/index";
import { Link, useParams, useNavigate } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
// import EstimatorAssistant from "../manageProject/component/EstimatorAssistant";
// import StandardContract from "../manageProject/component/StandardContract";
import SelectedSamplePhotos from "../manageProject/component/SelectedSamplePhotos";
// import Bids from "./component/Bids";
// import ViewingActivity from "./component/ViewingActivity";
// import AnswerQuestions from "./component/AnswerQuestions";
// import { DeleteProject, viewOneProject } from "../../services/project";
import { DeleteProject } from "../../services/project";
import { project_details } from "./services/contractor";
import { contMediaImages } from "./services/contractor";
import { success } from "../../components/common/Toastify";
import { alert, confirm } from "react-bootstrap-confirmation";
// import { message, options } from "../../components/common/utils/message";
import { message, options } from "../../components/common/utils/message";
import _ from 'underscore';
import { API_BASEURL } from "../../environment";

// import { setParamFirst, setParamSecond } from "../../components/common/general/GeneralSlice";
import { setProjectID } from "../../components/common/general/GeneralSlice";
import { useDispatch } from "react-redux";
// import SendBidInvitation from "./component/SendBidInvitation";
import images from '../../assets/images/logo.svg'
import { contStripe } from "./services/contractor";
import { Message } from "./common/Message";
import { useSelector } from 'react-redux';
import NoDataFound from "../../components/common/NoDataFound";
const ContractorProjectView = () => {
  let project_id = useParams();
  const [projectData, setProjectData] = useState();
  const [projectMedia, setProjectMedia] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isHide, setHideButton] = useState(false);
  const [messages, setMessage] = useState("");
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false)

  const handleShow = async () => {
    setModalShow(true)
    await contStripe(project_id, (data, res) => {
      sessionStorage.setItem('session_id', JSON.stringify(data.id))
      sessionStorage.setItem('project_id', JSON.stringify(project_id.id))
      window.location.href = data.url
    }, dispatch)
      ;
  }


  dispatch(setProjectID(project_id.id));
  const navigate = useNavigate();
  let type;

  const loginUserState = useSelector((purchased_opportunities
  ) => purchased_opportunities
    .login?.user?.data?.user?.purchased_opportunities
  )
  useEffect(() => {

    project_details(project_id, (data) => {
      setProjectData(data);
      if (data.project_status === 'close') {
        setIsButtonDisabled(true);
      }

      if (data.project_status === 'close') {
        setHideButton(true)
      }
    })
  }, [])



  useEffect(() => {
    contMediaImages(project_id, type = "images", (data) => {
      let newData = JSON.parse(JSON.stringify(data))
      setProjectMedia(newData);
    })

  }, [])

  /**
  * @description Function For delete project.
  * @param {id} id 
  */
  const _handleDelete = async (id) => {
    const result = await confirm(message.deleteData, options);
    if (result) {
      DeleteProject(id, async res => {
        if (res.status === "SUCCESS" && res.code === 200) {
          success(res.message);
          navigate("/project-list")
          await alert(message.ProjectDeleted);
        }
      })
    }
  }

  const onCancel = async () => {
    const result = await confirm(message.cancelProjectView, options);
    if (result) {
      navigate('/project-list')
    }
  }

  // some code
  let found = loginUserState.find(loginUserState => loginUserState === project_id.id);
  return (
    <div className="dashboard-inner">
      <div className="d-h-wrap m-t-15">
        <div className="d-h-l">
          <h4 className='dashboard-heading'>  {projectData?.project_title}</h4>
        </div>

        <div className="d-h-r">
          <ul className="dashboard-sub-filters dashboard-sm-sub-filters">

            {found === undefined ? <li>

              <button type="button" className="btn btn-blue btn-min-width-200" onClick={handleShow} disabled={isButtonDisabled}>Buy Oppurtunity</button>
            </li> : <li>  <button type="button" className="btn btn-blue btn-min-width-200" disabled>ALREADY PRUCHASED</button>
            </li>}



          </ul>
        </div>



      </div>
      <div className="project-view m-b-25">
        <span className="project-view-img">
          <img src={projectMedia?.length > 0 ? `${API_BASEURL}/${_.findWhere(projectMedia, { type: "images" })?.path}` : images} alt="" />
        </span>
        <div className="project-view-header">
          <div className="project-view-header-l">
            <h4 className="project-view-time">
              <span>
                {/* <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.5915 0.0443902C6.7585 0.355997 3.34287 2.54611 1.45104 5.90518C-0.137981 8.72655 -0.439425 12.1803 0.633308 15.2736C1.19971 16.9068 2.04706 18.2711 3.28035 19.5354C6.73585 23.0778 12.067 24.0069 16.5253 21.8436C18.9309 20.6763 20.9299 18.6178 21.9952 16.2106C23.749 12.2475 23.1814 7.76262 20.4926 4.34024C19.8614 3.53675 18.6032 2.40018 17.7267 1.84156C16.749 1.21854 15.5384 0.685637 14.4659 0.406274C13.6511 0.193995 13.1096 0.103389 12.2834 0.041009C11.5489 -0.0144618 11.3091 -0.0139717 10.5915 0.0443902ZM12.9944 2.2177C13.9868 2.39426 14.6972 2.61981 15.5618 3.0329C19.1916 4.76696 21.3096 8.59802 20.8425 12.5847C20.4325 16.0852 18.0984 19.0705 14.8089 20.3021C10.6679 21.8525 6.0444 20.3412 3.61872 16.6445C2.91805 15.5767 2.45119 14.3727 2.19648 12.9764C2.07761 12.325 2.07761 10.6877 2.19648 10.0363C2.38479 9.00405 2.60424 8.31292 3.01892 7.44631C4.42174 4.5143 7.17348 2.55787 10.4689 2.14949C10.9934 2.08446 12.4697 2.12435 12.9944 2.2177ZM11.0083 4.31525C10.887 4.38003 10.7215 4.52694 10.6405 4.64171L10.4934 4.85041L10.4787 8.26416C10.468 10.7407 10.4795 11.7317 10.5206 11.8739C10.5675 12.036 10.8666 12.3602 12.2508 13.7495C14.0334 15.5386 14.1975 15.6716 14.6239 15.6716C15.3815 15.6716 15.8787 14.8952 15.5653 14.2015C15.4923 14.04 15.0728 13.5875 13.9982 12.5109L12.5307 11.0408L12.5174 7.94947L12.504 4.8581L12.37 4.6663C12.1555 4.35945 11.9298 4.23474 11.5531 4.2147C11.2838 4.20039 11.1918 4.21734 11.0083 4.31525Z" fill="#27316F" />
                </svg> */}
              </span>
            </h4>
          </div>
          <div className="project-view-header-r">
            <ul className="project-view-list">
              {/* <li>
                <button className="btn-m-action">
                  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 3H3C1.89543 3 1 3.89543 1 5V19C1 20.1046 1.89543 21 3 21H17C18.1046 21 19 20.1046 19 19V5C19 3.89543 18.1046 3 17 3Z" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M14 1V5" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 1V5" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1 9H19" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </li>
              <li>
                <button className="btn-m-action">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M5 8L10 13L15 8" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 13V1" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </li> */}
              {/* <li>
                <Link className="btn-m-action" title="Update" to={`/project-form?id=${project_id.id}`}>
                  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 3.12109H3C2.46957 3.12109 1.96086 3.33181 1.58579 3.70688C1.21071 4.08195 1 4.59066 1 5.12109V19.1211C1 19.6515 1.21071 20.1602 1.58579 20.5353C1.96086 20.9104 2.46957 21.1211 3 21.1211H17C17.5304 21.1211 18.0391 20.9104 18.4142 20.5353C18.7893 20.1602 19 19.6515 19 19.1211V12.1211" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5 1.62132C17.8978 1.2235 18.4374 1 19 1C19.5626 1 20.1022 1.2235 20.5 1.62132C20.8978 2.01915 21.1213 2.55871 21.1213 3.12132C21.1213 3.68393 20.8978 4.2235 20.5 4.62132L11 14.1213L7 15.1213L8 11.1213L17.5 1.62132Z" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Link>
              </li>
              <li>
                <button className="btn-m-action" title="Delete" onClick={() => _handleDelete(project_id.id)}>
                  <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 5H3H19" stroke="#FF5121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6 5V3C6 2.46957 6.21071 1.96086 6.58579 1.58579C6.96086 1.21071 7.46957 1 8 1H12C12.5304 1 13.0391 1.21071 13.4142 1.58579C13.7893 1.96086 14 2.46957 14 3V5M17 5V19C17 19.5304 16.7893 20.0391 16.4142 20.4142C16.0391 20.7893 15.5304 21 15 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5H17Z" stroke="#FF5121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8 10V16" stroke="#FF5121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 10V16" stroke="#FF5121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </li>
            */}
            </ul>
          </div>
        </div>
        <div className="project-view-body">
          <ul className="p-infos">
            <li>
              <div className="p-info">
                <span>
                  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 5.88867C11 9.77756 6 13.1109 6 13.1109C6 13.1109 1 9.77756 1 5.88867C1 4.56259 1.52678 3.29082 2.46447 2.35314C3.40215 1.41546 4.67392 0.888672 6 0.888672C7.32608 0.888672 8.59785 1.41546 9.53553 2.35314C10.4732 3.29082 11 4.56259 11 5.88867Z" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.00016 7.55501C6.92064 7.55501 7.66683 6.80882 7.66683 5.88835C7.66683 4.96787 6.92064 4.22168 6.00016 4.22168C5.07969 4.22168 4.3335 4.96787 4.3335 5.88835C4.3335 6.80882 5.07969 7.55501 6.00016 7.55501Z" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <p>{projectData?.city},{projectData?.state},{projectData?.country}</p>
              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3889 2.11133H2.61111C1.99746 2.11133 1.5 2.60879 1.5 3.22244V11.0002C1.5 11.6139 1.99746 12.1113 2.61111 12.1113H10.3889C11.0025 12.1113 11.5 11.6139 11.5 11.0002V3.22244C11.5 2.60879 11.0025 2.11133 10.3889 2.11133Z" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.72217 1V3.22222" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.27783 1V3.22222" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.5 5.44434H11.5" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <p className="desc-info-detail">{moment(projectData?.createdAt).format('DD/MM/YYYY')}</p>
              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="3.75" stroke="#27316F" strokeWidth="2.5" />
                  </svg>
                </span>
                <p className={projectData?.status ? "text-success" : "text-danger"}>{projectData?.status ? "Active" : "Inactive"}</p>
              </div>
            </li>
            <li>
              <div className="p-info">

              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="9" height="12" stroke="#27316F" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.7143 3.28571H6.28571V9.71429H12.7143V3.28571ZM5 2V11H14V2H5Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 4H7V9H12V4ZM6 3V10H13V3H6Z" fill="#27316F" />
                    <rect x="7" y="4" width="5" height="5" fill="white" />
                  </svg>
                </span>
                <p>Size : {projectData?.square_footage} SF</p>
              </div>
            </li>
          </ul>
          <ul className="project-info-sub">
            <li>
              <h6>Status</h6>
              <h5>{projectData?.project_status}</h5>

            </li>
            <li>
              <h6>Bidding Period</h6>
              <h5>{projectData?.bid_start_date} to {projectData?.bid_end_date} </h5>


            </li>
            {/*
           <li>
              <h6>Owner Name</h6>
              <h5>{projectData?.ownerId?.first_name}</h5>

            </li>
            <li>
              <h6>Email Address</h6>
              <h5>{projectData?.ownerId?.email}</h5>
            </li>
            <li>
              <h6>Location, City & State</h6>
              <h5>{projectData?.city} {projectData?.state}</h5>
            </li>
          */}

            <li>

              <h6>Winning bid Fee</h6>
              <h5>NA</h5>
            </li>
          </ul>
        </div>
      </div>

      <div className="desc-info">

        <h6 className="desc-info-detail">Project Description</h6>
        <h5 className="desc-info-detail">Addtional notes</h5>
        <p className="desc-info-heading">{projectData?.notes}</p>
      </div>
      <div className="desc-info">
        <h6 className="desc-info-detail">Project Description</h6>
        <p className="desc-info-heading" dangerouslySetInnerHTML={{ __html: projectData?.description }} />

        <p>

          <div class="dashboard-table">
            <div className="responsive-table">
              <table className='table-update dark-header'  >
                <thead  >
                  <tr >

                    <th className="text-center ">
                      <h5>Title</h5>
                    </th>
                    <th className="text-center">
                      <h5>Description</h5>
                    </th>

                  </tr>
                </thead>
                <tbody >
                  {projectData && projectData?.projectDescription
                    .map((dataItem, index) => (
                      <tr key={index} >

                        <td>
                          <div className='master-estimate'>
                            <h4>{dataItem.title}</h4>

                          </div>
                        </td>
                        <td className="text-center">
                          <div className='master-estimate'>
                            <h5>{dataItem.description}</h5>
                          </div>

                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {projectData?.projectDescription?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center"><NoDataFound /></h5>) : null}
            </div>

          </div>

        </p>



      </div>
      <div className="accordians">

      </div>

    </div>
  );
}

export default ContractorProjectView;