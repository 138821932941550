import { ErrorMessage, useField } from 'formik'
import React, { useCallback,useMemo } from "react";
import "./FileField.scss";
//import Dropzone from "react-dropzone";
import Icons from '../../../assets/images';
import {useDropzone} from 'react-dropzone';


export const DragDropField = ({ ...props }) => {

    const [field, meta, helpers] = useField(props.name);

    const onRemove = (inx) => {
        let metadata = meta.value
        metadata.splice(inx, 1)
        helpers.setValue(metadata)
    }

    /*
     * function when drop event occurs
     */
    const onDrop = useCallback(
        (acceptedFiles) => {
            if (acceptedFiles && acceptedFiles.length) {
                helpers.setValue(acceptedFiles)
            }
        },
    );
    const imagetype=props.accept;

    const {
        getRootProps,
        getInputProps,
        isDragReject,
      } = useDropzone({
        onDrop,
        accept: {
          'images/*' :[imagetype] ,
          'application.pdf':[imagetype],
          'application.mp4': [imagetype],
        }
      });
      const rejectStyle = {
        borderColor: '#ff1744'
      };

      const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
      };

      const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragReject
      ]);
      
    return (
        <>
            <label className="f-label" htmlFor={props.name}>{props.label}{props.isrequired ? <span className="error">*</span> : ""}</label>
            <div className='f-field'>
                <div className="draggable-wrap">
                    <label
                        className="draggable-box"
                        htmlFor={props.id}
                    >
                        {/* <Dropzone
                            onDrop={onDrop}
                            accept= 'image/jpg' 
                            
                        >
                            {({ getRootProps, getInputProps ,isDragReject }) => (
                                <> */}
                                    <div className="inner-blc">
                                        <div {...getRootProps({style})}  >
                                            <input
                                                {...getInputProps()}
                                                {...props}
                                                type="file"
                                                // onChange={(e) => {
                                                //     helpers.setValue(Array.from(e.target.files));
                                                // }}
                                                autoComplete="off"
                                                multiple
                                            />
                                            <span className="file-icon">
                                                <img src={Icons.file} alt="" />
                                            </span>
                                            <h4>Drag file(s) here to upload</h4>
                                            <p>Alternatively, you can select file by</p>
                                            <h6>Clicking here.</h6>
                                        </div>
                                    </div>
                                    {isDragReject && (<p>"File type not accepted, sorry!"</p>)}
                                {/* </>
                            )}
                        </Dropzone> */}
                        {meta?.value &&
                            meta?.value?.map((item, key) => (
                                <div className='mainFileDes'>
                                    <span key={key}>
                                        {item.name}
                                    </span>
                                    <i class="bi bi-x-circle-fill" onClick={(e) => { e.stopPropagation(); e.preventDefault(); onRemove(key) }}></i>
                                </div>
                            ))
                        }
                        {props?.media?.length > 0 && props?.media?.map((item, key) => (
                            <div className='mainFileDes'>
                                <span key={key}>
                                    {item.filename}
                                </span>
                                <i class="bi bi-x-circle-fill" onClick={(e) => { e.stopPropagation(); e.preventDefault(); props?.updatemedia(item._id, item.filename) }}></i>
                            </div>
                        ))}
                    </label>
                </div>
                <ErrorMessage component="div" name={props.name} className="error" />
            </div>
        </>
    )
}

