import React from "react";
import Icons from "../../assets/images/index";
import Accordion from "react-bootstrap/Accordion";

const PrivilegeList = () => {
    return (
        <div className="dashboard-inner">
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <div className="custom-seachbox">
                        <input type="search" placeholder="Search" />
                        <button className="btn btn-primary btn-search" >
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <div className="sortby">
                                <span>State : </span>
                                <div className="nocolor-dropdown">
                                    <select>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="sortby">
                                <span>Sort by : </span>
                                <div className="nocolor-dropdown">
                                    <select>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Viewing Privileges</h4>
                </div>
            </div>
            <div className="accordians">
                <Accordion >
                    <Accordion.Item eventKey={0} key={0}>
                        <Accordion.Header >South Mountain Crossing [rpc102494-001]</Accordion.Header>
                        <Accordion.Body>
                            <div className='responsive-table'>
                                <table className='table-odd-even cellSpace15'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={2} key={2}>
                        <Accordion.Header >South Mountain Crossing [rpc102494-001]</Accordion.Header>
                        <Accordion.Body>
                            <div className='responsive-table'>
                                <table className='table-odd-even cellSpace15'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={3} key={3}>
                        <Accordion.Header >South Mountain Crossing [rpc102494-001]</Accordion.Header>
                        <Accordion.Body>
                            <div className='responsive-table'>
                                <table className='table-odd-even cellSpace15'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={4} key={4}>
                        <Accordion.Header >South Mountain Crossing [rpc102494-001]</Accordion.Header>
                        <Accordion.Body>
                            <div className='responsive-table'>
                                <table className='table-odd-even cellSpace15'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey={5} key={5}>
                        <Accordion.Header >South Mountain Crossing [rpc102494-001]</Accordion.Header>
                        <Accordion.Body>
                            <div className='responsive-table'>
                                <table className='table-odd-even cellSpace15'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='bid-details'>
                                                    <span className='bid-icon'>
                                                        <img src={Icons.Owner} alt='' />
                                                    </span>
                                                    <h4>Ronald Richards</h4>
                                                </div>
                                            </td>
                                            <td className='text-right'>Location: California</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </div>
    )
}

export default PrivilegeList

