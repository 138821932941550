import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { API_BASEURL } from '../../../environment';
import { getPageBySlug } from '../../../services/cms.service';

const AboutUs = () => {
    const dispatch = useDispatch();
    const location = useLocation()

    const [pageData, setPageData] = useState(null)

    useEffect(() => {
        getPageBySlug(location.pathname, (data) => {
            setPageData(data)
        }, dispatch)
    }, [])

    return (
        <>
            {pageData && <>
                <div className="banner-section m-b-30">
                    <img src={`${API_BASEURL}/${pageData?.banner_images[0].path}`} alt="" />
                </div>
                <h4 className='dashboard-title p-b-20'>{pageData.page_title}</h4>
                <div className="static-desc m-b-30">
                    {pageData.page_content}
                </div></>
            }
        </>

    )
}

export default AboutUs;