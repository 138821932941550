import React, { useEffect, useState } from "react";
import Icons from "../../../assets/images/index";
import { pagination,PaginationOption } from "../../../components/common/utils/message";
import ReactPaginate from 'react-paginate';
import SearchSelectBox from "../../../components/common/formInput/SearchSelectBox";
import { useDispatch } from "react-redux";
import { _status } from "../../../components/common/utils/message";



import { success,failed } from "../../../components/common/Toastify";
import "./style.css";
import { listSendBidInvitation } from "../../../services/project";
const SendBidInvitationList = () => {

    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState([]);
    const [openForm, setOpenForm] = useState(false);
    const [openSendNotificationForm, setOpenSendNotificationForm] = useState(false);

    const [selectedMenu, setSelectedMenu] = useState(null);
    const totalPages = resPayload && resPayload.totalPages;

    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page);
    const [sortBy, setSortBy] = useState()
    const [order, setOrder] = useState(1)

   

    useEffect(() => {
        _getListing();
    }, [dispatch]);

    /**
     * @description get all the RPC from db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {

        listSendBidInvitation({ page, limit, sortBy, order }, (data) => {
            setResPayload(data?.data?.docs)
        }, dispatch);
    }

    /**
     * @description /Function is on Page Change.
     * @param {param} param 
     */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listSendBidInvitation({ page: currentPage, limit, sort: sortBy, order: order }, data => {
            setResPayload(data?.data?.docs)
        }, dispatch)
    };

    /**
     * @description Function For delete RPC.
     * @param {id} id 
     */


    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);

        await listSendBidInvitation({ limit: displayPerPage, page }, data => {
            setResPayload(data?.data?.docs);
        }, dispatch)
    }

    /**
     * @description Function for get state by selected country
     * @param {param} val country
     */


    /**
     * @description Function for filter data with state fields.
     * @param {param} filterParam 
     */


    /**
    * @description Function for filter data with status fields.
    * @param {param} filterParam 
    */


    /**
    * @description Function for Search data with all fields.
    * @param {param} filterParam 
    */


    /**
    * @description Function for Search data with all fields.
    * @param {param} filterParam 
    */
    const _handleSorting = async (sortby, order) => {
        setSortBy(sortby);
        setOrder(order);
        await listSendBidInvitation({ limit: limit, page: page, sort: sortby, order: order }, response => {
            setResPayload(response.data.docs);
        }, dispatch)
    }
   
    return (
        <div className='dashboard-inner'>


            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Invitation List</h5>
                    <div className='d-card-actions'>
                       
                    </div>

                </div>

            </div>
            <div className="dashboard-table">
                <div className="responsive-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Serial <br /> No. </th>
                                <th><span className="sort-icon">Email</span></th>
                                <th><span className="sort-icon">bid_start</span></th>
                                <th><span className="sort-icon">bid_end</span></th>
                                <th>Status</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {resPayload?.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item. not_registered_contractors}</td>
                                    <td>{item.bid_start}</td>
                                    <td>{item.bid_start}</td>
                                    <td
                                    // className={item.enabled ? "text-success" : "text-danger"}
                                    >
                                        {/* {item.enabled ? "Active" : "Inactive"} */}
                                       


                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {resPayload?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No data found!</h5>) : null}
                </div>

            </div>
           
        </div>
    )
}

export default SendBidInvitationList

