import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from "react-redux";
// import { addFaq, updateFaq, listOneFaq } from '../../services/faq';

import { asqQuestion } from '../../../services/questionAns';
import { Formik, Form } from "formik";
import TextField from '../../../components/common/formInput/TextField';
import { useNavigate } from "react-router-dom";
import { failed,success } from '../../../components/common/Toastify';
// import { faqSchema } from '../../components/common/validations/Validation';
import { askQueContractorSchema } from '../../../components/common/validations/Validation';
import Editor from '../../../components/common/formInput/Editor';
import { _status } from '../../../components/common/utils/message';
import SearchSelectBox from '../../../components/common/formInput/SearchSelectBox';
import { useSelector } from "react-redux";



const AsqQuesForm = ({ handleClose, show, editFaq, handleModal }) => {
    let projID = useSelector(state => state.general.setProjectID);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();

    const [initialValue, setInitialValue] = useState({
      
        question_text: "",
       
    });
    
    
    // useEffect(() => {
    //     if (editFaq)
    //         listOneFaq({ _id: editFaq }, (data) => { setResPayload(data) }, dispatch)
    //     else
    //         setResPayload({})
    // }, [editFaq]);

    useEffect(() => {
        setInitialValue({
            project_id: resPayload?.project_id ? resPayload.project_id : "",
            question_text: resPayload?.question_text ? resPayload.question_text : ""
           
            
        })
    }, [resPayload]);

   

    /**
    * @description Function is for add Faq.
    * @param {object} data 
    */
    const handleChange1 = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInitialValue(values => ({ name : value }))
        
        
        
   
    }
    
    const _submitData = (data) => {     

        let reqDataa ={

            "question_text": data.question_text.replace(/<\/?[^>]+(>|$)/g, ""),
       
           "project_id": projID
       
       }
      
        try {
            asqQuestion(reqDataa, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleModal();
                    handleClose();
                    success(result.message)
                    // navigate("/Question/Answer")
                    navigate(`/Question/Answer/${projID}`)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update Faq
     * @param {object} data 
     */
    const _updateData = async (data) => {
       
    };

    return (
        <div className='dashboard-inner'>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{resPayload?.question_text ? "Edit FAQ’s" : "Add New Question’s"}</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValue}
                    enableReinitialize
                    validationSchema={askQueContractorSchema}
                    onSubmit={(values) => resPayload?.question_text ? _updateData(values) : _submitData(values)}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className="f-row alignTop">
                                    <div className="f-50">

                                    </div>
                                </div>
                                <div className="f-row alignTop">
                                    <div className="f-100">
                                        <Editor
                                            label="Question"
                                            name="question_text"
                                            defaultValue={formik?.values?.question_text}
                                          //  onChange={formik.handleChange}
                                          onChange={e => handleChange1(e)}
                                            required={true}
                                        />
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li><button className="btn btn-primary">{resPayload?.question_text ? "Edit" : "Add"}</button></li>
                                    <li><button type='button' className="btn btn-grey" onClick={handleClose}>Cancel</button></li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default AsqQuesForm

