import React from 'react';
import { Link } from 'react-router-dom';
import Icons from '../../assets/images/index'
import { listProjects , project_details_data} from '../../services/project';
import { useState, useEffect } from 'react';
import { pagination, PaginationOption } from "../../components/common/utils/message";
import { useDispatch } from "react-redux";
import SelectBox from "../../components/common/formInput/SelectBox";
import ReactPaginate from 'react-paginate';

export default function Dashboard() {
    const dispatch = useDispatch();
    const [resPayload, setResPayload] = useState();
    const [resPayloadData, setResPayloadData] = useState();
    const [activeData, setActiveData] = useState();
    const totalPages = resPayload && resPayload.totalPages;

    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    useEffect(() => {
        project_details_data( (data) => {
            setResPayloadData(data)
        }, dispatch)
    }, [dispatch]);


    useEffect(() => {
        listProjects({ page, limit: 5 }, (data) => {
            setResPayload(data)
        }, dispatch)
    }, [dispatch]);
    /**
* @description Function for show in dropdown entries with page option.
* @param {param} displayPerPage 
*/
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listProjects({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }

    /**
* @description /Function is on Page Change.
* @param {param} param 
*/
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listProjects({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };
    
console.log( ' resPayloadData --- ',resPayloadData)
if( resPayloadData != undefined){
    console.log( ' resPayloadData projectSummary--- ',resPayloadData.projectSummary)
}

    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Dashboard</h4>
            <ul className='dashboard-tiles'>
                <li> 
                    <Link to="/project-list" className='d-tile light-blue'>
                        <div className='d-tile-header'>
                            <div className='d-t-left'> 
                                <h4>{resPayload?.totalDocs}</h4>
                                <p>Current Projects</p>
                            </div>
                            <div className='d-t-right'>
                                <span className='tile-icon'>
                                    <img src={Icons.rocket} alt='' />
                                </span>
                            </div>
                        </div>
                        <div className='d-tile-footer'>
                        {/* Start/smartdata/staticData*/}
                        
                            <h5> <span></span></h5>
                        </div>
                    </Link>
                </li>
                <li>

                {/* Start/smartdata/staticData*/}

                    <Link to="/project-list" className='d-tile light-red'>
                        <div className='d-tile-header'>
                            <div className='d-t-left'>
                                <h4>{resPayloadData != undefined ? resPayloadData.totalActiveBids : ' '}</h4>
                                <p>Active New Bids</p>
                            </div>
                            <div className='d-t-right'>
                                <span className='tile-icon'>
                                    <img src={Icons.hammer} alt='' />
                                </span>
                            </div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/project-list" className='d-tile light-blue'>
                        <div className='d-tile-header'>
                            <div className='d-t-left'>
                                <h4>
                                {resPayloadData != undefined ? resPayloadData.projectSummary.openProjects : ' '}</h4>
                                <p>Open Project</p>
                            </div>
                            <div className='d-t-right'>
                                <span className='tile-icon'>
                                    <img src={Icons.hammer} alt='' />
                                </span>
                            </div>
                        </div>
                    </Link>
                </li>
                <li>
                    <Link to="/project-list" className='d-tile light-green'>
                        <div className='d-tile-header'>
                            <div className='d-t-left'>
                                <h4>{resPayloadData != undefined ? resPayloadData.projectSummary.closedProjects : ' '}</h4>
                                <p>Closed Project</p>
                            </div>
                            <div className='d-t-right'>
                                <span className='tile-icon'>
                                    <img src={Icons.user} alt='' />
                                </span>
                            </div>
                        </div>
                        <div className='d-tile-footer'>
                            
                        </div>
                    </Link>
                </li>
            </ul>
            <div className='dashboard-cards-row'>
                <div className='d-card-col-68'>
                    <div className='d-card vl-padding m-b-30'>
                        <div className='d-card-body'>
                            <ul className='maps-list'>
                                <li>
                                    <Link to="/" className='map-box'>
                                        <img src={Icons.map01} alt='' />
                                        <div className='map-desc'>
                                            <h5>United States</h5>
                                            <h6>Projects : 145</h6>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className='map-box'>
                                        <img src={Icons.map02} alt='' />
                                        <div className='map-desc'>
                                            <h5>Alaska</h5>
                                            <h6>Projects : 145</h6>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className='map-box'>
                                        <img src={Icons.map03} alt='' />
                                        <div className='map-desc'>
                                            <h5>Hawaii</h5>
                                            <h6>Projects : 145</h6>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/" className='map-box'>
                                        <img src={Icons.map04} alt='' />
                                        <div className='map-desc'>
                                            <h5>Caribbean</h5>
                                            <h6>Projects : 145</h6>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='d-card'>
                        <div className='d-card-header'>
                            <h5 className='d-card-heading'>Active Projects</h5>
                            <div className='d-card-actions'>
                                {/* <div className='sortby'>
                                    <span>Sort by :</span>
                                    <div className='nocolor-dropdown'>
                                        <select>
                                            <option>Location</option>
                                            <option>Location</option>
                                            <option>Location</option>
                                            <option>Location</option>
                                        </select>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className='d-card-body p-b-0 p-t-0 r-m-b-30'>
                            <div className='responsive-table'>
                                <table className='table-odd-even'>
                                    <tbody>
                                        {resPayload && resPayload.docs.map((item, inx) => {
                                            if (item.status === true) {
                                                return <tr key={inx}>
                                                    <td> { console.log(' item ---  206 ', item)}
                                                        <div className='bid-details'>
                                                            <span className='bid-icon'>
                                                                <img src={Icons.projectImg} alt='' />
                                                            </span>
                                                            <h4>{item.project_title}</h4>
                                                            <h5>Company Name : Deserunt Ullamco</h5>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h5 className='bid-closing'>Bid Closed On : {item.bid_end_date}</h5>
                                                    </td>
                                                </tr>
                                            }
                                        })}
                                    </tbody>
                                    <div className="table-filters">
                                        <div className="t-f-cell">
                                            <div className="records-filter">
                                                <span>Show</span>
                                                <div className="grey-dropdown bg-white">
                                                    <SelectBox
                                                        options={PaginationOption}
                                                        val={limit}
                                                        handleVal={(e) => setPagination(e)}
                                                    />
                                                </div>
                                                <span>Entries</span>
                                            </div>
                                        </div>
                                        {totalPages > 1 ? (
                                            <div className="t-f-cell">
                                                <ReactPaginate
                                                    breakLabel={"..."}
                                                    nextLabel={'Next >'}
                                                    onPageChange={handlePageChange}
                                                    pageCount={totalPages}
                                                    previousLabel={'< Back'}
                                                    renderOnZeroPageCount={null}
                                                    containerClassName={'custom-pagination '}
                                                    pageClassName={'btn-page'}
                                                    pageLinkClassName={'btn-page'}
                                                    previousClassName={'btn-page'}
                                                    previousLinkClassName={'btn-previous'}
                                                    nextClassName={'btn-page'}
                                                    nextLinkClassName={'btn-next'}
                                                    activeClassName={'active'}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-card-col-32'>
                    <div className='d-card m-b-30'>
                        <div className='d-card-header'>
                            <h5 className='d-card-heading'>Projects</h5>
                            <div className='d-card-actions'>
                                {/* <div className='grey-dropdown'>
                                    <select>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                    </select>
                                </div> */}
                            </div>
                        </div>
                        <div className='d-card-body'>
                            <div className='graph'>
                                <img src={Icons.graph} alt='' />
                            </div>
                        </div>
                    </div>
                    <div className='d-card'>
                        <div className='d-card-header'>
                            <h5 className='d-card-heading'>Winning bids</h5>
                            <div className='d-card-actions'>
                                <h6 className='card-sub-heading'>Total bids : <span>100</span></h6>
                            </div>
                        </div>
                        <div className='d-card-body'>
                            <ul className='bids-list'>
                                <li>
                                    <div className='bid'>
                                        <div className='bid-header'>
                                            <div className='bid-header-left'>
                                                <h6 className='bid-label'>Customer Name</h6>
                                                <h5 className='bid-value'>Devon Lane</h5>
                                            </div>
                                            <div className='bid-header-right'>
                                                <span className='bid-date'>12/05/2022</span>
                                            </div>
                                        </div>
                                        <div className='bid-footer'>
                                            <div className='bid-footer-left'>
                                                <h6 className='bid-label'>Project Name</h6>
                                                <h5 className='bid-value'>Amet minim mollit</h5>
                                            </div>
                                            <div className='bid-footer-right'>
                                                <h4 className='bid-price'>$10.99</h4>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className='bid'>
                                        <div className='bid-header'>
                                            <div className='bid-header-left'>
                                                <h6 className='bid-label'>Customer Name</h6>
                                                <h5 className='bid-value'>Devon Lane</h5>
                                            </div>
                                            <div className='bid-header-right'>
                                                <span className='bid-date'>12/05/2022</span>
                                            </div>
                                        </div>
                                        <div className='bid-footer'>
                                            <div className='bid-footer-left'>
                                                <h6 className='bid-label'>Project Name</h6>
                                                <h5 className='bid-value'>Amet minim mollit</h5>
                                            </div>
                                            <div className='bid-footer-right'>
                                                <h4 className='bid-price'>$10.99</h4>

                                                  {/* END /smartdata/staticData*/}
                                                  
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
