import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { Link, useNavigate } from "react-router-dom";
import { DeleteOwner, listOwners, Get_State, Get_Countries } from "../../services/owner";
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { success } from "../../components/common/Toastify";
import ReactPaginate from 'react-paginate';
import SelectBox from "../../components/common/formInput/SelectBox";
import { setParamFirst } from "../../components/common/general/GeneralSlice";
import { useDispatch } from "react-redux";
import { _status } from '../../components/common/utils/message';

const AllOwners = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [countries, setCountries] = useState();
  const [country, setCountry] = useState("United States");
  const [states, setStates] = useState();

  const [resPayload, setResPayload] = useState();

  const [searchKey, setSearchKey] = useState("");
  const [searchByState, setSearchByState] = useState();
  const [searchBystatus, setSearchByStatus] = useState();

  const totalPages = resPayload && resPayload.totalPages;

  const [limit, setLimit] = useState(pagination.limit);
  const [page, setPage] = useState(pagination.page);
  const [sortBy, setSortBy] = useState();
  const [order, setOrder] = useState();

  useEffect(() => {
    _getListing();
    Get_Countries(res => { setCountries(res.data) });
    _getState("United States");
  }, [dispatch]);

  /**
   * @description get all the contractors from db where page is pageChange and Limit for limit of the data.
   */
  const _getListing = () => {
    listOwners({ page, limit, sortBy, order }, (data) => {
      setResPayload(data);
    }, dispatch);
  }

  /**
   * @description /Function is on Page Change.
   * @param {param} param 
   */
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    setPage(currentPage)
    await listOwners({ page: currentPage, limit, sort: sortBy, order: order }, response => {
      setResPayload(response);
    }, dispatch)
  };

  /**
   * @description Function For delete owner.
   * @param {id} id 
   */
  const _handleDelete = async (id) => {
    const result = await confirm(message.deleteData, options);
    if (result) {
      DeleteOwner(id, async res => {
        if (res.status === "SUCCESS" && res.code === 200) {
          _getListing();
          success(res.message);
          await alert(message.OwnerDeleted);
        }
      }, dispatch)
    }
  }

  /**
   * @description Function for show in dropdown entries with page option.
   * @param {param} displayPerPage 
   */
  const setPagination = async (displayPerPage) => {
    setLimit(displayPerPage);
    await listOwners({ limit: displayPerPage, page }, response => {
      setResPayload(response);
    }, dispatch)
  }

  /**
  * @description Function for get state by selected country
  * @param {param} val country
  */
  const _getState = (val) => {
    Get_State({ country: val }, result => {
      setStates(result);
    }, dispatch)
  }

  /**
   * @description Function for filter data with state fields.
   * @param {param} filterParam 
   */
  const onStateSearch = async (filterParam) => {
    setSearchByState(filterParam);
    await listOwners({ limit, sortBy, order, fields: "state", filter: filterParam }, response => {
      setResPayload(response);
    }, dispatch)
  }
  
  const onCountySearch = async (filterParam) => {
    setSearchByState(filterParam);
    await listOwners({ limit, sortBy, order, fields: "country", filter: filterParam }, response => {
        setResPayload(response);
    }, dispatch)
}


  /**
   * @description Function for filter data with status fields.
   * @param {param} filterParam 
   */
  const onStatusSearch = async (filterParam) => {
    setSearchByStatus(filterParam);
    let newParam = true;
    if (filterParam === 'Active') newParam = true
    if (filterParam === 'Deactivate') newParam = false
    await listOwners({ limit, sortBy, order, fields: "status", extraFilter: newParam }, response => {
      setResPayload(response);
    }, dispatch)
  }

  /**
  * @description Function for Search data with all fields.
  * @param {param} filterParam 
  */
  const onCombineSearch = async () => {
    await listOwners({ limit, sortBy, order, fields: "user_id,first_name,company_name,email,state,city", filter: searchKey }, response => {
      setResPayload(response);
    }, dispatch)
  }

  /**
  * @description Function for Search data with all fields.
  * @param {param} filterParam 
  */
  const _handleSorting = async (sortby, order) => {
    setSortBy(sortby);
    setOrder(order)
    await listOwners({ limit: limit, page: page, sort: sortby, order: order }, response => {
      setResPayload(response);
    }, dispatch)
  }

  const __handleSearch = async (val) => {
    if (val.trim() && val.trim().replace(/\./g, "")) {
      setSearchKey(val)
    } else {
      setSearchKey(val.trim().replace(/\./g, ""))
      await listOwners({ limit, sortBy, order, fields: "user_id,first_name,company_name,email,state,city", filter: val }, response => {
        setResPayload(response);
      }, dispatch)
    }
  };

  return (
    <div className="dashboard-inner">
      <div className="d-h-wrap">
        <div className="d-h-l">
          <div className="custom-seachbox">
            <input type="search" placeholder="Search" value={searchKey} onChange={(e) => __handleSearch(e.target.value)} />
            <button className="btn btn-primary btn-search"  disabled={!searchKey}  onClick={(e) => onCombineSearch(e)} >
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
        <div className="d-h-r">
          <ul className="dashboard-sub-filters">
            <li>
              <div className="sortby">
                <span>Country : </span>
                <div className="nocolor-dropdown">
                  <SelectBox
                    Placeholder="Select Country"
                    options={countries}
                    val={country}
                    handleVal={(val) => {
                      setCountry(val)
                      _getState(val);
                      onCountySearch(val);
                    }}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="sortby">
                <span>State : </span>
                <div className="nocolor-dropdown">
                  <SelectBox
                    Placeholder="Select State"
                    options={states?.data?.states?.map((item) => { return item.name })}
                    val={searchByState}
                    handleVal={(e) => onStateSearch(e)}
                  />
                </div>
              </div>
            </li>
            <li>
              <div className="sortby">
                <span>Sort by : </span>
                <div className="nocolor-dropdown">
                  <SelectBox
                    Placeholder="Select Status"
                    options={_status.map(item => item.label)}
                    val={searchBystatus}
                    handleVal={(e) => onStatusSearch(e)}
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="d-h-wrap">
        <div className="d-h-l">
          <h4 className='dashboard-heading'>Owner List</h4>
        </div>
        <div className="d-h-r">
          <ul className="dashboard-sub-filters">
            <li>
              <Link to="/add-owner" className="btn btn-blue">Add Owner</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="dashboard-table">
        <div className="responsive-table">
          <table>
            <thead>
              <tr>
                <th>Serial <br /> No. </th>
                <th>User Id</th>
                <th><span className="sort-icon">Name<i class="bi bi-arrow-down" onClick={() => _handleSorting("first_name", -1)}></i><i class="bi bi-arrow-up" onClick={() => _handleSorting("first_name", 1)}></i></span></th>
                <th><span className="sort-icon">Company Name<i class="bi bi-arrow-down" onClick={() => _handleSorting("company_name", -1)}></i><i class="bi bi-arrow-up" onClick={() => _handleSorting("company_name", 1)}></i></span></th>
                <th><span className="sort-icon">Email Address<i class="bi bi-arrow-down" onClick={() => _handleSorting("email", -1)}></i><i class="bi bi-arrow-up" onClick={() => _handleSorting("email", 1)}></i></span></th>
                <th><span className="sort-icon">Mobile Number<i class="bi bi-arrow-down" onClick={() => _handleSorting("mobile_no", -1)}></i><i class="bi bi-arrow-up" onClick={() => _handleSorting("mobile_no", 1)}></i></span></th>
                <th><span className="sort-icon">Location<i class="bi bi-arrow-down" onClick={() => _handleSorting("city", -1)}></i><i class="bi bi-arrow-up" onClick={() => _handleSorting("city", 1)}></i></span></th>
                <th>Status</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {resPayload && resPayload.docs.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.user_id ? item.user_id : "old"}</td>
                  <td>{item.first_name}</td>
                  <td>{item.company_name}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile_no}</td>
                  <td>{item.city},{item.state}</td>
                  <td className={item.status === true ? "text-success" : "text-danger"}>{item.status === true ? "Active" : "Inactive"}</td>
                  <td className="text-center">
                    <span className="sort-icon">
                      <button className="dashboard-action" title="View" onClick={(e) => { navigate('/owner-view'); dispatch(setParamFirst(item._id)); }}>
                        <img src={Icons.eye} alt="" />
                      </button>
                      <Link className="dashboard-action" to={`/add-owner?id=${item._id}`} title="Edit">
                        <img src={Icons.edit} alt="" />
                      </Link>
                      <button className="dashboard-action" title="Delete" onClick={() => _handleDelete(item._id)}>
                        <img src={Icons.bin} alt="" />
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No data found!</h5>) : null}
        </div>
        <div className="table-filters">
          <div className="t-f-cell">
            <div className="records-filter">
              <span>Show</span>
              <div className="grey-dropdown bg-white">
                <SelectBox
                  options={PaginationOption}
                  val={limit}
                  handleVal={(e) => setPagination(e)}
                />
              </div>
              <span>Entries</span>
            </div>
          </div>
          {totalPages > 1 ? (
            <div className="t-f-cell">
              <ReactPaginate
                breakLabel={"..."}
                nextLabel={'Next >'}
                onPageChange={handlePageChange}
                pageCount={totalPages}
                previousLabel={'< Back'}
                renderOnZeroPageCount={null}
                containerClassName={'custom-pagination '}
                pageClassName={'btn-page'}
                pageLinkClassName={'btn-page'}
                previousClassName={'btn-page'}
                previousLinkClassName={'btn-previous'}
                nextClassName={'btn-page'}
                nextLinkClassName={'btn-next'}
                activeClassName={'active'}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AllOwners;
