import React from "react";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
// import LeftMenu from "../../../features/contractorInterface/common/LeftMenu"; 
import LoaderUI from "../loader/loader";
import Header from "../../../features/contractorInterface/common/Header";
import Footer from "../../../features/contractorInterface/common/Footer";
import { useNavigate } from "react-router-dom";
import LeftMenuContractor from "../../../features/dashboard/LeftMenuContractor";
// import HeaderMenu from "../../../features/dashboard/HeaderMenu";
import FooterMenu from "../../../features/dashboard/FooterMenu";
import HeaderMenuContractor from "../../../features/dashboard/HeaderMenuContractor";

const ContractorInterfaceLayout = ({ children }) => {
    //Responsive menu toggle
    const [toggle, setToggle] = React.useState(false);

    const handleToggle = () => {
        setToggle(!toggle);
    };

    const navigate = useNavigate();
    let loginUser = useSelector(state => state.login?.user?.data?.user)
    if (loginUser.role !== "CONTRACTOR")
        navigate('/dashboard')

    let isLoading = useSelector(state => state.loader.isLoading);
    return (
        // <main className={`${toggle ? 'toggled' : ''} main`}>
        //     <ContractorHomeHeader toggle={handleToggle} />
        //     <span className='overlay' onClick={handleToggle}></span>
        //     <ToastContainer />
        //     <div className="v-wrapper">
        //         <div className='container'>
        //             {isLoading && <LoaderUI
        //                 loader={isLoading}
        //                 overlay={true}
        //                 overlayRadius='rounded-10'
        //                 FullWindow={true}
        //                 color="danger"
        //                 animation="border"
        //                 customSize="lg"
        //             />}
        //             {children}
        //         </div>
        //     </div>
        //     <ContractorHomeFooter />
        // </main>

        // <div className={`${toggle ? "active-sidebar" : ""} dashboard`}>
        //     <Header />
        //     <ToastContainer />
        //     <div className="dashboard-body">
        //         <LeftMenuContractor />
        //         <div className="dashboard-main">
        //             {isLoading && <LoaderUI
        //                 loader={isLoading}
        //                 overlay={true}
        //                 overlayRadius='rounded-10'
        //                 FullWindow={true}
        //                 color="danger"
        //                 animation="border"
        //                 customSize="lg"
        //             />}
        //             {children}
        //         </div>
        //     </div>
        //     <Footer />
        //     <span className="overlay" onClick={handleToggle}></span>
        // </div>

        <div className={`${toggle ? "active-sidebar" : ""} dashboard`}>
        <HeaderMenuContractor />
        
            <ToastContainer />
            <div className="dashboard-body">
                <LeftMenuContractor />
                <div className="dashboard-main">
                    {isLoading && <LoaderUI
                        loader={isLoading}
                        overlay={true}
                        overlayRadius='rounded-10'
                        FullWindow={true}
                        color="danger"
                        animation="border"
                        customSize="lg"
                    />}
                    {children}
                </div>
            </div>
            <FooterMenu />
            <span className="overlay" onClick={handleToggle}></span>
        </div>

    );
};

export default ContractorInterfaceLayout;
