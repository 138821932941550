import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all materialType data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listMaterialType = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/material/view/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data)
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description add new MaterialType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addMaterialType = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/material/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description update MaterialType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateMaterialType = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/rpc/material/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description Soft Delete MaterialType in db
 * @param {*} id 
 * @param {*} callback 
 */
export const DeleteMaterialType = async (id, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.delete(`${apiUrl}${PORT}/rpc/material/delete/${id}`, option)
        .then((response) => {
          callback(response.data)
        }).catch((error) => {
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};


export const viewOneMaterialType = async (param, callback) => {
  try {
      let token = localStorage.getItem('authentication')
      if (token) {
          let option = {
              headers: {
                  'Authorization': `Bearer ${token}`
              }
          }
          axios.get(`${apiUrl}${PORT}/rpc/material/view?${queryString.stringify(param)}`, option).then((response) => {
              callback(response.data.data)
          }).catch((error) => {
              callback(false)
          });
      }
  } catch (err) {
      console.log(err);
  }
};

/**
 * @description update status of materialType in db.
 * @param {*} data 
 * @param {*} callback 
 */
 export const updateMaterialStatus = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/material/update/status`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};