import Modal from 'react-bootstrap/Modal';
import React, { useEffect, useState } from 'react'
import Icons from "../../../assets/images/index";
import { bids } from '../../../services/project';
import moment from 'moment';
import { project_bids } from '../services/owner';
import { useDispatch } from 'react-redux';

const ProjectBids = ({ projectData, onClose }) => {
    const dispatch = useDispatch();

    const [modalShow, setModalShow] = useState(false);
    const [bidders, setBidders] = useState();
    const [resPayload, setResPayload] = useState();

    useEffect(() => {
        getbidsList();
    }, [])

    const getbidsList = () => {
        project_bids({ project_Id: projectData._id }, (data) => {
            setResPayload(data)
        }, dispatch)
    }

    return (
        <>
            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Project Bids</h5>
                    <button className='btn btn-grey m-2' onClick={onClose}>Close</button>
                </div>
                <div className='d-card-body'>
                    <div className='responsive-table'>
                        <table className='table-odd-even'>
                            <tbody>
                                {resPayload && resPayload.docs.map((item) => (
                                    <tr onClick={() => { setModalShow(true); setBidders(item) }}>
                                        <td>
                                            <div className='bid-details'>
                                                <span className='bid-icon'>
                                                    <img src={Icons.Owner} alt='' />
                                                </span>
                                                <h4>{item?.contractor_id?.first_name}</h4>
                                            </div>
                                        </td>
                                        <td>
                                            <h5 className='bid-hd-sm'>Date</h5>
                                            <h5 className='bid-hd-l'>{moment(item.createdAt).format('DD/MM/YYYY')}</h5>
                                        </td>
                                        <td>
                                            <h5 className='bid-hd-sm'>Bid Price</h5>
                                            <h5 className='bid-hd-l'>{item.bidPrice}</h5>
                                        </td>
                                        <td>
                                            <h5 className='bid-hd-sm'>Distance From Project</h5>
                                            <h5 className='bid-hd-l'>20 Mile</h5>
                                        </td>
                                        <td>
                                            <h5 className='bid-hd-sm'>Location</h5>
                                            <h5 className='bid-hd-l'>{item?.contractor_id?.city}</h5>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Bid Done yet!</h5>) : null}
                    </div>
                </div>
            </div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="noBorder">
                </Modal.Header>
                <Modal.Body>
                    <div className="bidder-wrapper">
                        <div className="bidder-header">
                            <span className="bidder-img">
                                <img src={Icons.Owner} alt='' />
                            </span>
                            <div className="f-row">
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-md-detail">Name:{bidders?.contractor_id?.first_name}</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-md-detail">Date: 14/05/2022</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-md-detail">Bid Price: $5599 USD</p>
                                    </div>
                                </div>
                            </div>
                            <div className="f-row">
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-detail">Location:{bidders?.contractor_id?.first_name}</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-detail">Distance From Project: 20 Mile</p>
                                    </div>
                                </div>
                            </div>
                        </div><div className="f-row">
                            <div className="f-100">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Details by Contractor</h6>
                                    <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                </div>
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Company Name</h6>
                                    <p className="desc-info-detail">{bidders?.contractor_id?.company_name}</p>
                                </div>
                            </div>
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Company Description</h6>
                                    <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ProjectBids