import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { useNavigate } from "react-router-dom";
import { listRpc } from "../../services/rpc";
import { useDispatch, useSelector } from "react-redux";
import { pagination } from "../../components/common/utils/message";
import { API_BASEURL } from "../../environment";

const ManufacturerView = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();

    useEffect(() => {
        listRpc({ page: pagination.page }, (data) => { setResPayload(data) }, dispatch);
    }, [dispatch]);

    const general = useSelector((state) => state.general)
    const get_rpc = resPayload && resPayload.docs.find((item) => item._id === general.param)

    return (
        <div className='dashboard-inner'>
            {/* <div className='d-h-wrap'>
                <div className='d-h-l'>
                    <div className="custom-seachbox">
                        <input type="search" placeholder="Search" onChange={(e) => setSearchKey(e.target.value)} />
                        <button className="btn btn-primary btn-search">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className='d-h-r'>
                    <ul className='dashboard-sub-filters'>
                        <li>
                            <div className='sortby'>
                                <span>State : </span>
                                <div className='nocolor-dropdown'>
                                    <select>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='sortby'>
                                <span>Sort by : </span>
                                <div className='nocolor-dropdown'>
                                    <select>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div> */}
            <h4 className='dashboard-heading p-b-15'>View RPC Manager</h4>
            <div className="owner">
                <div className="owner-left">
                    <span className="owner-img">
                        <img src={get_rpc?.company_logo ? `${API_BASEURL}/${get_rpc.company_logo}` : (Icons.profile)} alt="" />
                    </span>
                    <h4>{get_rpc?.first_name} {get_rpc?.last_name}</h4>
                    <p>{get_rpc?.email}</p>
                </div>
                <div className="owner-right">
                    <h5>Location: {get_rpc?.street_address} {get_rpc?.city} {get_rpc?.state}</h5>
                </div>
            </div>
        </div>
    );
};

export default ManufacturerView;
