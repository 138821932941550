import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icons from '../../../assets/images/index';
import { success } from '../../../components/common/Toastify';
import { message } from '../../../components/common/utils/message';
import { logout } from '../../auth/loginSlice';

const HomeHeader = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const loginDetails = useSelector((state) => state.login)

    const Logout = () => {
        dispatch(logout());
        success(message.loggedOut)
    }

    const onClickDashboard = () => {
        if (loginDetails?.user?.data?.user?.role === "OWNER") {
            navigate('/owner-dashboard')
        } else {
            navigate('/dashboard')
        }
    }

    //Responsive menu toggle
    const [toggle, setToggle] = React.useState(false)

    const handleToggle = () => {
        setToggle(!toggle)
    }

    return (
        <header className='site-header'>
            <div className="top-header">
                <div className='container'>
                    <ul className='top-links'>
                        <li>
                            <a href='tel:(203)231-2843'><span className='link-icon'><img src={Icons.phone} alt="" /></span>(203) 231-2843</a>
                        </li>
                        <li>
                            <a href='mailto:test123@test.com'><span className='link-icon'><img src={Icons.email} alt="" /></span>Email us</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="bottom-header">
                <div className='container'>
                    <div className={toggle ? 'main toggled header-inner' : "header-inner"}>
                        <div className='brand'>
                            <Link to="/" >
                                <img src={Icons.logo} alt="RP" />

                            </Link>
                        </div>
                        <ul className="dashboard-menu">
                            {/* <li className="responsive-close">
                                <button type="button" className="btn-toggle" onClick={() => handleToggle()} >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                            </li> */}
                            <li className={`${location?.pathname === `/about-us` ? 'active' : ""}`}>
                                <Link to="/about-us" className="menu-item">About Us</Link>
                            </li>
                            <li className={`${location?.pathname === `/building-owners` ? 'active' : ""}`}>
                                <Link to="/building-owners" className="menu-item">Building Owners</Link>
                            </li>
                            <li className={`${location?.pathname === `/roofing-contractors` ? 'active' : ""}`}>
                                <Link to="/roofing-contractors" className="menu-item">Contractors</Link>
                            </li>
                            <li className={`${location?.pathname === `/material-manufacturers` ? 'active' : ""}`}>
                                <Link to="/material-manufacturers" className="menu-item">Material Manufacturers</Link>
                            </li>
                            <li className={`${location?.pathname === `/library-of-terms` ? 'active' : ""}`}>
                                <Link to="/library-of-terms" className="menu-item">Library of Terms</Link>
                            </li>
                            <li className={`${location?.pathname === `/faq` ? 'active' : ""}`}>
                                <Link to="/faq" className="menu-item">FAQ</Link>
                            </li>
       
                            <li className='btn-item'>
                                {loginDetails?.isLoggedIn ? <button onClick={() => onClickDashboard()} className="btn btn-grey">Dashboard</button> :
                                    <Link to="/registration" className="btn btn-grey">Register</Link>
                                }
                            </li>
                            <li className='btn-item'>
                                {loginDetails?.isLoggedIn ? <Link to="" className="btn btn-blue" onClick={Logout}>Log Out</Link> :
                                    <Link to="/login" className="btn btn-blue">Log In</Link>}
                            </li>
                        </ul>
                        <button type="button" className="btn-toggle" onClick={() => handleToggle()} >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default HomeHeader