import React, { useState } from "react";

const SelectBox = React.forwardRef(({ Name, Placeholder, val = "", id = '', optName = "name", isSub = false, optNameChild = 'en', optVal = "_id", options, handleVal }) => {
  const [opt, setOpt] = useState({ value: val, isValid: false });

  const handleOnChange = (value) => {
    if (value && value !== Placeholder) {
      if (value.length !== 0) {
        setOpt({ value: value, isValid: false });
        handleVal(value);
      }
    } else {

      setOpt({ value: "", isValid: true });
      handleVal('');
    }
  };
  const charactorLimit = (item) => item && item.length > 65 ? `${item.substring(0, 65)}...` : item
  return (
    <>
      {Name && <label htmlFor="name">{Name}</label>}
      {/* {Name && isRequired && <span style={{ 'color': '#ff0000' }}>  *</span>} */}
      <select
        id={id}
        value={val}
        opt={opt}
        onChange={($event) => {
          handleOnChange($event ? (typeof $event === "string" ||
            typeof $event === "number" ? $event : $event.target && $event.target.value) : '');
        }}
      >
        (<option value="">
           {Placeholder}</option>);

        {options && options.map((item, index) => {
          if (typeof item === 'object')
            return (<option key={index} value={item[optVal]}>
              {isSub ? charactorLimit(item[optName][optNameChild]) : charactorLimit(item[optName])}</option>);
          return (<option key={index} value={item}>
            {charactorLimit(item)}</option>);
        })}
      </select>
    </>
  );
}
);

export default SelectBox;

