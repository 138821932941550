import React, { useEffect, useState } from "react";
import Icons from "../../../assets/images";
import Modal from 'react-bootstrap/Modal';
import { bids, listBidResultAccordian , awardProjectForm} from '../../../services/project';
import { makeBidWinner, generateInvoice, downloadInvoice, mailInvoice } from "../../../services/rpc";
import { useParams } from "react-router-dom";
import moment from 'moment';
import { success, failed } from "../../../components/common/Toastify";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";

import { useLocation, useNavigate } from "react-router-dom";

// add bid form dialog start
function BidFormModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Bid Form"}</Modal.Title>
            </Modal.Header> {console.log('props in bid form model', props)}
            {console.log('props in bid line_items model', props.userData.total_bid_amount_in_words)}
            
            <Formik>
                {(formik) => (
                    <Form className='custom-form view-detail border-0 px-4'>
                        <Modal.Body className="p-0">
                            <Formik
                            // initialValues={formValues}
                            // validationSchema={valueBidFormSchemaProject}
                            // enableReinitialize
                            // onSubmit={handleUpdate}
                            //    onSubmit={(values) => {
                            //     _submitData(values);
                            // }}
                            >
                                {(formik) => (
                                    <div className='d-card'>
                                        <div className='f-row'>
                                            <div className='f-100'>
                                                <h6 className='mb-2'>Provide the name of the Material Manufacturer/Supplier you are a Certified Installer
                                                    for and will be issuing the required number of years Warranty for Roof Project </h6>
                                            </div>
                                            <div className='f-50'>
                                                {/* <ReactSelect
                                                                className="pro-input-two pro-select-two-new"
                                                                options={manufacturerData}

                                                                closeMenuOnSelect={false}
                                                                hideSelectedOptions={false}
                                                                components={{
                                                                    Option
                                                                }}
                                                                onChange={selectManfacturer}
                                                                allowSelectAll={true}
                                                                value={selectedManufactures}
                                                                defaultValue={checkBId.selected_manufacturer}
                                                            /> */}
                                            </div>
                                            <div className='f-100 my-4'>
                                                <h3>BIDDING INSTRUCTIONS :</h3>
                                                <p>1. Please type in your bid price(s) in both words and figures in the appropriate line items given.</p>
                                                <p>2. Please type in your bid price(s) in both words and figures in the appropriate line items given.</p>
                                                <p>3. The amounts only accept (0123456789.) absolutely no commas or $ sign.</p>
                                                <p>4. The winning bidder will be charged a fee of $17,780.
                                                    Please review “ answer “to roofer questions and the “EA”
                                                    before placing your bid as new information may be added since you last review them.</p>
                                            </div>
                                        </div>

                                        <div className='bid-outer-wrap'>
                                            <div className='table-responsive'>
                                                <table>
                                                    <thead>
                                                        <th className='text-center th-bg' colSpan={3}>DealPoint Bid Information For #0295 Acme- Seaside Heights, NJ </th>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                            <td><h6>Total Bid Amount In Words </h6></td>
                                                            <td><h6>Bid Amount ($) </h6></td>
                                                        </tr>

                                                        <tr>
                                                            <td></td>
                                                            <td>
                                                                <input type="text" name='total_bid_amount_in_words' defaultValue={props.userData.total_bid_amount_in_words}  class="form-control f-input" disabled />
                                                            </td>
                                                            <td>
                                                                <input type="text" name='total_bid_amount' defaultValue={props.userData.total_bid_amount} class="form-control f-input" disabled />

                                                            </td>
                                                        </tr>


                                                        {  props.userData.line_items && props.userData.line_items?.map((itemdata2, index) => (
                                                                        <tr key={index}>
                                                                            <td>1</td>
                                                                            <td>{itemdata2.item}</td>
                                                                            <td> <input type="text" name={index} defaultValue={itemdata2.bid_amount}  class="form-control f-input"  disabled /></td>
                                                                        </tr>
                                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <p className="bid-time"> We can start project on or before {props.userData.expected_start_date}
                                                and it will take us
                                                {/* <TextField
                            label=""
                            className='f-days'
                            name="days_to_complete" /> */}

                                                <input type="text" name='days_to_complete' defaultValue={props.userData.days_to_complete} class=" f-days form-control f-input" disabled />
                                                days to complete.
                                            </p>
                                        </div>
                                    </div>
                                )
                                }
                            </Formik >

                        </Modal.Body>
                        <Modal.Footer>
                            <ul className="modal-actions">
                               
                                <li><button type='button' className="btn btn-grey" onClick={props.onHide}>Cancel</button></li>
                            </ul>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
// end with add bid form dialog


// add award project dialog start
function AwardProjectModal(props) {

    const navigate = useNavigate();
const dispatch = useDispatch();

    console.log('AwardProject model props', props);
    console.log('Award ProjectprojecIDDD ', props.userData.project_id._id);
    let bid_id = props.userData._id;

    console.log('AwardProject bid_id ', bid_id);

    const _handleAwarded = (bidId) => {

        console.log('bidId ---- bidId --- ',bidId);

        
         let resData = {

            "bid_id": bidId
         
         }

        try {
            awardProjectForm(resData, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate(`/project-view/${props.userData.project_id._id}`)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    }

    

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Award Project"}</Modal.Title>
            </Modal.Header>
            <Formik>
                {(formik) => (
                    <Form>
                        <Modal.Body>
                            <div className="f-row">
                                <div className="f-100">
                                    <p>Do you want to add award project?</p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <ul className="modal-actions">
                                <li><button className="btn btn-primary" type='submit' onClick={() => _handleAwarded(bid_id)}>Add</button></li>
                                <li><button type='button' className="btn btn-grey" onClick={props.onHide}>Cancel</button></li>
                            </ul>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
// end with award project dialog

const Bids = (props) => {

    const [bidmodalShow, setBidModalShow] = React.useState(false);
    const [AwardmodalShow, setAwardModalShow] = React.useState(false);

    const dispatch = useDispatch();
    const [modalShow, setModalShow] = useState(false);
    const [resPayload, setResPayload] = useState();
    const [resPayloadData, setResPayloadData] = useState();
    const [bidders, setBidders] = useState();

    let project_id = useParams();
    console.log( ' Before If condition ', project_id.id);
    if(project_id.id == null  ){
        project_id.id = props.projectId
    }else{
        project_id.id = project_id.id
    }
   
  

    useEffect(() => {
        getbidsList(project_id.id)
    }, [])

    useEffect(() => {
        _getListingAccordian();
    }, [dispatch]);

    const getbidsList = (project_id) => {
        let id = { project_Id: project_id }
        bids(id, (data) => {
            setResPayloadData(data)
        })
    }
    // code for according bid list start
    const _getListingAccordian = () => {
        listBidResultAccordian(project_id.id, (data) => {

            setResPayload(data)
        }, dispatch)
    }

    // end
    const bidWinnerSubmit = () => {
        const { _id, project_Id } = resPayload.docs[0];
        makeBidWinner({ bidId: _id, projectId: project_Id }, (result) => {
            if (result.status === "SUCCESS" && result.code === 200) {
                getbidsList(project_id.id);
                success(result.message);
                setModalShow(false)

            } else if (result.status === "ERROR" && result.code === 403) {
                failed(result.message)
            } else if (result.status === "ERROR" && result.code === 409) {
                failed(result.message)
                setModalShow(false)
            }
        }, dispatch)
    }
    const generateInvoiceAcion = () => {
        generateInvoice({ bid_id: bidders._id }, (res) => {
            success(res.message);
            setModalShow(false)
        }, dispatch)
    }
    const downloadInvoiceAcion = () => {
        downloadInvoice({ bid_id: bidders._id }, (res) => {
            setModalShow(false)
        }, dispatch)
    }

    const mailInvoiceAction = () => {
        mailInvoice({ bid_id: bidders._id, email: bidders.contractor_id.email }, (res) => {
            success(res.message);
            setModalShow(false)
        }, dispatch)
    }

    // hide show row
    const data = [{
        "id": 1,
        "Contractor": "Exteriors, Inc. dba: CALIFORNIA COMMERCIAL ROOFING SYSTEMS",
        "Address": "Ventura, CA",
        "phone-email": "805-644-1640, dweaver@calcommercialroofing.com",
        "Manufacturer": "Carlisle",
        "Amount": "20.00",
        "sq-ft": " ",
        "Start-Date": "Carlisle",
        "days": "40",
    },
    {
        "id": 2,
        "Contractor": "Exteriors By Design, Inc. dba: ROOFING SYSTEMS",
        "Address": "Ventura, CA",
        "phone-email": "805-644-1640, dweaver@roofing.com",
        "Manufacturer": "Carlisle",
        "Amount": "20.00",
        "sq-ft": " ",
        "Start-Date": "Carlisle",
        "days": "40",
    }];

    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState([]);

    // State variable to keep track which row is currently expanded.
    const [expandState, setExpandState] = useState({});

    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleEpandRow = (event, userId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(userId);

        let obj = {};
        isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
        setExpandState(obj);

        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== userId) :
            currentExpandedRows.concat(userId);

        setExpandedRows(newExpandedRows);
    }
    // end hide show

    console.log('resPayload - for bid result ', resPayload);
    return (
        <>
            <div className="d-card">
                <div className="d-card-header border-0">
                    <h5 className="d-card-heading">  Bid Results  </h5>
                </div>
                <div className="d-card-body p-0 bid-result">
                    <div className="dashboard-table">
                        <div className='responsive-table'>

                            <table>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Contractor</th>
                                        <th className="text-center">Address</th>
                                        <th className="text-center">Phone / Email</th>
                                        <th className="text-center">Manufacturer</th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">$ / Sq Ft</th>
                                        <th className="text-center">Start Date</th>
                                        <th className="text-center"># of Days</th>
                                        <th className="text-center">Bid Winner</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Info</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        resPayload && resPayload.map((user) =>
                                            <>
                                                <tr key={user._id}>
                                                    <td>

                                                    </td> { console.log(' user -- IN BId --- user ---->',user)}
                                                    <td>
                                                        {user.submitted_by.company_name}
                                                    </td>
                                                    <td>
                                                        {user.submitted_by.city} ,   {user.submitted_by.state}
                                                    </td>
                                                    <td>
                                                        {user.submitted_by.mobile_no} {'/'}  {user.submitted_by.email}
                                                    </td>
                                                    <td>
                                                        {user.selected_manufacturer.company_name}
                                                    </td>
                                                    <td>
                                                        {user.total_bid_amount}
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        {user.expected_start_date}
                                                    </td>
                                                    <td>
                                                        {user.days_to_complete}
                                                    </td>
                                                    <td> 
                                                        {(user.is_bid_winner == false) ? " NO" : " YES"}
                                                    </td>
                                                    <td>
                                                        {user.bid_status}
                                                    </td>

                                                    <td>
                                                        <a className="link"
                                                            variant="link"
                                                            onClick={event => handleEpandRow(event, user._id)}>
                                                            {
                                                                expandState[user._id] ?
                                                                    'hide' : 'Show'
                                                            }
                                                        </a>
                                                    </td>
                                                </tr>
                                                <>
                                                    {
                                                        expandedRows.includes(user._id) ?
                                                            <tr>
                                                                <td colspan="10">
                                                                    <div className="expand-area">
                                                                        <p>Contractor's profile goes here. </p>
                                                                        <p>Tell the owner about yor business goes here.</p>
                                                                        <div className="btn-wrapper">
                                                                            <a className="btn btn-info" title="Bid Form" onClick={() => setBidModalShow(true)}>Bid Form</a>
                                                                            <BidFormModal
                                                                                show={bidmodalShow}
                                                                                userData = {user}
                                                                                onHide={() => setBidModalShow(false)}
                                                                            />
                                                                            <a className="btn btn-info" title="Award Project" onClick={() => setAwardModalShow(true)}>Award Project</a>
                                                                            <AwardProjectModal
                                                                                show={AwardmodalShow}
                                                                                userData = {user}
                                                                                onHide={() => setAwardModalShow(false)}
                                                                            />
                                                                            {/* <a className="btn btn-info" title="Email Contractor">Email Contractor</a> */}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr> : null
                                                    }
                                                </>
                                                {/* <tr>  {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Bid Done yet!</h5>) : null}</tr> */}
                                            </>
                                        )}
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="noBorder">
                </Modal.Header>
                <Modal.Body>
                    <div className="bidder-wrapper">
                        <div className="bidder-header">
                            <span className="bidder-img">
                                <img src={Icons.Owner} alt='' />
                            </span>
                            <div className="f-row">
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-md-detail">Name:{bidders?.contractor_id?.first_name}</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-md-detail">Date: 14/05/2022</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-md-detail">Bid Price: $5599 USD</p>
                                    </div>
                                </div>
                            </div>
                            <div className="f-row">
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-detail">Location:{bidders?.contractor_id?.first_name}</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <p className="desc-info-detail">Distance From Project: 20 Mile</p>
                                    </div>
                                </div>
                            </div>
                        </div><div className="f-row">
                            <div className="f-100">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Details by Contractor</h6>
                                    <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</p>
                                </div>
                            </div>
                        </div>
                        <div className="f-row">
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Company Name</h6>
                                    <p className="desc-info-detail">Deserunt ullamco est</p>
                                </div>
                            </div>
                            <div className="f-auto">
                                <div className="desc-info">
                                    <h6 className="desc-info-heading">Company Description</h6>
                                    <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ul className="modal-actions">
                        <li>
                            <button className="btn btn-primary" type="button" onClick={() => bidWinnerSubmit()}>Winner Bid</button>
                        </li>
                        <li>
                            <button className="btn btn-grey" type="button" onClick={() => setModalShow(false)}>Cancel</button>
                        </li>
                        {bidders?.is_invoice_generated ? <li>
                            <button className="btn btn-primary" type="button" onClick={() => downloadInvoiceAcion()}>Download</button>
                            <button className="btn btn-primary" type="button" onClick={() => mailInvoiceAction()}>Mail</button>

                        </li> : <li>
                            <button className="btn btn-primary" type="button" onClick={() => generateInvoiceAcion()}>Generate</button>
                        </li>}

                    </ul>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Bids;