import { createSlice } from "@reduxjs/toolkit"

export const GeneralSlice = createSlice({
  name: "general",
  initialState: {
    param: "",
    param2: "",
    details: []
  },
  reducers: {
    setParamFirst: (state, action) => {
      state.param = action.payload
    },
    setParamSecond: (state, action) => {
      state.param2 = action.payload
    },
    estimator_assistant: (state, action) => {
      // state.details.push(action.payload);
      return { ...state, details: [ ...state.details, action.payload] };
    },
    estimator_assistant1: (state, action) => {
      // state.details.push(action.payload);
      return { ...state, details: action.payload };
    },
    setProjectID: (state, action) => {
      state.setProjectID = action.payload
    }
  }
})

export const { setParamFirst, setParamSecond, estimator_assistant ,estimator_assistant1,setProjectID} = GeneralSlice.actions;
export default GeneralSlice.reducer;