import { ErrorMessage, useField } from 'formik'
import React from 'react'

const TextArea = ({ label, required, viewOnly = false, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <div className="form-group">
            <label className="f-label" htmlFor={field.name}>{label}{required ? <span className="error">*</span> : ""}</label>
            <div className='f-field'>
                <textarea
                    className={`form-control f-area ${meta.touched && meta.error && 'is-invalid'}`}
                    {...field} {...props}
                    onChange={(e) => {
                        helpers?.setValue(e.target.value)
                        props?.changeHandle?.(e.target.value)
                    }}
                    disabled={viewOnly}
                    placeholder={props.placeholder}
                    autoComplete="off"
                >
                </textarea>
            </div>
            <ErrorMessage component="div" name={field.name} className="error" />
        </div>
    )
}

export default TextArea;