import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import EmailBidderList from '../EmailBidderList';
import EmailBidderView from '../EmailBidderView';

const EmailBiddersRoutes = [
    {
        path: `/email-bidder-view/:id`,
        layout: DefaultLayout,
        component: < EmailBidderView />
    },
    {
        path: `/email-bidder-list`,
        layout: DefaultLayout,
        component: <  EmailBidderList />
    }
]

export default EmailBiddersRoutes;