import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import RoofTypeList from '../RoofTypeList';
import RoofTypeView from '../RoofTypeView';
import RoofTypeForm from '../RoofTypeForm';

const RoofTypeRoutes = [
    {
        path: `/roof-type-list`,
        layout: DefaultLayout,
        component: <  RoofTypeList />
    },
    {
        path: `/roof-type-view/:id`,
        layout: DefaultLayout,
        component: < RoofTypeView />
    },
    {
        path: `/roof-type-form`,
        layout: DefaultLayout,
        component: < RoofTypeForm />
    }
]

export default RoofTypeRoutes;