import React from "react";
import { resetSchema } from "../../components/common/validations/Validation";
import { Formik, Form } from "formik";
import TextInput from "./common/TextInput";
import queryString from "query-string";
import { useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import { failed, success } from "../../components/common/Toastify";

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const initialValues = {
    password: "",
    confirmPassword: "",
    token: "",
  };
  const { assertionToken } = queryString.parse(location.search);

  assertionToken != null ? (initialValues.token = assertionToken) :
    (initialValues.token = "123");

  const Submit_reset = (data) => {
    try {
      AuthService.resetPassword(data).then((res) => {
        if (res.code === 200 && res.status === 'SUCCESS') {
          success(res.message);
          navigate("/login");
        } else {
          failed(res.message);
        }
      });
    } catch (e) {
    }
  };

  return (
    <div className="login-left-wrapper">
      <Formik
        initialValues={initialValues}
        validationSchema={resetSchema}
        onSubmit={(values) => {
          Submit_reset(values);
        }}
      >
        <Form>
          <div className="login-box">
            <h4 className="form-heading">Reset Password</h4>
            <p className="form-desc">Enter your New Password.</p>
            <TextInput
              label="New Password"
              name="password"
              eyeIcon={true}
              required={true}
            />
            <TextInput
              label="Confirm Password"
              name="confirmPassword"
              eyeIcon={true}
              required={true}
            />
            <div className="form-row">
              <div className="w100">
                <div className="form-action">
                  <button className="btn-square btn-blue" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ResetPassword;
