import React from 'react';
import { Formik, Form } from "formik";
import { contact_us } from '../../services/auth.service';
import { contactSchema } from '../../components/common/validations/Validation';
import TextField from '../../components/common/formInput/TextField';
import { useDispatch } from 'react-redux';
import { failed, success } from "../../components/common/Toastify";
import TextArea from '../../components/common/formInput/TextArea';

const Contact = () => {

    const dispatch = useDispatch();

    /** 
    * @description Function is for contactus.
    * @param {object} data 
    */
       const _submitData = (data) => {
        try {
            contact_us(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    return (
        <>
            <section className='registration-section'>
                <div className="container">
                    <Formik
                        initialValues={{
                            first_name: "",
                            last_name: "",
                            phone: "",
                            email: "",
                            subject: "",
                            query: "",
                        }}
                        enableReinitialize
                        validationSchema={contactSchema}
                        onSubmit={(values) => { _submitData(values) }}
                    >
                        {(formik) => (
                            <Form>
                                <div className="contact-us-wrapper">
                                    <h5 className='dashboard-heading text-center p-b-30'>Contact RoofingProjects.com</h5>
                                    <div className='d-card bordered m-b-25'>
                                        <div className='d-card-header'>
                                            <p className='d-card-desc'>Please complete the form and someone will respond within 24 hours.</p>
                                        </div>
                                        <div className='d-card-body p-b-0'>
                                            <div className='form-content'>
                                                <div className='f-row'>
                                                    <div className='f-50'>
                                                        <TextField
                                                            label="First Name"
                                                            placeholder="First Name"
                                                            name="first_name"
                                                            value={formik?.values?.first_name}
                                                            onChange={formik.handleChange}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className='f-50'>
                                                        <TextField
                                                            label="Last Name"
                                                            placeholder="Last Name"
                                                            name="last_name"
                                                            value={formik?.values?.last_name}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='f-row'>
                                                    <div className='f-50'>
                                                        <TextField
                                                            label="Email Address"
                                                            placeholder="Email Address"
                                                            name="email"
                                                            value={formik?.values?.email}
                                                            onChange={formik.handleChange}
                                                            required={true}
                                                        />
                                                    </div>
                                                    <div className='f-50'>
                                                        <TextField
                                                            label="Phone Number"
                                                            placeholder="Phone Number"
                                                            name="phone"
                                                            value={formik?.values?.phone}
                                                            onChange={formik.handleChange}
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='f-row'>
                                                    <div className='f-100'>
                                                        <TextField
                                                            label="Subject"
                                                            placeholder="Subject"
                                                            name="subject"
                                                            value={formik?.values?.subject}
                                                            onChange={formik.handleChange}
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='f-row'>
                                                    <div className='f-100'>
                                                        <TextArea
                                                            label="Your Questions / Comments"
                                                            placeholder="Your Questions"
                                                            name="query"
                                                            value={formik?.values?.query}
                                                            onChange={formik.handleChange}
                                                            required={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-card-bottom">
                                        <div className="d-card-bottom-cell">
                                            <button className="btn btn-blue" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    )
}

export default Contact;