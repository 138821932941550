import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { inspectionSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import { options, message, pagination } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addInspection, viewOneInspection, updateInspection } from "../../services/inspection";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import DatePicker from "../../components/common/formInput/DatePicker";
import Editor from "../../components/common/formInput/Editor";
import { DragDropField } from "../../components/common/formInput/DragDropField";
import { listProjects, viewOneProject } from '../../services/project';
import { project_details } from "../../services/project";

const InspectionForm = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();
    const [removedItems, setRemovedItems] = useState([]);
    const [projectRes, setProjectRes] = useState();
    const [projectDetails, setProjectDetails] = useState();
   
    const [isDate, setDate] = useState(null)

    const inspectionID = queryString.parse(location.search);

    useEffect(() => {
        listProjects({ page: pagination.page }, (data) => { setProjectRes(data) }, dispatch);
    }, []);

    let projects = projectRes && projectRes.docs && projectRes.docs.map((item) => ({ value: item._id, label: item.project_title }));

    useEffect(() => {
        viewOneInspection(inspectionID, (data) => {
            setResPayload(data);
        }, dispatch);
    }, []);

    const initialValue = {
        inspector_name: resPayload ? resPayload.inspector_name : "",
        projectId: resPayload ? resPayload?.projectId?._id : "",
        date: resPayload ? resPayload.date : "",
        inspection_images: resPayload ? resPayload.inspection_images : "",
        inspection_documents: resPayload ? resPayload.inspection_documents : "",
        inspection_videos: resPayload ? resPayload.inspection_videos : "",
        remark: resPayload ? resPayload.remark : "",
        removed_items: removedItems ? removedItems : "",
        country: projectDetails === undefined ? resPayload?.country : projectDetails?.country,
        state: projectDetails === undefined ? resPayload?.state : projectDetails?.state,
        city: projectDetails === undefined ? resPayload?.city : projectDetails?.city
    };

    const [initialValueData, setInitialValue] = useState(initialValue);
//     useEffect(() => {
//         project_details((data) => {
//             setProjectDetails(data);
//         })
//     }, [])

//     useEffect(() => {
//         setInitialValue(initialValue)
//     }, [resPayload, projects])
// console.log(projects,"abc70")
    
    /**
    * @description Function is for evaluate project state 
    * @param {object} data 
    */
    const evaluateProjectstate = (project_id) => {
        viewOneProject(project_id, (data) => { setProjectDetails(data) })
    }

    /**
     * @description Function is used for add Project 
     * @param {object} data 
     */
    const _submitData = (data) => {
        console.log(data,"data123>>>>>>>>>>>")
        let form = new FormData();
        form.append("inspector_name", data.inspector_name)
        form.append("date", data.date)
        form.append("projectId", data.projectId)
        form.append("remark", data.remark)
        form.append("country", projectDetails?.country)
        form.append("state", projectDetails?.state)
        form.append("city", projectDetails?.city)
        if (data.inspection_images)
            for (let i = 0; i < data.inspection_images.length; i++) {
                form.append("inspection_images", data.inspection_images[i])
            }
        if (data.inspection_videos)
            for (let i = 0; i < data.inspection_videos.length; i++) {
                form.append("inspection_videos", data.inspection_videos[i])
            }
        if (data.inspection_documents)
            for (let i = 0; i < data.inspection_documents.length; i++) {
                form.append("inspection_documents", data.inspection_documents[i])
            }
        try {
            addInspection(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    navigate('/inspection-list')
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.cancelInspectionForm, options);
        if (result) {
            // alert(message.cancelInspectionForm);
            navigate("/inspection-list");
        }
    };

    /**
     * @description Function is For Delete/update Media.
     */
    const updatemedia = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            let inx = resPayload?.media.findIndex((item) => item._id === id)
            let obj = resPayload?.media?.splice(inx, 1)
            setRemovedItems([...removedItems, obj[0]])
            setResPayload({ ...resPayload, media: resPayload.media })
        }
    }

    /**
    * @description Function is for Update prject.
    * @param {object} data 
    */
    const _updateData = async (data) => {
        let form = new FormData();
        form.append("_id", resPayload._id)
        form.append("inspector_name", data.inspector_name)
        form.append("date", data.date)
        form.append("projectId", data.projectId)
        form.append("remark", data.remark)
        form.append("removed_items", JSON.stringify(data.removed_items))
        form.append("country", projectDetails === undefined ? data.country : projectDetails?.country)
        form.append("state", projectDetails === undefined ? data.state : projectDetails?.state)
        form.append("city", projectDetails === undefined ? data.city : projectDetails?.city)
        if (data.inspection_images)
            for (let i = 0; i < data.inspection_images.length; i++) {
                form.append("inspection_images", data.inspection_images[i])
            }
        if (data.inspection_videos)
            for (let i = 0; i < data.inspection_videos.length; i++) {
                form.append("inspection_videos", data.inspection_videos[i])
            }
        if (data.inspection_documents)
            for (let i = 0; i < data.inspection_documents.length; i++) {
                form.append("inspection_documents", data.inspection_documents[i])
            }
        try {
            updateInspection(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    navigate("/inspection-list")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    return (
        <div className="dashboard-inner p-b-0">
            <Formik
                initialValues={initialValue}
                enableReinitialize
                validationSchema={inspectionSchema}
                onSubmit={(values) => resPayload ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">{resPayload ? "Edit Inspection" : "Add Inspection"}</h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Select Project"
                                                name="projectId"
                                                value={formik?.values?.projectId}
                                                changeHandle={(val) => {
                                                    evaluateProjectstate({ id: val });
                                                }}
                                                required={true}
                                                options={projects}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                            // readOnly
                                                label="Inspector Name"
                                                name="inspector_name"
                                                value={formik?.values?.inspector_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DatePicker
                                                name="date"
                                                label="Date"
                                                placeholder="Date"
                                                selected={isDate}
                                                dateFormat="yyyy/MM/dd"
                                                value={formik?.values?.date}
                                                required={true}
                                                changeHandle={(date) => setDate(date)}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Inspection Photo"
                                                name="inspection_images"
                                                media={resPayload?.documents?.filter((item) => { if (item.type === "inspection_images") return item }) || []}
                                                updatemedia={updatemedia}
                                                id="file005"
                                                accept={'image/jpeg'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Inspection PDFs"
                                                name="inspection_documents"
                                                media={resPayload?.documents?.filter((item) => { if (item.type === "inspection_documents") return item }) || []}
                                                updatemedia={updatemedia}
                                                id="file006"
                                                accept={'application/pdf'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Inspection Videos"
                                                name="inspection_videos"
                                                media={resPayload?.documents?.filter((item) => { if (item.type === "inspection_videos") return item }) || []}
                                                updatemedia={updatemedia}
                                                id="file007"
                                                accept={'video/mp4'}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <Editor
                                                label="Remark"
                                                name="remark"
                                                value={formik?.values?.remark}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{resPayload ? "Edit Inspection" : "Add Inspection"}</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" type="button" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default InspectionForm;
