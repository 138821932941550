import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { projectSchema } from "../../../components/common/validations/Validation";
import TextField from "../../../components/common/formInput/TextField";
import RedioButton from "../../../components/common/formInput/RedioButton";
import SearchSelectBox from "../../../components/common/formInput/SearchSelectBox";
import { alert, confirm } from 'react-bootstrap-confirmation';
import { options, message, _status } from '../../../components/common/utils/message';
import { useNavigate, useLocation } from "react-router-dom";
import { addProjects, project_details, updateProject, viewOneProject } from "../../../services/project";
import { success, failed } from "../../../components/common/Toastify";
import TextArea from "../../../components/common/formInput/TextArea";
import _ from 'underscore';
import { useDispatch, useSelector } from "react-redux";
import queryString from 'query-string';
import { Get_State } from "../../../services/owner";
import { DragDropField } from "../../../components/common/formInput/DragDropField";
import Icons from "../../../assets/images";
import Modal from 'react-bootstrap/Modal'
import ApplicationMenuService from "../../../services/application.menu";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import Editor from "../../../components/common/formInput/Editor";
import { viewOneBuilding } from "../../../services/building";

const Option = (props) => {
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const AddProjects = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();
    const [resStates, setResStates] = useState();
    const [removedItems, setRemovedItems] = useState([]);
    const [openApplicationMenuForm, setOpenApplicationMenuForm] = useState(false);
    const [applicationMenu, setApplcationMenu] = useState([]);
    const [allowedBids, setAllowedbids] = useState([]);
    const [selectedManufactures, setSelectedManufactures] = useState([]);
    const [projectDetails, setProjectDetails] = useState();
    const [buildingDetails, setBuildingDetails] = useState();

    const [selectedMenus, setSelectedMenus] = useState([]);

    const project_id = queryString.parse(location.search);
    const generalData = useSelector((state) => state.general)

    const selectAllowedBidsStates = (selected) => {
        setAllowedbids(selected)
    }
    const selectManfacturer = (selected) => {
        setSelectedManufactures(selected)
    }

    useEffect(() => {
        viewOneProject(project_id, (data) => {
            setResPayload(data);
            if (project_id.id) {
                setSelectedMenus(data.selectedMenuItems);
                setAllowedbids(data.allowedBid);
                setSelectedManufactures(data.manufacturers);
            }
        }, dispatch);
        Get_State({ country: "United States" }, result => { setResStates(result.data); }, dispatch);
        viewOneBuilding({ id: generalData.param }, (data) => { setBuildingDetails(data) }, dispatch)
        getApplicationMenus();
    }, []);

    const initialData = {
        project_title: resPayload ? resPayload.project_title : "",
        ownerId: generalData.param2,
        buildingId: generalData.param,
        square_footage: resPayload ? resPayload.square_footage : "",
        manufacturers: resPayload ? resPayload.manufacturers : "",
        bidder_fee: resPayload ? resPayload.bidder_fee : "",
        allowedBid: resPayload ? resPayload.allowedBid : "",
        project_status: resPayload ? resPayload.project_status : "",
        notes: resPayload ? resPayload.notes : "",
        description: resPayload ? resPayload.description : "",
        estimatorAssistance: resPayload ? resPayload.estimatorAssistance : "",
        images: resPayload ? resPayload.images : "",
        roof_drawings: resPayload ? resPayload.roof_drawings : "",
        standard_contract: resPayload ? resPayload.standard_contract : "",
        insurance: resPayload ? resPayload.insurance : "",
        detail_drawings: resPayload ? resPayload.detail_drawings : "",
        specifications: resPayload ? resPayload.specifications : "",
        videos: resPayload ? resPayload.videos : "",
        status: resPayload ? resPayload.status : true,
        removed_items: removedItems ? removedItems : [],
        country: buildingDetails === undefined ? resPayload?.country : buildingDetails?.country,
        state: buildingDetails === undefined ? resPayload?.state : buildingDetails?.state,
        city: buildingDetails === undefined ? resPayload?.city : buildingDetails?.city
    }

    useEffect(() => {
        project_details((data) => {
            setProjectDetails(data);
        })
    }, [])

    const [initialValue, setInitialValue] = useState(initialData);

    useEffect(() => {
        setInitialValue(initialData)
    }, [resPayload])

    let manufacturerData = projectDetails?.manufacturer?.map((item) => ({ value: item._id, label: item.first_name }));
    let statesData = resStates && resStates.states && resStates.states.map((item) => ({ value: item.name, label: item.name }));

    /**
     * @description Function is used for add Project 
     * @param {object} data 
     */
    const _submitData = (data) => {
        let form = new FormData();
        form.append("project_title", data.project_title)
        form.append("ownerId", data.ownerId)
        form.append("building_type", data.building_type)
        form.append("buildingId", data.buildingId)
        form.append("square_footage", data.square_footage)
        form.append("manufacturers", JSON.stringify(selectedManufactures))
        form.append("bidder_fee", data.bidder_fee)
        form.append("allowedBid", JSON.stringify(allowedBids))
        form.append("project_status", data.project_status)
        form.append("notes", data.notes)
        form.append("description", data.description)
        form.append("status", data.status)
        form.append("estimatorAssistance", data.estimatorAssistance)
        form.append("selectedMenuItems", JSON.stringify(selectedMenus))
        form.append("country", buildingDetails?.country)
        form.append("state", buildingDetails?.state)
        form.append("city", buildingDetails?.city)
        if (data.images) {
            for (let i = 0; i < data.images.length; i++) {
                form.append("images", data.images[i]);
            }
        }
        if (data.roof_drawings) {
            for (let i = 0; i < data.roof_drawings.length; i++) {
                form.append("roof_drawings", data.roof_drawings[i]);
            }
        }
        if (data.videos) {
            for (let i = 0; i < data.videos.length; i++) {
                form.append("videos", data.videos[i]);
            }
        }
        if (data.standard_contract) {
            for (let i = 0; i < data.standard_contract.length; i++) {
                form.append("standard_contract", data.standard_contract[i])
            }
        }
        if (data.insurance) {
            for (let i = 0; i < data.insurance.length; i++) {
                form.append("insurance", data.insurance[i])
            }
        }
        if (data.detail_drawings) {
            for (let i = 0; i < data.detail_drawings.length; i++) {
                form.append("detail_drawings", data.detail_drawings[i])
            }
        }
        if (data.specifications) {
            for (let i = 0; i < data.specifications.length; i++) {
                form.append("specifications", data.specifications[i])
            }
        }
        try {
            addProjects(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    navigate(`/building-view/${generalData.param}`)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };


    /**
    * @description Function is for Update prject.
    * @param {object} data 
    */
    const _updateData = async (data) => {
        let form = new FormData();
        form.append("_id", resPayload._id)
        form.append("project_title", data.project_title)
        form.append("ownerId", data.ownerId)
        form.append("building_type", data.building_type)
        form.append("buildingId", data.buildingId)
        form.append("square_footage", data.square_footage)
        if (data.manufacturers) form.append("manufacturers", JSON.stringify(selectedManufactures))
        if (data.bidder_fee) form.append("bidder_fee", data.bidder_fee)
        if (data.allowedBid) form.append("allowedBid", JSON.stringify(allowedBids))
        if (data.project_status) form.append("project_status", data.project_status)
        if (data.notes) form.append("notes", data.notes)
        if (data.description) form.append("description", data.description)
        if (data.status) form.append("status", data.status)
        if (data.estimatorAssistance) form.append("estimatorAssistance", data.estimatorAssistance)
        if (data.removed_items) form.append("removed_items", JSON.stringify(data.removed_items))
        form.append("selectedMenuItems", JSON.stringify(selectedMenus))
        form.append("country", buildingDetails === undefined ? data.country : buildingDetails?.country)
        form.append("state", buildingDetails === undefined ? data.state : buildingDetails?.state)
        form.append("city", buildingDetails === undefined ? data.city : buildingDetails?.city)
        if (data.images) {
            for (let i = 0; i < data.images.length; i++) {
                form.append("images", data.images[i]);
            }
        }
        if (data.roof_drawings) {
            for (let i = 0; i < data.roof_drawings.length; i++) {
                form.append("roof_drawings", data.roof_drawings[i]);
            }
        }
        if (data.videos) {
            for (let i = 0; i < data.videos.length; i++) {
                form.append("videos", data.videos[i]);
            }
        }
        if (data.standard_contract) {
            for (let i = 0; i < data.standard_contract.length; i++) {
                form.append("standard_contract", data.standard_contract[i])
            }
        }
        if (data.insurance) {
            for (let i = 0; i < data.insurance.length; i++) {
                form.append("insurance", data.insurance[i])
            }
        }
        if (data.detail_drawings) {
            for (let i = 0; i < data.detail_drawings.length; i++) {
                form.append("detail_drawings", data.detail_drawings[i])
            }
        }
        if (data.specifications) {
            for (let i = 0; i < data.specifications.length; i++) {
                form.append("specifications", data.specifications[i])
            }
        }
        try {
            updateProject(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    navigate(`/building-view/${generalData.param}`)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.cancelProjectForm, options);
        if (result) {
            alert(message.canceled);
            navigate(`/building-view/${generalData.param}`)
        }
    }

    /**
     * @description Function is For Delete/update Media.
     */
    const updatemedia = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            let inx = resPayload?.media.findIndex((item) => item._id === id)
            let obj = resPayload?.media?.splice(inx, 1)
            setRemovedItems([...removedItems, obj[0]])
            setResPayload({ ...resPayload, media: resPayload.media })
        }
    }

    const openApplicationMenu = () => {
        const updatedApplicationMenu = [...applicationMenu];
        const selectedIds = selectedMenus.map(e => e._id);
        updatedApplicationMenu.forEach(m => {
            if (selectedIds.includes(m._id)) {
                m.checked = true;
            } else {
                m.checked = false
            }

        })

        setOpenApplicationMenuForm(true)
    };

    const closeApplicationMenu = () => {
        const updatedApplicationMenu = [...applicationMenu];
        updatedApplicationMenu.forEach(m => {
            m.checked = false
        })
        setApplcationMenu(updatedApplicationMenu);
        setOpenApplicationMenuForm(false);
    }

    const getApplicationMenus = () => {
        ApplicationMenuService.getAllApplicationMenu((result) => {
            const withCheckMenus = result.docs.map(e => {
                if (e.type === "dropdown" || e.type === "multi-dropdown") {

                    const updatedOptions = e.options.map(opt => {
                        return {
                            label: opt.key,
                            value: opt.value
                        }
                    })
                    e.options = [...updatedOptions];
                    e.selectedOptions = [];
                }
                if (e.type === "boolean") {
                    e.options = [...e.options]
                }
                if (e.type === "text") {
                    e.selectedOptions = [""];
                }
                if (e.type === "range") {
                    e.selectedOptions = [0];
                }
                return { ...e, menuId: e._id, checked: false };
            });

            setApplcationMenu(withCheckMenus);
        }, dispatch)
    }

    const selectMenu = (e) => {
        const updatedApplicationMenu = [...applicationMenu];
        updatedApplicationMenu.forEach(m => {
            if (m.menu_title === e.target.value) {
                if (m.checked) m.checked = false;
                else m.checked = true
            }
        })
        setApplcationMenu(updatedApplicationMenu);
    }

    const _submitApplicationMenuForm = () => {
        const updatedSelectedMenu = applicationMenu.filter(e => e.checked);
        const existedMenu = selectedMenus.map(e => e._id);
        setSelectedMenus([...selectedMenus, ...updatedSelectedMenu.filter(e => !existedMenu.includes(e._id))]);
        closeApplicationMenu();
    };

    const deleteApplicationMenu = (menuId) => {
        const udpatedSelectedMenus = [...selectedMenus];
        const menuIndex = udpatedSelectedMenus.findIndex(e => e._id === menuId);
        udpatedSelectedMenus.splice(menuIndex, 1)
        setSelectedMenus(udpatedSelectedMenus);
    }

    const textTypeHandler = (e, menuId) => {

        const udpatedSelectedMenus = [...selectedMenus];
        const menuIndex = udpatedSelectedMenus.findIndex(e => e._id === menuId);
        udpatedSelectedMenus[menuIndex].selectedOptions[0] = e.target.value;
        setSelectedMenus(udpatedSelectedMenus);

    }

    const rangeTypeHandler = (e, menuId) => {

        const udpatedSelectedMenus = [...selectedMenus];
        const menuIndex = udpatedSelectedMenus.findIndex(e => e._id === menuId);
        udpatedSelectedMenus[menuIndex].selectedOptions[0] = +e.target.value;
        setSelectedMenus(udpatedSelectedMenus);
    }
    const dropdownTypeHandler = (e, menuId) => {

        const udpatedSelectedMenus = [...selectedMenus];
        const menuIndex = udpatedSelectedMenus.findIndex(e => e._id === menuId);
        udpatedSelectedMenus[menuIndex].selectedOptions[0] = e.target.value;
        setSelectedMenus(udpatedSelectedMenus);
    }
    const multidropdownTypeHandler = (e, menuId) => {

        const udpatedSelectedMenus = [...selectedMenus];
        const menuIndex = udpatedSelectedMenus.findIndex(e => e._id === menuId);
        const optionsIndex = udpatedSelectedMenus[menuIndex].selectedOptions.findIndex(x => x === e.target.value);
        if (optionsIndex < 0) {
            udpatedSelectedMenus[menuIndex].selectedOptions.push(e.target.value)
        } else {
            udpatedSelectedMenus[menuIndex].selectedOptions.splice(optionsIndex, 1);
        }
        setSelectedMenus(udpatedSelectedMenus);
    }
    const booleanTypeHandler = (e, menuId) => {

        const udpatedSelectedMenus = [...selectedMenus];
        const menuIndex = udpatedSelectedMenus.findIndex(e => e._id === menuId);
        const optionsIndex = udpatedSelectedMenus[menuIndex].selectedOptions.findIndex(x => x === e.target.value);
        if (e.target.value === "on") {
            udpatedSelectedMenus[menuIndex].options[optionsIndex].selected = true

        } else {
            udpatedSelectedMenus[menuIndex].options[optionsIndex].selected = false
        }
        setSelectedMenus(udpatedSelectedMenus);
    }

    return (
        <div className="dashboard-inner p-b-0">
            <Formik
                initialValues={initialValue}
                enableReinitialize
                validationSchema={projectSchema}
                onSubmit={(values) => resPayload ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">{resPayload ? "Edit Project" : "Create New Project"}</h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Project Name"
                                                name="project_title"
                                                value={formik?.values?.project_title}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Square Footage"
                                                name="square_footage"
                                                value={formik?.values?.square_footage}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <label className="f-label">Manufacturers</label>
                                            <div className='f-field'>
                                                <div className='cm-dropdown'>
                                                    <ReactSelect
                                                        className="pro-input-two pro-select-two-new"
                                                        options={manufacturerData}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={selectManfacturer}
                                                        allowSelectAll={true}
                                                        value={selectedManufactures}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Status"
                                                name="status"
                                                options={_status}
                                                value={formik?.values?.status}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">Add Application Menu</h5>
                                <button className="btn btn-grey m-r-15" type="button" onClick={openApplicationMenu}>Select Menus</button>
                            </div>
                            <div className="f-row alignTop">
                                {Array.isArray(selectedMenus) &&
                                    selectedMenus.map(_menu => {
                                        return (<>
                                            {_menu.type === "text" &&
                                                <div className="f-33 d-flex align-items-start mb-3">
                                                    <div className='high-low-value'>
                                                        <p>{_menu.menu_title}</p>
                                                        <div className="h-l-dropdown">
                                                            <input type="text" value={_menu.selectedOptions[0]} name={_menu.key} onInput={(e) => textTypeHandler(e, _menu.menuId)} />
                                                        </div>
                                                    </div>
                                                    <img src={Icons.removeIcon} className='icons-size icons-input-margin' onClick={() => deleteApplicationMenu(_menu.menuId)} />
                                                </div>
                                            }
                                            {_menu.type === "range" &&
                                                <div className="f-33 d-flex align-items-start mb-3">
                                                    <div className='high-low-value'>
                                                        <p>{_menu.menu_title}</p>
                                                        <div className="h-l-dropdown">
                                                            <div>
                                                                <input type="number" value={_menu.selectedOptions[0]} name={_menu.key} min={_menu.min_value} max={_menu.max_value} onInput={(e) => rangeTypeHandler(e, _menu.menuId)} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <img src={Icons.removeIcon} className='icons-size icons-input-margin' onClick={() => deleteApplicationMenu(_menu.menuId)} />
                                                </div>
                                            }
                                            {
                                                _menu.type === "boolean" &&
                                                <div className="f-33 d-flex align-items-start mb-3">
                                                    <div className='high-low-value'>
                                                        <p>{_menu.menu_title}</p>
                                                        <div className="h-l-dropdown">
                                                            {_menu.options.map(opt => {
                                                                return (
                                                                    <div>
                                                                        <input type="radio" name={_menu.key} checked={opt.selected} onChange={(e) => booleanTypeHandler(e, _menu.menuId, opt.value)} /> {
                                                                            opt.value
                                                                        }
                                                                    </div>
                                                                )
                                                            })}

                                                        </div>
                                                    </div>
                                                    <img src={Icons.removeIcon} className='icons-size icons-input-margin' onClick={() => deleteApplicationMenu(_menu.menuId)} />
                                                </div>

                                            }
                                            {
                                                _menu.type === "dropdown" &&
                                                <div className="f-33 d-flex align-items-start mb-3">
                                                    <ul className="dashboard-sub-filters sub-filters-select">
                                                        <li>
                                                            {_menu.options && <div className='sortby multiple-select-sortby'>
                                                                <span className="roof-text">{_menu.menu_title} :</span>
                                                                <div className='nocolor-dropdown'>
                                                                    <select name={_menu.key} value={_menu.selectedOptions[0]} onChange={(e) => dropdownTypeHandler(e, _menu.menuId)} >
                                                                        {_menu.options.map(opt => {
                                                                            return <option value={opt.value} >{opt.label}</option>
                                                                        })}
                                                                    </select>
                                                                </div>
                                                            </div>}
                                                        </li>
                                                    </ul>
                                                    <img src={Icons.removeIcon} className='icons-size icons-input-margin' onClick={() => deleteApplicationMenu(_menu.menuId)} />
                                                </div>
                                            }
                                            {
                                                _menu.type === "multi-dropdown" &&
                                                <div className="f-33 d-flex align-items-start mb-3">
                                                    <ul className="dashboard-sub-filters sub-filters-select">
                                                        <li>
                                                            {_menu.options && <div className='sortby multiple-select-sortby'>
                                                                <span className="roof-text">{_menu.menu_title} :</span>
                                                                <div className='nocolor-dropdown'>
                                                                    <select name={_menu.key} value={_menu.selectedOptions} onChange={(e) => multidropdownTypeHandler(e, _menu.menuId)} multiple>
                                                                        {_menu.options.map(opt => {
                                                                            return <option value={opt.value} >{opt.label}</option>
                                                                        })}
                                                                    </select>

                                                                </div>
                                                            </div>}
                                                        </li>
                                                    </ul>
                                                    <img src={Icons.removeIcon} className='icons-size icons-input-margin' onClick={() => deleteApplicationMenu(_menu.menuId)} />
                                                </div>
                                            }
                                        </>

                                        )
                                    })}
                            </div>
                        </div>

                        <div className="d-card m-b-25">
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <DragDropField
                                                label="Standard Contract"
                                                name="standard_contract"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "standard_contract") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file008"
                                                accept={'application/pdf'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Insurance"
                                                name="insurance"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "insurance") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file009"
                                                accept={'application/pdf'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">
                                    Upload Project Specific Items
                                </h5>
                            </div>
                            <div className="d-card-body">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Roof Drawing"
                                                name="roof_drawings"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "roof_drawings") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file0010"
                                                accept={'image/jpeg'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Roof Photos"
                                                name="images"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "images") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file0011"
                                                accept={'image/jpeg'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Project Video"
                                                name="videos"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "videos") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file0013"
                                                accept={'video/mp4'}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Detail Drawings"
                                                name="detail_drawings"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "detail_drawings") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file0012"
                                                accept={['image/jpeg', 'application/pdf']}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Project Specifications"
                                                name="specifications"
                                                media={resPayload?.media?.filter((item) => { if (item.type === "specifications") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file0018"
                                                accept={['image/jpeg', 'application/pdf']}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <TextArea
                                                label="Any Specific Notes Regarding The Project"
                                                name="notes"
                                                value={formik?.values?.notes}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row">
                                        <div className="f-33">
                                            <label className="f-label">Bid is allowed from</label>
                                            <div className='f-field'>
                                                <div className='cm-dropdown'>
                                                    <ReactSelect
                                                        className="pro-input-two pro-select-two-new"
                                                        options={statesData}
                                                        isMulti
                                                        closeMenuOnSelect={false}
                                                        hideSelectedOptions={false}
                                                        components={{
                                                            Option
                                                        }}
                                                        onChange={selectAllowedBidsStates}
                                                        allowSelectAll={true}
                                                        value={allowedBids}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="f-33">
                                            <label className="f-label">Project Status</label>
                                            <div className="f-field">
                                                <div className="checkbox-row">
                                                    <RedioButton
                                                        label="Open Project"
                                                        name="project_status"
                                                        checked={formik?.values?.project_status === 'open'}
                                                        value="open"
                                                        onChange={formik.handleChange} />
                                                    <RedioButton
                                                        label="Close Project"
                                                        name="project_status"
                                                        checked={formik?.values?.project_status === 'close'}
                                                        value="close"
                                                        onChange={formik.handleChange} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card m-b-25">
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <TextArea
                                                label="Estimator Assistant"
                                                name="estimatorAssistance"
                                                value={formik?.values?.estimatorAssistance}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <Editor
                                                label="Project Description"
                                                name="description"
                                                value={formik?.values?.description}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{resPayload ? "Edit Project" : "Add Project"}</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" type="button" onClick={onCancel}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

            {openApplicationMenuForm &&
                <Modal
                    show={openApplicationMenuForm}
                    onHide={closeApplicationMenu}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                        <div className="contractor-wrapper">
                            <h5 className="contractor-heading">Select Application Menu</h5>
                            <div className="contractor-body">
                                <ul className="contractor-list">
                                    {applicationMenu && applicationMenu.map((_menu, index) => {
                                        return (<li>
                                            <div className="contractor-check">
                                                <label className='checkbox nolabel' htmlFor={index}>
                                                    <input type="checkbox" id={index} value={_menu.menu_title} checked={_menu.checked} onChange={(e) => selectMenu(e)} />
                                                    <span></span>
                                                </label>
                                            </div>
                                            <div className="contractor-details">
                                                <div className='bid-details'>
                                                    <h4>{`${_menu.menu_title}`}</h4>
                                                </div>
                                            </div>
                                        </li>)
                                    })}
                                </ul>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ul className="modal-actions">
                            <li>
                                <button className="btn btn-primary" type="submit" onClick={_submitApplicationMenuForm}>Submit</button>
                            </li>
                            <li>
                                <button className="btn btn-grey" type="button" onClick={closeApplicationMenu}>Cancel</button>
                            </li>
                        </ul>
                    </Modal.Footer>
                </Modal>}
        </div >
    );
};

export default AddProjects;
