import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import queryString from 'query-string';
import { loadingAction } from "../../../components/common/loader/LoaderSlice";

/**
 * @description list of Project data.
 * @param {*} param 
 * @param {*} callback 
 */
export const list_Projects = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/building/get/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
* @description list for project details.
* @param {*} param 
* @param {*} callback 
*/
export const project_details = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/project/view?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
* @description  Profile view.
* @param {*} param 
* @param {*} callback 
*/
export const profile_view = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/user/profile/view`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};


/**
* @description  Profile view.
* @param {*} param 
* @param {*} callback 
*/
export const profile_edit = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/user/profile/update`, param, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const project_bids = async (id, callback,dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(false))
            axios.post(`${apiUrl}${PORT}/rpc/bids/view/all`, id, option).then((response) => {
                dispatch(loadingAction(false))
                callback(response.data.data)
            }).catch((error) => {
                dispatch(loadingAction(false))
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description list for Faq data.
 * @param {*} param 
 * @param {*} callback 
 */
 export const list_Faqs = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/faq/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    console.log(response);
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                    console.log("amit",response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};