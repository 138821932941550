import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import CreateRPC from "../RPCmanagerForm";
import AllRpc from '../RPCmanagerList';
import RPCmanagerView from '../RPCmangerView';


const RpcRoutes = [
    {
        path: `/add-rpc`,
        layout: DefaultLayout,
        component: <  CreateRPC />
    },
    {
        path: `/list-rpc`,
        layout: DefaultLayout,
        component: <  AllRpc />
    },
    {
        path: `/rpc-manager-view/:id`,
        layout: DefaultLayout,
        component: <  RPCmanagerView />
    },
   
]

export default RpcRoutes;