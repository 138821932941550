import React, { useState } from "react";
import {
    Elements,
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./checkout.css";

function CheckoutForm() {
    const [isPaymentLoading, setPaymentLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const payMoney = async (e) => {
        e.preventDefault();
        if (!stripe || !elements) {
            return;
        }
        setPaymentLoading(true);
        const clientSecret = 'pi_1Dsr5O2eZvKYlo2C46jqxgP0_secret_fajwgP8mzDE3koEAOen3JF2YQ';
        const paymentResult = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    name: "Faruq Yusuff",
                },
            },
        });
        setPaymentLoading(false);
        if (paymentResult.error) {
            alert(paymentResult.error.message);
        } else {
            if (paymentResult.paymentIntent.status === "succeeded") {
                alert("Success!");
            }
        }
    };

    return (
        <div
            style={{
                padding: "3rem",
            }}
        >
            <div
                style={{
                    maxWidth: "500px",
                    margin: "0 auto",
                }}
            >
                <form
                    style={{
                        display: "block",
                        width: "100%",
                    }}
                    onSubmit={payMoney}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <CardElement
                            className="card"
                            options={{
                                style: {
                                    base: {
                                        backgroundColor: "white"
                                    }
                                },
                            }}
                        />
                        <button
                            className="pay-button"
                            disabled={isPaymentLoading}
                        >
                            {isPaymentLoading ? "Loading..." : "Pay"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

const Payment = () => {
    const stripe = loadStripe(
        "pk_test_51MFC7rSHDFGPyHcyEOAGoAOw4DBSqlzVvZXmqtH0aATGlgvHbpDjm5yLM40zsqMwIAvmT4Yn5mQEYLEDzzfEtWRw00KiwLga4E"
    );
    return (
        <Elements stripe={stripe}>
            <CheckoutForm />
        </Elements>
    );
};
export default Payment;