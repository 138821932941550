import React from 'react';
import Icons from '../../assets/images/index'
import Dropdown from 'react-bootstrap/Dropdown';

const OwnerWarranty = () => {

    return (
        <div className='d-card'>
            <div className='d-card-header'>
                <h5 className='d-card-heading d-card-sub-heading'>Warranty</h5>
                <div className='d-card-actions'>
                    <div className="document-actions staticPos">
                        <div className="d-action">
                            <button className="btn-download">
                                <img src={Icons.Download} alt="" />
                            </button>
                        </div>
                        <div className="d-action">
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic" className='btn-transparent dashboard-action'>
                                    <svg width="4" height="16" viewBox="0 0 4 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.875 8.875C2.35825 8.875 2.75 8.48325 2.75 8C2.75 7.51675 2.35825 7.125 1.875 7.125C1.39175 7.125 1 7.51675 1 8C1 8.48325 1.39175 8.875 1.875 8.875Z" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1.875 2.75C2.35825 2.75 2.75 2.35825 2.75 1.875C2.75 1.39175 2.35825 1 1.875 1C1.39175 1 1 1.39175 1 1.875C1 2.35825 1.39175 2.75 1.875 2.75Z" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M1.875 15C2.35825 15 2.75 14.6082 2.75 14.125C2.75 13.6418 2.35825 13.25 1.875 13.25C1.39175 13.25 1 13.6418 1 14.125C1 14.6082 1.39175 15 1.875 15Z" stroke="#808080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-card-body'>
                <div className="desc-info p-b-10">
                    <p className="desc-info-detail">Due to the volatility of roofing industry pricing, Lamar Co will consider a change order to the contract price if verified documentation is presented that shows the material pricing at the time the material order was placed (a material order can only be placed when a fully executed contract is in place) and any changes in the material pricing when it is shipped from the manufacturer. When the material order is placed with the manufacturer, the contractor must provide Lamar Co (with a CC to RoofingProjects) a copy of the contractor’s material order form with pricing clearly displayed for the major roof system components (IE. Roof Membrane, Specified Insulation, Roof Fasteners and Plates, and Roofing Adhesives). If the stated material order form is not provided at the time the order is placed then a material price increase change order will not be considered. There will be no exceptions to this requirement.</p>
                </div>
                <div className="desc-info p-b-10">
                    <h6 className="desc-info-heading">Site Visit</h6>
                    <p className="desc-info-detail">Not required - RoofingProjects provides all the information needed to bid the project right from your desk top computer. If you have any concerns and are being considered for the project you will be given enough time to visit the site to verify information before signing the contract.</p>
                </div>
                <div className="f-row">
                    <div className="f-auto">
                        <div className="desc-info p-b-10">
                            <h6 className="desc-info-heading">Tear Off</h6>
                            <p className="desc-info-detail">No - limited to wet insulation identified by RoofingProjects moisture survey</p>
                        </div>
                    </div>
                    <div className="f-auto">
                        <div className="desc-info p-b-10">
                            <h6 className="desc-info-heading">Warranty</h6>
                            <p className="desc-info-detail">20 Year Labor and Material NDL</p>
                        </div>
                    </div>
                </div>
                <div className="desc-info p-b-10">
                    <h6 className="desc-info-heading">New Roof System</h6>
                    <p className="desc-info-detail">Overlay - Mechanically Attached /Rhino Bond 60 Mil TPO over 1/2 inch HD separator board with an Option for a mechanically attached felt/fleece back directly over the existing single ply roof.</p>
                </div>
                <div className="desc-info p-b-10">
                    <h6 className="desc-info-heading">Roofing Contractors accepted from the following areas:</h6>
                    <p className="desc-info-detail">Local, Regional and National Roofing Contractors that are certified by Carlisle, Firestone, GAF and Johns Manville.</p>
                </div>
                <div className="desc-info p-b-10">
                    <h6 className="desc-info-heading">Job Start</h6>
                    <p className="desc-info-detail">As soon as possible after signing the contract. Lamar Co is very aware of the material availability crisis pauging the Roofing Industry and they will work with the selected Roofing Contractor with the goal of beginning the projectt as soon as material is available. Add this project to your backlog so you know where future work is coming from.</p>
                </div>
                <div className="desc-info p-b-0">
                    <h6 className="desc-info-heading">Project Notes</h6>
                    <p className="desc-info-detail p-b-0">Lamar Companies is a full-service, privately held, real estate investment company headquartered in Fairfield, New Jersey. Since its founding in 1972, the Company has routinely been ranked in the top 100 largest owners of retail shopping centers in the US according to National Real Estate Investors rankings. South Mountain Crossing is an excellent opportunity to introduce your Roofing Contractor Company to this dynamic REIT. This is very straight forward project. Install the New Specified TPO Roof System over the exisitng single ply roof.. Access is excellent and if you are certified by the selected manufacturers then Lamar Companies wants to see your bid.</p>
                </div>
            </div>
        </div>
    )
}

export default OwnerWarranty;