import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { ownerSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import { options, message, _status } from "../../components/common/utils/message";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import { alert, confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { addOwner, Get_Countries, listOwners, updateOwner, Get_State } from "../../services/owner";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { listOwnerType } from "../../services/ownerType";
import { UploadLogoField } from "../../components/common/formInput/UploadLogoField";
import Breadcrumb from "./Breadcrumb";
// import images from "../../assets/images/defaultimage.png"

const CreateOwner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [resPayload, setResPayload] = useState();

  const [ownerType, setOwnerType] = useState();
  const [resStates, setResStates] = useState();
  const [resCountries, setResCountries] = useState();
  const [countrySelect, setCountrySelect] = useState("United States");

  useEffect(() => {
    listOwners({ page: 1 }, (data) => { setResPayload(data) }, dispatch);
    listOwnerType({ page: 1 }, (data) => { setOwnerType(data) }, dispatch);
    Get_Countries(result => { setResCountries(result.data) }, dispatch);
  }, []);

  // BL - for default state select  check now and remove this comment
  useEffect(() => {
    Get_State({ country: "United States" }, result => { 
                            setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                          }, dispatch);
  }, []);
  

  const owner_id = queryString.parse(location.search)
  const get_owner = resPayload && resPayload.docs.find((item) => item._id === owner_id.id)

  let countryData = resCountries?.map((item) => ({ value: item.name, label: item.name }));
  let ownerTypeData = ownerType?.docs.map((item) => ({ value: item.owner_type_name, label: item.owner_type_name }));

  const initialData = {
    _id: get_owner ? get_owner._id : "",
    first_name: get_owner ? get_owner.first_name : "",
    last_name: get_owner ? get_owner.last_name : "",
    email: get_owner ? get_owner.email : "",
    city: get_owner ? get_owner.city : "",
    address1: get_owner ? get_owner.address1 : "",
    address2: get_owner ? get_owner.address2 : "",
    mobile_no: get_owner ? get_owner.mobile_no : "",
    state: get_owner ? get_owner.state : "",
    country: get_owner ? get_owner.country : "United States",
    zip_code: get_owner ? get_owner.zip_code : "",
    company_name: get_owner ? get_owner.company_name : "",
    owner_type: get_owner ? get_owner.owner_type : "",
    status: get_owner ? get_owner.status : true,
    company_logo: get_owner  ? get_owner.company_logo : ""
  };

  useEffect(() => {
    Get_State({ country: get_owner?.country }, result => {
      if (result?.data?.states) {
        setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
      }
    }, dispatch)
  }, [get_owner?.country]);

  /**
   * @description Function is for add Owner.
   * @param {object} data 
   */
  const _submitData = (data) => {
    let form = new FormData();
    form.append('first_name', data.first_name)
    form.append('last_name', data.last_name)
    form.append('email', data.email)
    form.append('address1', data.address1)
    form.append('address2', data.address2)
    form.append('company_name', data.company_name)
    form.append('mobile_no', data.mobile_no)
    form.append('owner_type', data.owner_type)
    form.append('city', data.city)
    form.append('state', data.state)
    form.append('country', data.country)
    form.append('zip_code', data.zip_code)
    form.append('company_logo', data.company_logo)
    form.append('status', data.status)
    try {
      addOwner(form, result => {
        if (result.status === "SUCCESS" && result.code === 200) {
          success(result.message)
          navigate("/list-owner")
        } else if (result.status === "ERROR" && result.code === 403) {
          failed(result.message)
        }
      }, dispatch)
    } catch (e) {
      failed(e)
    }
  };

  /**
   * @description Function is for Update Owner with building.
   * @param {object} data 
   */
  const _updateData = async (data) => {
    let form = new FormData();
    form.append('_id', data._id)
    form.append('first_name', data.first_name)
    form.append('last_name', data.last_name)
    form.append('email', data.email)
    form.append('address1', data.address1)
    form.append('address2', data.address2)
    form.append('company_name', data.company_name)
    form.append('mobile_no', data.mobile_no)
    form.append('owner_type', data.owner_type)
    form.append('city', data.city)
    form.append('state', data.state)
    form.append('country', data.country)
    form.append('zip_code', data.zip_code)
    form.append('company_logo', data.company_logo)
    form.append('status', data.status)
    try {
      updateOwner(form, result => {
        if (result.status === "SUCCESS" && result.code === 200) {
          success(result.message);
          navigate("/list-owner")
        } else if (result.status === "ERROR" && result.code === 403) {
          failed(result.message)
        }
      }, dispatch)
    } catch (e) {
      failed(e)
    }
  };

  /**
   * @description Function is For on Cancel Confirm Box.
   */
  const onCancel = async () => {
    const result = await confirm(message.cancelOwnerForm, options);
    if (result) {
      // alert(message.canceledOwnerForm);
      navigate("/list-owner");
    }
  };

  return (
    <div className="dashboard-inner p-b-0">
    <Breadcrumb/>
      <Formik
        initialValues={initialData}
        enableReinitialize
        validationSchema={ownerSchema}
        onSubmit={(values) => get_owner ? _updateData(values) : _submitData(values)}
      >
        {(formik) => (
          <Form>
            <div className="d-card m-b-25">
              <div className="d-card-header">
                <h5 className="d-card-heading">{get_owner ? "Edit Owner" : "Add Owner"}</h5>
              </div>
              <div className="d-card-body p-b-0">
                <div className="form-content">
                  <div className="f-row alignTop">
                    <div className="f-33">
                      <TextField
                        label="First Name"
                        name="first_name"
                        value={formik?.values?.first_name}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="f-33">
                      <TextField
                        label="Last Name"
                        name="last_name"
                        value={formik?.values?.last_name}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="f-33">
                      <TextField
                        label="Email Address"
                        name="email"
                        value={formik?.values?.email}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="f-row alignTop">
                    <div className="f-33">
                      <TextField
                        label="Phone"
                        name="mobile_no"
                        value={formik?.values?.mobile_no}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="f-33">
                      <SearchSelectBox
                        label="Country"
                        placeholder="Select Country"
                        name="country"
                        value={formik?.values?.country}
                        onChange={formik.handleChange}
                        changeHandle={(val) => {
                          Get_State({ country: val }, result => { 
                            setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                          }, dispatch)
                        }}
                        required={true}
                        options={countryData}
                      />
                    </div>
                    <div className="f-33">
                      <SearchSelectBox
                        label="State"
                        placeholder="Select States"
                        name="state"
                        value={formik?.values?.state}
                        onChange={formik.handleChange}
                        required={true}
                        options={resStates}
                      />
                    </div>
                  </div>
                  <div className="f-row alignTop">
                    <div className="f-33">
                      <TextField
                        label="Address Line 1"
                        name="address1"
                        value={formik?.values?.address1}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="f-33">
                      <TextField
                        label="Address Line 2"
                        name="address2"
                        value={formik?.values?.address2}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="f-33">
                      <TextField
                        label="City"
                        name="city"
                        value={formik?.values?.city}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="f-row alignTop">
                    <div className="f-33">
                      <TextField
                        label="Zip Code"
                        name="zip_code"
                        value={formik?.values?.zip_code}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="f-33">
                      <TextField
                        label="Company Name"
                        name="company_name"
                        value={formik?.values?.company_name}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                    <div className="f-33">
                      <SearchSelectBox
                        label="Owner Type"
                        name="owner_type"
                        options={ownerTypeData}
                        value={formik?.values?.owner_type}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                  <div className="f-row alignTop">
                    <div className="f-33">
                      <UploadLogoField
                        label="Upload Logo"
                        name="company_logo"
                        id="file002"
                        profileDetails={get_owner?.company_logo }
                        isrequired={true}
                      />
                    </div>
                    <div className="f-33">
                      <SearchSelectBox
                        label="Status"
                        name="status"
                        options={_status}
                        value={formik?.values?.status}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-card-bottom">
              <div className="d-card-bottom-cell">
                <button className="btn btn-blue" type="submit">{get_owner ? "Edit Owner" : "Add Owner"}</button>
              </div>
              <div className="d-card-bottom-cell">
                <button className="btn btn-grey" onClick={onCancel} type="button">
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateOwner;
