import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';

const JobBook = () => {
    const [tab, setTab] = useState("systemWarranty");
    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Job Book</h4>
            <ul className='head-tabs'>
                <li>
                    <button className={`tab-item ${tab === 'systemWarranty' ? 'active' : ''}`} onClick={() => setTab("systemWarranty")}>Roofing System Warranty</button>
                </li>
                <li>
                    <button className={`tab-item ${tab === 'inspectionForm' ? 'active' : ''}`} onClick={() => setTab("inspectionForm")}>Roof Inspection Form</button>
                </li>
            </ul>
            {tab === 'systemWarranty' &&
                <div className='d-card m-b-25'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>Roofing System Warranty</h5>
                    </div>
                    <div className='d-card-body p-b-0'>
                        <div className='form-content'>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Manufacturer's Name</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Manufacturers Name' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Warranty Issue Date</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Warranty Issue Date' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Warranty Number</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Warranty Number' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Warranty Expiration Date</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Warranty Expiration Date' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Project Name</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Project Name' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Square Footage</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Square Footage' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Roofing Contractor's Name </label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Contractors Name ' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Roofing Contractor Number</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Contractor Number' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Roofing Contractor Email Address</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Contractor Email Address' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-66'>
                                    <label className='f-label'>Owner Address</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Reference No.' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Proposal Number</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Date of Issuance' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-66'>
                                    <label className='f-label'>Project Site Address</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Reference No.' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-100'>
                                    <label className='f-label'>Description</label>
                                    <div className='f-field'>
                                        <textarea className='f-area' placeholder='Description'></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {tab === 'inspectionForm' &&
                <div className='d-card m-b-25'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>Roof Inspection Form</h5>
                    </div>
                    <div className='d-card-body p-b-0'>
                        <div className='form-content'>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Inspector Name</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Inspector Name' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Manufacture's Name</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Manufactures Name' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Project Size</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Project Size' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Project Name</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Project Name' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>New Roof System Type</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='New Roof System Type' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>RPC Project Number</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='RPC Project Number' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Owner Name </label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Owner Name ' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Owner Contact Number</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Owner Contact Number' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Owner Email Address</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Owner Email Address' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-66'>
                                    <label className='f-label'>Project Address</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Project Address' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>City</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='City' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Zip Code</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Zip Code' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>State</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='State' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Country</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Country' />
                                    </div>
                                </div>
                            </div>
                            <h4 className='form-inner-heading'>Roofing Company Contact Information</h4>
                            <div className='f-row'>
                                <div className='f-66'>
                                    <label className='f-label'>Roofing Contractor Company</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Contractor Company' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Date of Last Inspection</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Date of Last Inspection' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Roofing Company Contact</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Company Contact' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Roofing Company Phone</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Company Phone' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Roofing Company Email</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Company Email' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-66'>
                                    <label className='f-label'>Roofing Company Address</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Roofing Company Address' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>City</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='City' />
                                    </div>
                                </div>
                            </div>
                            <div className='f-row'>
                                <div className='f-33'>
                                    <label className='f-label'>Zip Code</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Zip Code' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>State</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='State' />
                                    </div>
                                </div>
                                <div className='f-33'>
                                    <label className='f-label'>Country</label>
                                    <div className='f-field'>
                                        <input className='f-input' placeholder='Country' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="d-card-bottom">
                <div className="d-card-bottom-cell">
                    <button className="btn btn-blue" type="submit">Send Warranty Card</button>
                </div>
                <div className="d-card-bottom-cell">
                    <button className="btn btn-grey" type="button" >Download Form</button>
                </div>
            </div>
        </div>
    )
}

export default JobBook

