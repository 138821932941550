import React from 'react'
import Icons from '../../../assets/images/index';
import { Link } from 'react-router-dom';

const OwnerHomeFooter = () => {
    return (
        <footer className='site-footer'>
            <div className='container'>
                <div className='footer-inner'>
                    <div className='footer-left'>
                        <Link to="/" className="logo"><img src={Icons.footerLogo} alt="RP" /></Link>
                    </div>
                    <div className='footer-right'>
                        <ul className='footer-links'>
                            <li>
                                <a href="https://in.linkedin.com/"><i class="bi bi-linkedin"></i> LinkedIn</a>
                            </li>
                            <li>
                                <a href="https://twitter.com/i/flow/login"><i class="bi bi-twitter"></i> Twitter</a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/"><i class="bi bi-instagram"></i> Instagram</a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com"><i class="bi bi-youtube"></i> YouTube</a>
                            </li>
                            <li>
                                <a href="https://www.facebook.com/"><i class="bi bi-facebook"></i> Facebook</a>
                            </li>
                        </ul>
                        <ul className='top-links'>
                            <li>
                                <a href='tel:(203)231-2843'><span className='link-icon'><img src={Icons.phone} alt="" /></span>(203) 231-2843</a>
                            </li>
                            <li>
                                <a href='mailto:test123@test.com'><span className='link-icon'><img src={Icons.email} alt="" /></span>Email us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default OwnerHomeFooter