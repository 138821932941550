import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all owner data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listBid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/convensional-bid/get/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description add new owner in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addBid = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/convensional-bid/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description update owner in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateBid = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/rpc/convensional-bid/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description Soft Delete Owner in db
 * @param {*} id 
 * @param {*} callback 
 */
export const DeleteBid = async (id, callback,dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.delete(`${apiUrl}${PORT}/rpc/convensional-bid/delete/${id}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const viewOneBid = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/convensional-bid/get?${queryString.stringify(param)}`, option).then((response) => {
        dispatch(loadingAction(false))
        callback(response.data.data)
      }).catch((error) => {
        dispatch(loadingAction(false))
        callback(false)
      });
    }
  } catch (err) {
    console.log(err);
  }
};