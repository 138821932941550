import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all contractor data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listContractors = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/contractor/view/all?${queryString.stringify(param)}`, option)

        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data)
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description add new manufacturer in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addContractor = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': `multipart/form-data`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/contractor/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description update manufacturer in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateContractor = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/rpc/contractor/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description Soft Delete manufacturer in db
 * @param {*} id 
 * @param {*} callback 
 */
export const DeleteContractor = async (id, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.delete(`${apiUrl}${PORT}/rpc/contractor/delete/${id}`, option)
        .then((response) => {
          callback(response.data)
        }).catch((error) => {
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const listBids = async (id, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/bidden/projects?id=${id}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
export const Get_Countries = async (callback) => {
  try {
    axios.get(`https://countriesnow.space/api/v0.1/countries/positions`)
      .then((response) => {
        callback(response.data)
      }).catch((error) => {
        callback(false)
      });
  } catch (err) {
    console.log(err);
  }
};
export const Get_State = async (state, callback,dispatch) => {
  try {
    dispatch(loadingAction(true))
    axios.post(`https://countriesnow.space/api/v0.1/countries/states`, state)
      .then((response) => {
        dispatch(loadingAction(false))
        callback(response.data)
      }).catch((error) => {
        dispatch(loadingAction(false))
        callback(false)
      });
  } catch (err) {
    console.log(err);
  }
};
export const Get_Cities = async (country, callback,dispatch) => {
  try {
    dispatch(loadingAction(true))
    axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, country)
      .then((response) => {
        dispatch(loadingAction(false))
        callback(response.data)
      }).catch((error) => {
        dispatch(loadingAction(false))
        callback(false)
      });
  } catch (err) {
    console.log(err);
  }
};