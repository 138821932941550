import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../auth/loginSlice';
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Icons from '../../assets/images/index';
// import LeftMenu from './LeftMenu';
import LeftMenu from './LeftMenuContractor';
import { myNotification, notificationsUnreadCount } from '../../services/notification.service';
import { Link,useLocation } from 'react-router-dom';
import { SocketContext } from '../../socket/socket-context';
import { notify, success } from '../../components/common/Toastify';
import { message,options } from '../../components/common/utils/message';
import { confirm } from 'react-bootstrap-confirmation';
import images from '../../assets/images/defaultimage.png'
import { API_BASEURL } from '../../environment';
import { get_profile } from '../../services/auth.service';


const HeaderMenuContractor = () => {
    const client = useContext(SocketContext);
    const user = useSelector((state) => state.login.user.data.user) || {}
    const Userdata = useSelector((state) => state.login);
    // const [showMenu, setShowMenu] = useState(false)
    const [toggle, setToggle] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(null);
    const [loginUser, setLoginUser] = useState()
    const location = useLocation();

    useEffect(() => {
        get_profile((data) => { setLoginUser(data) }, dispatch)
    }, [])

    useEffect(() => {
        client.onopen = () => {
          
        };
        client.onmessage = (message) => {
            try {
                const parsedMessage = JSON.parse(message.data)
                if (parsedMessage?.to_user_ids.includes(user?._id)) {
                    notify(parsedMessage?.title);
                    getNotifications()
                }
            } catch (e) { }

        };
    }, [client])

    const handleToggle = () => {
        setToggle(!toggle)
    }

    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        getNotifications();
    }, []);

    const getNotifications = () => {
        notificationsUnreadCount((data) => {
            // const thumbnailData = data?.data?.slice(0, 3);
            const thumbnailData = [{ title: "hhh" }, { title: "sdf" }]
            setNotifications(thumbnailData);
            setUnreadCount(data.unreadCount)
        }, dispatch)
    }

    const Logout = async() => {
        const result = await confirm(message.LogoutConfrm, options);
        if (result) {
        dispatch(logout())
        navigate('/login');
        success(message.loggedOut)
    }
}

    const Profile = () => {
        navigate('/profile')
    }
    const handleEmptyNotification = () => {
        navigate('/contractor-notification')
    }
    return (
        
        <div className='dashboard-header'>
            <div className='container'>
                <div className='dashboard-header-inner'>
                    <div className='dashboard-filters'>
                        {/* <div className='dashboard-search'>
                            <div className='search-box'>
                                <input type="search" className='search-input' placeholder='Search' />
                                <span className='search-icon'>
                                    <img src={Icons.search} alt='' />
                                </span>
                            </div>
                        </div> */}

                        <ul className="dashboard-menu">
                        <li className="responsive-close">
                            <button type="button" className="btn-toggle" onClick={toggle} >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </li>
                        <li className={`${location?.pathname === `/contractor/dashboard` ? 'active' : ""}`}>
                            <Link className='menu-item' to="/contractor/dashboard">New Projects</Link>
                        </li>
                        <li className={`${location?.pathname === `/contractor-purchased-oppourtunity` ? 'active' : ""}`}>
                            <Link className='menu-item' to="/contractor-purchased-oppourtunity">Purchased Projects</Link>
                        </li>
                        <li className={`${location?.pathname === `/contractor-closed-oppourtunity` ? 'active' : ""}`}>
                            <Link className='menu-item' to="/contractor-closed-oppourtunity">Closed Projects</Link>
                        </li>
                        <li className={`${location?.pathname === `/contractor-won-bids` ? 'active' : ""}`}>
                            <Link className='menu-item' to="/contractor-won-bids">Bids Won
                            </Link>
                        </li>
                     {/*   <li className={`${location?.pathname === `/contractor-bid-form` ? 'active' : ""}`}>
                        <Link className='menu-item' to="/contractor-bid-form">Bid Form</Link>
                    </li>*/}
                        <li>
                            <ul className='dashboard-actions contractor/dashboard-actions'>
                               
                                
                                
                            </ul>
                        </li>
                        <li></li>
                    </ul>


                        <ul className='dashboard-actions'>
                            <li>
                                <div className='notification-dropdown' >
                                    <Dropdown onClick={handleEmptyNotification}>
                                        <Dropdown.Toggle id="btn-notification" >
                                            <img src={Icons.notification} alt='' />
                                            <span>{unreadCount}</span>

                                        </Dropdown.Toggle>
                                        {/* {<Dropdown.Menu>
                                            {notifications?.length > 0 ? <>
                                                {notifications?.map(e => {
                                                    return <Dropdown.Item href="#/action-1">{e.title}</Dropdown.Item>
                                                })}<Link to="/my-notification">see all</Link> </>
                                                :
                                                "No notification"}
                                        </Dropdown.Menu>} */}
                                    </Dropdown>
                                </div>
                            </li>
                            <li></li>
                            <li>
                                <div className='profile-dropdown'>
                                    <Dropdown>
                                        <Dropdown.Toggle id="btn-profile">
                                            <img src={loginUser?.profile_pic ? `${API_BASEURL}/${loginUser?.profile_pic?.path}` : images} alt="" />
                                   
                                            {/* <img src={`${API_BASEURL}/${Userdata?.user?.data?.user?.profile_pic[0]?.path}`} alt='' /> */}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item>{Userdata?.user?.data?.user?.first_name} {Userdata?.user?.data?.user?.last_name}</Dropdown.Item>
                                            <Dropdown.Item>{Userdata?.user?.data?.user?.email}</Dropdown.Item>
                                            <Dropdown.Item onClick={Profile}>Profile Settings</Dropdown.Item>
                                            <Dropdown.Item onClick={Logout}>Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </li>
                            <li className={toggle ? 'active-sidebar' : 'res-toggle'}>
                                <button type="button" className="btn-toggle dashboard-toggle" onClick={() => handleToggle()} >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                {toggle && <LeftMenu changeToggle={() => { handleToggle() }} />}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderMenuContractor