import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

export const listRpc = async (param, callback, dispatch) => {

  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/admin/rpc-management/read/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};
export const getRPC = async (param, callback, dispatch) => {

  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/admin/rpc-management/getOne?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data);
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const addRpc = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': `multipart/form-data`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/admin/rpc-management/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const updateRpc = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/admin/rpc-management/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const DeleteRpc = async (id, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.delete(`${apiUrl}${PORT}/admin/rpc-management/delete?id=${id}`, option)
        .then((response) => {
          callback(response.data)
        }).catch((error) => {
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};


export const sentInvitationEmail = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/bids/send-invite`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }

}

export const makeBidWinner = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/bids/make-bid-winner`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(error.response.data)
        });
    }
  } catch (err) {
    console.log(err);
  }
}


export const get_all_q_n_a = async (projectId, callback) => {
  try {  console.log(   'apiUrl---- ',apiUrl);
  console.log(   'PORT---- ', PORT);
 
 // console.log(' param QueryString---- ',queryString.stringify(param));
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.get(`${apiUrl}${PORT}/admin/projects/getAll/questionAnswers?projectId=${projectId}`, option).then((response) => { 
        console.log(   'QA REesponse---- ',response.data.data)
        callback(response.data.data)
      }).catch((error) => {
        callback(false)
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const get_all_q_n_aData = async (param, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }

      axios.get(`${apiUrl}${PORT}/rpc/getAll/questionAnswers?${queryString.stringify(param)}`, option).then((response) => {
        callback(response.data.data)
      }).catch((error) => {
        callback(false)
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const add_answer = (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/admin/post/answer`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const add_answer_old = (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/answer`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};


export const generateInvoice = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/invoice/generate`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(error.response.data)
        });
    }
  } catch (err) {
    console.log(err);
  }
}


export const downloadInvoice = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        responseType: 'blob',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/invoice/download?bid_id=${data.bid_id}`, option)
        .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${data.bid_id}.pdf`);
          document.body.appendChild(link);
          link.click();
          dispatch(loadingAction(false))
          callback(res.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(error.response.data)
        });
    }
  } catch (err) {
    console.log(err);
  }
}


export const mailInvoice = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/invoice/mail`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(error.response.data)
        });
    }
  } catch (err) {
    console.log(err);
  }
}


export const getAllInvoices = async (callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/invoices`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(error.response.data)
        });
    }
  } catch (err) {
    console.log(err);
  }
} 