import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';

const AddTemplate = () => {
    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Notifications</h4>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Add Templates</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="form-content">
                        <div className='f-row'>
                            <div className='f-33'>
                                <label className='f-label'>Add Templates</label>
                                <div className='f-field'>
                                    <input className='f-input' />
                                </div>
                            </div>
                        </div>
                        <div className='f-row'>
                            <div className='f-100'>
                                <label className='f-label'>Template Description</label>
                                <div className='f-field'>
                                    <textarea className='f-area'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-card-bottom">
                <div className="d-card-bottom-cell">
                    <button className="btn btn-blue" type="submit">Add Template</button>
                </div>
                <div className="d-card-bottom-cell">
                    <button className="btn btn-grey" type="button" >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default AddTemplate

