import AuthRoute from '../features/auth/routes/AuthRoutes'
import ContractorRoutes from '../features/contractor/routes/ContractorRoutes';
import DashboardRoute from '../features/dashboard/routes/DashboardRoute';
import MaterialRoutes from '../features/manageMaterial/routes/MaterialRoutes';
import ProjectRoutes from '../features/manageProject/routes/ProjectRoutes';
import WindUpliftRoutes from '../features/manageWindUplift/routes/WindUpliftRoutes';
import RoofTypeRoutes from '../features/manageRoofType/routes/RoofTypeRoutes';
import ManufacturerRoutes from '../features/manufacturer/routes/ManufacturerRoutes';
import OwnerRoutes from '../features/owner/routes/OwnerRoutes';
import RpcRoutes from '../features/rpcManager/routes/RpcRoutes';
import NewRoofTypeRoutes from '../features/manageNewRoofType/routes/NewRoofTypeRoutes';
import RvalueRoutes from '../features/manageRvalue/routes/RvalueRoutes';
import RoofApprochRoutes from '../features/manageRoofTypeApproach/routes/RoofApproachRoutes';
import BuildingRoutes from '../features/manageBuildings/routes/BuildingRoutes';
import DocumentRoutes from '../features/manageDocuments/routes/DocumentRoutes';
import EmailBiddersRoutes from '../features/manageEmailBiders/routes/EmailBiddersRoutes';
import InspectionRoutes from '../features/manageInspection/routes/InspectionRoutes';
import FaqList from '../features/manageFaq/routes/FaqRoutes';
import PaymentManagementRoutes from '../features/paymentManagement/routes/PaymentManagementRoutes';
import OwnerTypeRoutes from '../features/manageOwnerType/routes/OwnerTypeRoutes';
import BuildingTypeRoutes from '../features/manageBuildingType/routes/BuildingTypeRoutes';
import PrivilegeRoutes from '../features/privilegeManagement/routes/PrivilegeRoutes';
import NotificationRoutes from '../features/notification/routes/NotificationRoutes';
import InvoiceRoutes from '../features/invoice/routes/InvoiceRoutes';
import HomePagesRoutes from '../features/homePages/routes/HomePagesRoutes';
import OwnerInterfaceRoute from '../features/ownerInterface/routes/OwnerInterfaceRoute';
import PaymentsRoutes from '../features/managePayments/routes/PaymentRoutes';
import CMSRoutes from '../features/cms/routes/CMSRoutes';
import ManuInterfaceRoute from '../features/manufacturerInterface/routes/Route';
import BidRoutes from '../features/convensionalBidding/routes/Bid'
import EstimatorRoutes from '../features/estimatorAssistance/routes/Estimator';
import TermLsit from '../features/manageTerms/routes/TermRoutes';
import CategoryManagementRoutes from "../features/CategoryManagement/routes/CategoryRoutes"
import ContractorInterfaceRoute from "../features/contractorInterface/routes/ContractorInterfaceRoute"
import GraphRoutes from '../features/graphs/routes/GraphRoutes';
import GRRoutes from '../features/GraphReports/routes/GRRoutes';
export const routes = [
    ...DashboardRoute, ...ProjectRoutes, ...OwnerRoutes, ...RpcRoutes, ...ManufacturerRoutes, ...MaterialRoutes, ...ContractorRoutes, ...WindUpliftRoutes, ...RoofTypeRoutes,
    ...NewRoofTypeRoutes, ...RvalueRoutes, ...RoofApprochRoutes, ...BuildingRoutes, ...DocumentRoutes, ...EmailBiddersRoutes, ...InspectionRoutes, ...PaymentManagementRoutes, ...OwnerTypeRoutes, ...BuildingTypeRoutes, ...PrivilegeRoutes,
    ...NotificationRoutes, ...FaqList, ...InvoiceRoutes, ...PaymentsRoutes, ...CMSRoutes, ...BidRoutes, ...EstimatorRoutes, ...TermLsit, ...CategoryManagementRoutes, ...GraphRoutes, ...GRRoutes
]

export const authRoutes = [
    ...AuthRoute,
]
export const homeRoutes = [
    ...HomePagesRoutes,
]
export const OwnerInterfaceRoutes = [
    ...OwnerInterfaceRoute
]

export const ManuInterfaceRoutes = [
    ...ManuInterfaceRoute
]
export const ContractorInterfaceRoutes = [
    ...ContractorInterfaceRoute
]