import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { Link, useNavigate } from "react-router-dom";
import { listOwners } from "../../services/owner";
import Accordion from 'react-bootstrap/Accordion'
import { useDispatch, useSelector } from "react-redux";
import { setParamFirst } from '../../components/common/general/GeneralSlice';
import { listBuildings } from "../../services/building";
import ReactPaginate from 'react-paginate';
import SelectBox from "../../components/common/formInput/SelectBox";
import { pagination, PaginationOption } from "../../components/common/utils/message";
import { API_BASEURL } from "../../environment";
import { listAllBuildings, deleteBuilding } from "../../services/building";
import images from '../../assets/images/defaultimage.png'
const OwnerView = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [resPayload, setResPayload] = useState();
  const [buildingRes, setBuildingRes] = useState();
  const [searchKey, setSearchKey] = useState("");

  const totalPages = buildingRes && buildingRes.totalPages;
  const [perPage, setPerPage] = useState(pagination.limit);
  const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)
    const [sortBy, setSortBy] = useState()
    const [order, setOrder] = useState()

  

  useEffect(() => {
    listOwners({ page: pagination.page }, (data) => { setResPayload(data) }, dispatch);
    listBuildings({ page: pagination.page, owner: general.param, owner_specific: 1 }, (data) => { setBuildingRes(data) }, dispatch);
  }, [dispatch]);

  /**
 * @description Function for show in dropdown entries with page option. Contractor git 
 * @param {param} displayPerPage 
 */

 
  // Contractor to development 
  
  const setPagination = async (displayPerPage) => {
    setPerPage(displayPerPage);
    await listBuildings({ limit: displayPerPage }, response => {
      setBuildingRes(response);
    }, dispatch)
  }

  /**
 * @description /Function is on Page Change.
 * @param {param} param 
 */
  const handlePageChange = async (param) => {
    let currentPage = param.selected + 1;
    await listBuildings({ page: currentPage }, response => {
      setBuildingRes(response);
    }, dispatch)
  };

   /**
    * @description Function for Search data with all fields.
    * @param {param} filterParam 
    */
   const onCombineSearch = async () => {
    await listAllBuildings({ limit, sortBy, order, fields: "name,state,city", filter: searchKey }, response => {
      console.log('response response', response);
      setBuildingRes(response);
    }, dispatch)
}

const __handleSearch = async (val) => { console.log('val val', val);
  if (val.trim() && val.trim().replace(/\./g, "")) {
      setSearchKey(val)
  } else {
      setSearchKey(val.trim().replace(/\./g, ""))
      await listAllBuildings({ limit, page, fields: "name,state,city", filter: val }, response => {
        setBuildingRes(response);
      }, dispatch)
  }
};

  const general = useSelector((state) => state.general)
  const get_owner = resPayload && resPayload.docs.find((item) => item._id === general.param)

  return (
    <div className='dashboard-inner'>
      <div className='d-h-wrap'>
        <div className='d-h-l'>
          <div className="custom-seachbox">
          <input type="search" placeholder="Search" value={searchKey} onChange={(e) => __handleSearch(e.target.value)} />
                        <button className="btn btn-primary btn-search" onClick={(e) => onCombineSearch(e)}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <h4 className='dashboard-heading p-b-15'>View Owner</h4>
      <div className="owner">
        <div className="owner-left">
          <span className="owner-img">
            <img src={get_owner?.company_logo ? `${API_BASEURL}/${get_owner.company_logo}` : images} alt="" />
          </span>
          <h4>{get_owner?.first_name} {get_owner?.last_name}</h4>
          <p>{get_owner?.email}</p>
        </div>
        <div className="owner-right">
          <h5>Location: {get_owner?.street_address} {get_owner?.city} {get_owner?.state}</h5>
        </div>
      </div>
      <div className='d-h-wrap'>
        <div className='d-h-l'>
          <h4 className='dashboard-heading'>Buildings</h4>
        </div>
        <div className='d-h-r'>
          <ul className='dashboard-sub-filters'>
            <li>
              <button className="btn btn-blue" onClick={(e) => { navigate('/add-building'); dispatch(setParamFirst(get_owner._id)); }}>Add Building</button>
            </li>
            <li>
              <Link to="/list-owner" className="btn btn-grey">Cancel</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className='d-card m-b-25'>
        <div className='d-card-header'>
          <h5 className='d-card-heading d-card-sub-heading'>Building Projects</h5>
        </div>
        <div className='d-card-body p-b-0'>
          <div className='responsive-table'>
            <table className='table-odd-even'>
              <tbody>
                {buildingRes?.docs?.map((item, inx) => (
                  <tr key={inx}>
                    <td>
                      <div className='p-2'>
                        <p>Building Name</p>
                        <h4>{item?.name}</h4>
                      </div>
                    </td>
                    <td>
                      <button className="btn-edit" onClick={(e) => navigate(`/add-building?id=${item._id}`)}>
                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.8789 3.40479H3.87891C3.34847 3.40479 2.83977 3.6155 2.46469 3.99057C2.08962 4.36564 1.87891 4.87435 1.87891 5.40479V19.4048C1.87891 19.9352 2.08962 20.4439 2.46469 20.819C2.83977 21.1941 3.34847 21.4048 3.87891 21.4048H17.8789C18.4093 21.4048 18.918 21.1941 19.2931 20.819C19.6682 20.4439 19.8789 19.9352 19.8789 19.4048V12.4048" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                          <path d="M18.3789 1.90501C18.7767 1.50719 19.3163 1.28369 19.8789 1.28369C20.4415 1.28369 20.9811 1.50719 21.3789 1.90501C21.7767 2.30284 22.0002 2.8424 22.0002 3.40501C22.0002 3.96762 21.7767 4.50719 21.3789 4.90501L11.8789 14.405L7.87891 15.405L8.87891 11.405L18.3789 1.90501Z" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {buildingRes?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Project found!</h5>) : null}
          </div>
        </div>
      </div>
      <div className="table-filters">
        <div className="t-f-cell">
          <div className="records-filter">
            <span>Show</span>
            <div className="grey-dropdown bg-white">
              <SelectBox
                options={PaginationOption}
                val={perPage}
                handleVal={(e) => setPagination(e)}
              />
            </div>
            <span>Entries</span>
          </div>
        </div>
        {totalPages > 1 ? (
          <div className="t-f-cell">
            <ReactPaginate
              breakLabel={"..."}
              nextLabel={'Next >'}
              onPageChange={handlePageChange}
              pageCount={totalPages}
              previousLabel={'< Back'}
              renderOnZeroPageCount={null}
              containerClassName={'custom-pagination '}
              pageClassName={'page-item'}
              pageLinkClassName={'btn-page'}
              previousClassName={'page-item'}
              previousLinkClassName={'btn-page btn-previous'}
              nextClassName={'page-item'}
              nextLinkClassName={'btn-page btn-next'}
              activeClassName={'active'}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default OwnerView;
