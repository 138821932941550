import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icons from '../../assets/images/index'
import { list_Projects } from './services/manufacturer';
import moment from 'moment';
import { API_BASEURL } from '../../environment';
import _ from 'underscore';
import { setParamFirst } from '../../components/common/general/GeneralSlice';
import { useNavigate } from 'react-router-dom';

const Projects = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loginUserState = useSelector((state) => state.login?.user?.data?.user?.state)
    const [resPayload, setResPayload] = useState()

    let _projects = resPayload?.docs?.map((item) => item?.projects)

    useEffect(() => {
        list_Projects({ fields: "state", filter: loginUserState }, (data) => { setResPayload(data) }, dispatch);
    }, [dispatch]);

    return (
        <>
            <div className="ds-header m-b-30">
                <div className="ds-cell">
                    <h4 className='dashboard-title'>Projects</h4>
                </div>
            </div>
            <ul className='projects-list'>
                {_projects?.slice(1, _projects?.length).map((item) => {
                    return (
                        <>
                            {item.map((subItem) => (
                                <li>
                                    <div className='project'>
                                        <span className='project-img'>
                                            <a onClick={() => { navigate('/manu-project-view'); dispatch(setParamFirst(subItem._id)) }}>
                                            <img src={subItem?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(subItem?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                                            </a>
                                        </span>
                                        <div className='project-details'>
                                            <div className='project-header'>
                                                <div className='project-header-left'>
                                                    <h5 className='project-name'>{subItem.project_title}</h5>
                                                </div>
                                                <div className='project-header-right'>
                                                    <span className='project-date'>{moment(subItem.createdAt).format('DD/MM/YYYY')}</span>
                                                </div>
                                            </div>
                                            <div className='project-body'>
                                                <p>{subItem.notes}</p>
                                                <div className='project-sub-details r3'>
                                                    <div className='project-sub-left project-sub-cell'>

                                                        {/*  Start/Smartdata/staticData*/}

                                                        <h5 className="p-wallet">5000 <span><sup>S</sup>/<sub>F</sub></span></h5>
                                                    </div>
                                                    <div className="project-sub-cell">
                                                        <p className='project-location'>{loginUserState}</p>
                                                    </div>
                                                    <div className='project-sub-right project-sub-cell'>
                                                        <h5 className="p-dollar">$254 USD</h5>
                                                        
                                                        {/*  END/Smartdata/staticData*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </>

                    );
                })}
            </ul>
        </>
    )
}

export default Projects;