import { ErrorMessage, useField } from 'formik'
import React from 'react'

export default function RedioButton({ label, ...props }) {
    const [field, meta, helpers] = useField(props);
    return (
        <div className="checkbox-wrap">
            <label
                //  className="checkbox"
                htmlFor="openProject">
                <input
                    className={`${meta.touched && meta.error && 'is-invalid'}`}
                    type="radio"
                    id='openProject'
                    {...field} {...props}
                    onChange={(e) => {
                        helpers?.setValue(e.target.value)
                        props?.changeHandle?.(e.target.value)
                    }}
                >
                </input>
                <span>{label}</span>
            </label>
            <ErrorMessage component="div" name={field.name} className="error" />
        </div>
    )
}
