import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { buildingSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import { options, message, pagination, _status } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBuilding, viewOneBuilding, updateBuilding } from "../../services/building";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import { Get_Countries, listOwners, Get_State } from "../../services/owner";
import { listBuildingType } from "../../services/buildingType";
import { DragDropField } from "../../components/common/formInput/DragDropField";

const CreateBuilding = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [buildingRes, setBuildingRes] = useState();
    const [buildingType, setBuildingType] = useState();
    const [ownerRes, setOwnerRes] = useState();
    const [resCountries, setResCountries] = useState();
    const [resStates, setResStates] = useState();
    const [removedItems, setRemovedItems] = useState([]);

    const building_id = queryString.parse(location.search)
    const general = useSelector((state) => state.general)

    useEffect(() => {
        viewOneBuilding(building_id, (data) => { setBuildingRes(data) }, dispatch);
        listBuildingType({ page: pagination.page }, (data) => { setBuildingType(data) }, dispatch);
        listOwners({ page: pagination.page }, (data) => { setOwnerRes(data) }, dispatch)
        Get_Countries(result => { setResCountries(result.data) }, dispatch);
    }, []);

    let countryData = resCountries?.map((item) => ({ value: item.name, label: item.name }));
    let buildingTypeData = buildingType?.docs?.map((item) => ({ value: item.building_type_name, label: item.building_type_name }));

    const initialData = {
        name: buildingRes ? buildingRes.name : "",
        address1: buildingRes ? buildingRes.address1 : "",
        address2: buildingRes ? buildingRes.address2 : "",
        city: buildingRes ? buildingRes.city : "",
        state: buildingRes ? buildingRes.state : "",
        country: buildingRes ? buildingRes.country : "",
        zip_code: buildingRes ? buildingRes.zip_code : "",
        ownerId: general.param,
        actual_size: buildingRes ? buildingRes.actual_size : "",
        building_type: buildingRes ? buildingRes.building_type : "",
        status: buildingRes ? buildingRes.status : true,
        building_images: buildingRes ? buildingRes.building_images : "",
        building_documents: buildingRes ? buildingRes.building_documents : "",
        building_videos: buildingRes ? buildingRes.building_videos : "",
        removed_items: removedItems ? removedItems : []
    };

    useEffect(() => {
        Get_State({ country: buildingRes?.country }, result => {
            if (result?.data?.states) {
                setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
            }
        }, dispatch)
    }, [buildingRes?.country]);

    /**
     * @description add Owner's Building with img, pdf & vedio in media collection.
     * @param {object} data 
     */
    const _submitData = (data) => {

        let form = new FormData();
        form.append('name', data.name)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('ownerId', data.ownerId)
        form.append('actual_size', data.actual_size)
        form.append('building_type', data.building_type)
        if (data.building_images) {
            for (let i = 0; i < data.building_images.length; i++) {
                form.append("building_images", data.building_images[i]);
            }
        }
        if (data.building_documents) {
            for (let i = 0; i < data.building_documents.length; i++) {
                form.append("building_documents", data.building_documents[i]);
            }
        }
        if (data.building_videos) {
            for (let i = 0; i < data.building_videos.length; i++) {
                form.append("building_videos", data.building_videos[i]);
            }
        }
        form.append('status', data.status)
        try {
            addBuilding(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    navigate("/owner-view")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
    * @description update Owner's Building with img, pdf & vedio in media collection.
    * @param {object} data 
    */
    const _updateData = (data) => {
        let form = new FormData();
        form.append('_id', buildingRes._id)
        form.append('name', data.name)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('ownerId', data.ownerId)
        form.append('actual_size', data.actual_size)
        form.append('building_type', data.building_type)
        form.append("removed_items", JSON.stringify(data.removed_items))
        if (data.building_images) {
            for (let i = 0; i < data.building_images.length; i++) {
                form.append("building_images", data.building_images[i]);
            }
        }
        if (data.building_documents) {
            for (let i = 0; i < data.building_documents.length; i++) {
                form.append("building_documents", data.building_documents[i]);
            }
        }
        if (data.building_videos) {
            for (let i = 0; i < data.building_videos.length; i++) {
                form.append("building_videos", data.building_videos[i]);
            }
        }
        form.append('status', data.status)
        try {
            updateBuilding(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/owner-view")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.cancelBuildingForm, options);
        if (result) {
            alert(message.canceledBuildingForm);
            navigate("/owner-view")
        }
    };

    const updatemedia = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            let inx = buildingRes?.media.findIndex((item) => item._id === id)
            let obj = buildingRes?.media?.splice(inx, 1)
            setRemovedItems([...removedItems, obj[0]])
            setBuildingRes({ ...buildingRes, media: buildingRes.media })
        }
    }

    return (
        <div className="dashboard-inner p-b-0">
            <Formik
                initialValues={initialData}
                enableReinitialize
                validationSchema={buildingSchema}
                onSubmit={(values) => buildingRes ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">{buildingRes ? "Edit Building" : "Add Building"}</h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Building Name"
                                                name="name"
                                                value={formik?.values?.name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Building Type"
                                                name="building_type"
                                                options={buildingTypeData}
                                                value={formik?.values?.building_type}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Address Line 1"
                                                name="address1"
                                                value={formik?.values?.address1}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Address Line 2"
                                                name="address2"
                                                value={formik?.values?.address2}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Country"
                                                placeholder="Select Country"
                                                name="country"
                                                value={formik?.values?.country}
                                                onChange={formik.handleChange}
                                                changeHandle={(val) => {
                                                    Get_State({ country: val }, result => {
                                                        setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                                                    }, dispatch)
                                                }}
                                                required={true}
                                                options={countryData}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="State"
                                                placeholder="Select States"
                                                name="state"
                                                value={formik?.values?.state}
                                                onChange={formik.handleChange}
                                                required={true}
                                                options={resStates}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="City"
                                                name="city"
                                                value={formik?.values?.city}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Zip Code"
                                                name="zip_code"
                                                value={formik?.values?.zip_code}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Status"
                                                name="status"
                                                options={_status}
                                                value={formik?.values?.status}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Building Photo"
                                                name="building_images"
                                                media={buildingRes?.media?.filter((item) => { if (item.type === "building_images") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file005"
                                                accept={'image/jpeg'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Building PDFs"
                                                name="building_documents"
                                                media={buildingRes?.media?.filter((item) => { if (item.type === "building_documents") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file006"
                                                accept={'application/pdf'}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Building videos"
                                                name="building_videos"
                                                media={buildingRes?.media?.filter((item) => { if (item.type === "building_videos") return item }) || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file007"
                                                accept={'video/mp4'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">Other Details </h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row">
                                        <div className="f-25">
                                            <TextField
                                                label="Square Footage"
                                                name="actual_size"
                                                value={formik?.values?.actual_size}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{buildingRes ? "Edit Building" : "Add Building"}</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" type="button" onClick={onCancel}>
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateBuilding;
