export const options = {
  okText: 'Confirm',
  message: "Do you really want to quit?",
  msg:"Confirmation"
}

export const message = {
  loggedOut: "LoggedOut successfully!",
  cancelForm: "Are you sure want to cancel?",
  cancelProjectForm: "Are you sure want to cancel Project Form?",
  canceled: 'Project Form Canceled!',
  cancelOwnerForm: "Are you sure want to cancel add-owner Form?",
  canceledOwnerForm: 'Add Owner Form Canceled!',
  canceledRpcForm: 'Add RPC Form Canceled!',
  cancelProjectView: 'Are you sure want to cancel Project view?',
  LogoutConfrm:"Are you sure to logout?",
  cancelBuildingForm: "Are you sure want to cancel Building Form?",
  canceledBuildingForm: 'Building Form Canceled!',
  BuildingDeleted: 'Building Deleted!',
  deleteData: 'Are you sure you want to delete?',
  OwnerDeleted: "owner Deleted!",
  RPCDeleted: "RPC Manager Deleted!",
  MediaDeleted: "Media Deleted!",
  ProjectDeleted: "Project Deleted!",
  canceledManufacturerForm: 'Add Manufacturer Form Canceled',
  ManufacturerDeleted: 'Manufacturer Deleted!',
  OwnerTypeDeleted: 'Owner-Type Deleted!',
  ContentManagementDeleted: 'Content-management Deleted!',
  BuildingTypeDeleted: 'Building-Type Deleted!',
  cancelMaterialTypeForm: "Are you sure want to cancel add-material Form?",
  MaterialTypeDeleted: 'MaterialType Deleted!',
  canceledMaterialForm: 'Add Material Form Canceled!',
  cancelRoofTypeForm: "Are you sure want to cancel add Roof-Type Form?",
  cancelOwnerTypeForm: "Are you sure want to cancel add Owner-Type Form?",
  canceledOwnerTypeForm: 'Add Owner type Form Canceled!',
  canceledRoofForm: 'Add Roof Type Form Canceled!',
  RoofTypeDeleted: 'RoofType Deleted!',
  canceledContractorForm: "Add Contractor Form Canceled",
  ContractorDeleted: "Contractor Deleted!",
  cancelWindUplift: "Are you sure want to cancel add-wind-uplift Form?",
  canceledWindUpliftForm: 'Add WindUplift Form Canceled!',
  changeStatus: 'Are you sure you want to change status?',
  StatusChanged: "Status Changed Successfully",
  cancelInspectionForm: "Are you sure want to cancel Inspection Form?",
  WindUpliftDeleted: 'Wind Uplift Deleted!',
  cancelNewRoofType: "Are you sure want to cancel New Roof Type Form?",
  canceledNewRoofType: 'Add New Roof Type Form Canceled!',
  canceledNewRoofTypeForm: 'Add New Roof Type Form Canceled!',
  cancelRValue: "Are you sure want to cancel Add R-Value Form?",
  NewRoofTypeDeleted: 'NewRoofType Deleted!',
  canceledRValueForm: 'Add R-Value Form Canceled!',
  RValueDeleted: 'RValue Deleted!',
  cancelRoofApproach: "Are you sure want to cancel Add Roof Approach Managment Form?",
  canceledRoofApproach: 'Add Roof Approach Managment Form Canceled!',
  RoofApproachDeleted: 'RoofApproach Deleted!',
  cancelBuildingType: "Are you sure want to cancel Add Building Type Form?",
  canceledBuildingType: 'Add Building Type Form Canceled!',
  cancelBidForm: "Add Bid Form Canceled!",
  BidDeleted: 'Building Deleted!',
  EstimateUpdate: "Kindly check checkbox before update",
  FaqDeleted: "Faq deleted!",
  InspectorDeleted: "Inspector deleted",
  EstimateAssistanceDeleted: 'Estimator Assistance Deleted!',
}

export const Owner_type = [
  { "value": "REIT", "label": "REIT" },
  { "value": "Retail", "label": "Retail" },
  { "value": "Office Buildin", "label": "Office Buildin" },
];

export const Building_type = [
  { "value": "Fire Resistive", "label": "Fire Resistive" },
  { "value": "Ordinary", "label": "Ordinary" },
  { "value": "Heavy Timber", "label": "Heavy Timber" },
  { "value": "Wood Frame", "label": "Wood Frame" },
];

export const ReRoofing_approach = [
  { "value": "Total Tear Off", "label": "Total Tear Off" },
  { "value": "Partial Tear Off", "label": "Partial Tear Off" },
  { "value": "Overlay with cover board", "label": "Overlay with cover board" },
  { "value": "Without cover board", "label": "Without cover board" },
];

export const contractor_data = [
  { "value": "Local Contractor", "label": "Local Contractor" },
  { "value": "Regional Contractors", "label": "Regional Contractors" },
  { "value": "National Contractors", "label": "National Contractors" }
];

export const union_value = [
  { "value": "Union", "label": "Union" },
  { "value": "Non Union", "label": "Non Union" },
];

export const qtrBidding = [
  { "value": "1st", "label": "1st" },
  { "value": "2nd", "label": "2nd" },
  { "value": "3rd", "label": "3rd" },
];

export const roof_system_Layer = [
  { "value": "1st", "label": "1st" },
  { "value": "2nd", "label": "2nd" },
  { "value": "3rd", "label": "3rd" },
];

export const roof_section_data = [
  { "value": "1-3", "label": "1-3" },
  { "value": "3-5", "label": "3-5" },
  { "value": "greater than 5", "label": "greater than 5" },
];

export const penetration = [
  { "value": "Low", "label": "Low" },
  { "value": "Average", "label": "Average" },
  { "value": "High", "label": "High" },
];

export const roof_access_data = [
  { "value": "Normal", "label": "Normal" },
  { "value": "Moderate", "label": "Moderate" },
  { "value": "Difficult", "label": "Difficult" },
];

export const stories_data = [
  { "value": "1", "label": "1" },
  { "value": "2", "label": "2" },
  { "value": "3", "label": "3" },
  { "value": "4", "label": "4" },
  { "value": "High Rise", "label": "High Rise" },
  { "value": "Sky Scraper", "label": "Sky Scraper" },
];

export const _status = [
  { "value": true, "label": "Active" },
  { "value": false, "label": "Inactive" }
];
export const publish_status = [
  { "value": true, "label": "Active" },
  { "value": false, "label": "Inactive" }
];
export const project_status = [
  { "value": "open" , "label": "Open" },
  { "value": "close", "label": "Closed" } 
];
export const approach_type = [
  { "value": "tearoff", "label": "Tear Off" },
  { "value": "overlay", "label": "Overlay" },
  { "value": "coated", "label": "Coated" }
];

export const _roles = [
  // { "value": "RPC_MANAGER", "label": "RPC Manager" },
  // { "value": "OWNER", "label": "Owner" },
  { "value": "CONTRACTOR", "label": "Contractor" },
  { "value": "MANUFACTURER", "label": "Manufacturer" },
];

export const _zipCode = {
  "unitedstates": 5,
}

export const pagination = {
  page: 1,
  limit: 10,
  maxlimit: 10000,
  scrollLimit: 12,
  projectLimit: 9
};

export const PaginationOption = [
  10, 15, 20, 25
];

export const ProjectPaginationOption = [
  9, 18, 27, 36
];