import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import RoofApproachList from '../RoofApproachList';
import RoofApproachView from '../RoofApproachView';
import RoofApproachForm from '../RoofApproachForm'

const RoofApprochRoutes = [
    {
        path: `/roof-approach-list`,
        layout: DefaultLayout,
        component: <  RoofApproachList />
    },
    {
        path: `/roof-approach-form`,
        layout: DefaultLayout,
        component: <  RoofApproachForm />
    },
    {
        path: `/roof-approach-view/:id`,
        layout: DefaultLayout,
        component: <  RoofApproachView />
    }
    
    
]

export default RoofApprochRoutes ;