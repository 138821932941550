import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { Link, useNavigate } from "react-router-dom";
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { success } from "../../components/common/Toastify";
import ReactPaginate from 'react-paginate';
import SelectBox from "../../components/common/formInput/SelectBox";
import { useDispatch } from "react-redux";
import { DeleteManufacturer, listManufacturer } from "../../services/manufacturer";
import { setParamFirst } from "../../components/common/general/GeneralSlice";
import { Get_Countries, Get_State } from "../../services/owner";
import { _status } from '../../components/common/utils/message';
import Modal from 'react-bootstrap/Modal';
import BarChartSample from "../graphs/BarChart";
import LineChartSample from "../graphs/LineChart";
import PieChartSample from "../graphs/PieChart";
import { allGraph } from "../../services/project"

const GraphReports = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [resPayload, setResPayload] = useState();
    const [modalShow, setModalShow] = useState(false);

    const [searchKey, setSearchKey] = useState("");
    const [searchByState, setSearchByState] = useState();
    const [searchBystatus, setSearchByStatus] = useState();

    const [countries, setCountries] = useState();
    const [country, setCountry] = useState("United States");
    const [states, setStates] = useState();

    const [allGraphData, setAllGraphData] = useState();

    const totalPages = resPayload && resPayload.totalPages;

    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page);
    const [sortBy, setSortBy] = useState();
    const [order, setOrder] = useState();

    useEffect(() => {
        _getListing();
      //  _getListingData();
        Get_Countries(res => { setCountries(res.data) })
        _getState("United States");
    }, [dispatch]);


    useEffect(() => {
       
        allGraph(res => { setAllGraphData(res.data) })
       
    }, []);

    // const _getListingData = () => {
        
    //     allGraph( (data) => { setResPayload(data) })
    // }

    /**
     * @description get all the contractors from db where page is pageChange and Limit for limit of the data. change here
     */
    const _getListing = () => {
        listManufacturer({ page, limit, sortBy, order }, (data) => {
            setResPayload(data);
        }, dispatch);
    }

    /**
    * @description Function For delete RPC.
    * @param {id} id 
    */
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            DeleteManufacturer(id, async res => {
                if (res.status === "SUCCESS" && res.code === 200) {
                    _getListing();
                    success(res.message);
                    await alert(message.ManufacturerDeleted);
                }
            })
        }
    }

    /**
     * @description /Function is on Page Change.
     * @param {param} param 
     */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listManufacturer({ page: currentPage, limit, sort: sortBy, order: order }, response => {
            setResPayload(response);
        }, dispatch)
    };

    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listManufacturer({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }

    /**
* @description Function for get state by selected country
* @param {param} val country
*/
    const _getState = (val) => {
        Get_State({ country: val }, result => {
            setStates(result);
        }, dispatch)
    }

console.log(' allGraph -------> ', allGraphData);
    return (
        <>
            <div className="dashboard-inner">

                <div className="d-h-wrap">
                    <div className="d-h-l">
                        <h4 className='dashboard-heading'>Graph Reports</h4>
                    </div>
                    <div className="d-h-r">
                        <ul className="dashboard-sub-filters">
                            <li>
                                <Link to="/create-graph" className="btn btn-blue" title="Create New Graph">Create New Graph</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="dashboard-table">
                    <div className="responsive-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Serial No. </th>
                                    <th className="text-center">Report Name</th>
                                    <th className="text-center">Chart Type</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>01</td>
                                    <td className="text-center">Test Report 1</td>
                                    <td className="text-center">Bar Chart1111111</td>
                                    <td className="text-center">
                                        <span className="sort-icon">
                                            <Link className="dashboard-action"  to={`/view-graph`}>
                                                <img src={Icons.eye} alt="" />
                                            </Link>
                                            <Link className="dashboard-action" to={`/create-graph`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete">
                                                <img src={Icons.bin} alt="" onClick={() => _handleDelete()} />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>02</td>
                                    <td className="text-center">Test Report 2</td>
                                    <td className="text-center">Line Chart</td>
                                    <td className="text-center">
                                        <span className="sort-icon">
                                            <button className="dashboard-action" title="View" onClick={(e) => { navigate(`/manufacturer-view`); dispatch(setParamFirst()); }}>
                                                <img src={Icons.eye} alt="" />
                                            </button>
                                            <Link className="dashboard-action" to={`/add-manufacturer`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete">
                                                <img src={Icons.bin} alt="" onClick={() => _handleDelete()} />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>03</td>
                                    <td className="text-center">Test Report 3</td>
                                    <td className="text-center">Pie Chart</td>
                                    <td className="text-center">
                                        <span className="sort-icon">
                                            <button className="dashboard-action" title="View" onClick={(e) => { navigate(`/manufacturer-view`); dispatch(setParamFirst()); }}>
                                                <img src={Icons.eye} alt="" />
                                            </button>
                                            <Link className="dashboard-action" to={`/add-manufacturer`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete">
                                                <img src={Icons.bin} alt="" onClick={() => _handleDelete()} />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>04</td>
                                    <td className="text-center">Test Report 4</td>
                                    <td className="text-center">Bar Chart</td>
                                    <td className="text-center">
                                        <span className="sort-icon">
                                            <button className="dashboard-action" title="View" onClick={(e) => { navigate(`/manufacturer-view`); dispatch(setParamFirst()); }}>
                                                <img src={Icons.eye} alt="" />
                                            </button>
                                            <Link className="dashboard-action" to={`/add-manufacturer`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete">
                                                <img src={Icons.bin} alt="" onClick={() => _handleDelete()} />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-filters">
                        <div className="t-f-cell">
                            <div className="records-filter">
                                <span>Show</span>
                                <div className="grey-dropdown bg-white">
                                    <SelectBox
                                        options={PaginationOption}
                                        val={limit}
                                        handleVal={(e) => setPagination(e)}
                                    />
                                </div>
                                <span>Entries</span>
                            </div>
                        </div>
                        <div className="t-f-cell">
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel={'Next >'}
                                onPageChange={handlePageChange}
                                pageCount={totalPages}
                                previousLabel={'< Back'}
                                renderOnZeroPageCount={null}
                                containerClassName={'custom-pagination '}
                                pageClassName={'btn-page'}
                                pageLinkClassName={'btn-page'}
                                previousClassName={'btn-page'}
                                previousLinkClassName={'btn-previous'}
                                nextClassName={'btn-page'}
                                nextLinkClassName={'btn-next'}
                                activeClassName={'active'}
                            />
                        </div>
                    </div>
                </div>
            </div>


            {/* <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Bar Chart</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-card m-b-25">
                        <div className="d-card-body p-0">
                            <h6 className="chart-head">Details</h6>
                            <div className="chart-detail">
                                <div className="left">
                                    <ul>
                                        <li>
                                            <div className="label">Report Name</div>
                                            <div className="data">Test Report</div>
                                        </li>
                                        <li>
                                            <div className="label">Chart Type</div>
                                            <div className="data">Bar Chart</div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="full-wrap">Select Criteria</li>
                                        <li>
                                            <div className="label">Country</div>
                                            <div className="data">USA</div>
                                        </li>
                                        <li>
                                            <div className="label">States</div>
                                            <div className="data">Arizona</div>
                                        </li>
                                        <li>
                                            <div className="label">City</div>
                                            <div className="data">Phoenix</div>
                                        </li>
                                        <li>
                                            <div className="label">Quaters</div>
                                            <div className="data">2nd</div>
                                        </li>
                                        <li>
                                            <div className="label">Date Range</div>
                                            <div className="data">8/15/2021  To  6/20/2022</div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="right">
                                    <ul>
                                        <li>
                                            <div className="label">Y - Axis</div>
                                            <div className="data">Total</div>
                                            <div className="sub-data">Cost 11  <br /> Size 11 </div>
                                        </li>
                                        <li>
                                            <div className="label">X - Axis</div>
                                            <div className="data">Manufacture</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="chart-wrap">
                                <BarChartSample  />
                                <LineChartSample />
                                <PieChartSample />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}


        </>

    );
};

export default GraphReports;
