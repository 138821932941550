import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import InspectionForm from '../InspectionForm';
import InspectionList from '../InspectionList';
import InspectionView from '../InspectionView';

const InspectionRoutes = [
    {
        path: `/inspection-view/:id`,
        layout: DefaultLayout,
        component: < InspectionView />
    },
    {
        path: `/inspection-form`,
        layout: DefaultLayout,
        component: < InspectionForm />
    },
    {
        path: `/inspection-list`,
        layout: DefaultLayout,
        component: < InspectionList />
    },
    {
        path: `/inspection-view`,
        layout: DefaultLayout,
        component: < InspectionView />
    }
]

export default InspectionRoutes;