import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import EstimatorList from '../EstimatorList';
import EstimatorForm from '../EstimatorForm';
import ViewEstimator from '../ViewEstimator';

const EstimatorRoutes = [
    {
        path: `/estimator`,
        layout: DefaultLayout,
        component: <  EstimatorList />
    },
    {
        path: `/estimator-form`,
        layout: DefaultLayout,
        component: <  EstimatorForm />
    },
    {
        path: `/view-estimator`,
        layout: DefaultLayout,
        component: <  ViewEstimator />
    }
]

export default EstimatorRoutes;