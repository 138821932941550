import React, { useEffect, useState } from 'react';
import Icons from '../../../assets/images';
import { useDispatch } from "react-redux";
import { listQuesAns } from '../../../services/questionAns';
import { message, options, pagination, PaginationOption } from '../../../components/common/utils/message';
import { success } from '../../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";
import AsqQuesForm from './AsqQuesForm';
import ReactPaginate from 'react-paginate';
import SelectBox from '../../../components/common/formInput/SelectBox';
import { Link, useParams, useNavigate } from "react-router-dom";
import ContractorInterfaceLayout from '../../../components/common/layout/ContractorInterfaceLayout';
import ContractorProjectDescription from "../common/ContractorProjectDescription";


const QuestionAns = () => {

    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => setModalShow(false)
    const handleShow = () => setModalShow(true);

    const dispatch = useDispatch();
    const [resPayload, setResPayload] = useState();
    const [editFaq, setEditFaq] = useState("");

    const totalPages = resPayload && resPayload.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    let projectId= useParams();
   

    
    
    useEffect(() => {
        _getListing();
    }, [dispatch]);

    /**
     * @description get all the faq db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {
      listQuesAns( projectId.id, (data) => {

            setResPayload(data)
        }, dispatch)
    }

    /**
    * @description Function For delete faq.
    * @param {id} id 
    // */
    // const _handleDelete = async (id) => {
    //     const result = await confirm(message.deleteData, options);
    //     if (result) {
    //         deleteFaq(id, async res => {
    //             if (res.status === "SUCCESS" && res.code === 200) {
    //                 _getListing();
    //                 success(res.message);
    //                 await alert(message.FaqDeleted);
    //             }
    //         })
    //     }
    // }

    /**
    * @description /Function is on Page Change.
    * @param {param} param 
    */
    const handlePageChange = async (data) => {
        let currentPage = data.selected + 1;
        setPage(currentPage)
        await listQuesAns({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };
        
    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listQuesAns({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }

    return (<>
    <ContractorProjectDescription />
  
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Question Answer</h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <button className="btn btn-blue" onClick={() => { setModalShow(true); setEditFaq("") }}>Ask Question</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Question and Answer</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <ul className='faq-list'>
                        {resPayload?.map((item, index) => (
                                <li> 
                                    <div className='faq-item'>
                                        <h4>{index + 1}. {item.question_text}</h4>
                                        { item.answer_text ?  <p dangerouslySetInnerHTML={{ __html: item.answer_text }} /> : " NA " }
                                      
                                        {/* <div className='quick-actions'>
                                            <span className={item.status === true ? "text-success" : "text-danger"}>{item.status === true ? "Active" : "Inactive"}</span>
                                            <button className="dashboard-action" title="Edit" onClick={(e) => { setModalShow(true); setEditFaq(item._id) }}>
                                                <img src={Icons.edit} alt="" />
                                            </button>
            
                                        </div> */}
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            <div className="table-filters">
                <div className="t-f-cell">
                    <div className="records-filter">
                        <span>Show</span>
                        <div className="grey-dropdown bg-white">
                            <SelectBox
                                options={PaginationOption}
                                val={limit}
                                handleVal={(e) => setPagination(e)}
                            />
                        </div>
                        <span>Entries</span>
                    </div>
                </div>
                {totalPages > 1 ? (
                    <div className="t-f-cell">
                        <ReactPaginate
                            breakLabel={"..."}
                            nextLabel={'Next >'}
                            onPageChange={handlePageChange}
                            pageCount={totalPages}
                            previousLabel={'< Back'}
                            renderOnZeroPageCount={null}
                            containerClassName={'custom-pagination'}
                            pageClassName={'btn-page'}
                            pageLinkClassName={'btn-page'}
                            previousClassName={'btn-page'}
                            previousLinkClassName={'btn-previous'}
                            nextClassName={'btn-page'}
                            nextLinkClassName={'btn-next'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}

            </div>
            <AsqQuesForm
                show={modalShow}
                handleClose={handleClose}
                editFaq={editFaq}
                handleModal={() => { _getListing(); setEditFaq("") }}
            />
        </div>
        </>
    )
}

export default QuestionAns;

