import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import BuildingView from '../BuildingView';
import BuildingList from '../BuildingList';
import BuildingForm from '../BuildingForm';
import AddProjects from '../components/AddProjects';

const BuildingRoutes = [
    {
        path: `/building-list`,
        layout: DefaultLayout,
        component: <  BuildingList />
    },
    {
        path: `/building-view/:id`,
        layout: DefaultLayout,
        component: < BuildingView />
    },
    {
        path: `/building-form`,
        layout: DefaultLayout,
        component: < BuildingForm />
    },
    {
        path: `/add-project`,
        layout: DefaultLayout,
        component: < AddProjects />
    }
]

export default BuildingRoutes;