import React from 'react'
import moment from 'moment';
import { Link } from 'react-router-dom'
import Icons from '../../../assets/images'
import _ from 'underscore'
import { API_BASEURL } from '../../../environment'
import { useDispatch, useSelector } from 'react-redux';
import { contStripe } from '../services/contractor';
import { useState } from 'react';
import { setProjectID } from '../../../components/common/general/GeneralSlice';
import { useParams } from 'react-router-dom';
import ContractorProjectView from '../ContractorProjectView';

const GridView = ({ resPayload }) => {
    let project_id = useParams();
    const dispatch = useDispatch();
   
const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [modalShow, setModalShow] = useState(false);

    const handleShow = async() => {setModalShow(true)
        await contStripe(project_id,(data,res) => {
           // window.location.href =data.url
        },dispatch)
        ;}
      
        dispatch(setProjectID(project_id.id)); 

    const loginUserState = useSelector((purchased_opportunities
        ) => purchased_opportunities
        .login?.user?.data?.user?.purchased_opportunities
        )
      

      //  let found = loginUserState.find(loginUserState => loginUserState === project_id.id);
      //  loginUserState.find(loginUserState => loginUserState ===  item._id) 
    return ( 
        <div>
             <ul className='projects-list'>
           
                {resPayload && resPayload?.docs?.map((item, ind) => ( 
                    <li key={ind}>
                        <Link to={`/contractor-project-view/${item._id}`}> 
                        <div className='project'>
                        
                            <span className='project-img'>
                                
                                     <img src={item?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                               
                            </span>
                            <div className='project-details'>
                                <div className='project-header'>
                                    <div className='project-header-left'>
                                        <h5 className='project-name'>{item?.project_title}</h5>
                                    </div>
                                    <div className='project-header-right'>
                                        <span className='project-date'>{moment(item?.createdAt).format('DD/MM/YYYY')}</span>
                                    </div>
                                </div>
                                <div className='project-details'>
                                <div className='project-header'>
                                    <div className='project-header-left'>
                                        <h5 className='project-name'>Bidding Period
                                        :{item?.bid_start_date 
                                        } To {item?.bid_end_date}</h5>
                                    </div>
                                   </div>
                               {/* <div className='project-body'>
                                    <p>{item.notes}</p>
                                    <div className='project-sub-details'>
                                        <div className='project-sub-left'>
                                            <h5>Bid Fee : $150 USD</h5>
                                        </div>
                                        <div className='project-sub-right'>
                                            <h5>Winning bid Fee : $254 USD</h5>
                                        </div>
                                    </div>
                                </div>
                */}
                <div className='project-body'>
                <p>Square footage : {item.square_footage }</p>
                                    </div>
                <div className='project-body'>
                <p>Status : <b></b>{item.project_status
                }</p>   
                                   
                                    </div>

                                    <div className='project-body'>
                                    <p>owner : {item.ownerId.company_name


                                    }</p>   
                                                       
                                                        </div>
                                    <div className='project-body'>
                                    <p>Winning bid Fee :NA</p>
                                </div>
                                <div className='project-footer'>
                                <p className='project-location'>{item?.buildingId?.city},{item?.buildingId?.state}</p>
                            </div>
                                 <div>
                                    {(loginUserState.find(loginUserState => loginUserState ===  item._id) === undefined) ? 
                                        
                                        <div class="d-flex justify-content-end">
                                        <Link to={`/contractor-project-view/${item._id}`}>
                                      
                                       <button type="button" className="btn btn-success btn-sm"  onClick={handleShow} disabled={isButtonDisabled}>Buy Oppurtunity</button></Link> </div>: 
                                        <div class="d-flex justify-content-end">
                                         <button type="button" className="btn btn-success btn-sm" disabled>ALREADY PRUCHASED</button></div>}
                                    </div>
                               
                            </div>
                        </div>
                        </div>
                     </Link>
                    </li>
                    
                ))}
            </ul>
            
        </div>
    )
}

export default GridView