import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from "formik";
import TextField from '../../../components/common/formInput/TextField';
import { useDispatch } from "react-redux";
import { success, failed } from "../../../components/common/Toastify";
import SearchSelectBox from '../../../components/common/formInput/SearchSelectBox';
import * as Yup from 'yup';
import { updateNotificationSetting } from "../../../services/notification.service";
import TextArea from '../../../components/common/formInput/TextArea';

const options = [{
    label: "Enabled",
    value: true
}, {
    label: "Disabled",
    value: false
}]
const ValdiationShema = Yup.object().shape({
    title: Yup.string()
        .required('Required'),
    type: Yup.string()
        .required('Required'),
    notification_content: Yup.string()
        .required('Required'),
    enabled: Yup.boolean()
        .required('Required')
});

const UpatedNotificationSetting = ({ show, handleClose, setting }) => {
    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
        _id: "",
        type: "",
        title: "",
        enabled: true,
        notification_content: ""

    });
    useEffect(() => {
        setFormState(getInitialFormState(setting));
    }, [setting])


    const getInitialFormState = (setting) => {
        return {
            _id: setting._id,
            type: setting.type,
            title: setting.title,
            enabled: setting.enabled,
            notification_content: setting.notification_content
        }
    }

    const _submitData = (data) => {
        if (setting) {
            updateNotificationSetting({ ...data }, (result) => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    handleClose();
                }
                else {
                    failed(result.message);
                }
            }, dispatch)

        }
    }

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="custom-inner-header">
                        <Modal.Title>{"Edit"}</Modal.Title>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={formState}
                    validationSchema={ValdiationShema}
                    enableReinitialize
                    onSubmit={(values) => {
                        _submitData(values);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className='f-row'>
                                    <div className='f-50'>
                                        <TextField
                                            label="Type"
                                            name="type"
                                            value={formik?.values?.type}
                                            onChange={formik.handleChange}
                                            required={true}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div className='f-50'>
                                        <TextField
                                            label="Title"
                                            name="title"
                                            value={formik?.values?.title}
                                            onChange={formik.handleChange}
                                            required={true}
                                            viewOnly={true}
                                        />
                                    </div>
                                    <div className='f-50'>
                                        <TextArea
                                            label="Notification Content"
                                            name="notification_content"
                                            value={formik?.values?.notification_content}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>

                                    <div className='f-50'>
                                        <SearchSelectBox
                                            label={"Status"}
                                            name="enabled"
                                            value={formik?.values?.enabled}
                                            onChange={formik.handleChange}
                                            options={options}
                                            required={true}
                                        />
                                    </div>
                                </div>

                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li>
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </li>
                                    <li>
                                        <button className="btn btn-grey" type="button" onClick={handleClose}>Cancel</button>
                                    </li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default UpatedNotificationSetting