import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import TextField from "../../components/common/formInput/TextField";
import { failed, success } from "../../components/common/Toastify";
import { useDispatch } from "react-redux";
import { getAllCMSPages, getCMSPageBySlug, updateCMSPage } from "../../services/cms.service";
import Editor from "../../components/common/formInput/Editor";
import { DragDropField } from "../../components/common/formInput/DragDropField";

const CMSFORM = () => {


    const dispatch = useDispatch();

    const [pages, setPages] = useState([]);
    const [pageDetails, setPageDetails] = useState(null);
    const [removedImages, setremovedImages] = useState([])

    useEffect(() => {
        getAllCMSPages((data) => {
            setPages(data.docs)
        }, dispatch);
        getCMSPageBySlug("about-us", (data) => {
            setPageDetails(data);
        }, dispatch)
    }, []);

    const getPageDetails = (e) => {
        e.preventDefault()
        const slug = e.target.value;
        getCMSPageBySlug(slug, (data) => {
            setPageDetails(data);
        }, dispatch)
    }

    const _updatePage = (data) => {
        try {
            let form = new FormData();
            form.append("_id", data._id);
            form.append("page_title", data.page_title);
            form.append("page_content", data?.page_content);
            form.append("type", data.type);
            form.append("buildingId", data.buildingId);
            form.append("blocks", JSON.stringify(data.blocks));
            form.append("existing_images", JSON.stringify(data.banner_images));
            form.append("banner_heading", data.banner_heading);
            form.append("banner_description", data.banner_description);
            form.append("removed_items", JSON.stringify(removedImages));
            if (data.uploaded_images) {
                for (let i = 0; i < data.uploaded_images.length; i++) {
                    form.append("banner_images", data.uploaded_images[i]);
                }
            }
            updateCMSPage(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    const deleteBlock = (index) => {
        const updatedFormState = { ...pageDetails };
        let updatedBlocks = [...updatedFormState.blocks]
        updatedBlocks.splice(index, 1);
        updatedFormState.blocks = updatedBlocks;
        setPageDetails(updatedFormState);
    }

    const addBlock = (index) => {
        const updatedFormState = { ...pageDetails };
        updatedFormState.blocks.splice(index + 1, 0, { heading: " ", description: " " });
        setPageDetails(updatedFormState);
    }

    const updatemedia = async (id, filename) => {
        if (filename) {
            let existingImages = [...pageDetails.banner_images];
            let indexOfRemovimgfile = existingImages.findIndex(item => item.filename === filename);
            let removedImage = existingImages.splice(indexOfRemovimgfile, 1);
            setremovedImages([...removedImage, removedImage[0]])
            setPageDetails({ ...pageDetails, banner_images: existingImages })
        }
    }

    return (
        <div className="dashboard-inner p-b-0">
            <div className="d-h-r">
                <ul className="dashboard-sub-filters">
                    <li>
                        {pages && <div className='sortby'>
                            <span>Select Page : </span>
                            <div className='nocolor-dropdown'>
                                <select onChange={getPageDetails}>
                                    {pages.map(page => {
                                        return <option value={page.slug} >{page.page_title}</option>
                                    })}
                                </select>
                            </div>
                        </div>}
                    </li>
                </ul>
            </div>
            {pageDetails && <Formik
                initialValues={pageDetails}
                enableReinitialize
                onSubmit={(values) => _updatePage(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h6 className="d-card-heading">{pageDetails.page_title}</h6>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-50">
                                            <TextField
                                                label="Page Title"
                                                name="page_title"
                                                value={formik?.values?.page_title}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-50">
                                            <TextField
                                                label="Banner Heading"
                                                name="banner_heading"
                                                value={formik?.values?.banner_heading}
                                                required={false}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <DragDropField
                                                label="Upload Banner Images"
                                                name="uploaded_images"
                                                media={pageDetails?.banner_images || []}
                                                updatemedia={updatemedia}
                                                isrequired={true}
                                                id="file0011"
                                                accept={'image/jpeg'}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <Editor
                                                label="Content"
                                                name="page_content"
                                                value={formik?.values?.page_content}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <Editor
                                                label="Banner Description"
                                                name="banner_description"
                                                value={formik?.values?.banner_description}
                                                required={false}
                                            />
                                        </div>
                                    </div>
                                    {pageDetails.type === "BLOCK_CONTENT" && pageDetails.blocks.length > 0 && <button
                                        className="btn btn-blue m-4"
                                        type="button"
                                        onClick={() => addBlock()}
                                    >
                                        Add New Blocks
                                    </button>}
                                    {pageDetails.type === "BLOCK_CONTENT" && pageDetails.blocks.length > 0 &&
                                        pageDetails.blocks.map((block, index) => {
                                            return (<>
                                                <div className="f-row alignTop">

                                                    <div className="f-33">
                                                        <TextField
                                                            key={`${index}-"heading"`}
                                                            label="Heading"
                                                            name={`blocks[${index}].heading`}
                                                            value={formik?.values?.blocks[index]?.heading}
                                                            required={true} />
                                                    </div>
                                                </div><div className="f-row alignTop">
                                                    <div className="f-66">
                                                        <Editor
                                                            key={`${index}-"desc"`}
                                                            label="Description"
                                                            name={`blocks[${index}].description`}
                                                            value={formik?.values?.blocks[index]?.description}
                                                            required={true} />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-sm"
                                                        onClick={() => deleteBlock(index)}
                                                    >
                                                        <i class="bi bi-dash-lg"></i>
                                                    </button>
                                                </div>
                                            </>)
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{`Update ${pageDetails.page_title}`}</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>}
        </div>
    );
};

export default CMSFORM;
