import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import authReducer from "../features/auth/loginSlice";
import loaderReducer from '../components/common/loader/LoaderSlice';
import generalReducer from '../components/common/general/GeneralSlice'
import logger from "redux-logger";

const reducers = combineReducers({
  login: authReducer,
  loader: loaderReducer,
  general: generalReducer 
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  devTools: true,
});
