import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Icons from '../../../assets/images'

const ListView = ({ resPayload, handleDelete, handleSorting }) => {
    const navigate = useNavigate();

    return (
        <div className="dashboard-table">
            <div className="responsive-table">
                <table>
                    <thead>
                        <tr>
                            <th>Serial <br /> No. </th>
                            <th><span className="sort-icon">Project Title<i class="bi bi-arrow-up" onClick={() => handleSorting("project_title", 1)}></i><i class="bi bi-arrow-down" onClick={() => handleSorting("project_title", -1)}></i></span></th>
                            <th><span className="sort-icon">Building Name<i class="bi bi-arrow-down" onClick={() => handleSorting("company_name", -1)}></i><i class="bi bi-arrow-up" onClick={() => handleSorting("company_name", 1)}></i></span></th>
                            <th><span className="sort-icon">Owner Name<i class="bi bi-arrow-down" onClick={() => handleSorting("email", -1)}></i><i class="bi bi-arrow-up" onClick={() => handleSorting("email", 1)}></i></span></th>
                            <th><span className="sort-icon">Location<i class="bi bi-arrow-down" onClick={() => handleSorting("city", -1)}></i><i class="bi bi-arrow-up" onClick={() => handleSorting("city", 1)}></i></span></th>
                            <th>Status</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {resPayload && resPayload.docs
                            .map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.project_title}</td>
                                    <td>{item?.buildingId?.name}</td>
                                    <td>{item.ownerId.first_name}</td>
                                    <td>{item?.buildingId?.city},{item?.buildingId?.state}</td>
                                    <td className={item.status === true ? "text-success" : "text-danger"}>{item.status === true ? "Active" : "Inactive"}</td>
                                    <td className="text-center">
                                        <span className="sort-icon">
                                            <button className="dashboard-action" title="View" onClick={() => navigate(`/project-view/${item._id}`)}>
                                                <img src={Icons.eye} alt="" />
                                            </button>
                                            <Link className="dashboard-action" to={`/project-form?id=${item._id}`} title="Edit">
                                                <img src={Icons.edit} alt="" />
                                            </Link>
                                            <button className="dashboard-action" title="Delete" onClick={() => handleDelete(item._id)}>
                                                <img src={Icons.bin} alt="" />
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No data found!</h5>) : null}
            </div>
        </div>
    )
}

export default ListView