import React from 'react'
import Modal from 'react-bootstrap/Modal'

const DeleteConfirmation = ({ modalData, show, handleClose, deleteAction }) => {

    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="custom-inner-header">
                        <Modal.Title>{`You are deleting ${modalData.title}.`}</Modal.Title>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure ?</p>
                </Modal.Body>
                <Modal.Footer>
                    <ul className="modal-actions">
                        <li>
                            <button className="btn btn-primary" type="button" onClick={() => deleteAction(modalData.menuId)}>Submit</button>
                        </li>
                        <li>
                            <button className="btn btn-grey" type="button" onClick={handleClose}>Cancel</button>
                        </li>
                    </ul>
                </Modal.Footer>
            </Modal >
        </div >
    )
}

export default DeleteConfirmation