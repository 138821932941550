import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ProfileField } from '../../components/common/formInput/ProfileField';
import SearchSelectBox from '../../components/common/formInput/SearchSelectBox';
import TextField from '../../components/common/formInput/TextField';
import { failed, success } from '../../components/common/Toastify';
import { message, options } from '../../components/common/utils/message';
import { ownerProfile } from '../../components/common/validations/Validation';
import { Get_Countries, Get_State } from '../../services/owner';
import {confirm } from 'react-bootstrap-confirmation';
import { edit_profile } from '../../services/auth.service';

const ProfileEdit = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const generalData = useSelector((state) => state.general.param)

    const [resStates, setResStates] = useState();
    const [resCountries, setResCountries] = useState();

    useEffect(() => {
        Get_Countries(result => { setResCountries(result.data) }, dispatch);
    }, []);

    let countryData = resCountries?.map((item) => ({ value: item.name, label: item.name }));

    const initialData = {
        _id: generalData._id,
        profile_pic: "",
        first_name: generalData.first_name,
        last_name: generalData.last_name,
        mobile_no: generalData.mobile_no,
        city: generalData.city,
        state: generalData.state,
        country: generalData.country,
        zip_code: generalData.zip_code,
        company_name: generalData.company_name,
        address1: generalData.address1,
        address2: generalData.address2,
    }

    const _editProfile = (data) => {
        let form = new FormData();
        form.append('_id', data._id)
        form.append('profile_pic', data.profile_pic)
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        try {
            edit_profile(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/profile")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    }

    /**
    * @description Function is For on Cancel Confirm Box.
    */
    const onCancel = async () => {
        const result = await confirm(message.cancelForm, options);
        if (result) {
            
            navigate('/profile')
        }
    }

    useEffect(() => {
        Get_State({ country: generalData?.country }, result => {
            if (result?.data?.states) {
                setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
            }
        }, dispatch)
    }, [generalData?.country]);

    return (
        <section className='registration-section'>
            <div className="container">
                <div className='d-card bordered '>
                    <div className='d-card m-b-25'>
                        <div className='d-card-header'>
                            <h5 className='d-card-heading d-card-sub-heading'>Profile</h5>
                        </div>
                        <Formik
                            initialValues={initialData}
                            enableReinitialize
                            validationSchema={ownerProfile}
                            onSubmit={(values) => { _editProfile(values) }}
                        >
                            {(formik) => (
                                <Form>
                                    <div className='d-card-body'>
                                        <div className="owner-profile-wrapper m-b-30">
                                            <div className="u-p-l">
                                                <div className="owner-profile-header">
                                                    <ProfileField
                                                        name="profile_pic"
                                                        id="file0009"
                                                        profileDetails={generalData?.profile_pic}/>
                                                    
                                                    <h4 className='owner-name'>{generalData?.first_name}</h4>
                                                    <p className="desc-info-detail">{generalData?.email}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="f-row">
                                            <div className='f-33'>
                                                <TextField
                                                    label="First Name"
                                                    placeholder="First Name"
                                                    name="first_name"
                                                    value={formik?.values?.first_name}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                            <div className='f-33'>
                                                <TextField
                                                    label="Last Name"
                                                    placeholder="Last Name"
                                                    name="last_name"
                                                    value={formik?.values?.last_name}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                            <div className='f-33'>
                                                <TextField
                                                    label="Phone Number"
                                                    placeholder="Phone Number"
                                                    name="mobile_no"
                                                    value={formik?.values?.mobile_no}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="f-row">
                                            <div className='f-33'>
                                                <TextField
                                                    label="Company Name"
                                                    placeholder="Company Name"
                                                    name="company_name"
                                                    value={formik?.values?.company_name}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="f-33">
                                                <TextField
                                                    label="Address Line 1"
                                                    name="address1"
                                                    placeholder="Address Line 1"
                                                    value={formik?.values?.address1}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="f-33">
                                                <TextField
                                                    label="Address Line 2"
                                                    name="address2"
                                                    placeholder="Address Line 2"
                                                    value={formik?.values?.address2}
                                                    onChange={formik.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="f-row">
                                            <div className="f-33">
                                                <SearchSelectBox
                                                    label="Country"
                                                    placeholder="Select Country"
                                                    name="country"
                                                    value={formik?.values?.country}
                                                    onChange={formik.handleChange}
                                                    changeHandle={(val) => {
                                                        Get_State({ country: val }, result => {
                                                            setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                                                        }, dispatch)
                                                    }}
                                                    required={true}
                                                    options={countryData}
                                                />
                                            </div>
                                            <div className="f-33">
                                                <SearchSelectBox
                                                    label="State"
                                                    placeholder="Select States"
                                                    name="state"
                                                    value={formik?.values?.state}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                    options={resStates}
                                                />
                                            </div>
                                            <div className="f-33">
                                                <TextField
                                                    label="City"
                                                    name="city"
                                                    placeholder="City"
                                                    value={formik?.values?.city}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                            <div className="f-33">
                                                <TextField
                                                    label="Zip Code"
                                                    name="zip_code"
                                                    placeholder="Zip Code"
                                                    value={formik?.values?.zip_code}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="u-p-r">
                                            <div className="owner-profile-actions">
                                                <button type='submit' className='btn btn-blue btn-sm m-2'>Edit</button>
                                                <button type='button' className='btn btn-grey btn-sm' onClick={onCancel}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProfileEdit;