import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loadingAction } from "../../components/common/loader/LoaderSlice";
import AuthService from "../../services/auth.service";
import { setMessage } from "../message/messageSlice";

const initialState = {
  user: null,
};

export const login = createAsyncThunk("user/login", async (param, thunkAPI) => {
  try {
    thunkAPI.dispatch(loadingAction(true))
    const data = await AuthService.login(param,thunkAPI);
    thunkAPI.dispatch(loadingAction(false))
    return { user: data };
  } catch (error) {
    thunkAPI.dispatch(loadingAction(false))
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    thunkAPI.dispatch(setMessage(message));
    return thunkAPI.rejectWithValue();
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await AuthService.logout();
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: {
    [login.fulfilled]: (state, action) => {
      if (action.payload.user.data && action.payload.user.data.user.status === true) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false
      }

      state.user = action.payload.user;
    },
    [login.rejected]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
    },
  },
});

const { reducer } = authSlice;
export default reducer;
