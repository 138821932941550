import moment from 'moment';
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ViewEstimator = () => {

    const navigate = useNavigate();

    const generalData = useSelector((state) => state.general?.param)

    const close = () => {
        navigate('/estimator')
    }

    return (
        <div className='dashboard-inner'>
            <div className='d-h-wrap'>
                <div className='d-h-l'>
                    <h4 className='dashboard-heading'>View Estimates</h4>
                </div>
            </div>
            <div className='accordians'>
                <div className='d-card m-b-25'>
                    <div className='d-card-header'>
                        <h5 className='d-card-heading d-card-sub-heading'>Estimate Details</h5>
                        <button className='btn btn-grey m-2' onClick={() => close()}>Close</button>
                    </div>
                    {generalData && generalData?.map((item, inx) => (
                        <div className='d-card-body p-b-0' key={inx}>
                            <div className="f-row">
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <h6 className="desc-info-heading">Title</h6>
                                        <p className="desc-info-detail">{item?.title}</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <h6 className="desc-info-heading">Description</h6>
                                        <p className="desc-info-detail">{item?.description}</p>
                                    </div>
                                </div>
                                <div className="f-auto">
                                    <div className="desc-info">
                                        <h6 className="desc-info-heading">Created Date</h6>
                                        <p className="desc-info-detail">{moment(item.createdAt).format('DD/MM/YYYY')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {generalData?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Estimate updated!</h5>) : null}
                </div>
            </div>
        </div>
    )
}

export default ViewEstimator