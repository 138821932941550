import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from "formik";
import TextField from '../../../components/common/formInput/TextField';
import { useDispatch } from "react-redux";
import { success, failed } from "../../../components/common/Toastify";
import SearchSelectBox from '../../../components/common/formInput/SearchSelectBox';
import TextArea from '../../../components/common/formInput/TextArea';
import CategoryManagementService from '../../../services/category.service';

const AddCategory = ({ show, handleClose, category }) => {


    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
        name: category.name || ""
    });


    const _submitData = (data) => {

        CategoryManagementService.addCategory(data, (data) => {
            if (data.status === "SUCCESS" && data.code === 200) {
                success(data.message);
                handleClose();
            }
            else {
                failed(data.message);
            }
        }, dispatch);



    }
    const _editData = (data) => {

        const payload = { _id: category.id, name: data.name }
        CategoryManagementService.updateCategory(payload, (data) => {
            if (data.status === "SUCCESS" && data.code === 200) {
                success(data.message);
                handleClose();
            }
            else {
                failed(data.message);
            }
        }, dispatch);



    }
    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="custom-inner-header">
                        <Modal.Title>{category.id ? "Edit Category" : "Add Category"}</Modal.Title>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={formState}
                    enableReinitialize
                    onSubmit={(values) => {
                        category.id ? _editData(values) : _submitData(values);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className='f-row'>
                                    <div className='f-50'>
                                        <TextField
                                            label="Category Name"
                                            name="name"
                                            value={formik?.values?.name}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>

                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li>
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </li>
                                    <li>
                                        <button className="btn btn-grey" type="button" onClick={handleClose}>Cancel</button>
                                    </li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default AddCategory