import React, { useCallback } from "react";
import FileSaver from "file-saver";
import { useCurrentPng } from "recharts-to-png";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LabelList,
} from "recharts";
import { allGraph } from "../../services/project";
import { useState, useEffect } from "react";

const PieChartSample = (props) => {
  const [graphData, setGraphData] = useState();
  const [alldata, setAlldata] = useState();
  let propsdata=props.data
  console.log("propsdata",propsdata);
  useEffect(() => {
    

      setAlldata(propsdata);
      let sqfoot = 0;
      let Avgsqfoot;
      var data = new Array();
      var arrs = new Array();
      var arrays = new Array();
      var obj = new Object();
      let totalAvg = [];
      let noOfPro = [];
      let noOfProject = 0;
      if(propsdata != undefined ){
      for (let k = 0; k < propsdata?.length; k++) {
        for (
          let i = 0;
          i < propsdata[k]?.optionWiseProjectsData.length;
          i++
        ) {
          obj.title = propsdata[k]?.optionWiseProjectsData[i].title;

          for (
            let j = 0;
            j < propsdata[k]?.optionWiseProjectsData[i].projects.length;
            j++
          ) {
            sqfoot =
              sqfoot +
              propsdata[k]?.optionWiseProjectsData[i].projects[j]
                .square_footage;
            totalAvg = Math.trunc(
              sqfoot /
              propsdata[k]?.optionWiseProjectsData[i].projects.length
            );
            noOfProject =
            propsdata[k]?.optionWiseProjectsData[i].projects.length;
          }
          console.log(noOfProject, "noOfProjects");
          obj.noOfProjections = noOfProject;
          Avgsqfoot = sqfoot / propsdata[k]?.optionWiseProjectsData.length;

          obj.squareFootage = sqfoot;
          arrs.push(obj.title);

          data.push(obj.squareFootage);

          obj.Averagesquarefoot = totalAvg;

          arrays.push(obj.Averagesquarefoot);
          noOfPro.push(obj.noOfProjections);
          console.log(noOfPro, "noOfProarry");
          sqfoot = 0;
          noOfProject = 0;
        }
      }

    }else{
      alert("Data not found")
    }

      let finalarr1 = [];
      for (let h = 0; h < arrs.length; h++) {
        let obj = new Object();
        obj.Title = arrs[h];
        obj.Averagesquarefoot = arrays[h];
        obj.NoOfProjects = noOfPro[h];
        finalarr1.push(obj);
        console.log(finalarr1, "finalarr1");
      }

      setGraphData(finalarr1);
    
  }, []);

  const COLORS = [
    "#DE3163",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#CD5C5C",
    "#F08080",
    "#FFBB28",
    "#DFFF00",
    "#FFBF00",
    "#FF7F50",
    "#DE3163",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#CD5C5C",
    "#F08080",
    "#FFBB28",
    "#DFFF00",
    "#FFBF00",
    "#FF7F50",
  ];
  const menu_title = alldata?.menu_title;

  const [getPiePng, { ref: pieRef }] = useCurrentPng();
  const handlePieDownload = useCallback(async () => {
    const png = await getPiePng();
    if (png) {
      FileSaver.saveAs(png, "pie-chart.png");
    }
  }, [getPiePng]);
  return (
    <div style={{ width: "50%", height: 400, margin: "0 auto" }}>
      <ResponsiveContainer>
        <PieChart width={500} height={400} ref={pieRef}>
          <Pie
            dataKey="Averagesquarefoot"
            nameKey="Title"
            isAnimationActive={false}
            data={graphData}
            cx={200}
            cy={200}
            outerRadius={150}
            fill="#8884d8"
            label
          >
            <LabelList
              dataKey="NoOfProjects"
              position="right"
              style={{ fontSize: "15px" }}
            />
            {graphData &&
              graphData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <h5 align="center">{menu_title}</h5>
      <br />
      <button onClick={handlePieDownload}>
        <code>Download Pie Chart</code>
      </button>
    </div>
  );
};
export default PieChartSample;
