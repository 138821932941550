import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import PaymentManagement from '../PaymentManagement';

const PaymentManagementRoutes = [
    {
        path: `/payment-management`,
        layout: DefaultLayout,
        component: < PaymentManagement />
    }
]

export default PaymentManagementRoutes;