import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import TermList from '../TermList';

const TermRoutes = [
    {
        path: `/term-list`,
        layout: DefaultLayout,
        component: < TermList />
    },
]

export default TermRoutes;