import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

export const addMenu = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/admin/menu/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const getAllApplicationMenu = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/menu/view/all`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateMenu = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/admin/menu/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const deleteMenu = async (menuId, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.delete(`${apiUrl}${PORT}/admin/menu/delete/${menuId}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


const ApplicationMenuService = {
    addMenu,
    updateMenu,
    getAllApplicationMenu,
    deleteMenu
};

export default ApplicationMenuService;