import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import NewRoofTypeList from '../NewRoofTypeList';
import NewRoofTypeView from '../NewRoofTypeView';
import NewRoofTypeForm from '../NewRoofTypeForm'

const NewRoofTypeRoutes = [
    {
        path: `/new-roof-type-list`,
        layout: DefaultLayout,
        component: <  NewRoofTypeList />
    },
    {
        path: `/new-roof-type-form`,
        layout: DefaultLayout,
        component: <  NewRoofTypeForm />
    },
    {
        path: `/new-roof-type-view/:id`,
        layout: DefaultLayout,
        component: <  NewRoofTypeView />
    }
    
    
]

export default NewRoofTypeRoutes ;