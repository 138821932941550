import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

export const addInspection = (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': `multipart/form-data`
                }
            }
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/inspection/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const listInspection = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/inspection/view/all?${queryString.stringify(param)}`, option).then((response) => {
                dispatch(loadingAction(false))
                callback(response.data.data)
            }).catch((error) => {
                dispatch(loadingAction(false))
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const DeleteInspection = async (id, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.delete(`${apiUrl}${PORT}/rpc/inspection/delete/${id}`, option)
                .then((response) => {
                    callback(response.data)
                }).catch((error) => {
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const viewOneInspection = async (param, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/rpc/inspection/view?${queryString.stringify(param)}`, option).then((response) => {
                callback(response.data.data)
            }).catch((error) => {
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateInspection = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': `multipart/form-data`
                }
            }
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/rpc/inspection/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};