import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../../assets/images/index';
import { emailBidderProject } from '../../services/project';
import _ from 'underscore';
import { API_BASEURL } from '../../environment';
import ReactPaginate from 'react-paginate';
import { pagination, PaginationOption } from "../../components/common/utils/message";
import { useDispatch } from "react-redux";
import SelectBox from "../../components/common/formInput/SelectBox";

const EmailBidderList = () => {
    const dispatch = useDispatch();
    const [resPayload, setResPayload] = useState();

    const totalPages = resPayload && resPayload.totalPages;

    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    useEffect(() => {
        emailBidderProject({ page: pagination.page }, (data) => {
            setResPayload(data)
        }, dispatch)
    }, [dispatch]);

    /**
     * @description /Function is on Page Change.
     * @param {param} param 
     */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await emailBidderProject({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };

    /**
   * @description Function for show in dropdown entries with page option.
   * @param {param} displayPerPage 
   */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await emailBidderProject({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }

    return (
        <div className='dashboard-inner p-b-0'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <div className="custom-seachbox">
                        <input type="search" placeholder="Search" />
                        <button className="btn btn-primary btn-search">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <div className="sortby">
                                <span>State : </span>
                                <div className="nocolor-dropdown">
                                    <select>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                        <option>California</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="sortby">
                                <span>Sort by : </span>
                                <div className="nocolor-dropdown">
                                    <select>
                                        <option>Active</option>
                                        <option>Inactive</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className='sortby'>
                                <span>Page : </span>
                                <div className='nocolor-dropdown'>
                                    <select>
                                        <option>01</option>
                                        <option>02</option>
                                        <option>03</option>
                                        <option>04</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Projects List</h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <Link to="/project-form" className="btn btn-blue">Add Project</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <ul className='projects-list'>
                {resPayload && resPayload.docs.map((item, ind) => (
                    <li key={ind}>
                        <div className='project'>
                            <span className='project-img'>
                                <Link to={`/email-bidder-view/${item._id}`}> <img src={`${API_BASEURL}/${_.findWhere(item?.media, { type: "images" })?.path}` || (Icons.projectBigImg)} alt="" /></Link>
                            </span>
                            <div className='project-details'>
                                <div className='project-header'>
                                    <div className='project-header-left'>
                                        <h5 className='project-name'>{item.project_Id.project_title}</h5>
                                    </div>
                                    <div className='project-header-right'>
                                        <span className='project-date'>{item.project_Id.createdAt}</span>
                                    </div>
                                </div>
                                <div className='project-body'>
                                    <p>{item.project_Id.description}</p>
                                    <div className='project-sub-details'>
                                        <div className='project-sub-left'>
                                            <h5>Bid Fee : $150 USD</h5>
                                        </div>
                                        <div className='project-sub-right'>
                                            <h5>Winning bid Fee : ${item.bidPrice} USD</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className='project-footer'>
                                    <p className='project-location'>{item.project_Id.state}</p>
                                </div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
            <div className="table-filters">
                <div className="t-f-cell">
                    <div className="records-filter">
                        <span>Show</span>
                        <div className="grey-dropdown bg-white">
                            <SelectBox
                                options={PaginationOption}
                                val={limit}
                                handleVal={(e) => setPagination(e)}
                            />
                        </div>
                        <span>Entries</span>
                    </div>
                </div>
                {totalPages > 1 ? (
                    <div className="t-f-cell">
                        <ReactPaginate
                            breakLabel={"..."}
                            nextLabel={'Next >'}
                            onPageChange={handlePageChange}
                            pageCount={totalPages}
                            previousLabel={'< Back'}
                            renderOnZeroPageCount={null}
                            containerClassName={'custom-pagination '}
                            pageClassName={'btn-page'}
                            pageLinkClassName={'btn-page'}
                            previousClassName={'btn-page'}
                            previousLinkClassName={'btn-previous'}
                            nextClassName={'btn-page'}
                            nextLinkClassName={'btn-next'}
                            activeClassName={'active'}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default EmailBidderList

