import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { ownerTypeSchemaProject } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import Icons from '../../assets/images';
import { options, message } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import TextArea from "../../components/common/formInput/TextArea";
import { addOwnerType, updateOwnerType, listOwnerType } from "../../services/ownerType";
import { listBidForm, addBidForm, updateBidForm, getSumbitBidAdmin } from '../../services/project';
import Bids from "./component/Bids";
//  listOwnerType == listBidForm   updateOwnerType == updateBidForm addOwnerType === addBidForm
// list type check now

const BidResult = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();
    const [formValues, setFormValues] = useState(
        [
            {
                item: "",
                default_value: 0,
                allowed_in_words: false

            }]
    );
    const [checkBId, setCheckBId] = useState();
    let strHref = window.location.href;
    let projectId = (strHref.split("=")).slice(-1).pop();
    useEffect(() => {
        _checkgetListing();

    }, [dispatch]);



   
    useEffect(() => {
        listBidForm(projectId, (data) => { setResPayload(data) }, dispatch);
    }, []);



    const _checkgetListing = () => {
        getSumbitBidAdmin(projectId, (data) => {
            setCheckBId(data)
        }, dispatch)
    }

    // code start here

    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, {
            item: "", default_value: 0,
            allowed_in_words: false
        }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let handleSubmit = (event) => {
        
        event.preventDefault();
        let data1 = (JSON.stringify(formValues));
        let resData = {
            "project_id": projectId,
            "line_items": JSON.parse(JSON.stringify(formValues))
        }

        try {
            addBidForm(resData, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("project-view/"`${projectId}`)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    }


    // code end here

    const bid_form_id = queryString.parse(location.search)
    const get_owner_type = resPayload && resPayload.docs.find((item) => item.project_id === bid_form_id.id)


    const initialData = {

        item: get_owner_type ? get_owner_type.line_items : "",

    };

    /**
     * @description Function is for add R-Value.
     * @param {object} data 
     */
    const _submitData = (data) => {
        alert(data);
        try {
            alert(data);

            const payload = {}
            // payload.options = data.options;
            payload.options = data.options;

            if (get_owner_type == null) {
                addBidForm(payload, result => {

                    if (result.status === "SUCCESS" && result.code === 200) {
                        success(result.message)
                        navigate("/owner-type-list")
                    } else if (result.status === "ERROR" && result.code === 403) {
                        failed(result.message)
                    }
                }, dispatch)
            }
            if (get_owner_type != null) {
                updateBidForm({ ...payload, _id: get_owner_type._id }, (result) => {
                    if (result.status === "SUCCESS" && result.code === 200) {
                        success(result.message);
                        // handleClose();
                    }
                    else {
                        failed(result.message);
                    }
                }, dispatch)
            }


        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update New Roof Type
     * @param {object} data 
     */


    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.cancelOwnerTypeForm, options);
        if (result) {
            alert(message.canceledOwnerTypeForm);
            navigate("/owner-type-list");
        }
    };


    // change bid first here chek the issue on this page 
    let abc;
    let isCheckbidArray = Array.isArray(checkBId);


    const data = [{
        "id": 1,
        "Contractor": "Exteriors, Inc. dba: CALIFORNIA COMMERCIAL ROOFING SYSTEMS",
        "Address": "Ventura, CA",
        "phone-email": "805-644-1640, dweaver@calcommercialroofing.com",
        "Manufacturer": "Carlisle",
        "Amount": "20.00",
        "sq-ft": " ",
        "Start-Date": "Carlisle",
        "days": "40",
    },
    {
        "id": 2,
        "Contractor": "Exteriors By Design, Inc. dba: ROOFING SYSTEMS",
        "Address": "Ventura, CA",
        "phone-email": "805-644-1640, dweaver@roofing.com",
        "Manufacturer": "Carlisle",
        "Amount": "20.00",
        "sq-ft": " ",
        "Start-Date": "Carlisle",
        "days": "40",
    }];

    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState([]);

    // State variable to keep track which row is currently expanded.
    const [expandState, setExpandState] = useState({});

    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleEpandRow = (event, userId) => {
        const currentExpandedRows = expandedRows;
        const isRowExpanded = currentExpandedRows.includes(userId);

        let obj = {};
        isRowExpanded ? (obj[userId] = false) : (obj[userId] = true);
        setExpandState(obj);

        // If the row is expanded, we are here to hide it. Hence remove
        // it from the state variable. Otherwise add to it.
        const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== userId) :
            currentExpandedRows.concat(userId);

        setExpandedRows(newExpandedRows);
    }

    return (
        <div className="dashboard-inner bid-result">

            <div className="d-card m-b-25">
                <div className="d-card-header">
                <a class="btn btn-blue mx-3" href=""> Back </a>
                   
                </div>
                {/* <button className="btn btn-success" type="button">Add</button> */}
                <div className="d-card-body">
                    <div className="dashboard-table">
                    <Bids projectId ={projectId} />
                    </div>
                </div>
            </div>

            {/* bid line */}
            {/* <div className="d-card m-b-25">
                <div className="d-card-header">
                    <h5 className="d-card-heading"> - Line Item / Unit Prices </h5>
                </div>
                <div className="d-card-body">
                    <div className="dashboard-table">
                        <div className="responsive-table">
                            <table className='table-odd-even table-update'>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Line Item</th>
                                        <th className="text-center"># 1</th>
                                        <th className="text-center"># 2</th>
                                        <th className="text-center"># 3</th>
                                        <th className="text-center"># 4</th>
                                        <th className="text-center"># 5</th>
                                        <th className="text-center"># 6</th>
                                        <th className="text-center"># 7</th>
                                        <th className="text-center">Avg</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="text-center">1</td>
                                        <td>"Deduct Alternate amount to deduct from the lump sum base bid price to eliminate the top layer of 2”</td>
                                        <td className="text-center">165,348.00</td>
                                        <td>19348.00</td>
                                        <td>292.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>15,995.86</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">2</td>
                                        <td>"Deduct Alternate amount to deduct from the lump sum base bid price to eliminate the top layer of 2”</td>
                                        <td className="text-center">165,348.00</td>
                                        <td>19348.00</td>
                                        <td>292.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>15,995.86</td>
                                    </tr>
                                    <tr>
                                        <td className="text-center">3</td>
                                        <td>"Deduct Alternate amount to deduct from the lump sum base bid price to eliminate the top layer of 2”</td>
                                        <td className="text-center">165,348.00</td>
                                        <td>19348.00</td>
                                        <td>292.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>165,348.00</td>
                                        <td>15,995.86</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>



    )


};

export default BidResult;
