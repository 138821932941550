import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import GraphReports from '../graphreports';
import CreateGraphForm from '../creategraphform';
import GraphReportPage from '../graphReportPage';
const GRRoutes = [
    {
        path: `/graph-reports`,
        layout: DefaultLayout,
        component: < GraphReports />
    },
    {
        path: `/create-graph`,
        layout: DefaultLayout,
        component: < CreateGraphForm />
    },
    {
        path: `/view-graph`,
        layout: DefaultLayout,
        component: < GraphReportPage/>
    },
    

]

export default GRRoutes;