import axios from "axios";
import { apiUrl, PORT } from "../environment";
import { loadingAction } from "../components/common/loader/LoaderSlice";

export const getAllCMSPages = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/page/view/all`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const getCMSPageBySlug = async (slug, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/page/view/${slug}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateCMSPage = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/admin/page/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const getPageBySlug = async (slug, callback, dispatch) => {
    try {
        dispatch(loadingAction(true))
        axios.get(`${apiUrl}${PORT}/pages${slug}`)
            .then((response) => {
                dispatch(loadingAction(false))
                callback(response.data.data)
            })
            .catch((error) => {
                dispatch(loadingAction(false))
                callback(false);
            });
    } catch (err) {
        console.log(err);
    }
};

const CMSService = {
    getAllCMSPages,
    getCMSPageBySlug,
    updateCMSPage,
    getPageBySlug
};

export default CMSService;