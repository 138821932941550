import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Icons from "../../assets/images/index";
import { Formik, Form } from "formik";
import { loginSchema } from "../../components/common/validations/Validation";
import { login } from "./loginSlice";
import { useDispatch,useSelector } from "react-redux";
import { success, failed } from "../../components/common/Toastify";
import TextInput from "./common/TextInput";
import CryptoJS from 'crypto-js';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const remember = sessionStorage.getItem('remember_admin');

  /**
   * @description For redirect to dashboard already Login User.
   */
   let loginUser = useSelector(state => state.login?.isLoggedIn);
   useEffect(() => {    
   loginUser ?  navigate("/dashboard") : navigate("/login"); 
  }, []);
   

  let username = '';
  let password = '';
  let decryptedData = '';
  let rememberObj = '';

  /**
   * @description conditon used for RememberMe
   */
  if (remember) {
    if (typeof remember === 'string')
      rememberObj = JSON.parse(remember);
    if (typeof rememberObj === 'object' && rememberObj.password !== undefined) {
      let bytes = CryptoJS.AES.decrypt(rememberObj.password, 'my-secret-key@123');
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      username = rememberObj.username;
      password = decryptedData;
    }
  }
  
  /**
   * @description Function is for Login User.
   * @param {object} data 
   */
  const Login = async (data) => {
    let iniData = data;
    try {
      const loginDispatch = await dispatch(login(data));
      if (loginDispatch.payload.user.code === 200 && loginDispatch.payload.user.status === "SUCCESS" && loginDispatch.payload.user.data.user.status) {

        /**Start checking for the rememberMe */
        if (isChecked) {
          var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(iniData.password), 'my-secret-key@123').toString();
          const myJSON = JSON.stringify({ username: iniData.email, password: ciphertext });
          sessionStorage.setItem('remember_admin', myJSON);
        }
        else {
          sessionStorage.setItem('remember_admin', "");
        }
        /**End */

        /**Start Check Owner/Manufacturer And first time login user to change password forcefully */
        if (loginDispatch.payload.user.data.user.role !== "ADMIN" && loginDispatch.payload.user.data.user.password_change_required) {
          await navigate("/change-password");
        } else if (loginDispatch.payload.user.data.user.role === "OWNER") {
          await navigate("/owner-dashboard");
          success("Welcome Back To Roofing Project Dashboard");
        } else if (loginDispatch.payload.user.data.user.role === "MANUFACTURER") {
          await navigate("/manu-dashboard");
          success("Welcome Back To Roofing Project Dashboard");
        }
        else if (loginDispatch.payload.user.data.user.role === "CONTRACTOR") {
          await navigate("/contractor/dashboard");
          success("Welcome Back To Roofing Project Dashboard");
        }
        else {
          await navigate("/dashboard");
          success("Welcome Back To Roofing Project Dashboard");
        }
        /**End */

      } else {
        await failed(loginDispatch.payload.user.message);
      }
    } catch (e) {
      failed(e);
    }
  };

  /**
   * @description Function is used for OnChecked RememberMe
   * @param {event} e 
   */
  const onCheckboxChecked = (e) => {
    const checked = e.target.checked;
    if (checked) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem('remember_admin'))
      setIsChecked(true);
  }, []);

  return (

    <div className="login-left-wrapper">
      <div className="brand">
        <Link to="/">
          <img src={Icons.logo} alt="RP" />
        </Link>
      </div>

      <Formik
        initialValues={{
          email: username,
          password: password,
        }}
        validationSchema={loginSchema}
        onSubmit={(values) => {
          Login(values);
        }}
      >
        <Form>
          <div className="login-box">
            <h4 className="form-heading">Log in</h4>
            <p className="form-desc">
              Enter your credentials to acces your account.
            </p>
            <TextInput
              label="Username"
              name="email"
              type="email"
              required={true}
            />
            <TextInput
              label="Password"
              name="password"
              required={true}
              eyeIcon={true}
            />
            <div className="form-row">
              <div className="w50">
                <div className="form-field">
                  <label className="checkbox" htmlFor="rememberme">
                    <input type="checkbox" id="rememberme" checked={isChecked} value="1"
                      onClick={(e) => { onCheckboxChecked(e) }} />
                    <span>Remember me</span>
                  </label>
                </div>
              </div>
              <div className="w50">
                <div className="form-field text-right">
                  <Link
                    to="/forgot-password"
                    className="additional-link"
                  >
                    Forgot password?
                  </Link>
                </div>
              </div>
            </div>
            <div className="form-row">
              <div className="w100">
                <div className="form-action">
                  <button className="btn-square btn-blue">
                    Sign In
                  </button>
                </div>
                <div className="dont-have-account">
                  <span>If you don't have account!</span>
                  <Link to={{ pathname: `/registration` }}>Register</Link>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Login;
