import React from "react";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
import { useState,useEffect } from "react";
import { contlistEstimator } from "../services/contractor";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import NoDataFound from "../../../components/common/NoDataFound";

const EstimatorAssistance = () => {
    let id= useParams();
   

    const dispatch = useDispatch();

    const [projectData, setprojectData] = useState([]);


    useEffect(() => {
        _getListing();
    }, [dispatch]);

    const _getListing = () => {
      
        contlistEstimator( id.id, (data) => {
           
  
            let newData = JSON.parse(JSON.stringify(data))
            setprojectData(newData)
            
          }, dispatch)
      }
    return( 
   
    <>
    <div className='dashboard-inner'>
   
    <ContractorProjectDescription/>
     
    <div className="dashboard-table responsive-table vr-table">
    <h2>Estimator Assistance </h2>
        {projectData?.length  >0 ?
            (<table>
                <thead>
                    <tr>
                   
                        <th>Title</th>
                        <th>Description</th>
                      
                    </tr>
                </thead>
               
                <tbody>

                {projectData?.map((data, inx) => (
                        <tr>
                            <td>{data?.title}</td>
                            <td>{data?.description}</td>
                            
                        </tr>
                        
                    ))}  
      
                </tbody>
            </table>)
            
            
            :
                    <h5 className="d-flex align-items-center justify-content-center"><div><NoDataFound/></div></h5>}
    </div>
    </div>
</>
    )
}
export default EstimatorAssistance;