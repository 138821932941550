import "./App.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedOutlet from "./ProtectedRoutes";
import { routes, authRoutes, homeRoutes, OwnerInterfaceRoutes, ManuInterfaceRoutes, ContractorInterfaceRoutes } from "./routes/index";
import PageNotFound  from "./components/common/pageNotFound/PageNotFound";
import UnauthorisedAccess from "./components/common/pageNotFound/UnauthorisedAccess";

function App() {
  var hist = createBrowserHistory();

  return (
    <>
      <Router>
        <ToastContainer />
        <Routes history={hist}>
          {homeRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={<route.layout>{route.component}</route.layout>}
              />
            );
          })}
          {authRoutes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                element={<route.layout>{route.component}</route.layout>}
              />
            );
          })}
          <Route element={<ProtectedOutlet />}>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
          </Route>
          <Route element={<ProtectedOutlet />}>
            {OwnerInterfaceRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
          </Route>
          <Route element={<ProtectedOutlet />}>
            {ManuInterfaceRoutes.map((route, index) => {
             return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
          </Route>
          <Route element={<ProtectedOutlet />}>
            {ContractorInterfaceRoutes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  element={<route.layout>{route.component}</route.layout>}
                />
              );
            })}
          </Route>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/acces-denied" element={<UnauthorisedAccess />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
