import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { deleteEstimate, listEstimator, updateProjectEstimator } from '../../services/estimator';
import { message, options, pagination, PaginationOption } from "../../components/common/utils/message";
import { failed, success } from '../../components/common/Toastify';
import { alert, confirm } from "react-bootstrap-confirmation";
import ReactPaginate from 'react-paginate';
import SelectBox from '../../components/common/formInput/SelectBox';
import EstimatorForm from './EstimatorForm';
import Icons from '../../assets/images';
import _ from 'underscore';
import { viewOneProject } from '../../services/project';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const EstimatorList = () => {

    const location = useLocation();
    const dispatch = useDispatch();

    const _projectId = queryString.parse(location.search);

    const [modalShow, setModalShow] = useState(false);
    const [cloneEstimator, setCloneEstimator] = useState("");
    const handleClose = () => setModalShow(false)

    const [resPayload, setResPayload] = useState();
    const [projectName, setProjectName] = useState();

    const totalPages = resPayload && resPayload.totalPages;
    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page)

    let [estimatorData, setEstimatorData] = useState([]);
    let [isRightCheck, setIsRightCheck] = useState([]);
    let [isLeftCheck, setIsLeftCheck] = useState([]);

    useEffect(() => {
        _getListing();
        //getting Estimator data from project by project_id
        viewOneProject(_projectId, (data) => {
            setEstimatorData(data?.associatedEstimatorAssistance);
            setProjectName(data?.project_title)
        })
    }, [dispatch]);

    useEffect(() => {
        _getListing();
    }, [estimatorData])
console.log(estimatorData)
    /**
     * @description get all the faq db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {
        console.log(estimatorData)
        let ignoreRecords = estimatorData?.map((item) => item._id)
        listEstimator({ page, limit, ignoreRecords }, (data) => { setResPayload(data) }, dispatch)
    }

    /**
    * @description Function For delete faq.
    * @param {id} id 
    */
    const _handleDelete = async (id) => {
        const result = await confirm(message.deleteData, options);
        if (result) {
            deleteEstimate(id, async res => {
                if (res.status === "SUCCESS" && res.code === 200) {
                    _getListing();
                    success(res.message);
                    await alert(message.EstimateAssistanceDeleted);
                }
            })
        }
    }

    //Left checkbox handlecheck.............
    const handleChangeCheck = (id, e) => {
        let checkedData = JSON.parse(JSON.stringify(isLeftCheck))
        if (!e.target.checked) {
            let index = checkedData.findIndex(item => item === id)
            if (index !== -1)
                checkedData.splice(index, 1)
            setIsLeftCheck([...checkedData])
        } else
            setIsLeftCheck([...checkedData, String(id)])
    }

    //Right checkbox handlecheck.............
    const _handleCheck = (id, e) => {
        let checkValue = JSON.parse(JSON.stringify(isRightCheck))
        if (!e.target.checked) {
            let index = checkValue.findIndex(item => item === id)
            if (index !== -1)
                checkValue.splice(index, 1)
            setIsRightCheck([...checkValue])
        } else
            setIsRightCheck([...checkValue, String(id)])
    }

    //For Right side remove data....
    const _handleArrowDown = () => {
        let checkData = estimatorData.filter(e => {
            if (!isRightCheck.includes(e._id))
                return e
        })
        setEstimatorData([...checkData])
    }

    // on data move left to right
    const _handleArrowUp = async () => {
        if (_projectId.id) {
            let rightEstimates = resPayload.docs
            await rightEstimates?.forEach(item => {
                if (isLeftCheck.includes(item._id)) {
                    estimatorData.push(item)
                }
            })
            setIsLeftCheck([])
            var output = rightEstimates.filter((el) => {
                if (!isLeftCheck.includes(el._id)) {
                    return el
                }
            })
            setResPayload({ ...resPayload, docs: output })
        } else {
            failed("Project is not associated! Select a project first!.")
        }
    }

    //update description handle change
    const _handleChange = (item, e, attribute) => {
        for (var i = 0; i < estimatorData.length; i++) {
            if (estimatorData[i]._id === item._id) {
                estimatorData[i][attribute] = e.target.value
            }
        }
        setEstimatorData([...estimatorData])
    }

    //update description textare
    const updateInput = (item, className) => {
        return (
            <div className='f-field' style={{ border: '0.5px solid #ccc' }}>
                <textarea
                    className={className}
                    type="text"
                    value={className === "f-area" ?
                        item.description :
                        item.title
                    }
                    onChange={(e) => { _handleChange(item, e, className === "f-area" ? "description" : "title") }} />
            </div>
        )
    }

    /**
    * @description Function For Update Estimator.
    * @param {id} id 
    */
    const _updateData = async () => {
        if (_projectId.id) {
            try {
                updateProjectEstimator({ associatedEstimatorAssistance: estimatorData, _id: _projectId.id }, result => {
                    if (result.status === "SUCCESS" && result.code === 200) {
                        success(result.message);
                        setEstimatorData([...estimatorData])
                    } else if (result.status === "ERROR" && result.code === 403) {
                        failed(result.message)
                    }
                }, dispatch)
            } catch (e) {
                failed(e)
            }
        } else {
            failed("Project is not associated! Select a project first!.")
        }
    }

    /**
    * @description /Function is on Page Change.
    * @param {param} param 
    */
    const handlePageChange = async (param) => {
        let currentPage = param.selected + 1;
        setPage(currentPage)
        await listEstimator({ page: currentPage, limit }, response => {
            setResPayload(response);
        }, dispatch)
    };

    /**
     * @description Function for show in dropdown entries with page option.
     * @param {param} displayPerPage 
     */
    const setPagination = async (displayPerPage) => {
        setLimit(displayPerPage);
        await listEstimator({ limit: displayPerPage, page }, response => {
            setResPayload(response);
        }, dispatch)
    }
console.log(' _projectId ---- 202', _projectId.id)
    return (
        <div className='dashboard-inner'>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Project Estimator Assistance </h4>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters dashboard-sm-sub-filters">
                        { _projectId.id != null || _projectId.id !=  undefined ? 
                        
                        <li>
                        <button className="btn btn-blue" type='submit' onClick={(e) => { _updateData(); }}>Update</button>
                    </li> : ''
                    }
                        
                        <li>
                            <button className="btn btn-blue btn-min-width-200" onClick={() => { setModalShow(true); setCloneEstimator("") }} >Add New Estimate</button>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h6 className='text-center'>Master Estimator Assistance</h6>
                </div>
                <div className="d-h-r">
                    {projectName ? <h6>Project Name:<span> {projectName}</span></h6> :
                        <h6 className='text-center'>Assigned Estimator Assistance</h6>}
                </div>
            </div>
            <div className='estimator-container'>
                <div className='estimator-blocks'>
                    <div className="dashboard-table">
                        <div className="responsive-table">
                            <table className='table-odd-even'>
                                <thead>
                                    <tr>
                                        <th className="text-center">select</th>
                                        <th>
                                            <div className='master-estimate'>
                                                <h4>Title</h4>
                                                <h6>Description</h6>
                                            </div>
                                        </th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resPayload && resPayload?.docs
                                        .map((item, index) => (
                                            <tr key={index}>
                                                <td className="text-center">
                                                    <label className='checkbox nolabel' htmlFor={`check004${index}`}>
                                                        <input type="checkbox"
                                                            checked={isLeftCheck.includes(item._id)}
                                                            onClick={(e) => { handleChangeCheck(item._id, e) }}
                                                            id={`check004${index}`}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <div className='master-estimate'>
                                                        <h4>{item.title}</h4>
                                                        <h6>{item.description}</h6>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <span className="sort-icon">
                                                        <button className="dashboard-action" title="Clone" onClick={(e) => { setModalShow(true); setCloneEstimator(item._id) }}>
                                                            <img src={Icons.cloneIcon} alt="" />
                                                        </button>
                                                        <button className="dashboard-action" title="Delete" onClick={() => _handleDelete(item._id)}>
                                                            <img src={Icons.bin} alt="" />
                                                        </button>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="table-filters">
                        <div className="t-f-cell">
                            <div className="records-filter">
                                <span>Show</span>
                                <div className="grey-dropdown bg-white">
                                    <SelectBox
                                        options={PaginationOption}
                                        val={limit}
                                        handleVal={(e) => setPagination(e)}
                                    />
                                </div>
                                <span>Entries</span>
                            </div>
                        </div>
                        {totalPages > 1 ? (
                            <div className="t-f-cell">
                                <ReactPaginate
                                    breakLabel={"..."}
                                    nextLabel={'Next >'}
                                    onPageChange={handlePageChange}
                                    pageCount={totalPages}
                                    previousLabel={'< Back'}
                                    renderOnZeroPageCount={null}
                                    containerClassName={'custom-pagination'}
                                    pageClassName={'btn-page'}
                                    pageLinkClassName={'btn-page'}
                                    previousClassName={'btn-page'}
                                    previousLinkClassName={'btn-previous'}
                                    nextClassName={'btn-page'}
                                    nextLinkClassName={'btn-next'}
                                    activeClassName={'active'}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
                { _projectId.id != null || _projectId.id !=  undefined  ? 
                <div className='estimator-arrow'>
                <span onClick={() => _handleArrowUp()}><i class="bi bi-arrow-right-square-fill"></i></span><br />
                <span onClick={() => _handleArrowDown()}><i class="bi bi-arrow-left-square-fill"></i></span>
            </div>
                
                : ''}
                
                <div className='estimator-blocks'>
                    <div className="dashboard-table">
                        <div className="responsive-table">
                            <table>
                                { _projectId.id != null || _projectId.id !=  undefined  ?                                 <thead>
                                    <tr>
                                        <th className="text-center">select</th>
                                        <th>Title</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                 : '' }
                                <tbody>
                                    {estimatorData?.map((item, index) => (
                                        <tr key={index}>
                                            <td className="text-center">
                                                <label className='checkbox nolabel' htmlFor={`check005${index + 1}`}>
                                                    <input type="checkbox" id={`check005${index + 1}`}
                                                        checked={isRightCheck.includes(item._id)}
                                                        onClick={(e) => _handleCheck(item._id, e)} />
                                                    <span></span>
                                                </label>
                                            </td>
                                            <td>{updateInput(item, "f-input")}</td>
                                            <td>{updateInput(item, "f-area")}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {/* {estimatorData === undefined || estimatorData?.length === 0 ? ( <h5 className="d-flex align-items-center justify-content-center">No data found!</h5>) : null} */}
                        </div>
                    </div>
                </div>
            </div>
            <EstimatorForm
                show={modalShow}
                handleClose={handleClose}
                cloneEstimator={cloneEstimator}
                handleModal={() => { _getListing(); setCloneEstimator("") }}
            />
        </div>
    )
}

export default EstimatorList

