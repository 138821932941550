import { ErrorMessage, useField } from 'formik'
import React from 'react'

const TextField = ({ label, required, ...props }) => {

    const [field, meta, helpers] = useField(props);
    return (
        <div className='form-group'>
            <label className="f-label" htmlFor={field.name}>{label}{required ? <span className="error">*</span> : ""}</label>
            <div className='f-field'>
                <input
                className={`form-control f-input ${meta.touched && meta.error && 'is-invalid'}`}
                    {...field} {...props} 
                    onChange={(e) => {
                        helpers?.setValue(e.target.value)
                        props?.changeHandle?.(e.target.value)
                    }}
                    autoComplete="off"
                    type={props.type}
                />
                <ErrorMessage component="div" name={field.name} className="error" />
            </div>
        </div>
    )
}

export default TextField;