import { ErrorMessage, useField } from 'formik'
import React, { useCallback, useState } from "react";
import "./FileField.scss";
import Dropzone from "react-dropzone";
import Icons from '../../../assets/images';
import { API_BASEURL } from '../../../environment';
import images from '../../../assets/images/defaultimage.png'
export const UploadLogoField = ({ ...props }) => {

    const [field, meta, helpers] = useField(props.name);
    const [image, setImage] = useState(null);
    const [errorMsg,setErrorMsg]= useState('')

    return (
        <>
            <label className="f-label" htmlFor={props.name}>{props.label}{props.isrequired ? <span className="error">*</span> : ""}</label>
            <div className='f-field'>
                <div className="draggable-wrap">
                    <label
                        className="draggable-box"
                        htmlFor={props.id}
                    >
                        <>
                            <div className="inner-blc">
                                <input
                                    {...props}
                                    type="file"
                                    onChange={(e) => {
                                        let file = e.target.files ? e.target.files[0] : File;
                                        if(file && file.type.indexOf('image') !== -1){
                                        helpers.setValue(file);
                                        setImage(URL.createObjectURL(e.target.files[0]));
                                        setErrorMsg('')
                                        }else{
                                            setErrorMsg('File type not accepted, sorry!"')
                                        }
                                    }}
                                    autoComplete="off"
                                />
                                <span className="file-icon">
                                    <img src={Icons.file} alt="" />
                                </span>
                                <h4>Drag file(s) here to upload</h4>
                                <p>Alternatively, you can select file by</p>
                                <h6>Clicking here.</h6>
                            </div>
                        </>

                        <div className='mainFile'>
                            <img src={image ? image : props?.profileDetails ? `${API_BASEURL}/${props?.profileDetails}` : images} alt="preview image" />
                        </div>
                        {errorMsg && (<p style={{ color: 'red' }} >"File type not accepted, sorry!"</p>)}
                    </label>
                </div>
                <ErrorMessage component="div" name={props.name} className="error" />
            </div>
        </>
    )
}

