import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import TextField from "../../components/common/formInput/TextField";
import Icons from '../../assets/images';
import TextArea from "../../components/common/formInput/TextArea";
import Modal from 'react-bootstrap/Modal';

// add new bid dialog start
function AddNewDescription(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{"Add Bid"}</Modal.Title>
            </Modal.Header>
            <Formik>
                {(formik) => (
            <Form>
                <Modal.Body>
                    <div className="f-row">
                        <div className="f-100">
                            <TextField
                                label="Title"
                                name="title"
                                value="title"
                            />
                        </div>
                    </div>
                    <div className="f-row">
                        <div className="f-100">
                            <TextArea
                                label="Description"
                                name="description"
                                value="description"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ul className="modal-actions">
                        <li><button className="btn btn-primary" type='submit'>Add</button></li>
                        <li><button type='button' className="btn btn-grey" onClick={props.onHide}>Cancel</button></li>
                    </ul>
                </Modal.Footer>
            </Form>
            )}
            </Formik>
        </Modal>
    );
}
// end with add new bid dialog

const CreateDescription = () => {
    const [modalShow, setModalShow] = React.useState(false);

    const [tableRows, setTableRows] = useState([
        { id: 1, title: 'Lorem Ipsum 99 ', description: 'Lorem 444  Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s' },
        { id: 2, title: 'Lorem Ipsum 88 ', description: 'Lorem 555 Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s' },
        { id: 3, title: 'Lorem Ipsum 77 ', description: 'Lorem 666  Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s' },
    ]);

    const handleDeleteButtonClick = (rowId) => {
        // Remove the row with the specified id from the table
        setTableRows(tableRows.filter((row) => row.id !== rowId));
    };

    return (
        <div className="dashboard-inner bid-result listing">
            <div className="d-card m-b-25">
                <div className="d-card-header">
                    <h5 className="d-card-heading"> Description List  </h5>
                    <button className="btn btn-success" type="button" onClick={() => setModalShow(true)}>Add Description</button>
                    <AddNewDescription
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>

                <div className="d-card-body">
                    <div className="dashboard-table">
                        <div className="responsive-table">
                            <table className='table-odd-even table-update'>
                                <thead>
                                    <tr>
                                        <th className="text-center">#</th>
                                        <th className="text-center">Title</th>
                                        <th className="text-center">Description</th>
                                        <th style={{width: '100px'}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableRows.map((row) => (

                                        <tr key={row.id}>
                                            <td className="text-center">
                                                {row.id}
                                            </td>
                                            <td>
                                                <div className='master-estimate'>
                                                    <h6>{row.title}</h6>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='master-estimate'>
                                                    <h6>{row.description}</h6>
                                                </div>
                                            </td>
                                            <td className="text-center">
                                                <span className="sort-icon">
                                                    <button className="dashboard-action" title="Delete" onClick={() => handleDeleteButtonClick(row.id)} >
                                                        <img src={Icons.bin} alt="" />
                                                    </button>
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )


};

export default CreateDescription;
