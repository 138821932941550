import React, { useEffect, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icons from '../../assets/images/index'
import { pagination, PaginationOption } from '../../components/common/utils/message';
import { API_BASEURL } from '../../environment';
import { list_Projects ,bidden_Projects} from './services/contractor';
import ReactPaginate from 'react-paginate';
import _ from 'underscore';
import moment from 'moment';
import SelectBox from '../../components/common/formInput/SelectBox';
import { setParamFirst } from '../../components/common/general/GeneralSlice';

const ContractorProjects = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allProjects, setAllProjects] = useState();
    const [biddenProjects, setBiddenProjects] = useState();

    const loginUserState = useSelector((state) => state.login?.user?.data?.user?.state)
    const loginUserCountry=useSelector((country) =>country.login?.user.data?.user?.country)
   
    let all_projects = allProjects?.docs?.map((item) => item?.projects)
    let Bidden_projects = biddenProjects?.docs?.map((item) => item?.projects)
    const [tab, setTab] = useState("allProjects");

       useEffect(() => {
        _listProjects();
        }, [dispatch]);

    const _listProjects = () => {
        list_Projects({ fields: "country", filter: loginUserCountry }, (data) => { setAllProjects(data) }, dispatch);
        list_Projects({ fields: "state", filter: loginUserState }, (data) => { setAllProjects(data) }, dispatch);
        bidden_Projects({ fields: "name", filter: "dgsdgh" }, (data) => { setBiddenProjects(data)}, dispatch);
    }

   return (
        <>
            <div className="ds-header m-b-30">
                <div className="ds-cell">
                    <h4 className='dashboard-title'>Projects</h4>
                </div>
                <div className="ds-cell">
                <ul className='projects-tabs'>
                    <li>
                        <button className={`tab-item ${tab === 'allProjects' ? 'active' : ''}`} onClick={() => setTab("allProjects")}> All Projects</button>
                    </li>
                    <li>
                        <button className={`tab-item ${tab === 'biddenProjects' ? 'active' : ''}`} onClick={() => setTab("biddenProjects")}>Bidden Projects</button>
                    </li>
                </ul>
            </div>
            </div>
            {tab === 'allProjects' &&
            <ul className='projects-list'>
            {all_projects?.slice(1, all_projects?.length).map((item) => {
                return (
                    <>
                        {item.map((subItem) => (
                            <li>
                                <div className='project'>
                                    <span className='project-img'>
                                        <a onClick={() => { navigate('/contractor-project-view'); dispatch(setParamFirst(subItem._id)) }}>
                                        <img src={subItem?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(subItem?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                                        </a>
                                    </span>
                                    <div className='project-details'>
                                        <div className='project-header'>
                                            <div className='project-header-left'>
                                                <h5 className='project-name'>{subItem.project_title}</h5>
                                            </div>
                                            <div className='project-header-right'>
                                                <span className='project-date'>{moment(subItem.createdAt).format('DD/MM/YYYY')}</span>
                                            </div>
                                        </div>
                                        <div className='project-body'>
                                            <p>{subItem.notes}</p>
                                            <div className='project-sub-details r3'>
                                                <div className='project-sub-left project-sub-cell'>
                                                    <h5 className="p-wallet">{subItem.square_footage} <span><sup>S</sup>/<sub>F</sub></span></h5>
                                                </div>
                                                <div className="project-sub-cell">
                                                                <p className='project-location'>{loginUserCountry},{loginUserState}</p>
                                                            </div>
                                                <div className='project-sub-right project-sub-cell'>
                                                {/*  Start/Smartdata/staticData*/}
                                                    <h5 className="p-dollar">254 USD</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </>

                );
            })}
                {all_projects?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Open Project found!</h5>) : null}
            </ul>
        } {tab === 'biddenProjects' &&
        <ul className='projects-list'>
        {Bidden_projects?.slice(1, Bidden_projects?.length).map((item) => {
            return (
                <>
                    {item.map((subItem) => (
                        <li>
                            <div className='project'>
                                <span className='project-img'>
                                    <a onClick={() => { navigate('/contractor-project-view'); dispatch(setParamFirst(subItem._id)) }}>
                                    <img src={subItem?.media?.length > 0 ? `${API_BASEURL}/${_.findWhere(subItem?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
                                    </a>
                                </span>
                                <div className='project-details'>
                                    <div className='project-header'>
                                        <div className='project-header-left'>
                                            <h5 className='project-name'>{subItem.project_title}</h5>
                                        </div>
                                        <div className='project-header-right'>
                                            <span className='project-date'>{moment(subItem.createdAt).format('DD/MM/YYYY')}</span>
                                        </div>
                                    </div>
                                    <div className='project-body'>
                                        <p>{subItem.notes}</p>
                                        <div className='project-sub-details r3'>
                                            <div className='project-sub-left project-sub-cell'>
                                            {/*  Start/Smartdata/staticData*/}
                                                <h5 className="p-wallet">5000 <span><sup>S</sup>/<sub>F</sub></span></h5>
                                            </div>
                                            <div className="project-sub-cell">
                                                <p className='project-location'>{loginUserCountry}</p>
                                            </div>
                                            <div className="project-sub-cell">
                                                <p className='project-location'>{loginUserState}</p>
                                            </div>
                                            <div className='project-sub-right project-sub-cell'>
                                            {/* END/Smartdata/staticData*/}
                                                <h5 className="p-dollar">$254 USD</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </>

            );
        })} {Bidden_projects?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No Closed Project yet!</h5>) : null}
        </ul>}
        </> )}
export default ContractorProjects;