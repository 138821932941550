import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../assets/images/index'
import { setParamFirst } from '../../../components/common/general/GeneralSlice';
import { API_BASEURL } from '../../../environment';
import { profile_view } from '../services/manufacturer';
import ChangePassword from './ChangePassword';
import images from '../../../assets/images/defaultimage.png'

const ProfileView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginUser, setLoginUser] = useState()

    useEffect(() => {
        profile_view((data) => { setLoginUser(data) }, dispatch)
    }, [])

    return (
        <>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Pererrofile</h5>
                </div>
                <div className='d-card-body'>
                    <div className="owner-profile-wrapper">
                        <div className="u-p-l">
                            <div className="owner-profile-header">
                                <span className='owner-profile-img'>
                                    <img src={loginUser?.profile_pic ? `${API_BASEURL}/${loginUser?.profile_pic?.path}` : images} alt="" />
                                </span>
                                <h4 className='owner-name'>{loginUser?.first_name}{loginUser?.last_name}</h4>
                                <div className="f-row">
                                    <div className="f-auto">
                                        <div className="desc-info p-b-5 p-t-5">
                                            <h6 className="desc-info-heading">Email Address</h6>
                                            <p className="desc-info-detail">{loginUser?.email}</p>
                                        </div>
                                    </div>
                                    <div className="f-auto">
                                        <div className="desc-info p-b-5 p-t-5">
                                            <h6 className="desc-info-heading">Phone Number</h6>
                                            <p className="desc-info-detail">{loginUser?.mobile_no}</p>
                                        </div>
                                    </div>
                                    <div className="f-auto">
                                        <div className="desc-info p-b-5 p-t-5">
                                            <h6 className="desc-info-heading">Address</h6>
                                            <p className="desc-info-detail">{loginUser?.city},{loginUser?.state},{loginUser?.user?.zip_code}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="owner-profile-body">
                                <div className="desc-info p-b-5 p-t-5">
                                    <h6 className="desc-info-heading">Description</h6>
                                    <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam. Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint.</p>
                                </div>
                            </div> */}
                        </div>
                        <div className="u-p-r">
                            <div className="owner-profile-actions">
                                <button className='btn btn-blue btn-sm' onClick={() => { navigate('/manu-profile-edit'); dispatch(setParamFirst(loginUser)) }}>Edit Profile</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ChangePassword />
        </>

    )
}

export default ProfileView;