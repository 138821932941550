import { ErrorMessage, useField } from 'formik'
import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Icons from '../../../assets/images';

const ReactDatePicker = ({ label, required, viewOnly = false, ...props }) => {
    const [field, meta, helpers] = useField(props);
    return (
        <>
            <label className="f-label" htmlFor={field.name}>{label}{required ? <span className="error">*</span> : ""}</label>
            <div className='f-field has-icon'>
                <DatePicker
                    className={`form-control f-input ${meta.touched && meta.error && 'is-invalid'}`}
                    selected={props.selected}
                    value={props.value}
                    onChange={(date) => {
                        helpers?.setValue(date)
                        props?.changeHandle?.(date)
                    }}
                    dateFormat={props.dateFormat}
                    showMonthYearPicker={props.showMonthYearPicker}
                    disabled={viewOnly}
                    minDate={new Date()}
                    autoComplete="off"
                    placeholderText={props.placeholder}
                />
                <span className="f-icon">
                    <img src={Icons.calIcon} alt="" />
                </span>
                <ErrorMessage component="div" name={field.name} className="error" />
            </div>
        </>

    )
}

export default ReactDatePicker;