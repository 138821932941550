import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all manufacturer data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listManufacturer = async (param, callback, dispatch) => {

  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/manufacturer/view/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data)
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description add new manufacturer in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addManufacturer = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/manufacturer/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description update manufacturer in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateManufacturer = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/rpc/manufacturer/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description Soft Delete manufacturer in db
 * @param {*} id 
 * @param {*} callback 
 */
export const DeleteManufacturer = async (id, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.delete(`${apiUrl}${PORT}/rpc/manufacturer/delete/${id}`, option)
        .then((response) => {
          callback(response.data)
        }).catch((error) => {
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};