import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from "formik";
import { bidInvitationSchema } from '../../../components/common/validations/Validation';
import TextField from '../../../components/common/formInput/TextField';
import ReactDatePicker from '../../../components/common/formInput/DatePicker';
import Icons from '../../../assets/images';
import { listContractors } from "../../../services/contractor";
import { sentInvitationEmail } from "../../../services/rpc";
import { useDispatch } from "react-redux";
import { success, failed } from "../../../components/common/Toastify";
import { sendInvitation } from '../../../services/project';
import { useParams } from "react-router-dom";
import { viewOneProject } from '../../../services/project';
import moment from 'moment';
const SendBidInvitation = ({ show, handleClose, city, projectId }) => {
    let id = useParams();
    const project_id = useParams();
    const dispatch = useDispatch();
    const [projectData, setProjectData] = useState()
    const [isStartDate, setIsStartDate] = useState()
    const [isEndDate, setIsEndDate] = useState()
    const [resPayload, setResPayload] = useState();
    const [isAllChecked, setIsAllChecked] = useState(false)
    useEffect(() => {

        viewOneProject(project_id, (data) => {

            setProjectData(data);
        })
    }, [])

    useEffect(() => {

        listContractors({ page: 1, fields: 'city', filter: city,  }, (data) => {
            setResPayload(data && data.docs ? data.docs.map(e => { return { ...e, isChecked: false } }) : [])

        }, dispatch)
    }, [dispatch]);
    
    const handleChange = (e) => {
        console.log(e,"line41")
        console.log(resPayload,"line42")
        const updatedState = [...resPayload];

        updatedState.forEach(x => {
            if (x.email === e.target.value) {
                x.isChecked = e.target.checked;
            }
        })
        setResPayload(updatedState);

    }
    const handleSelectUnSelectAll = () => {

        const updatedState = [...resPayload];
        const updatedCheckedState = isAllChecked;

        updatedState.forEach(x => {
            if (!updatedCheckedState) {
                x.isChecked = true;
            } else {
                x.isChecked = false;
            }
        })
        setIsAllChecked(!updatedCheckedState);
        setResPayload(updatedState);
    }

    const _submitData = (data) => {

        // making call to sentInvitationEmail from service file
        const payload = {
            // contractor_ids: [...resPayload.filter(e => e.isChecked).map(e => e.id), ...data.id.split(",").map(e => e.trim())],
            contractor_ids: [...resPayload.filter(e => e.isChecked).map(e => e._id)],
            // bid_start: data.bid_start,
            // bid_end: data.bid_end,
            not_registered_contractors: [...data.email.split(",").map(e => e.trim())],
            // timezone: data.timezone,
           project_id:project_id.id
        }


        sendInvitation(payload, (result) => {
            if (result.status === "SUCCESS" && result.code === 200) {
                success(result.message);
                handleClose()
            } else if (result.status === "ERROR" && result.code === 403) {
                failed(result.message)
            }
        }, dispatch)
    };
    //remove console
    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="custom-inner-header">
                        <Modal.Title>Bid Invitation</Modal.Title>
                        {/* <div className='sortby'>
                            <span>Sort by : </span>
                            <div className='nocolor-dropdown'>
                                <select>
                                    <option>Active</option>
                                    <option>Inactive</option>
                                </select>
                            </div>
                        </div> */}
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={
                        {
                            email: "",
                            bid_start: "",
                            bid_end: "",
                            // timezone: "",
                        }
                    }
                    enableReinitialize
                    validationSchema={bidInvitationSchema}
                    onSubmit={(values) => {
                        _submitData(values);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className='f-row'>
                                    <div className='f-33'>
                                        <TextField
                                            label="Email Address"
                                            name="email"
                                            value={formik?.values?.email}
                                            onChange={formik.handleChange}
                                        // required={true}
                                        />
                                    </div>
                                    <div className='f-33'>
                                        <label className='f-label'>Bid Period</label>
                                        <div className='f-row'>
                                            <div className='f-50'>
                                                <TextField
                                                    label="From"
                                                    name="bid_start"
                                                    placeholder={moment(projectData?.bid_start_date).format('DD/MM/YYYY')}
                                                    value={formik?.values?.bid_start}
                                                    onChange={formik.handleChange}
                                                // required={true}
                                                />
                                            </div>
                                            <div className='f-50'>

                                                <TextField
                                                    label="To"
                                                    name="bid_end"
                                                    placeholder={moment(projectData?.bid_end_date).format('DD/MM/YYYY')}
                                                    value={formik?.values?.bid_end}
                                                    onChange={formik.handleChange}
                                                // required={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className='f-33'>
                                        <TextField
                                            label="Time Zone"
                                            name="timezone"
                                            required={true}
                                            onChange={formik.handleChange}
                                            value={formik?.values?.timezone}
                                        />
                                    </div> */}
                                </div>
                                {/* <div className='f-row'>
                                    <div className='f-100'>
                                        <label className='checkbox' htmlFor='union'>
                                            <input type="checkbox" id="union" />
                                            <span>Union Only</span>
                                        </label>
                                    </div>
                                </div> */}
                                <div className="contractor-wrapper">
                                    <div className="contractor-header">
                                        <div className="contractor-header-l">
                                            <h5 className="contractor-heading">Select Contractors</h5>
                                        </div>
                                        <div className="contractor-header-r">
                                            <button className="btn btn-primary btn-sm" type="button" onClick={handleSelectUnSelectAll}>{isAllChecked ? 'Unselect All' : 'Select All'}</button>
                                        </div>
                                    </div>
                                    <div className="contractor-body">
                                        <ul className="contractor-list">
                                            {resPayload && resPayload.map((contractor, index) => {
                                                return (<li>
                                                    <div className="contractor-check">
                                                        <label className='checkbox nolabel' >
                                                            <input type="checkbox" id={index} value={contractor.email} onChange={(e) => handleChange(e)} checked={contractor.isChecked} />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <div className="contractor-details">
                                                        <div className='bid-details'>
                                                           
                                                            <h4>{`${contractor.first_name} ${contractor.last_name}`}</h4>
                                                        </div>
                                                    </div>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li>
                                        <button className="btn btn-primary" type="submit">Send Invitation</button>
                                    </li>
                                    <li>
                                        <button className="btn btn-grey" type="button" onClick={handleClose}>Cancel</button>
                                    </li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    )
}

export default SendBidInvitation