import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Icons from '../../assets/images/index'
import { getPageBySlug } from "../../services/cms.service";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { API_BASEURL } from '../../environment';

const AboutUs = () => {
    const dispatch = useDispatch();
    const location = useLocation()

    const [pageData, setPageData] = useState(null)

    useEffect(() => {
        getPageBySlug(location.pathname, (data) => {
            setPageData(data)
        }, dispatch)
    }, [])

    return (
        <>
            <div className='container'>
                {pageData && <>
                    <section className='hero-section'>
                    <div className="banner-section m-b-30">
                        <img src={`${API_BASEURL}/${pageData?.banner_images[0].path}`} alt="" />
                    </div>
                    </section>
                    <h4 className='dashboard-title p-b-20' dangerouslySetInnerHTML={{ __html: pageData.page_title }} />
                    <div className="static-desc m-b-30" dangerouslySetInnerHTML={{ __html: pageData.page_content }} />
                </>
                }
            </div>
        </>
    )
}

export default AboutUs;