import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all RValue data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listOwnerType = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/owner-type/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description add new RValue in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addOwnerType = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/owner-type/create`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description update RValue in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateOwnerType = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/rpc/owner-type/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                }).catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description Soft Delete RValue in db
 * @param {*} id 
 * @param {*} callback 
 */
export const deleteOwnerType = async (id, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.delete(`${apiUrl}${PORT}/rpc/owner-type/delete/${id}`, option)
                .then((response) => {
                    callback(response.data)
                }).catch((error) => {
                    callback(false)
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const viewOneOwnerType = async (param, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/rpc/owner-type/view?${queryString.stringify(param)}`, option).then((response) => {
                callback(response.data.data)
            }).catch((error) => {
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};


export const updateOwnerTypeStatus = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/rpc/owner-type/update/status`,data , option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};