import React from 'react';
import AboutUs from '../common/AboutUs';
import ManufacturerLayout from '../../../components/common/layout/ManufacturerLayout';
import Dashboard from '../Dashboard';
import Faq from '../common/Faq';
import TermsAndConditions from '../common/TermsAndConditions';
import Projects from '../Projects';
import ProfileView from '../common/ProfileView';
import ProfileEdit from '../common/ProfileEdit';
import ProjectView from '../ProjectView';

const ManuInterfaceRoute = [
    {
        path: `/manu-about-us`,
        layout: ManufacturerLayout,
        component: <  AboutUs />
    },
    {
        path: `/manu-dashboard`,
        layout: ManufacturerLayout,
        component: <  Dashboard />
    },
    {
        path: `/manu-faq`,
        layout: ManufacturerLayout,
        component: <  Faq />
    },
    {
        path: `/manu-projects`,
        layout: ManufacturerLayout,
        component: <  Projects />
    },
    {
        path: `/terms-and-conditions`,
        layout: ManufacturerLayout,
        component: <  TermsAndConditions />
    },
    {
        path: `/manu-profile`,
        layout: ManufacturerLayout,
        component: <  ProfileView />
    },
    {
        path: `/manu-profile-edit`,
        layout: ManufacturerLayout,
        component: <  ProfileEdit />
    },
    {
        path: `/manu-project-view`,
        layout: ManufacturerLayout,
        component: <  ProjectView />
    }
]

export default ManuInterfaceRoute;