import moment from 'moment';
import React from 'react';
import Icons from "../../../assets/images/index";
import { API_BASEURL } from '../../../environment';

const ProjectDocuments = ({ projectDetails, onClose }) => {

    return (
        <div className='d-card'>
            <div className='d-card-header'>
                <h5 className='d-card-heading d-card-sub-heading'>Project Documents</h5>
                <button className='btn btn-grey m-2' onClick={onClose}>Close</button>
            </div>
            <div className='d-card-body'>
                <h6 className="d-card-heading d-card-sub-heading">Standard Contract</h6>
                <ul className="documents-list">
                    {projectDetails?.media?.map((doc, ind) => {
                        return (
                            doc?.type === "standard_contract" &&
                            <li>
                                <div className="document" key={ind}>
                                    <span className="document-icon">
                                        <img src={Icons.Document} alt="" />
                                    </span>
                                    <h4 className="document-title">{doc.filename}</h4>
                                    <p className="document-date">{moment(doc.createdAt).format('DD/MM/YYYY')}</p>
                                    <div className="document-actions">
                                        <div className="d-action">
                                            <button className="btn-download">
                                                <a href={`${API_BASEURL}/${doc.path}`} >
                                                    <img src={Icons.Download} alt="" />
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className='d-card-body'>
                <h6 className="d-card-heading d-card-sub-heading">Insurance</h6>
                <ul className="documents-list">
                    {projectDetails?.media?.map((doc, ind) => {
                        return (
                            doc?.type === "insurance" &&
                            <li>
                                <div className="document" key={ind}>
                                    <span className="document-icon">
                                        <img src={Icons.Document} alt="" />
                                    </span>
                                    <h4 className="document-title">{doc.filename}</h4>
                                    <p className="document-date">{moment(doc.createdAt).format('DD/MM/YYYY')}</p>
                                    <div className="document-actions">
                                        <div className="d-action">
                                            <button className="btn-download">
                                                <a href={`${API_BASEURL}/${doc.path}`} >
                                                    <img src={Icons.Download} alt="" />
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className='d-card-body'>
                <h6 className="d-card-heading d-card-sub-heading">Specifications</h6>
                <ul className="documents-list">
                    {projectDetails?.media?.map((doc, ind) => {
                        return (
                            doc?.type === "Specifications" &&
                            <li>
                                <div className="document" key={ind}>
                                    <span className="document-icon">
                                        <img src={Icons.Document} alt="" />
                                    </span>
                                    <h4 className="document-title">{doc.filename}</h4>
                                    <p className="document-date">{moment(doc.createdAt).format('DD/MM/YYYY')}</p>
                                    <div className="document-actions">
                                        <div className="d-action">
                                            <button className="btn-download">
                                                <a href={`${API_BASEURL}/${doc.path}`} >
                                                    <img src={Icons.Download} alt="" />
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default ProjectDocuments