import React, { useEffect, useState } from "react";
import Icons from "../../assets/images/index";
import { listContractors } from "../../services/contractor";
import { useDispatch, useSelector } from "react-redux";
import _ from 'underscore';
import { pagination } from "../../components/common/utils/message";
import { API_BASEURL } from '../../environment';
import images from '../../assets/images/defaultimage.png'
const ContractorView = () => {

    const dispatch = useDispatch();
  
    const [resPayload, setResPayload] = useState();

    useEffect(() => {
        listContractors({ page: pagination.page }, (data) => { setResPayload(data) }, dispatch);
    }, [dispatch]);

    const general = useSelector((state) => state.general)
    const get_contractor = resPayload && resPayload.docs.find((item) => item._id === general.param)

    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-heading p-b-15'>View Contractor</h4>
            <div className="owner">
                <div className="owner-left">
                    <span className="owner-img">
                        <img src={get_contractor?.company_logo ? `${API_BASEURL}/${get_contractor.company_logo}` : images} alt="" />
                    </span>
                    <h4>{get_contractor?.first_name} {get_contractor?.last_name}</h4>
                    <p>{get_contractor?.email}</p>
                </div>
                <div className="owner-right">
                    <h5>Location: {get_contractor?.street_address} {get_contractor?.city} {get_contractor?.state}</h5>
                </div>
            </div>
        </div>
    );
};

export default ContractorView;
