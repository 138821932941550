import * as Yup from "yup";
import { _zipCode } from "../utils/message";

const phoneRegExp =/^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/

const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/

export const registerSchema = Yup.object().shape({
  role: Yup.string()
    .required("Customer Type is required!"),
  first_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
  .max(15)
    .required("First Name field is required"),  
  last_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
    .max(15)
    .required("Last Name is required!"),
  city: Yup.string()
    .required("City field is required!"),
  mobile_no: Yup.string()
    .matches(phoneRegExp, 'Mobile Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Mobile Number is required!"),
  email: Yup.string()
    .required("Email field is required!")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  state: Yup.string()
    .required("State is required!"),
  city: Yup.string()
    .required("City is required!"),
  company_name: Yup.string()
    .required("Company Name is required!"),
  country: Yup.string()
    .required("Country is required!"),
  zip_code: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
    .required("Zip Code field is required"),
  address1: Yup.string()
    .required("Address is required"),
  address2: Yup.string()
    .required("Address line 2 is required!"),
  company_logo: Yup.string()
    .required("Company Logo is required"),
  phone_no: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  toll_free_no: Yup.string()
    .required("Toll Free Number is required!")
});

export const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email field is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .matches(emailRegex, 'Email address is not valid'),
  password: Yup.string()
    .required("Password field is required")
    .min(8, "Password must be atleast 8 characters")
    .max(10, "Maximum 10 characters"),
});

export const forgotSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email field is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .matches(emailRegex, 'Email address is not valid'),
});

export const resetSchema = Yup.object().shape({
  password: Yup.string()
    .required("New Password field is required")
    .min(8, "Password must be atleast 8 characters")
    .max(10, "Maximum 10 characters"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), null],
      "Confirm Password must be same of Password"
    )
    .required("Confirm Password field is required!"),
});

export const changePassSchema = Yup.object().shape({
  currentPwd: Yup.string()
    .required("current Password is required!")
    .min(8, "Password must be atleast 8 characters")
    .max(10, "Maximum 10 characters"),
  newPwd: Yup.string()
    .required("New Password is required!")
    .min(8, "Password must be atleast 8 characters")
    .max(10, "Maximum 10 characters"),
  confirmPass: Yup.string()
    .oneOf(
      [Yup.ref("newPwd"), null],
      "Confirm Password must be same of Password"
    )
    .required("Confirm Password field is required!"),
});

export const contactSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name is required!"),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  email: Yup.string()
    .required("Email field is required!")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  subject: Yup.string()
    .required("Subject is required!"),
  query: Yup.string()
    .required("Question field is required!"),
});

export const projectSchema = Yup.object().shape({
  ownerId: Yup.string()
    .required("Owner Name field is required."),
  project_title: Yup.string()
    .required("Project Name field is required."),

  square_footage: Yup.string()
    .required("Square Address field is required."),

  buildingId: Yup.string()
    .required("Add Building is required."),

    bidder_fee: Yup.number()
    .required("Bid Amount Fees is required."),
    

    bid_start_date:Yup.date()
    .required(" Bid start date is required."),

    bid_end_date:Yup.date()
    .required(" Bid end date is required."),

    winners_bidder_fee:Yup.number()
    .required("Winning bid fee is required"),

    // allowedBids: Yup.array()
    // .required(" Bid is allowed from is required."),

      // roof_drawings: Yup.mixed().required(" Roof drawings Pdf is required.").test("fileFormat", "PDF only", (value) => {
      //   if (value && value.type) {
      //     return ["application/pdf"].includes(value.type);
      //   }
      //   return false;
      // }),
     
    //  roof_drawings: Yup.mixed().when('$mode', {
    //   is: true,
    //   then: Yup.mixed().required('File is required'),
    //   otherwise: Yup.mixed().notRequired(),
    // }),

    // images: Yup.string()
    // .required(" Roof photo is required."),

  //  images: Yup.mixed().required("Roof photo is required.")
  //  .test('format',
  //       'upload file', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),

    // videos: Yup.string()
    // .required("Videos is required."),
    // videos: Yup.mixed().required("Videos is required."),
    

    // detail_drawings: Yup.object()
    // .required("  Details drawings Pdf  is required."),
  //  detail_drawings: Yup.mixed().required("  Detail drawings drawings Pdf is required."),
    
    // specifications: Yup.object()
    // .required("   Specifications Pdf  is required."),
    // specifications: Yup.mixed().required(" Specifications  Pdf is required."),

   notes: Yup.string().required("   Any Specific Notes Regarding The Project  is required."),


    // insurance: Yup.object()
    // .required("   insurance Pdf is required."),
  //  insurance: Yup.mixed().required(" Insurance  Pdf is required."),

    // standard_contract: Yup.object()
    // .required(" Standard contract Pdf is required."),
  //  standard_contract: Yup.mixed().required(" Specifications  Pdf is required."),

    project_status: Yup.string().required(" Project status is required."),

     approach_type: Yup.string().required("  Approach type is required."),

     status: Yup.string().required(" Publish status is required."),

    //  manufacturers: Yup.string().required(" Publish status is required."),

    
   
    
});

export const BuildingprojectSchema = Yup.object().shape({
 
  project_title: Yup.string()
    .required("Project Name field is required."),

  square_footage: Yup.string()
    .required("Square footage field is required."),

  // buildingId: Yup.string()
  //   .required("Add Building is required."),

    bidder_fee: Yup.number()
    .required("Bid Amount Fees is required."),
    

    bid_start_date:Yup.date()
    .required(" Bid start date is required."),

    bid_end_date:Yup.date()
    .required(" Bid end date is required."),

    winners_bidder_fee:Yup.number()
    .required("Winning bid fee is required"),

    notes: Yup.string().required("   Any Specific Notes Regarding The Project  is required."),

    project_status: Yup.string().required(" Project status is required."),

    approach_type: Yup.string().required("  Approach type is required."),

    status: Yup.string().required(" Publish status is required."),

});
export const rpcSchema = Yup.object().shape({
  first_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid First Name')
  .max(15)
    .required("First Name field is required"),
    last_name:Yup.string()
    .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
    .max(15)
      .required("Last Name field is required"),
  company_name: Yup.string()
  .max(20),
  //   .required("Company Name is required"),
  city: Yup.string()
    .required("City field is required"),
  mobile_no: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  
  email: Yup.string()
    .required("Email field is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  state: Yup.string()
    .required("State is required"),
  country: Yup.string()
    .required("Country is required"),
  zip_code: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
    .required("Zip Code field is required"),
  address1: Yup.string()
  
    .required("Address is required"),
    company_name:Yup.string().required('Company name is required'),
    
    
});

export const contractorSchema = Yup.object().shape({
  first_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid First Name')
  .max(25)
    .required("First Name field is required"),
    last_name:Yup.string()
    .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
    .max(25)
      .required("Last Name field is required"),
  company_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid First Name')
  .max(20)
    .required("Company Name is required"),
  city: Yup.string()
    .required("City field is required"),
  mobile_no: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  email: Yup.string()
    .required("Email field is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  state: Yup.string()
    .required("State is required"),
  country: Yup.string()
    .required("Country is required"),
  zip_code: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
    .required("Zip Code field is required"),
  address1: Yup.string()
    .required("Address is required"),
});

export const ownerSchema = Yup.object().shape({
  first_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
  .max(25)
    .required("First Name is required!"),
    last_name:Yup.string()
    .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
    .max(25)
      .required("Last Name field is required"),
  email: Yup.string()
    .required("Email is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  city: Yup.string()
    .required("City is required!"),
  address1: Yup.string()
    .required("Address is required!"),
  mobile_no: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  state: Yup.string()
    .required("State is required!"),
  country: Yup.string()
    .required("Country is required!"),
  zip_code: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
    .required("Zip Code is required!"),
  company_name: Yup.string()
    .required("Company Name is required!"),
  owner_type: Yup.string()
    .required("Owner Type is required!")
});

export const buildingSchema = Yup.object().shape({
  name: Yup.string()
    .required("Building Name is required"),
  address1: Yup.string()
    .required("Building Address is required"),
  city: Yup.string()
    .required("City is required"),
  state: Yup.string()
    .required("State is required"),
  country: Yup.string()
    .required("Country is required"),
  building_type: Yup.string()
    .required("Building Type is required"),
  zip_code: Yup.string()
    .min(5, 'Minimum 5 characters')
    .max(5, 'Maximum 5 characters')
    .required("Zip Code is required"),
  ownerId: Yup.string()
    .required("Please select Owner"),
  actual_size: Yup.string()
    .required("Please add square footage"),
    // building_documents:Yup.string()
    // .required(" building documents is required.")
   
});

export const bidInvitationSchema = Yup.object().shape({

});
export const sendNotificationSchema = Yup.object().shape({
  title: Yup.string().required(),
  message: Yup.string().required(),
});

export const manufacturerSchema = Yup.object().shape({
  first_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid First Name')
  .max(25)
    .required("First Name field is required"),
    last_name: Yup.string()
    .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
      .max(25)
      .required("Last Name is required!"),
  company_name: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
  .max(20)
    .required("Company Name is required"),
  address1: Yup.string()
    .required("Address is required"),
  city: Yup.string()
  .matches(/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/, 'Please enter valid Last Name')
  .max(20)
    .required("City is required"),
  state: Yup.string()
    .required("State is required"),
  country: Yup.string()
    .required("Country is required"),
  mobile_no: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  email: Yup.string()
    .required("Email field is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  zip_code: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(5, 'Must be exactly 5 digits')
  .max(5, 'Must be exactly 5 digits')
    .required("Zip Code field is required"),
});

export const materialSchema = Yup.object().shape({
  material_name: Yup.string()
    .required("Material Name field is required"),
  material_type: Yup.string()
    .required("Material Type is required"),
  description: Yup.string()
    .required("description is required")
})

export const windUpliftSchema = Yup.object().shape({
  wind_uplift_name: Yup.string()
    .required("Material Name field is required"),
  description: Yup.string()
    .required("description is required")

})
export const newRoofTypeSchema = Yup.object().shape({
  roof_type_name: Yup.string()
    .required("New Roof Type Name field is required"),
  description: Yup.string()
    .required("description is required"),
  price: Yup.string()
    .required("Price is required")

})

export const roofApproachSchema = Yup.object().shape({
  name: Yup.string()
    .required("New Roof Type Name field is required"),
  description: Yup.string()
    .required("description is required"),
  price: Yup.string()
    .required("Price is required")

})

export const rValueSchema = Yup.object().shape({
  r_value_name: Yup.string()
    .required("R-Value Name field is required"),
  description: Yup.string()
    .required("Description is required")
})

export const ownerTypeSchema = Yup.object().shape({
  owner_type_name: Yup.string()
    .required("Owner-Type Name field is required"),
  description: Yup.string()
    .required("Description is required")
})

export const ownerTypeSchemaProject = Yup.object().shape({
  item: Yup.string()
    .required("Field is required"),
  
})

export const valueBidFormSchemaProject = Yup.object().shape({
  default_value: Yup.string()
    .required("Field is required"),
  
})


export const buildingTypeSchema = Yup.object().shape({
  building_type_name: Yup.string()
    .required("Building-Type Name field is required"),
  description: Yup.string()
    .required("Description is required")
})

export const roofTypeSchema = Yup.object().shape({
  name: Yup.string()
    .required("Roof Type Name field is required"),
  price: Yup.string()
    .required("Roof Type price is required"),
  description: Yup.string()
    .required("description is required")
})

export const inspectionSchema = Yup.object().shape({
  inspector_name: Yup.string()
    .required("Inspection Name is required"),
  projectId: Yup.string()
    .required("Select Project is required"),
  date: Yup.string()
    .required("Date   is required"),
  remark: Yup.string()
    .required("Remark is required"),
});

export const faqSchema = Yup.object().shape({
  faq_question: Yup.string()
    .required("Faq question is required"),
  faq_answer: Yup.string()
    .required("Faq answer is required")
});

export const askQueContractorSchema = Yup.object().shape({
  question_text: Yup.string()
    .required("Faq question is required"),
  
});

export const estimatorSchema = Yup.object().shape({
  title: Yup.string()
    .required("title is required"),
  description: Yup.string()
    .required("description is required")
});
export const BidFormSchema = Yup.object().shape({
  item: Yup.string()
    .required("title is required"),
 
});
export const MasterDesSchema = Yup.object().shape({
  title: Yup.string()
    .required("title is required"),
  description: Yup.string()
    .required("description is required")
 
});
export const ownerProfile = Yup.object().shape({
  // inspector_name: Yup.string()
  //   .required("Inspection Name is required"),
  // projectId: Yup.string()
  //   .required("Select Project is required"),
  // date: Yup.string()
  //   .required("Date   is required"),
  // remark: Yup.string()
  //   .required("Remark is required"),
});

export const ConvensionalBidSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("First Name field is required"),
  company_name: Yup.string()
    .required("Company Name is required"),
  city: Yup.string()
    .required("City field is required"),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone Number is not valid')
    .min(10, 'Minimum 10 characters')
    .max(13, 'Maximum 13 characters')
    .required("Phone Number is required!"),
  email: Yup.string()
    .required("Email field is required")
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .email("Enter valid email address"),
  state: Yup.string()
    .required("State is required"),
  country: Yup.string()
    .required("Country is required"),
  zip_code: Yup.string()
    // .min(5, 'Minimum 5 characters')
    // .max(5, 'Maximum 5 characters')
    .matches(/^[0-9]+$/, "Must be only digits")
.min(5, 'Must be exactly 5 digits')
.max(5, 'Must be exactly 5 digits')
    .required("Zip Code field is required!"),
  address1: Yup.string()
    .required("Address is required!"),
  project_Id: Yup.string()
    .required("Project is required!")
});
export const contractorProfile = Yup.object().shape({
  // inspector_name: Yup.string()
  //   .required("Inspection Name is required"),
  // projectId: Yup.string()
  //   .required("Select Project is required"),
  // date: Yup.string()
  //   .required("Date   is required"),
  // remark: Yup.string()
  //   .required("Remark is required"),
});

export const bidFormdataSchema = Yup.object().shape({
  item: Yup.string()
    .required("Owner-Type Name field is required")
 
})
