import React, { useEffect } from "react";
import Icons from "../../assets/images/index";
import { useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import { useDispatch } from "react-redux";

const PaymentList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
    }, [dispatch]);

    return (
        <div className="dashboard-inner">
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <div className="custom-seachbox">
                        <input type="search" placeholder="Search" />
                        <button className="btn btn-primary btn-search">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.11109 15.2222C12.0384 15.2222 15.2222 12.0384 15.2222 8.11109C15.2222 4.18374 12.0384 1 8.11109 1C4.18374 1 1 4.18374 1 8.11109C1 12.0384 4.18374 15.2222 8.11109 15.2222Z" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M17.0004 17L13.1338 13.1333" stroke="white" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="d-h-r">
                    <ul className="dashboard-sub-filters">
                        <li>
                            <div className='sortby'>
                                <span>Status : </span>
                                <div className='nocolor-dropdown'>
                                    <select>
                                        <option>Active</option>
                                        <option>InActive</option>
                                    </select>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="d-h-wrap">
                <div className="d-h-l">
                    <h4 className='dashboard-heading'>Payment List</h4>
                </div>
            </div>
            <div className="dashboard-table">
                <div className="responsive-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Serial <br /> No. </th>
                                <th><span className="sort-icon">User Name<i class="bi bi-arrow-down"></i><i class="bi bi-arrow-up"></i></span></th>
                                <th><span className="sort-icon">Email Address<i class="bi bi-arrow-down"></i><i class="bi bi-arrow-up"></i></span></th>
                                <th><span className="sort-icon">Transaction Id<i class="bi bi-arrow-down"></i><i class="bi bi-arrow-up"></i></span></th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>John</td>
                                <td>john@mail.com</td>
                                <td>8758758587</td>
                                <td>$6757</td>
                                <td className="text-success">Active</td>
                                <td className="text-center">
                                    <span className="sort-icon">
                                        <button className="dashboard-action" title="View" >
                                            <img src={Icons.eye} alt="" />
                                        </button>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/* {resPayload?.docs?.length === 0 ? (<h5 className="d-flex align-items-center justify-content-center">No data found!</h5>) : null} */}
                </div>
                <div className="table-filters">
                    <div className="t-f-cell">
                        <div className="records-filter">
                            <span>Show</span>
                            <div className="grey-dropdown bg-white">
                                <select>
                                    <option>15</option>
                                    <option>20</option>
                                    <option>25</option>
                                </select>
                            </div>
                            <span>Entries</span>
                        </div>
                    </div>
                    <div className="t-f-cell">
                        <ReactPaginate
                            breakLabel={"..."}
                            nextLabel={'Next >'}
                            onPageChange
                            pageCount
                            previousLabel={'< Back'}
                            renderOnZeroPageCount={null}
                            containerClassName={'custom-pagination '}
                            pageClassName={'btn-page'}
                            pageLinkClassName={'btn-page'}
                            previousClassName={'btn-page'}
                            previousLinkClassName={'btn-previous'}
                            nextClassName={'btn-page'}
                            nextLinkClassName={'btn-next'}
                            activeClassName={'active'}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentList;
