import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from "formik";
import TextField from '../../../components/common/formInput/TextField';
import { useDispatch } from "react-redux";
import { success, failed } from "../../../components/common/Toastify";
import SearchSelectBox from '../../../components/common/formInput/SearchSelectBox';
import TextArea from '../../../components/common/formInput/TextArea';
import ApplicationMenuService from "../../../services/application.menu";
import * as Yup from 'yup';

const options = [{
    label: "Boolean",
    value: "boolean"
}, {
    label: "Text",
    value: "text"
}, {
    label: "Single Select",
    value: "dropdown"
}, {
    label: "Multiple Select",
    value: "multi-dropdown"
}, {
    label: "Range",
    value: "range"
}]
const ValdiationShema = Yup.object().shape({
    menu_title: Yup.string()
        .required('Required'),
    type: Yup.string()
        .required('Required'),
});

const AddApplicationMenu = ({ show, handleClose, menu }) => {


    const dispatch = useDispatch();
    const [formState, setFormState] = useState({
        menu_title: "",
        type: "",
        description: "",
        min_value: "",
        highValue: "",
        optionOne: "",
        optionTwo: "",
        options: []

    });
    useEffect(() => {
        setFormState(getInitialFormState());
    }, [menu])

    const deleteBlock = (index) => {
        const updatedFormState = { ...formState };
        let updatedBlocks = [...updatedFormState.options]
        updatedBlocks.splice(index, 1);
        updatedFormState.options = updatedBlocks;
        setFormState(updatedFormState);
    }

    const addBlock = (index) => {
        const updatedFormState = { ...formState };
        updatedFormState.options.splice(index + 1, 0, { key: " ", value: " " });
        setFormState(updatedFormState);
    }
    const getInitialFormState = () => {

        if (menu) {
            if (menu.type === "boolean") {
                const optionOne = menu.options.filter(e => e.selected)[0].value;
                const optionTwo = menu.options.filter(e => !e.selected)[0].value;
                return { ...menu, options: [], optionOne, optionTwo }
            } else return { ...menu }
        } else {
            return {
                menu_title: "",
                type: "",
                description: "",
                min_value: "",
                highValue: "",
                optionOne: "",
                optionTwo: "",
                options: []

            }
        }
    }

    const _submitData = (data) => {
        const payload = {}
        if (data.type === "text") {
            payload.menu_title = data.menu_title;
            payload.type = data.type;
            payload.description = data.description;
        }
        else if (data.type === "boolean") {
            payload.menu_title = data.menu_title;
            payload.type = data.type;
            payload.options = [{
                key: "YES",
                value: data.optionOne,
                selected: true,
            }, {
                key: "NO",
                value: data.optionTwo,
                selected: false,
            }];
        }
        else if (data.type === "range") {
            payload.menu_title = data.menu_title;
            payload.type = data.type;
            payload.min_value = data.min_value;
            payload.max_value = data.max_value;
        }
        else if (data.type === "dropdown" || data.type === "multi-dropdown") {
            payload.menu_title = data.menu_title;
            payload.type = data.type;
            payload.options = data.options;
        }
        if (menu) {
            ApplicationMenuService.updateMenu({ ...payload, _id: menu._id }, (result) => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    handleClose();
                }
                else {
                    failed(result.message);
                }
            }, dispatch)
        } else {
            ApplicationMenuService.addMenu(payload, (result) => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    handleClose();
                }
                else {
                    failed(result.message);
                }
            }, dispatch)
        }


    }
    return (
        <div>
            <Modal
                show={show}
                onHide={handleClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <div className="custom-inner-header">
                        <Modal.Title>{menu ? "Edit" : "Add"}</Modal.Title>
                    </div>
                </Modal.Header>
                <Formik
                    initialValues={formState}
                    validationSchema={ValdiationShema}
                    enableReinitialize
                    onSubmit={(values) => {
                        _submitData(values);
                    }}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className='f-row'>
                                    <div className='f-50'>
                                        <TextField
                                            label="Title"
                                            name="menu_title"
                                            value={formik?.values?.menu_title}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>
                                    <div className='f-50'>
                                        <SearchSelectBox
                                            label={"Type"}
                                            placeholder="Select Type"
                                            name="type"
                                            value={formik?.values?.type}
                                            changeHandle={(val) => {
                                                const updatedState = { ...formState, menu_title: formik?.values?.menu_title, type: val }
                                                setFormState(updatedState)
                                            }}
                                            options={options}
                                            required={true}
                                            viewOnly={menu ? true : false}
                                        />
                                    </div>
                                </div>
                                {
                                    formState.type && formState.type === "text" && <div className='f-row'>
                                        <div className='f-100'>
                                            <TextArea
                                                label="Description"
                                                name="description"
                                                value={formik?.values?.description}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                }

                                {
                                    formState.type && formState.type === "range" && <div className='f-row'>
                                        <div className='f-33'>
                                            <TextField
                                                type="number"
                                                label="Low Value"
                                                name="min_value"
                                                value={formik?.values?.min_value}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='f-33'>
                                            <TextField
                                                type="number"
                                                label="High Value"
                                                name="max_value"
                                                value={formik?.values?.max_value}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    formState.type && formState.type === "boolean" && <div className='f-row'>
                                        <div className='f-33'>
                                            <TextField
                                                label="True"
                                                name="optionOne"
                                                value={formik?.values?.optionOne}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className='f-33'>
                                            <TextField
                                                label="False"
                                                name="optionTwo"
                                                value={formik?.values?.optionTwo}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    formState.type && (formState.type === "multi-dropdown" || formState.type === "dropdown") &&

                                    <>
                                        <button
                                            className="btn btn-sm m-3"
                                            type="button"
                                            onClick={() => addBlock()}
                                        >
                                             <i class="bi bi-plus-lg"></i> Add Option
                                        </button>
                                        {formState.options.map((option, index) => {
                                            return (
                                                <div className='f-row'>
                                                    <div className='f-33'>
                                                        <TextField
                                                            label={"Label"}
                                                            name={`options[${index}].key`}
                                                            value={formik?.values?.options[index]?.key}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                    <div className='f-33'>
                                                        <TextField
                                                            label={"Value"}
                                                            name={`options[${index}].value`}
                                                            value={formik?.values?.options[index]?.value}
                                                            onChange={formik.handleChange}
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="btn-sm"
                                                        onClick={() => deleteBlock(index)}
                                                    >
                                                        <i class="bi bi-dash-lg"></i>
                                                    </button>
                                                </div>)
                                        })}
                                    </>
                                }
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li>
                                        <button className="btn btn-primary" type="submit">Submit</button>
                                    </li>
                                    <li>
                                        <button className="btn btn-grey" type="button" onClick={handleClose}>Cancel</button>
                                    </li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default AddApplicationMenu