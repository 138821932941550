import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import Dashboard from '../Dashboard';

const DashboardRoute = [
    {
        path: `/dashboard`,
        layout: DefaultLayout,
        component: < Dashboard />

    }
]

export default DashboardRoute;
