import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/scss/global.scss';
import { store } from './store/app';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { SocketContext, client } from './socket/socket-context';

let persistor = persistStore(store);

const root = createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>

    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SocketContext.Provider value={client}>
          <App />
        </SocketContext.Provider>
      </PersistGate>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
