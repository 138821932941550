import React, { useEffect } from "react";
import Icons from "../../../assets/images";
import { API_BASEURL } from "../../../environment";
// import ImageViewer from 'react-simple-image-viewer';
// import { useState,  } from "react";

const SelectedSamplePhotos = ({ projectData }) => {
 
// const [currentImage, setCurrentImage] = useState(0);
// const [isViewerOpen, setIsViewerOpen] = useState(false);
// const [images,setImages]= useState([]);



// useEffect(() => {
//     const data = [];
//     projectData?.media?.map((item, inx) => {
//         item?.type === "images" &&
//         data.push(`${API_BASEURL}/${item?.path}`)
//     })
//     setImages(data)
      
// }, [] );

  

// const openImageViewer = (index) => {
    
//     setCurrentImage(index);
//     setIsViewerOpen(true);
//   };

//   const closeImageViewer = () => {
//     setCurrentImage(0);
//     setIsViewerOpen(false);
//   };

    return (
        <>
            {
                projectData?.media?.length > 0 ?
                    <>
                        <h1 className="d-card-heading d-card-sub-heading">Roof Photos</h1>
                        <ul className="sample-gallery">
                            {projectData?.media?.map((item, inx) => {
                                return (
                                    item?.type === "images" &&
                                    <li key={inx}>
                                        <span>
                                        <img src={`${API_BASEURL}/${item?.path}` || (Icons.projectBigImg)} style={{ margin: '2px' }} width="300" alt=''/>
                                        {/* onClick={ () => openImageViewer(inx)} */}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul>
                        {/* <h1 className="d-card-heading d-card-sub-heading">Roof Drawings</h1>
                        <ul className="sample-gallery">
                            {projectData?.media?.map((item, inx) => {
                                return (
                                    item?.type === "roof_drawings" &&
                                    <li key={inx}>
                                        <span>
                                        <img src={`${API_BASEURL}/${item?.path}` || (Icons.projectBigImg)} style={{ margin: '2px' }} width="300" alt='' />
                                        onClick={ () => openImageViewer(inx)}
                                        </span>
                                    </li>
                                )
                            })}
                        </ul> */}
                        {/* {isViewerOpen && (
                            <ImageViewer
                              src={ images }
                              currentIndex={ currentImage}
                              disableScroll={ false }
                              closeOnClickOutside={ true }
                              onClose={ closeImageViewer }
                            />
                          )} */}
                            
                    </>
                    :
                    <h5 className="d-flex align-items-center justify-content-center">No sample Photos found!</h5>
            }
        </>
    );
}
export default SelectedSamplePhotos;