import React, { useEffect, useState } from "react";
import Icons from "../../../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { message,options, pagination, PaginationOption } from "../../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { success } from "../../../components/common/Toastify";
// import ReactPaginate from 'react-paginate';
// import SelectBox from "../../components/common/formInput/SelectBox";
import { useDispatch } from "react-redux";
import { _status } from "../../../components/common/utils/message";
import { myNotification,markNotificationRead } from "../../../services/notification.service";
import moment from 'moment';
import NoDataFound from "../../../components/common/NoDataFound";
const MyNotification = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [notification, setNotifications] = useState([]);
    const [showMark, setShowMark] = useState(false)

    useEffect(() => {
        getMyNotification()
    }, []);
    useEffect(() => {
        const inx = notification.findIndex(e => e.isChecked);
        if (inx > -1) {
            setShowMark(true);
        } else {
            setShowMark(false);
        }
    }, [notification])

    const getMyNotification = () => {
        myNotification((data) => {
            const updatedNotifiactions = data.data.docs.map(e => {
                return { ...e, isChecked: false }
            })
            setNotifications(updatedNotifiactions);
        }, dispatch)
    }
    const onSelect = (id) => {
        const updatedNotification = [...notification];
        const inx = updatedNotification.findIndex(e => e._id === id);
        if (updatedNotification[inx].isChecked) updatedNotification[inx].isChecked = false;
        else updatedNotification[inx].isChecked = true;
        setNotifications(updatedNotification);
    }
    const markAsRead = () => {
        const updatedNotification = [...notification];
        const ids = updatedNotification.filter(e => e.isChecked).map(e => e._id);
        markNotificationRead({ notificationIds: ids }, (result) => {
            getMyNotification();
            success(result?.message);
        }, dispatch)
    }
    return (
        <div className='dashboard-inner'>

            <div className='d-card'>
                <div className='d-card-header'>
                           
                    <h5 className='d-card-heading d-card-sub-heading'>Notifications</h5>
                    {showMark && <div className='d-card-actions'>
                        <button className='btn btn-blue' onClick={markAsRead}>Mark as read</button>
                    </div>}

                </div>
                {
                        notification.length> 0 ?
                       

                <div className='d-card-body p-b-0'>
                    <div className='responsive-table'>
                        <table className='table-odd-even'>
                            <tbody>
                                {notification ? notification.map((n, index) => {
                                    return (<tr>

                                        <td>
                                            <div className='payments-details'>

                                                {n.unread && <div className="contractor-check">
                                                    <label className='checkbox nolabel' htmlFor={index}>
                                                        <input type="checkbox" id={index} value={n._id} checked={n.isChecked} onChange={() => onSelect(n._id)} />
                                                        <span></span>
                                                    </label>
                                                </div>}

                                                {/* <span className='payment-client noborder'>
                                                    <img src={Icons.Owner} alt="" />
                                                </span> */}
                                                <h4>{n.title}
                                                    {/* <span>(Manufacturer)</span> */}
                                                </h4>
                                                <p>{n.description}</p>
                                            </div>
                                        </td>
                                        <td>
                                            <h5 className='bid-closing p-b-20'>{moment(n.createdAt).format('DD/MM/YYYY HH:MM:SS')}</h5>
                                            {/* <h5 className='bid-closing'>Paid By : Cameron Williamson</h5> */}
                                        </td>
                                    </tr>)

                                }) :""}
                    
                
                            </tbody>
                        </table>
                    </div>
                
                
                    <div className="table-filters">

                        {/* <div className="t-f-cell">
                            <ReactPaginate
                                breakLabel={"..."}
                                nextLabel={'Next >'}
                                // onPageChange={handlePageChange}
                                // pageCount={totalPages}
                                previousLabel={'< Back'}
                                renderOnZeroPageCount={null}
                                containerClassName={'custom-pagination '}
                                pageClassName={'btn-page'}
                                pageLinkClassName={'btn-page'}
                                previousClassName={'btn-page'}
                                previousLinkClassName={'btn-previous'}
                                nextClassName={'btn-page'}
                                nextLinkClassName={'btn-next'}
                                activeClassName={'active'}
                            />
                        </div> */}
                    
                    </div>
                
                </div>
                :  <h5 className="d-flex align-items-center justify-content-center"><div><NoDataFound/></div></h5>
                
}

            </div>
        
        </div>
    )
}

export default MyNotification

