import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { buildingTypeSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import { options, message } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import TextArea from "../../components/common/formInput/TextArea";
import { addBuildingType, updateBuildingType, listBuildingType } from "../../services/buildingType";

const BuildingTypeForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();

    useEffect(() => {
        listBuildingType({ page: 1 }, (data) => { setResPayload(data) }, dispatch);
    }, []);

    const owner_type_id = queryString.parse(location.search)
    const get_owner_type = resPayload && resPayload.docs.find((item) => item._id === owner_type_id.id)

    const initialData = {
        _id: get_owner_type ? get_owner_type._id : "",
        building_type_name: get_owner_type ? get_owner_type.building_type_name : "",
        description: get_owner_type ? get_owner_type.description : "",
    };

    /**
     * @description Function is for add R-Value.
     * @param {object} data 
     */
    const _submitData = (data) => {
        try {
            addBuildingType(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/building-type-list")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update New Roof Type
     * @param {object} data 
     */
    const _updateData = async (data) => {
        try {
            updateBuildingType(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message);
                    navigate("/building-type-list")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.cancelBuildingType, options);
        if (result) {
            alert(message.canceledBuildingType);
            navigate("/building-type-list");
        }
    };

    return (
        <div className="dashboard-inner p-b-0">
            <Formik
                initialValues={initialData}
                enableReinitialize
                validationSchema={buildingTypeSchema}
                onSubmit={(values) => get_owner_type ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">Add Building Type</h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Building Type Name"
                                                name="building_type_name"
                                                value={formik?.values?.building_type_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-66">
                                            <TextArea
                                                label="Building Type Description"
                                                name="description"
                                                value={formik?.values?.description}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{get_owner_type ? "Edit Building-Type" : "Add Building-Type"}</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" onClick={onCancel} type="button">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default BuildingTypeForm;
