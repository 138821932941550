import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { Formik, Form } from 'formik'
import makeAnimated from 'react-select/animated'

import { sendNotificationSchema } from '../../../components/common/validations/Validation'
import TextField from '../../../components/common/formInput/TextField'
import TextArea from '../../../components/common/formInput/TextArea'
import Icons from '../../../assets/images'
import { listContractors } from '../../../services/contractor'
import { sentInvitationEmail } from '../../../services/rpc'
import { useDispatch } from 'react-redux'
import { success, failed } from '../../../components/common/Toastify'
import { default as ReactSelect } from 'react-select'
import { components } from 'react-select'
import { listOwners } from '../../../services/owner'
import { listManufacturer } from '../../../services/manufacturer'
import { broadcastNotification } from '../../../services/notification.service'
import MySelect from './Myselect'

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
)

const animatedComponents = makeAnimated()

const SendNotification = ({ show, handleClose }) => {
  const dispatch = useDispatch()

  const [contractor, setContractors] = useState([])
  const [owner, setOwners] = useState([])
  const [manufacturer, setManufacturer] = useState([])

  const [selectedContractors, setSelectedContractors] = useState([])
  const [selectedOwners, setSelectedOwners] = useState([])
  const [selectedManufacturer, setSelectedManufacturer] = useState([])

  useEffect(() => {
    listContractors(
      {},
      (data) => {
        setContractors(
          data && data.docs
            ? data.docs.map((e) => {
                return { value: e._id, label: `${e.first_name} ${e.last_name}` }
              })
            : [],
        )
      },
      dispatch,
    )

    listOwners(
      {},
      (data) => {
        setOwners(
          data && data.docs
            ? data.docs.map((e) => {
                return { value: e._id, label: `${e.first_name} ${e.last_name}` }
              })
            : [],
        )
      },
      dispatch,
    )

    listManufacturer(
      {},
      (data) => {
        setManufacturer(
          data && data.docs
            ? data.docs.map((e) => {
                return { value: e._id, label: `${e.first_name} ${e.last_name}` }
              })
            : [],
        )
      },
      dispatch,
    )
  }, [dispatch])

  const handleContractorChange = (selected) => {
    console.log('>>', selected)
    setSelectedContractors(selected)
  }
  const handleOwnerChange = (selected) => {
    setSelectedOwners(selected)
  }
  const handleManufacturerChange = (selected) => {
    setSelectedManufacturer(selected)
  }

  const _submitData = (data) => {
    const payload = {
      to_user_ids: [
        ...selectedContractors.map((e) => e.value),
        ...selectedManufacturer.map((e) => e.value),
        ...selectedOwners.map((e) => e.value),
      ],
      title: data.title,
      description: data.message,
    }

    broadcastNotification(
      payload,
      (result) => {
        if (result.status === 'SUCCESS' && result.code === 200) {
          success(result.message)
          handleClose()
        } else if (result.status === 'ERROR' && result.code === 403) {
          failed(result.message)
        }
      },
      dispatch,
    )
  }
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <div className="custom-inner-header">
            <Modal.Title>Send Notification</Modal.Title>
          </div>
        </Modal.Header>
        <Formik
          initialValues={{
            title: '',
            message: '',
          }}
          enableReinitialize
          validationSchema={sendNotificationSchema}
          onSubmit={(values) => {
            _submitData(values)
          }}
        >
          {(formik) => (
            <Form>
              <Modal.Body>
                <div className="f-33">
                  <div className="f-row">
                    <div className="f-50">
                      <TextField
                        label="Title"
                        name="title"
                        value={formik?.values?.title}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="f-row">
                    <div className="f-50">
                      <TextArea
                        label="Message"
                        name="message"
                        value={formik?.values?.message}
                        onChange={formik.handleChange}
                        required={true}
                      />
                    </div>
                  </div>

                  <div className="f-row">
                    <div className="f-50">
                      <div className="contractor-header-l">
                        <h5 className="contractor-heading">
                          Select Contractor
                        </h5>
                      </div>
{/* adding new code */}
                      {/* <ReactSelect
                                                className="pro-input-two pro-select-two-new"
                                                label="Contractor"
                                                name='contractors'
                                                options={contractor}
                                                isMulti
                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{       
                                                    Option
                                                }}
                                                onChange={handleContractorChange}
                                                allowSelectAll={true}
                                                value={selectedContractors}

                                            /> */}
                      <MySelect
                        options={contractor}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={handleContractorChange}
                        allowSelectAll={true}
                        value={selectedContractors}
                      />
                    </div>
                  </div>

                  <div className="f-row">
                    <div className="f-50">
                      <div className="contractor-header-l">
                        <h5 className="contractor-heading">Select Owner</h5>
                      </div>
                      {/* <ReactSelect
                        className="pro-input-two pro-select-two-new"
                        label="Contractor"
                        name="contractors"
                        options={owner}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={handleOwnerChange}
                        allowSelectAll={true}
                        value={selectedOwners}
                      /> */}

                       <MySelect
                        options={owner}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={handleOwnerChange}
                        allowSelectAll={true}
                        value={selectedOwners}
                      />
                    </div>
                  </div>

                  <div className="f-row">
                    <div className="f-50">
                      <div className="contractor-header-l">
                        <h5 className="contractor-heading">
                          Select Manufacturer
                        </h5>
                      </div>

                      {/* <ReactSelect
                        className="pro-input-two pro-select-two-new"
                        label="Contractor"
                        name="contractors"
                        options={manufacturer}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{
                          Option,
                        }}
                        onChange={handleManufacturerChange}
                        allowSelectAll={true}
                        value={selectedManufacturer}
                      /> */}
                      <MySelect
                        options={manufacturer}
                        isMulti
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option, MultiValue, animatedComponents }}
                        onChange={handleManufacturerChange}
                        allowSelectAll={true}
                        value={selectedManufacturer}
                      />
                    </div>
                  </div>
                </div>

                <div className="contractor-wrapper">
                  <div className="contractor-body"></div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <ul className="modal-actions">
                  <li>
                    <button className="btn btn-primary" type="submit">
                      Send Notification
                    </button>
                  </li>
                  <li>
                    <button
                      className="btn btn-grey"
                      type="button"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                  </li>
                </ul>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </div>
  )
}

export default SendNotification
