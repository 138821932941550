import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { contractorSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import { options, message, _status } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { Get_State, Get_Countries } from "../../services/owner";
import { addContractor, listContractors, updateContractor } from "../../services/contractor";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import { UploadLogoField } from "../../components/common/formInput/UploadLogoField";
import Breadcrumb from './Breadcrumb'
// import InputMask from 'react-input-mask';
const CreateContracor = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();
    const [resStates, setResStates] = useState();
    const [resCountries, setResCountries] = useState();
    
    useEffect(() => {
        listContractors({ page: 1 }, (data) => { setResPayload(data) }, dispatch);
        Get_Countries(result => { setResCountries(result.data) }, dispatch);
    }, []);

    useEffect(() => {
        Get_State({ country: "United States" }, result => {
            setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
        }, dispatch)
    }, []);

    const paramID = queryString.parse(location.search)
    const contractor_data = resPayload && resPayload.docs.find((item) => item._id === paramID.id)
    let countryData = resCountries?.map((item) => ({ value: item.name, label: item.name }));

    const initialData = {
        _id: contractor_data ? contractor_data._id : "",
        first_name: contractor_data ? contractor_data.first_name : "",
        last_name: contractor_data ? contractor_data.last_name : "",
        company_name: contractor_data ? contractor_data.company_name : "",
        city: contractor_data ? contractor_data.city : "",
        mobile_no: contractor_data ? contractor_data.mobile_no : "",
        state: contractor_data ? contractor_data.state : "",
        country: contractor_data ? contractor_data.country : "United States",
        zip_code: contractor_data ? contractor_data.zip_code : "",
        address1: contractor_data ? contractor_data.address1 : "",
        address2: contractor_data ? contractor_data.address2 : "",
        email: contractor_data ? contractor_data.email : "",
        status: contractor_data ? contractor_data.status : true,
        company_logo: contractor_data ? contractor_data.company_logo : ""
    };

    useEffect(() => {
        Get_State({ country: contractor_data?.country }, result => {
            if (result?.data?.states) {
                setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
            }
        }, dispatch)
    }, [contractor_data?.country]);

    /**
     * @description Function is for add Rpc.
     * @param {object} data 
     */
    const _submitData = (data) => {
        let form = new FormData();
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        try {
            addContractor(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    console.log(result);
                    success(result.message)
                    navigate("/list-contractor")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update Owner with building.
     * @param {object} data 
     */
    const _updateData = async (data) => {
        let form = new FormData();
        form.append('_id', data._id)
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        try {
            updateContractor(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/list-contractor")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.canceledContractorForm, options);
        if (result) {
            // alert(message.canceledContractorForm);
            navigate("/list-contractor");
        }
    };

    return (
        <div className="dashboard-inner p-b-0">
        <Breadcrumb/>
            <Formik
                initialValues={initialData}
                enableReinitialize
                validationSchema={contractorSchema}
                onSubmit={(values) => contractor_data ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card m-b-25">
                            <div className="d-card-header">
                                <h5 className="d-card-heading">{contractor_data ? "Edit Contractor" : "Add Contractor"}</h5>
                            </div>
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="First Name"
                                                placeholder="First Name"
                                                name="first_name"
                                                value={formik?.values?.first_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Last Name"
                                                placeholder="Last Name"
                                                name="last_name"
                                                value={formik?.values?.last_name}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Email Address"
                                                placeholder="Email Address"
                                                name="email"
                                                value={formik?.values?.email}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Phone"
                                                name="mobile_no"
                                                placeholder="Phone Number"
                                                value={formik?.values?.mobile_no}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                            {/* <InputMask
                                                mask='999-999-9999'
                                               >
                                            </InputMask> */}
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Country"
                                                placeholder="Select Country"
                                                name="country"
                                                value={formik?.values?.country}
                                                onChange={formik.handleChange}
                                                changeHandle={(val) => {
                                                    Get_State({ country: val }, result => {
                                                        setResStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                                                    }, dispatch)
                                                }}
                                                required={true}
                                                options={countryData}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="State"
                                                placeholder="Select States"
                                                name="state"
                                                value={formik?.values?.state}
                                                onChange={formik.handleChange}
                                                required={true}
                                                options={resStates}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Address Line 1"
                                                name="address1"
                                                placeholder="Address Line 1"
                                                value={formik?.values?.address1}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Address Line 2"
                                                name="address2"
                                                placeholder="Address Line 2"
                                                value={formik?.values?.address2}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="City"
                                                name="city"
                                                placeholder="City"
                                                value={formik?.values?.city}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Zip Code"
                                                name="zip_code"
                                                placeholder="Zip Code"
                                                value={formik?.values?.zip_code}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <TextField
                                                label="Company Name"
                                                name="company_name"
                                                placeholder="Company Name"
                                                value={formik?.values?.company_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Status"
                                                name="status"
                                                options={_status}
                                                value={formik?.values?.status}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <UploadLogoField
                                                label="Upload Logo"
                                                name="company_logo"
                                                id="file002"
                                                profileDetails={contractor_data?.company_logo }
                                                isrequired={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">{contractor_data ? "Edit Contractor" : "Add Contractor"}</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" onClick={onCancel} type="button"> Cancel </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default CreateContracor;
