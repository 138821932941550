import { Form, Formik } from 'formik'
import React from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import TextField from '../../../components/common/formInput/TextField'
import { changePassSchema } from '../../../components/common/validations/Validation';
import { changePassword } from '../../../services/auth.service';

const ChangePassword = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const _submitData = (data) => {
        const { currentPwd, newPwd } = data;
        changePassword({ currentPwd, newPwd }, (data) => {
            if (data) {
                localStorage.clear();
                navigate("/login");
            }
        }, dispatch);
    }

    return (
        <div>
            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Change Password</h5>
                </div>
                <Formik
                    initialValues={{
                        currentPwd: "",
                        newPwd: "",
                        confirmPass: ""
                    }}
                    enableReinitialize
                    validationSchema={changePassSchema}
                    onSubmit={(values) => { _submitData(values) }}
                >
                    {(formik) => (
                        <Form>
                            <div className='d-card-body'>
                                <div className="f-row">
                                    <div className="f-60">
                                        <div className="f-row">
                                            <div className='f-100'>
                                                <TextField
                                                    label="Current Password"
                                                    placeholder="Current Password"
                                                    name="currentPwd"
                                                    type="password"
                                                    value={formik?.values?.currentPwd}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="f-row">
                                            <div className='f-100'>
                                                <TextField
                                                    label="New Password"
                                                    placeholder="New Password"
                                                    name="newPwd"
                                                    type="password"
                                                    value={formik?.values?.newPwd}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="f-row">
                                            <div className='f-100'>
                                                <TextField
                                                    label="Confirm Password"
                                                    placeholder="Confirm Password"
                                                    name="confirmPass"
                                                    type="password"
                                                    value={formik?.values?.confirmPass}
                                                    onChange={formik.handleChange}
                                                    required={true}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="f-40">
                                        <div className="help-box m-b-20">
                                            <h5>Rules for passwords</h5>
                                            <p>To create a new password, you have to meet all of the following requirements:</p>
                                            <ul>
                                                <li>Minimum 8 character</li>
                                                <li>At least one special character</li>
                                                <li>At least one number</li>
                                                <li>Can’t be the same as a previous</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='d-card-bottom'>
                                    <div className='d-card-bottom-cell'>
                                        <button className='btn btn-blue'>Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default ChangePassword