import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import AddNotification from '../AddNotification';
import AddTemplate from '../AddTemplate';
import NotificationList from '../NotificationList';
import NotificationTemplates from '../NotificationTemplates';
import MyNotification from '../MyNotification';

const NotificationRoutes = [
    {
        path: `/notification-list`,
        layout: DefaultLayout,
        component: <  NotificationList />
    },
    {
        path: `/my-notification`,
        layout: DefaultLayout,
        component: <  MyNotification />
    },
    {
        path: `/add-notification`,
        layout: DefaultLayout,
        component: <  AddNotification />
    },
    {
        path: `/notification-templates`,
        layout: DefaultLayout,
        component: <  NotificationTemplates />
    },
    {
        path: `/add-template`,
        layout: DefaultLayout,
        component: <  AddTemplate />
    }
]

export default NotificationRoutes;