import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';

const AddNotification = () => {
    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Notifications</h4>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Send New Notification</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="form-content">
                        <div className='f-row'>
                            <div className='f-100'>
                                <label className='f-label'>Notification</label>
                                <div className='f-field'>
                                    <textarea className='f-area'></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Select Users</h5>
                    <div className='d-card-actions'>
                        <button className="btn btn-blue">Select All</button>
                    </div>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className='bidders-list-wrap'>
                        <ul className="contractor-list">
                            <li>
                                <div className="contractor-check">
                                    <label className='checkbox nolabel radio-checkbox' htmlFor="bidder01">
                                        <input type="checkbox" id="bidder01" />
                                        <span></span>
                                    </label>
                                </div>
                                <div className="contractor-details">
                                    <div className='bid-details'>
                                        <span className='bid-icon'>
                                            <img src={Icons.Owner} alt='' />
                                        </span>
                                        <h4>Ronald Richards</h4>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="contractor-check">
                                    <label className='checkbox nolabel radio-checkbox' htmlFor="bidder02">
                                        <input type="checkbox" id="bidder02" />
                                        <span></span>
                                    </label>
                                </div>
                                <div className="contractor-details">
                                    <div className='bid-details'>
                                        <span className='bid-icon'>
                                            <img src={Icons.Owner} alt='' />
                                        </span>
                                        <h4>Ronald Richards</h4>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-card-bottom">
                <div className="d-card-bottom-cell">
                    <button className="btn btn-blue" type="submit">Send</button>
                </div>
                <div className="d-card-bottom-cell">
                    <button className="btn btn-grey" type="button" >Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default AddNotification

