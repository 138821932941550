import { ErrorMessage, useField } from 'formik'
import React, { useState } from 'react'

const TextInput = ({ label, required, eyeIcon, ...props }) => {

    const [field, meta] = useField(props);
    const [passwordType, setPasswordType] = useState("password");

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")
        }
    }

    return (
        <div className='form-group form-row'>
            <div className="w100">
                <label className="form-label" htmlFor={field.name}>{label}{required ? <span className="error">*</span> : ""}</label>
                <div className={eyeIcon ? 'f-field' : 'form-field'}>
                    <input
                        className={`form-control f-control ${meta.touched && meta.error && 'is-invalid'}`}
                        {...field} {...props}
                        autoComplete="off"
                        type={props?.type ? props.type : passwordType}
                    >
                    </input>
                   {eyeIcon && <span className='f-icon clickable' onClick={togglePassword}>
                        {passwordType === "password" ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                    </span>}
                </div>
                <ErrorMessage component="div" name={field.name} className="error" />
            </div>
        </div>
    )
}

export default TextInput;