import React from 'react'
import { API_BASEURL } from '../../../environment'
import Icons from "../../../assets/images/index";

const ProjectDetails = ({ projectData }) => {
    return (
        <div>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Project Notes & Description</h5>
                </div>
                <div className='d-card-body'>
                    <div className="desc-info">
                        <h6 className="desc-info-heading">Project Notes</h6>
                        <p className="desc-info-detail">{projectData?.notes}</p>
                    </div>
                    <div className="desc-info">
                        <h6 className="desc-info-heading">Project Description</h6>
                        <p className="desc-info-detail" dangerouslySetInnerHTML={{ __html: projectData?.description }} />
                    </div>
                </div>
            </div>
            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Selected Sample Photos</h5>
                </div>
                <div className='d-card-body'>
                    <h1 className="d-card-heading d-card-sub-heading">Roof Photos</h1>
                    <ul className="sample-gallery">
                        {projectData?.media?.map((item, inx) => {
                            return (
                                item?.type === "images" &&
                                <li key={inx}>
                                    <span>
                                        <img src={`${API_BASEURL}/${item?.path}` || (Icons.projectBigImg)} alt='' />
                                    </span>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
                <div className='d-card-body'>
                    <h1 className="d-card-heading d-card-sub-heading">Roof Drawings</h1>
                    <ul className="sample-gallery">
                        {projectData?.media?.map((item, inx) => {
                            return (
                                item?.type === "roof_drawings" &&
                                <li key={inx}>
                                    <span>
                                        <img src={`${API_BASEURL}/${item?.path}` || (Icons.projectBigImg)} alt='' />
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ProjectDetails