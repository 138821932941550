import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import TextField from '../../components/common/formInput/TextField';
import { BidFormSchema } from '../../components/common/validations/Validation';
import TextArea from '../../components/common/formInput/TextArea';
import { AddNewLineItems } from '../../services/project';
import { failed, success } from '../../components/common/Toastify';

const BidForm = ({ handleClose, show, cloneEstimator, handleModal }) => {

    const dispatch = useDispatch();

    const [resPayload, setResPayload] = useState();

    const [initialValue, setInitialValue] = useState({
        _id: "",
        title: "",
        description: ""
    });

    // useEffect(() => {
    //     if (cloneEstimator)
    //         viewEstimator({ _id: cloneEstimator }, (data) => { setResPayload(data) }, dispatch)
    //     else
    //         setResPayload({})
    // }, [cloneEstimator]);

    useEffect(() => {
        setInitialValue({
            _id: resPayload?._id ? resPayload._id : "",
            item: resPayload?.item ? resPayload.item : "",
           
        })
    }, [resPayload]);

    /**
    * @description Function is for add Faq.
    * @param {object} data 
    */
    const _submitData = (data) => {
        try {
            AddNewLineItems(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleModal();
                    handleClose();
                    success(result.message)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

      /**
    * @description Function is for add Faq.
    * @param {object} data 
    */
       const _cloneData = (data) => {
        try {
            AddNewLineItems(data, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    handleModal();
                    handleClose();
                    success(result.message)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };


    return (
        <div className='dashboard-inner'>
            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{"Add New Line Items"}</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValue}
                    enableReinitialize
                    validationSchema={BidFormSchema}
                    onSubmit={(values) => resPayload ? _cloneData(values) :  _submitData(values)}
                >
                    {(formik) => (
                        <Form>
                            <Modal.Body>
                                <div className="f-row">
                                    <div className="f-100">
                                        <TextField
                                            label="Item"
                                            name="item"
                                            value={formik?.values?.item}
                                            onChange={formik.handleChange}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                
                            </Modal.Body>
                            <Modal.Footer>
                                <ul className="modal-actions">
                                    <li><button className="btn btn-primary" type='submit'>Add</button></li>
                                    <li><button type='button' className="btn btn-grey" onClick={handleClose}>Cancel</button></li>
                                </ul>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    )
}

export default BidForm

