import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import CMSFORM from '../CMSForm';


const CMSRoutes = [
    {
        path: `/cms`,
        layout: DefaultLayout,
        component: <  CMSFORM />
    },
    // {
    //     path: `/wind-uplift-view/:id`,
    //     layout: DefaultLayout,
    //     component: < WindUpliftView />
    // },
    // {
    //     path: `/wind-uplift-form`,
    //     layout: DefaultLayout,
    //     component: < WindUpliftForm />
    // }
]

export default CMSRoutes;