import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../../assets/images/index';
import _ from 'underscore';
import { alert, confirm } from "react-bootstrap-confirmation";
import { useDispatch } from "react-redux";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { _status } from '../../../components/common/utils/message';
import { listBidProject, submitBidFormContractor, getContractorSumbitBid, 
    project_details, viewOneProject ,updateBidFormContractor } from '../../../services/project';
import { useLocation, useNavigate } from "react-router-dom";
import { failed, success } from "../../../components/common/Toastify";
import { Formik, Form } from "formik";
import { valueBidFormSchemaProject } from "../../../components/common/validations/Validation";

import ContractorProjectDescription from "../common/ContractorProjectDescription";
import { data } from 'autoprefixer';
import { number } from 'yup';
import TextArea from "../../../components/common/formInput/TextArea";
import TextField from "../../../components/common/formInput/TextField";
import ReactDatePicker from "../../../components/common/formInput/DatePicker";
import SearchSelectBox from "../../../components/common/formInput/SearchSelectBox";
import BidEdit from './BidEdit';

const BidSubmit = () => {
    const dispatch = useDispatch();
    const formRef = React.useRef(null);
    const [checkBId, setCheckBId] = useState();
    const [resPayload, setResPayload] = useState();
    const [res, setRes] = useState();
    const [formValues, setFormValues] = useState(
        [
            {
                item: "",
                default_value: 0,
                allowed_in_words: false

            }]
    );
    const [count, setCount] = useState(0);
    const [inputs, setInputs] = useState({});
    const [inputUpdate, setInputUpdate] = useState({});
    const [selectedManufactures, setSelectedManufactures] = useState([]);
    const [projectDetails, setProjectDetails] = useState();
    const [isStartDate, setIsStartDate] = useState();
    const [bidamount, setBidamount] = useState({
        total_bid_amount_in_words: "",
        total_bid_amount: 0,
        days_to_complete:''
       

    });
    const [bidamountUpdate, setBidamountUpdate] = useState({
        total_bid_amount_in_words: "",
        total_bid_amount: 0,
        days_to_complete:'',
        itmes: [inputUpdate] 
       

    });
    let projectId = useParams();
    const navigate = useNavigate();
    let strHref = window.location.href;
    let projectIdDD = (strHref.split("/")).slice(-1).pop();
    var projBidId;

    const selectManfacturer = (selected) => {
        setSelectedManufactures(selected)
    }
    useEffect(() => {
        _getListing();
    }, [dispatch]);

    useEffect(() => {
        _checkgetListing();

    }, [dispatch]);

    useEffect(() => {
        viewOneProject(projectIdDD, (data) => {
            setRes(data);
            if (projectIdDD.id) {
                //  setSelectedMenus(data.selectedMenuItems);
                //  setAllowedbids(data.allowedBid);
                setSelectedManufactures(data.manufacturers);
            }
        }, dispatch);


    }, []);

   

    

    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };
    /**
     * @description get all the contractors from db where page is pageChange and Limit for limit of the data.
     */
    const _getListing = () => {
        listBidProject(projectIdDD, (data) => {
            setResPayload(data)
        }, dispatch)
    }

    const _checkgetListing = () => {
        getContractorSumbitBid(projectIdDD, (data) => {
            setCheckBId(data)
        }, dispatch)
    }
    useEffect(() => {
        project_details((data) => {
            setProjectDetails(data);
        })
    }, [])

   
    let manufacturerData = projectDetails?.manufacturer?.map((item) => ({ value: item._id, label: item.first_name }));
   

    
   

    // code start here

    let handleChange = (data) => {
      
    }
    let sum1 = 0;
    const handleChange1 = (event, index) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))      
        let sum_bid = 0;
        sum_bid = sum_bid + JSON.parse(inputs[index]);     
        function sumpp(obj) {
            var suml = 0;
            for (var el in obj) {
                if (obj.hasOwnProperty(el)) {
                    suml += parseFloat(obj[el]);
                }
            }
            return suml;
        }
        var summed = sumpp(inputs);       

        setCount(summed);     
    }
    


    const handleChangeData = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setBidamount(values => ({ ...values, [name]: value }))       
        
       
    }

   

  let handleSubmit = (event) => {
        event.preventDefault();
                  
        let sum_bid = 0;
        let line_req = [];
        resPayload && resPayload?.map((itemdata, index) => {
            
            sum_bid = sum_bid ;
            let data_abc = {
                "item": itemdata.item,
                "default_value": itemdata.default_value,

                "bid_amount": inputs[index],

                "allowed_in_words": itemdata.allowed_in_words
               
            }

            //  line_req.push(data_abc);
            line_req[index] = data_abc;

        });
        let newDate = new Date(isStartDate).toISOString();

        let newDate1 = isStartDate.toISOString();

       
        let reqData = {

            "project_id": projectIdDD,
            "selected_manufacturer": selectedManufactures.value,
           
            "days_to_complete": bidamount.days_to_complete,
            "total_bid_amount": bidamount.total_bid_amount,
            "total_bid_amount_in_words": bidamount.total_bid_amount_in_words,
            "line_items": line_req,
            "expected_start_date": newDate,        
        }

        



        try {
            submitBidFormContractor(reqData, result => {  
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/Bid/Submit/"`${projectId}`)
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }



        //  alert(JSON.stringify(formValues));
    }


    

    // code end here
let srNoOne = 0;
let srNo = 0;





    return (<>
        

       

        {(checkBId === undefined || checkBId === null || checkBId === false) ?
            <Formik
                initialValues={formValues}
                validationSchema={valueBidFormSchemaProject}
                enableReinitialize
            // onSubmit={_submitData}
            //    onSubmit={(values) => {
            //     _submitData(values);
            // }}
            >
                {(formik) => (

                    <div className='dashboard-inner'>
                        <div className='d-card'>
                            <div className='d-card-header'>
                                <h5 className='d-card-heading'>Bid Form  </h5>
                                <div className='d-card-actions'>

                                </div>
                            </div>
                            <ContractorProjectDescription />
                            {/* COUNT : {count} */}
                            <div className='d-card-body'>
                                <form className='custom-form' onSubmit={handleSubmit} ref={formRef} >
                                    <div className='f-row'>
                                        <div className='f-100'>
                                            <h6 className='mb-2'>Provide the name of the Material Manufacturer/Supplier you are a Certified Installer
                                                for and will be issuing the required number of years Warranty for Roof Project </h6>
                                        </div>
                                        <div className='f-50'>
                                            <ReactSelect
                                                className="pro-input-two pro-select-two-new"
                                                options={manufacturerData}

                                                closeMenuOnSelect={false}
                                                hideSelectedOptions={false}
                                                components={{
                                                    Option
                                                }}
                                                onChange={selectManfacturer}
                                                allowSelectAll={true}
                                                value={selectedManufactures}
                                            />
                                        </div>
                                        <div className='f-100 my-4'>
                                            <h3>BIDDING INSTRUCTIONS :</h3>
                                            <p>1. Please type in your bid price(s) in both words and figures in the appropriate line items given.</p>
                                            <p>2. Please type in your bid price(s) in both words and figures in the appropriate line items given.</p>
                                            <p>3. The amounts only accept (0123456789.) absolutely no commas or $ sign.</p>
                                            <p>4. The winning bidder will be charged a fee of $17,780.
                                                Please review “ answer “to roofer questions and the “EA”
                                                before placing your bid as new information may be added since you last review them.</p>
                                        </div>
                                    </div>

                                    <div className='bid-outer-wrap'>
                                        <div className='table-responsive'>
                                            <table>
                                                <thead>
                                                    <th className='text-center th-bg' colSpan={3}>DealPoint Bid Information For #0295 Acme- Seaside Heights, NJ </th>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td></td>
                                                        <td><h6>Total Bid Amount In Words </h6></td>
                                                        <td><h6>Bid Amount ($) </h6></td>
                                                    </tr>

                                                    <tr>
                                                        <td></td>
                                                        <td>
                                                        <input type="text" name= 'total_bid_amount_in_words' defaultValue='' onChange={e => handleChangeData(e)} class="form-control f-input" /> 
                                                        </td>
                                                        <td>
                                                        <input type="text" name= 'total_bid_amount' defaultValue='' onChange={e => handleChangeData(e)} class="form-control f-input" />

                                                        </td>
                                                    </tr>

                                                    {/* <tr>
                                                        <td></td>
                                                        <td><TextField
                                                            label=""
                                                            name="total_bid_amount_in_words"
                                                            value={formik?.values?.total_bid_amount_in_words}
                                                          //  onChange={formik.handleChange} handleChangeData
                                                          onChange={e => handleChangeData(e)}
                                                        /></td>
                                                        <td><TextField


                                                            label=""
                                                            name="total_bid_amount"
                                                            value={formik?.values?.total_bid_amount}
                                                         //   onChange={formik.handleChange}
                                                         onChange={e => handleChangeData(e)}


                                                        /></td>
                                                    </tr> */}
                                                    {resPayload && resPayload?.map((itemdata, index) => (
                                                        <tr key={index}>
                                                            <td>{srNo + 1 }</td>
                                                            <td>{itemdata.item}</td>
                                                            <td> <input type="text" name={index} defaultValue={itemdata.default_value} onChange={e => handleChange1(e, index)} class="form-control f-input" /></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <p className="bid-time"> We can start project on or before <ReactDatePicker
                                            label=""
                                            name="bid_start_date"
                                            placeholder="Select Bid Starts Time"
                                            selected={isStartDate}
                                            dateFormat="yyyy/MM/dd"
                                            value={formik?.values?.expected_start_date}

                                            //onChange={formik.handleChange}
                                            //onChange={date => setFieldValue('bid_start_date', date)}
                                            changeHandle={(date) => setIsStartDate(date)}
                                        /> and it will take us 
                                        {/* <TextField
                                                label=""
                                                className='f-days'
                                                name="days_to_complete" /> */}

<input type="text" name= 'days_to_complete' defaultValue='' onChange={e => handleChangeData(e)} class=" f-days form-control f-input" /> 
                                                 days to complete.
                                        </p>
                                    </div>

                                    <div className="button-section">
                                        <button className="btn btn-info" type="submit">Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                )
                }
            </Formik > :
            <div>
                <ContractorProjectDescription />
                <div className='d-card-body'>
                    
                    <BidEdit checkBId={checkBId} projectId = {projectIdDD} />
                </div>
            </div >
            
        }

    </>)
}

export default BidSubmit

