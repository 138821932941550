import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all RoofType data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listRoofType = async (param, callback, dispatch) => {
  try {
    let token = localStorage.getItem("authentication");
    if (token) {
      let option = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch(loadingAction(true))
      axios.get(`${apiUrl}${PORT}/rpc/roofType/view/all?${queryString.stringify(param)}`, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data.data)
        })
        .catch((error) => {
          dispatch(loadingAction(false))
          callback(false);
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description add new RoofType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const addRoofType = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.post(`${apiUrl}${PORT}/rpc/roofType/create`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description update RoofType in db.
 * @param {*} data 
 * @param {*} callback 
 */
export const updateRoofType = async (data, callback, dispatch) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      dispatch(loadingAction(true))
      axios.put(`${apiUrl}${PORT}/rpc/roofType/update`, data, option)
        .then((response) => {
          dispatch(loadingAction(false))
          callback(response.data)
        }).catch((error) => {
          dispatch(loadingAction(false))
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description Soft Delete RoofType in db
 * @param {*} id 
 * @param {*} callback 
 */
export const DeleteRoofType = async (id, callback) => {
  try {
    let token = localStorage.getItem('authentication')
    if (token) {
      let option = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
      axios.delete(`${apiUrl}${PORT}/rpc/roofType/delete/${id}`, option)
        .then((response) => {
          callback(response.data)
        }).catch((error) => {
          callback(false)
        });
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * @description update status of roofType in db.
 * @param {*} data 
 * @param {*} callback 
 */
 export const updateRoofStatus = async (data, callback, dispatch) => {
    try {
      let token = localStorage.getItem('authentication')
      if (token) {
        let option = {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
        dispatch(loadingAction(true))
        axios.post(`${apiUrl}${PORT}/rpc/roofType/update/status`, data, option)
          .then((response) => {
            dispatch(loadingAction(false))
            callback(response.data)
          }).catch((error) => {
            dispatch(loadingAction(false))
            callback(false)
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  export const viewOneRoofType = async (param, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/rpc/roofType/view?${queryString.stringify(param)}`, option).then((response) => {
                callback(response.data.data)
            }).catch((error) => {
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
  };