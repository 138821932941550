import React, { useRef } from 'react'
import { ErrorMessage, useField } from "formik";
import JoditEditor from "jodit-react";

const Editor = ({ label, required, viewOnly = false, ...props }) => {

    const editor = useRef(null)
    const [field, meta, helpers] = useField(props);

    return (
        <div className="form-group">
            <label className="f-label" htmlFor={field.name}>{label}{required ? <span className="error">*</span> : ""}</label>
            <div className='f-field'>
                <JoditEditor
                    className={`form-control f-des ${meta.touched && meta.error && 'is-invalid'}`}
                    ref={editor}
                    name={props.name}
                    value={props.value}
                    onChange={(e) => {
                        helpers?.setValue(e)
                        props?.changeHandle?.(e)
                    }}
                />
            </div>
            <ErrorMessage component="div" name={field.name} className="error" />
        </div>
    );
};

export default Editor;