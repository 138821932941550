import React from "react";
import Icons from "../../assets/images/index";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const SlideImg = () => {
  const settings = {
    arrows: false,
    autoplay:true,
    easing: "ease",
    transitionDuration: 5000,
    duration: 2000,

  };

  return (
    <div className="login-right">
      <div className="gallery">
        <div className="form-row">
          <div className="w100">
            <Fade {...settings}>
              <div className="gallery-item">
                <img src={Icons.gallery001} alt="" />
              </div>
            </Fade>
          </div>

          <div className="w50">
            <Fade {...settings}>
              <div className="gallery-item gallery-item-40">
                <img src={Icons.gallery002} alt="" />
              </div>
            </Fade>
            <Fade {...settings}>
              <div className="gallery-item gallery-item-60">
                <img src={Icons.gallery003} alt="" />
              </div>
            </Fade>
          </div>
          <div className="w50">
            <Fade {...settings}>
              <div className="gallery-item gallery-ite m-40">
                <img src={Icons.gallery004} alt="" />
              </div>
            </Fade>
            <Fade {...settings}>
              <div className="gallery-item gallery-item-60">
                <img src={Icons.gallery005} alt="" />
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SlideImg;
