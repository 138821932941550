import React from "react";
import Icons from "../../../assets/images";

const Invoice = (props) => {

    return (
        <div className='responsive-table'>
            <table className='table-odd-even'>
                <tbody>

                    <tr>
                        <td>
                            <div className='bid-details'>
                                <span className='bid-icon'>
                                    <img src={Icons.pdf} alt='' />
                                </span>
                                <h4>{`${props.name}.pdf`}</h4>
                            </div>
                        </td>

                        <td className="text-center" width="60">
                            <button className="btn-edit" onClick={() => props.download(props.name)}>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13" stroke="#28305C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5 8L10 13L15 8" stroke="#28305C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 13V1" stroke="#28305C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </button>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>
    );
}
export default Invoice;