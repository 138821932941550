import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Icons from '../../assets/images'
import { setParamFirst } from '../../components/common/general/GeneralSlice';
import { API_BASEURL } from '../../environment';
import { get_profile } from '../../services/auth.service';
import ChangePassword from './ChangePassword'
import images from '../../assets/images/defaultimage.png'

const Profile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loginUser, setLoginUser] = useState()

    useEffect(() => {
        get_profile((data) => { setLoginUser(data) }, dispatch)
    }, [])

    return (
        <>
            <section className='registration-section'>
                <div className="container">
                    <div className='d-card bordered '>
                        <div className='d-card-header'>
                            <h5 className='d-card-heading d-card-sub-heading'>Profile</h5>
                        </div>
                        <div className='d-card-body'>
                            <div className="owner-profile-wrapper">
                                <div className="u-p-l">
                                    <div className="owner-profile-header">
                                        <span className='owner-profile-img'>
                                            <img src={loginUser?.profile_pic ? `${API_BASEURL}/${loginUser?.profile_pic?.path}` : images} alt="" />
                                        </span>
                                        <h4 className='owner-name'>{loginUser?.first_name}{loginUser?.last_name}</h4>
                                        <div className="f-row">
                                            <div className="f-auto">
                                                <div className="desc-info p-b-5 p-t-5">
                                                    <h6 className="desc-info-heading">Email Address</h6>
                                                    <p className="desc-info-detail">{loginUser?.email}</p>
                                                </div>
                                            </div>
                                            <div className="f-auto">
                                                <div className="desc-info p-b-5 p-t-5">
                                                    <h6 className="desc-info-heading">Phone Number</h6>
                                                    <p className="desc-info-detail">{loginUser?.mobile_no}</p>
                                                </div>
                                            </div>
                                            <div className="f-auto">
                                                <div className="desc-info p-b-5 p-t-5">
                                                    <h6 className="desc-info-heading">Address</h6>
                                                    <p className="desc-info-detail">{loginUser?.city},{loginUser?.state},{loginUser?.zip_code}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="u-p-r">
                                    <div className="owner-profile-actions">
                                        <button className='btn btn-blue btn-sm'  onClick={() => { navigate('/edit-profile'); dispatch(setParamFirst(loginUser)) }}>Edit Profile</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ChangePassword />
        </>
    )
}

export default Profile