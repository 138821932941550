import { useField } from 'formik'
import Icons from '../../../assets/images';
import React, { useState } from "react";
import { API_BASEURL } from '../../../environment';
import images from '../../../assets/images/defaultimage.png'
export const ProfileField = ({ ...props }) => {
    const [field, meta, helpers] = useField(props.name);
    const [image, setImage] = useState(null);

    return (
        <>
            <span className='owner-profile-img'>
                <img src={image ? image : props?.profileDetails?.path ? `${API_BASEURL}/${props?.profileDetails?.path}` : images} alt="preview image" />
                <label htmlFor={props.id}>
                    <input
                        {...props}
                        type="file"
                        onChange={(e) => {
                            let file = e.target.files ? e.target.files[0] : File;
                            helpers.setValue(file);
                            setImage(URL.createObjectURL(e.target.files[0]));
                        }}
                        autoComplete="off"
                    />
                    <span className='edit-profile'>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13" cy="13" r="12" fill="#27316F" stroke="white" stroke-width="2" />
                            <path d="M15.0519 9.33424C15.1579 9.22827 15.2837 9.14421 15.4221 9.08687C15.5606 9.02952 15.709 9 15.8588 9C16.0087 9 16.1571 9.02952 16.2955 9.08687C16.434 9.14421 16.5598 9.22827 16.6658 9.33424C16.7717 9.4402 16.8558 9.56601 16.9131 9.70446C16.9705 9.84291 17 9.9913 17 10.1412C17 10.291 16.9705 10.4394 16.9131 10.5779C16.8558 10.7163 16.7717 10.8421 16.6658 10.9481L11.219 16.3948L9 17L9.60519 14.781L15.0519 9.33424Z" fill="#FEFEFF" stroke="#FEFEFE" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </span>
                </label>
            </span>
        </>
    )
}