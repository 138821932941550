import React from "react";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { contProjectNotes } from "../services/contractor";
import { useState,useEffect } from "react";
import NoDataFound from "../../../components/common/NoDataFound";
const ProjectNotes = () => {

    let id= useParams();
   

    const dispatch = useDispatch();

    const [projectData, setprojectData] = useState();


    useEffect(() => {
        _getListing();
    }, [dispatch]);

    const _getListing = () => {
      
        contProjectNotes( id.id, (data) => {
           
            setprojectData(data)
          }, dispatch)
      }




    return( 
        <>
        <div className='dashboard-inner'>
        
    <ContractorProjectDescription/>
    <div> <h2>Project Notes </h2></div><br></br>
   
    <div>
    {
    
    projectData?.length>0 ?<><p className="desc-info-detail">{projectData}</p> </>:<> <h5 className="d-flex align-items-center justify-content-center"><div><NoDataFound/></div></h5></>
    }

    </div>
    </div>

    </>
    )
}
export default ProjectNotes;