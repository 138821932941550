import axios from "axios";
import { apiUrl, PORT } from "../environment";
import queryString from 'query-string';
import { loadingAction } from "../components/common/loader/LoaderSlice";

/**
 * @description list for all contractor data.
 * @param {*} param 
 * @param {*} callback 
 */
export const listNotificatioSetting = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/user/notification/settings/all`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const updateNotificationSetting = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/user/notification/settings/update`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const notificationsUnreadCount = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/user/notification/unread `, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const myNotification = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/user/notification/my-notifications `, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const markNotificationRead = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/user/notification/markAsRead`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const broadcastNotification = async (data, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/admin/notifications/broadcastMessage`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(error.data)
                });
        }
    } catch (err) {
        console.log(err);
    }
};
