import React from 'react'

const FooterMenu = () => {
    return (
        <div className='dashboard-footer'>
            <p className='copyright'>&copy; Roofing Projects 2023</p>
        </div>
    )
}

export default FooterMenu