import React from 'react';
import HomeLayout from '../../../components/common/layout/HomeLayout';
import PrivateLayout from '../../../components/common/layout/PrivateLayout'
import ChangePassword from '../ChangePassword';
import Contact from '../ContactUs';
import ProfileEdit from '../EditProfile';
import ForgotPassword from '../ForgotPassword';
import Login from '../Login';
import Profile from '../Profile';
import Registration from '../Registration';
import ResetPassword from '../ResetPassword';

const AuthRoutes = [
    {
        path: `/login`,
        layout: PrivateLayout,
        component: <  Login />
    },
    {
        path: `/forgot-password`,
        layout: PrivateLayout,
        component: <  ForgotPassword />
    },
    {
        path: `/reset-password`,
        layout: PrivateLayout,
        component: <  ResetPassword />
    },
    {
        path: `/registration`,
        layout: HomeLayout,
        component: <  Registration />
    },
    {
        path: `/contact-us`,
        layout: HomeLayout,
        component: < Contact />
    },
    {
        path: `/change-password`,
        layout: HomeLayout,
        component: < ChangePassword />
    },
    {
        path: `/profile`,
        layout: HomeLayout,
        component: < Profile />
    },
    {
        path: `/edit-profile`,
        layout: HomeLayout,
        component: < ProfileEdit />
    }
]

export default AuthRoutes;