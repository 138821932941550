import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import ProjectForm from '../CreateProjectForm';
import ProjectList from '../ManageProjectList';
import ProjectView from '../ProjectView';
import CreateBidForm from '../CreateBidForm';
import UpdateBidForm from '../UpdateBidForm'
import BidResult from '../BidResult';
import CreateLineItems from '../CreateLineItems';
import CreateDescription from '../CreateDescription';
import CreateProDesForm from '../CreateProDesForm';
import SendBidInvitationList from '../component/SendBidInvitationList';
import BuildingCreateProject from '../BuildingCreateProject';



const ProjectRoutes = [
    {
        path: `/project-list`,
        layout: DefaultLayout,
        component: <  ProjectList />
    },
    {
        path: `/project-form`,
        layout: DefaultLayout,
        component: <  ProjectForm />
    },
    {
        path: `/project-view/:id`,
        layout: DefaultLayout,
        component: <  ProjectView />
    },

    {
        path: `/create-bid`,
        layout: DefaultLayout,
        component: < CreateBidForm />
    },
    {
        path: `/update-bid`,
        layout: DefaultLayout,
        component: <UpdateBidForm/>
    },
    {
        path: `/bid-result`,
        layout: DefaultLayout,
        component: < BidResult />
    },

    {
        path: `/create-line-items`,
        layout: DefaultLayout,
        component: < CreateLineItems />
    },

    {
        path: `/create-description`,
        layout: DefaultLayout,
        component: < CreateDescription />
    },

    {
        path: `/create-pro-des-form`,
        layout: DefaultLayout,
        component: < CreateProDesForm />
    },
    {
        path: `/create/project/form/:id`,
        layout: DefaultLayout,
        component: < BuildingCreateProject />
    },
    {
        path: `/invitation/List`,
        layout: DefaultLayout,
        component: < SendBidInvitationList />
    }
]

export default ProjectRoutes;