import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import OwnerTypeList from '../OwnerTypeList';
import OwnerTypeView from '../OwnerTypeView';
import OwnerTypeForm from '../OwnerTypeForm'

const OwnerTypeRoutes = [
    {
        path: `/owner-type-list`,
        layout: DefaultLayout,
        component: <  OwnerTypeList />
    },
    {
        path: `/owner-type-form`,
        layout: DefaultLayout,
        component: <  OwnerTypeForm />
    },
    {
        path: `/owner-type-view/:id`,
        layout: DefaultLayout,
        component: <  OwnerTypeView />
    }
    
    
]

export default OwnerTypeRoutes ;