import React, { useState, useEffect } from "react";
import Icons from "../../assets/images/index";
import _ from 'underscore';
import { API_BASEURL } from "../../environment";
import { useDispatch, useSelector } from "react-redux";
import { project_details } from "./services/manufacturer";
import moment from "moment";
import ProjectDetails from "./common/ProjectDetails";
import ProjectBids from "./common/ProjectBids";
import ProjectDocuments from "./common/ProjectDocuments";

const ProjectView = () => {

  const dispatch = useDispatch();

  const [projectData, setProjectData] = useState()
  const [isBidList, setIsBidList] = useState(false)
  const [isProjectDoc, setIsProjectDoc] = useState(false)

  const generalData = useSelector((state) => state.general.param)

  useEffect(() => {
    project_details({ id: generalData }, (data) => { setProjectData(data) }, dispatch)
  }, [])

  return (
    <div className="dashboard-inner">
      <div className="project-view m-b-25">
        <span className="project-view-img">
          <img src={projectData?.media.length > 0 ? `${API_BASEURL}/${_.findWhere(projectData?.media, { type: "images" })?.path}` : (Icons.projectBigImg)} alt="" />
        </span>
        <div className="project-view-header">
          <div className="project-view-header-l">
            <h4 className="project-view-time p-l-0">{projectData?.project_title} {projectData?.user_id}</h4>
          </div>
        </div>
        <div className="project-view-body">
          <ul className="p-infos">
            <li>
              <div className="p-info">
                <span>
                  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 5.88867C11 9.77756 6 13.1109 6 13.1109C6 13.1109 1 9.77756 1 5.88867C1 4.56259 1.52678 3.29082 2.46447 2.35314C3.40215 1.41546 4.67392 0.888672 6 0.888672C7.32608 0.888672 8.59785 1.41546 9.53553 2.35314C10.4732 3.29082 11 4.56259 11 5.88867Z" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M6.00016 7.55501C6.92064 7.55501 7.66683 6.80882 7.66683 5.88835C7.66683 4.96787 6.92064 4.22168 6.00016 4.22168C5.07969 4.22168 4.3335 4.96787 4.3335 5.88835C4.3335 6.80882 5.07969 7.55501 6.00016 7.55501Z" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <p>{projectData?.buildingId?.country} ,{projectData?.buildingId?.state}</p>
              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.3889 2.11133H2.61111C1.99746 2.11133 1.5 2.60879 1.5 3.22244V11.0002C1.5 11.6139 1.99746 12.1113 2.61111 12.1113H10.3889C11.0025 12.1113 11.5 11.6139 11.5 11.0002V3.22244C11.5 2.60879 11.0025 2.11133 10.3889 2.11133Z" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.72217 1V3.22222" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M4.27783 1V3.22222" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M1.5 5.44434H11.5" stroke="#27316F" strokeWidth="1.11111" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <p>{moment(projectData?.createdAt).format('DD/MM/YYYY')}</p>
              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="5" cy="5" r="3.75" stroke="#27316F" strokeWidth="2.5" />
                  </svg>
                </span>
                <p>{projectData?.status === true ? "Active" : "Inactive"}</p>
              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.5 1V13" stroke="#27316F" strokeWidth="1.09091" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M7.5 3H3.40909C2.90277 3 2.41718 3.20114 2.05916 3.55916C1.70114 3.91718 1.5 4.40277 1.5 4.90909C1.5 5.41541 1.70114 5.901 2.05916 6.25902C2.41718 6.61705 2.90277 6.81818 3.40909 6.81818H6.13636C6.64269 6.81818 7.12827 7.01932 7.4863 7.37734C7.84432 7.73537 8.04545 8.22095 8.04545 8.72727C8.04545 9.2336 7.84432 9.71918 7.4863 10.0772C7.12827 10.4352 6.64269 10.6364 6.13636 10.6364H1.5" stroke="#27316F" strokeWidth="1.09091" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
                <p>$5599 USD</p>
              </div>
            </li>
            <li>
              <div className="p-info">
                <span>
                  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="9" height="12" stroke="#27316F" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.7143 3.28571H6.28571V9.71429H12.7143V3.28571ZM5 2V11H14V2H5Z" fill="white" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 4H7V9H12V4ZM6 3V10H13V3H6Z" fill="#27316F" />
                    <rect x="7" y="4" width="5" height="5" fill="white" />
                  </svg>
                </span>
                <p>Approximately {projectData?.square_footage} square feet</p>
              </div>
            </li>
          </ul>
          <ul className="project-info-sub">
            <li>
              <h6>{projectData?.ownerId?.first_name}</h6>
              <h5>{projectData?.ownerId?.company_name}</h5>
            </li>
            <li>
              <h6>Email Address</h6>
              <h5>{projectData?.ownerId?.email}</h5>
            </li>
            <li>
              <h6>{projectData?.ownerId?.country}</h6>
              <h5>{projectData?.ownerId?.state}</h5>
            </li>
          </ul>
        </div>
      </div>
      <ul className='head-tabs'>
        <li>
          <button className={isBidList ? "tab-item active" : "tab-item"} onClick={() => { setIsBidList(true); setIsProjectDoc(false); }}>View Project Bid</button>
        </li>
        <li>
          <button className={isProjectDoc ? "tab-item active" : "tab-item"} onClick={() => { setIsBidList(false); setIsProjectDoc(true) }}>View Documents</button>
        </li>
      </ul>
      {isBidList ?
        <ProjectBids
          projectData={projectData} onClose={() => { setIsBidList(false); setIsProjectDoc(false) }} />
        : isProjectDoc ?
          <ProjectDocuments
            projectDetails={projectData} onClose={() => { setIsBidList(false); setIsProjectDoc(false) }} />
          : <ProjectDetails projectData={projectData} />}
    </div>
  );
}

export default ProjectView;