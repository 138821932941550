import React, { useEffect, useState } from "react";
import BarChartSample from "../graphs/BarChart";
import LineChartSample from "../graphs/LineChart";
import PieChartSample from "../graphs/PieChart";
import SquarefootBarChartSample from "../graphs/Squarefootbarchart";
import SquareFootLineChartSample from "../graphs/Squarefootlinechart";
import SquareFootPieChartSample from "../graphs/Squarefootpiechart";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import MySelect from "./MySelect";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import SelectBox from "../../components/common/formInput/SelectBox";
import ApplicationMenuService from "../../services/application.menu";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../assets/images";
import Modal from "react-bootstrap/Modal";
import makeAnimated from "react-select/animated";
import { Get_Countries, Get_State } from "../../services/owner";
import { listRpc, DeleteRpc } from "../../services/rpc";
import {
  pagination,
  PaginationOption,
} from "../../components/common/utils/message";

import {
  addProjects,
  project_details,
  updateProject,
  viewOneProject,
  allGraph,
} from "../../services/project";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();
const GraphReportPage = () => {
  const location = useLocation();

  const [result, setResult] = useState();

  const [resPayload, setResPayload] = useState();
  const [resStates, setResStates] = useState();
  const dispatch = useDispatch();
  const [squareFooton, setSquareFooton] = useState(false);
  const [avgsquareFooton, setavgSquareFooton] = useState(false);
  const [dropdownValue, setDropdownValue] = useState([]);
  // const [options, setOptions] = useState([]);
  const [allowedBids, setAllowedbids] = useState([]);
  // const [optionList, setoptionList] = useState([]);

  // const [applicationList, setApplicationlist] = useState([]);
  const project_id = queryString.parse(location.search);
  const [selectedManufactures, setSelectedManufactures] = useState([]);
  const [select, setSelect] = useState();
  const [select1, setSelect1] = useState();
  const [arrSet, setArrSet] = useState([]);

  const [drop, setDrop] = useState([]);
  const [multi, setMulti] = useState([]);
  const totalsqurefoot = () => {
    setavgSquareFooton(false);
    setSquareFooton(true);
  };
  const average = () => {
    setavgSquareFooton(true);
    setSquareFooton(false);
  };

  // console.log("resultttttttt", applicationList);
  const [openApplicationMenuForm, setOpenApplicationMenuForm] = useState(false);
  const [applicationMenu, setApplcationMenu] = useState([]);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const [buildingDetails, setBuildingDetails] = useState();

  const openApplicationMenu = () => {
    const updatedApplicationMenu = [...applicationMenu];
    const selectedIds = selectedMenus.map((e) => e._id);
    updatedApplicationMenu.forEach((m) => {
      if (selectedIds.includes(m._id)) {
        m.checked = true;
      } else {
        m.checked = false;
      }
    });

    setOpenApplicationMenuForm(true);
  };

  const textTypeHandler = (e, menuId) => {
    const udpatedSelectedMenus = [...selectedMenus];
    const menuIndex = udpatedSelectedMenus.findIndex((e) => e._id === menuId);
    udpatedSelectedMenus[menuIndex].selectedOptions[0] = e.target.value;
    console.log(udpatedSelectedMenus,"udpatedSelectedMenus inside textTypeHandler")
    setSelectedMenus(udpatedSelectedMenus);
  };
  const deleteApplicationMenu = (menuId) => {
    
    let indexOf = 0;

    const udpatedSelectedMenus = [...selectedMenus];
    console.log(udpatedSelectedMenus, "udpatedSelectedMenus");
    const menuIndex = udpatedSelectedMenus.findIndex((e) => e._id === menuId);
    udpatedSelectedMenus.splice(menuIndex, 1);
    console.log(udpatedSelectedMenus, "afterudpatedSelectedMenus");
    setSelectedMenus(udpatedSelectedMenus);
    console.log(arrSet, "arrsettesting");
    const newArrSet = arrSet;
    for (let i = 0; i < newArrSet.length; i++) {
      if (menuId == newArrSet[i]) {
        console.log("remove", arrSet[i]);
        console.log("indeex==>", i);
        newArrSet.splice(i, 1);
        // arrSet.remove(menuId)
      }
    }

    console.log(newArrSet, "after arrSet");
    setArrSet(newArrSet);
    //     if (indexOf > -1) {
    //       arrSet.splice(indexOf, 1);
    //     }

    // console.log(arrSet,"after arrset")
  };

  const rangeTypeHandler = (e, menuId) => {
    const udpatedSelectedMenus = [...selectedMenus];
    const menuIndex = udpatedSelectedMenus.findIndex((e) => e._id === menuId);
    udpatedSelectedMenus[menuIndex].selectedOptions[0] = +e.target.value;
    console.log(udpatedSelectedMenus,"udpatedSelectedMenus inside rangeTypeHandler")
    setSelectedMenus(udpatedSelectedMenus);
  };

  const [booleanType, setBooleanType] = useState([]);
  const booleanTypeHandler = (e, menuId, value) => {
    console.log("menuId", menuId, value, booleanType.length);
    if (booleanType.length === 0) {
      booleanType.push(value);
    } else {
      console.log("elseleseleselese");
      let result = booleanType.filter((element) => element.includes(value));
      console.log("resultresult", result.length);
      if (result.length != 0) {
        booleanType.map((ele, index) => {
          if (ele === value) {
            booleanType.splice(index, 1);
          }
        });
      } else {
        booleanType.push(value);
      }
    }

    console.log("KING=====>", booleanType);
  };
  if (buildingDetails != undefined) {
    console.log("buildingDetails", buildingDetails.building_type);
  }

  // const [multi, setMulti] = useState([]);

  const dropdownTypeHandler = (selected, id) => {
    console.log("selected", selected);
console.log(arrSet,"arrset inside dropdownTypeHandler")
    arrSet.map((elem, index) => {
      if (elem === id) {
        let array = [];
        selected.map((data) => {
          array.push(data.value);
        });
        multi[index] = array;
      }
    });

    console.log("multimultimultimulti", multi);
  };

  const multidropdownTypeHandler = (e, menuId) => {
    const udpatedSelectedMenus = [...selectedMenus];
    const menuIndex = udpatedSelectedMenus.findIndex((e) => e._id === menuId);
    const optionsIndex = udpatedSelectedMenus[
      menuIndex
    ].selectedOptions.findIndex((x) => x === e.target.value);
    if (optionsIndex < 0) {
      udpatedSelectedMenus[menuIndex].selectedOptions.push(e.target.value);
    } else {
      udpatedSelectedMenus[menuIndex].selectedOptions.splice(optionsIndex, 1);
    }
    console.log(udpatedSelectedMenus,"udpatedSelectedMenus inside multidropdownTypeHandler")
    
    setSelectedMenus(udpatedSelectedMenus);
  };
  const closeApplicationMenu = () => {
    const updatedApplicationMenu = [...applicationMenu];
    updatedApplicationMenu.forEach((m) => {
      m.checked = false;
    });
    setApplcationMenu(updatedApplicationMenu);
    setOpenApplicationMenuForm(false);
  };

  const selectMenu = (e) => {
    // let isPresent = arrSet.filter()

    const result = arrSet.filter((s) => s.includes(e));
    console.log(result.length, result);

    if (result.length === 0) {
      arrSet.push(e);
    } else {
      arrSet.map((item, index) => {
        if (item === e) {
          arrSet.splice(index, 1);
        }
      });
    }

    const updatedApplicationMenu = [...applicationMenu];

    updatedApplicationMenu.forEach((m) => {
      if (m._id === e) {
        if (m.checked) {
          m.checked = false;
        } else {
          m.checked = true;
        }
      }
    });

    setApplcationMenu(updatedApplicationMenu);
  };
  console.log("arr", arrSet);

  const _submitApplicationMenuForm = () => {
    const updatedSelectedMenu = applicationMenu.filter((e) => e.checked);
    const existedMenu = selectedMenus.map((e) => e._id);
    setSelectedMenus([
      ...selectedMenus,
      ...updatedSelectedMenu.filter((e) => !existedMenu.includes(e._id)),
    ]);
    closeApplicationMenu();
  };

  useEffect(() => {
    viewOneProject(
      project_id,
      (data) => {
        setResPayload(data);
        if (project_id.id) {
          setSelectedMenus(data.selectedMenuItems);
          setAllowedbids(data.allowedBid);
          setSelectedManufactures(data.manufacturers);
        }
      },
      dispatch
    );
    Get_State(
      { country: "United States" },
      (result) => {
        setResStates(result.data);
      },
      dispatch
    );
    getApplicationMenus();
  }, []);

  const getApplicationMenus = () => {
    ApplicationMenuService.getAllApplicationMenu((result) => {
      console.log("123", result);
      const withCheckMenus = result?.map((e) => {
        if (e.type === "dropdown" || e.type === "multi-dropdown") {
          const updatedOptions = e.options.map((opt) => {
            return {
              label: opt.key,
              value: opt.value,
            };
          });
          e.options = [...updatedOptions];
          e.selectedOptions = [];
        }
        if (e.type === "boolean") {
          e.options = [...e.options];
        }
        if (e.type === "text") {
          e.selectedOptions = [""];
        }
        if (e.type === "range") {
          e.selectedOptions = [0];
        }
        return { ...e, menuId: e._id, checked: false };
      });

      setApplcationMenu(withCheckMenus);
    }, dispatch);
  };

  const handleStateChange = (e) => {
    setSelect(e.target.value);
  };
  console.log("><><", select);

  const handleSelectChange = (e) => {
    setSelect1(e.target.value);
  };

  console.log("result123456", result);

  const [countries, setCountries] = useState();
  const [country, setCountry] = useState("United States");

  const [limit, setLimit] = useState(pagination.limit);
  const [page, setPage] = useState(pagination.page);
  const [sortBy, setSortBy] = useState();
  const [order, setOrder] = useState();
  const [searchByState, setSearchByState] = useState();
  const [states, setStates] = useState([]);
  const [name, setName] = useState("");
  const [approach, setApproach] = useState("tearoff");

  const handleApproachChange = (e) => {
    console.log("dropdown", e.target.value);
    setApproach(e.target.value);
  };
  console.log("approach", approach);

  const updateName = (event) => {
    console.log("eventtt", event.target.value);
    setName(event.target.value);
  };

  console.log(">>>name", name);
  useEffect(() => {
    //     Get_State({ country: "United States" }, result => {
    //         if (result.data) {
    //             const citiesDropDwownData = result?.data?.states?.map((item) => ({ value: item.name, label: item.name }));
    //             setStates(citiesDropDwownData);
    //         }
    //     }, dispatch)
    // }, []);
    _getListing();
    Get_Countries((res) => {
      setCountries(res.data);
    });
    _getState("United States");
  }, [dispatch]);

  const _getListing = () => {
    listRpc(
      { page, limit, sortBy, order },
      (data) => {
        setResPayload(data);
      },
      dispatch
    );
  };
  const _getState = (val) => {
    Get_State(
      { country: val },
      (result) => {
        setStates(result);
      },
      dispatch
    );
  };

  const onStateSearch = async (filterParam) => {
    setSearchByState(filterParam);
    await listRpc(
      { limit, sortBy, order, fields: "state", filter: filterParam },
      (response) => {
        setResPayload(response);
      },
      dispatch
    );
  };
  const onCountySearch = async (filterParam) => {
    setSearchByState(filterParam);
    await listRpc(
      { limit, sortBy, order, fields: "country", filter: filterParam },
      (response) => {
        setResPayload(response);
      },
      dispatch
    );
  };

  const handleShowgraph = (e) => {
    let array = [];

    multi.map((innerArray) => {
      innerArray.map((element) => {
        array.push(element);
      });
    });

    booleanType.map((boolean) => {
      array.push(boolean);
    });
    let reqData = {
      country: country,
      state: searchByState,
      city: name,
      selectedMenuItems: arrSet,
      selectedValues: array,
      approach_type: approach,
    };

    console.log("REQUEST DATA====>", reqData);

    try {
      allGraph(reqData, (result) => {
        setResult(result);
        console.log("suyesh", result);
        if (result.status === "SUCCESS" && result.code === 200) {
          // console.log(result.message,">>>>>>>");

          alert("sucess");
        } else if (result.status === "ERROR" && result.code === 403) {
          console.log(result.message, "errrr");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const [statusofchart, setstatusofchart] = useState();
  const handlegraphchange = (e) => {
    setstatusofchart(e.target.value);
  };

  const [averagetotal, setAveragetotal] = useState("total");
  const handleaveragechange = (e) => {
    setAveragetotal(e.target.value);
  };



/////////end//////////
  return (
    <div className="dashboard-inner graph-form p-b-0">
      <div className="d-card m-b-25">
        <div className="d-card-body p-0">
          <h6 className="chart-head" align="center">
            Graph Details
          </h6>

          <div className="d-card m-b-25">
            <div className="d-card-header">
              <h5 className="d-card-heading">Application Menu</h5>
              <button
                className="btn btn-grey m-r-15"
                type="button"
                onClick={openApplicationMenu}
              >
                Select Menus
              </button>
            </div>
            <div className="f-row alignTop">
              {Array.isArray(selectedMenus) &&
                selectedMenus.map((_menu) => {
                  console.log(_menu,"my menu")
                  return (
                    <>
                      {_menu.type === "text" && (
                        <div className="f-33 d-flex align-items-start mb-3">
                          <div className="high-low-value">
                            <p>{_menu.menu_title}</p>
                            <div className="h-l-dropdown">
                              {/* <input
                                type="text"
                                value={_menu.selectedOptions[0]}
                                name={_menu.key}
                                onInput={(e) =>
                                  textTypeHandler(e, _menu.menuId)
                                }
                              /> */}
                            </div>
                          </div>
                          <img
                            src={Icons.removeIcon}
                            className="icons-size icons-input-margin"
                            onClick={() => deleteApplicationMenu(_menu.menuId)}
                          />
                        </div>
                      )}
                      {_menu.type === "range" && (
                        <div className="f-33 d-flex align-items-start mb-3">
                          <div className="high-low-value">
                            <p>{_menu.menu_title}</p>
                            <div className="h-l-dropdown">
                              <div>
                                {/* <input
                                  type="number"
                                  value={_menu.selectedOptions[0]}
                                  name={_menu.key}
                                  min={_menu.min_value}
                                  max={_menu.max_value}
                                  onInput={(e) =>
                                    rangeTypeHandler(e, _menu.menuId)
                                  }
                                /> */}
                              </div>
                            </div>
                          </div>
                          <img
                            src={Icons.removeIcon}
                            className="icons-size icons-input-margin"
                            onClick={() => deleteApplicationMenu(_menu.menuId)}
                          />
                        </div>
                      )}
                      {_menu.type === "boolean" && (
                        <div className="f-33 d-flex align-items-start mb-3">
                          <div className="high-low-value">
                            <p>{_menu.menu_title}</p>
                            <div className="h-l-dropdown">
                              {_menu.options.map((opt) => {
                                return (
                                  <div>
                                    <input
                                      type="checkbox"
                                      name={_menu.key}
                                      onChange={(e) =>
                                        booleanTypeHandler(
                                          e,
                                          _menu.menuId,
                                          opt.value
                                        )
                                      }
                                    />{" "}
                                    {opt.value}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                          {/* <img
                            src={Icons.removeIcon}
                            className="icons-size icons-input-margin"
                            onClick={() => deleteApplicationMenu(_menu.menuId)}
                          /> */}
                        </div>
                      )}
                      {_menu.type === "dropdown" && (
                        <div className="f-33 d-flex align-items-start mb-3">
                          <ul className="dashboard-sub-filters sub-filters-select">
                            <li>
                              {_menu.options && (
                                <div className="sortby multiple-select-sortby">
                                  <span className="roof-text">
                                    {_menu.menu_title} :
                                  </span>
                                  <div className="nocolor-dropdown">
                                    {/* <select
                                      name={_menu.key}
                                      value={_menu.selectedOptions[0]}
                                      onChange={(e) =>
                                        dropdownTypeHandler(e, _menu.menuId)
                                      }
                                     
                                    >
                                      {_menu.options.map((opt) => {
                                        return (
                                          <option value={opt.value}>
                                            {opt.label}
                                          </option>
                                        );
                                      })}
                                    </select> */}
                                    <MySelect
                                      options={_menu.options}
                                      isMulti
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      components={{
                                        Option,
                                        MultiValue,
                                        animatedComponents,
                                      }}
                                      onChange={(e) =>
                                        dropdownTypeHandler(e, _menu.menuId)
                                      }
                                      // onChange={dropdownTypeHandler}
                                      allowSelectAll={true}
                                      value={_menu.selectedOptions[0]}
                                    />
                                  </div>
                                </div>
                              )}
                            </li>
                          </ul>
                          {/* <img
                            src={Icons.removeIcon}
                            className="icons-size icons-input-margin"
                            onClick={() => deleteApplicationMenu(_menu.menuId)}
                          /> */}
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>

          <div className="chart-detail">
            <div className="left">
              <ul>
                {/* <li>
                  <div className="label">Application Menu</div>

                  <div>
                    <select
                      className="form-control"
                      onChange={handleStateChange}
                    >
                      <option value="cost">Cost</option>
                      <option value="price">Price</option>
                    </select>
                  </div>
                </li> */}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div className="label">Country</div>

                  <SelectBox
                    Placeholder="Select Country"
                    label="Country"
                    options={countries}
                    val={country}
                    handleVal={(val) => {
                      setCountry(val);
                      _getState(val);
                      onCountySearch(val);
                    }}
                  />
                </li>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div>
                  <div className="label">State</div>

                  <SelectBox
                    Placeholder="Select State"
                    options={states?.data?.states?.map((item) => {
                      return item.name;
                    })}
                    val={searchByState}
                    handleVal={(e) => onStateSearch(e)}
                  />
                  </div>
                </li>

                &nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div className="label">City</div>

                  <input
                    type="text"
                    value={name}
                    className="form-control"
                    onChange={updateName}
                    placeholder="Please enter city"
                  />
                </li> &nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div>
                    <div className="label">Approach Type</div>
                    <select
                      className="form-control"
                      onChange={handleApproachChange}
                    >
                      <option value="tearoff">Tear Off</option>
                      <option value="overlay">Overlay</option>
                      <option value="coated">Coated</option>
                    </select>
                  </div>
                </li>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div>
                    <div className="label">Graph Type</div>
                    <select
                      className="form-control"
                      onChange={handlegraphchange}
                    >
                      <option value="bargraph" selected>
                        Bar Graph
                      </option>
                      <option value="linechart">Line Chart</option>
                      <option value="piechart">Pie Chart</option>
                    </select>
                  </div>
                </li>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;
                <li>
                  <div>
                    <div className="label">Size</div>
                    <select
                      className="form-control"
                      onChange={handleaveragechange}
                    >
                      <option value="total">Total</option>
                      <option value="average">Average</option>
                    </select>
                  </div>
                </li>
              </ul>
              <ul>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={handleShowgraph}
                >
                  Show Graph
                </button>
              </ul>
            </div>

            {/* <div className="right">
              <ul>
                <li>
                  <div class="form-check">
                    <div className="label">Size</div>
                    <input
                      type="radio"
                      class="form-check-input"
                      id="radio1"
                      name="optradio"
                      onClick={average}
                      value="average"
                    />
                    Average
                    <label class="form-check-label" for="radio1"></label>
                  </div>
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      id="radio2"
                      name="optradio"
                      onClick={totalsqurefoot}
                      value="total"
                    />
                    Total
                    <label class="form-check-label" for="radio2"></label>
                  </div>


                 

                </li>
              </ul>
            </div> */}
          </div>

          {averagetotal == "average" ? (
            <div className="chart-wrap">
              <h3 align="center"> Average Square Footage</h3>

              {statusofchart == "bargraph" ? (
                <BarChartSample data={result} />
              ) : (
                ""
              )}

              <br />
              <br />

              {statusofchart == "linechart" ? (
                <LineChartSample data={result} />
              ) : (
                ""
              )}

              <br />
              {statusofchart == "piechart" ? (
                <PieChartSample data={result} />
              ) : (
                ""
              )}

              <br />
            </div>
          ) : (
            ""
          )}

          {averagetotal == "total" ? (
            <div className="chart-wrap">
              <h3 align="center">Total Square Footage </h3>

              {statusofchart == "bargraph" ? (
                <SquarefootBarChartSample data={result} />
              ) : (
                ""
              )}

              {statusofchart == "linechart" ? (
                <SquareFootLineChartSample data={result} />
              ) : (
                ""
              )}
              {statusofchart == "piechart" ? (
                <SquareFootPieChartSample data={result} />
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {openApplicationMenuForm && (
        <Modal
          show={openApplicationMenuForm}
          onHide={closeApplicationMenu}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="contractor-wrapper">
              <h5 className="contractor-heading">Select Application Menu</h5>
              <div className="contractor-body">
                <ul className="contractor-list">
                  {applicationMenu &&
                    applicationMenu.map((_menu, index) => {
                      return (
                        <li>
                          <div className="contractor-check">
                            {/* <label className='checkbox nolabel' htmlFor={index}> */}
                            <label className="checkbox nolabel">
                              <input
                                type="checkbox"
                                id={index}
                                value={_menu.menuId}
                                // checked={_menu.checked}
                                onChange={(e) => selectMenu(_menu.menuId)}
                              />
                              <span></span>
                            </label>
                          </div>
                          <div className="contractor-details">
                            <div className="bid-details">
                              <h4>{`${_menu.menu_title}`}</h4>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ul className="modal-actions">
              <li>
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={_submitApplicationMenuForm}
                >
                  Submit
                </button>
              </li>
              <li>
                <button
                  className="btn btn-grey"
                  type="button"
                  onClick={closeApplicationMenu}
                >
                  Cancel
                </button>
              </li>
            </ul>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};
export default GraphReportPage;
