import React from 'react';
import { Link } from 'react-router-dom';

const CancelPage = () => {
    return <>
    <div className='dashboard-inner'>
    <div className='page-not-found'>
    <div className="card">
        <h2>Payment failed!</h2>
        <Link to={{ pathname: `/contractor/dashboard` }}><button className='btn btn-primary'>cancel</button></Link>
    </div>
</div>
    </div></>
}
export default CancelPage;