import React, { useRef, useCallback } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useState, useEffect } from "react";
import { allGraph } from "../../services/project";
import FileSaver from "file-saver";
import { useCurrentPng } from "recharts-to-png";

const LineChartSample = (props) => {
  const [graphData, setGraphData] = useState();
  const [alldata, setAlldata] = useState();
  let propsdata=props.data
  console.log("propsdata",propsdata);
  useEffect(() => {
   



      setAlldata(propsdata);
      let sqfoot = 0;
      let Avgsqfoot;
      var data = new Array();
      var arrs = new Array();
      var arrays = new Array();
      var obj = new Object();
      let totalAvg = [];
      let noOfPro = [];
      let noOfProject = 0;
      if(propsdata != undefined ){
        for (let k = 0; k < propsdata.length; k++) {
          for (
            let i = 0;
            i < propsdata[k]?.optionWiseProjectsData.length;
            i++
          ) {
            obj.title = propsdata[k]?.optionWiseProjectsData[i].title;
  
            for (
              let j = 0;
              j < propsdata[k]?.optionWiseProjectsData[i].projects.length;
              j++
            ) {
              sqfoot =
                sqfoot +
                propsdata[k]?.optionWiseProjectsData[i].projects[j]
                  .square_footage;
              totalAvg = Math.trunc(
                sqfoot /
                propsdata[k]?.optionWiseProjectsData[i].projects.length
              );
              noOfProject =
              propsdata[k]?.optionWiseProjectsData[i].projects.length;
            }
            console.log(noOfProject, "noOfProjects");
            obj.noOfProjections = noOfProject;
            Avgsqfoot = sqfoot / propsdata[k]?.optionWiseProjectsData.length;
  
            obj.squareFootage = sqfoot;
            arrs.push(obj.title);
  
            data.push(obj.squareFootage);
  
            obj.Averagesquarefoot = totalAvg;
  
            arrays.push(obj.Averagesquarefoot);
            noOfPro.push(obj.noOfProjections);
            console.log(noOfPro, "noOfProarry");
            sqfoot = 0;
            noOfProject = 0;
          }
        } 
      }else{
        alert("data not found")
      }
   


      let finalarr1 = [];
      for (let h = 0; h < arrs.length; h++) {
        let obj = new Object();
        obj.Title = arrs[h];
        obj.Averagesquarefoot = arrays[h];
        obj.NoOfProjects = noOfPro[h];
        finalarr1.push(obj);
        console.log(finalarr1, "finalarr1");
      }

      setGraphData(finalarr1);
  
  }, []);
  const menu_title = alldata?.menu_title;

  const [getPiePng, { ref: pieRef }] = useCurrentPng();
  const handlePieDownload = useCallback(async () => {
    const png = await getPiePng();
    if (png) {
      FileSaver.saveAs(png, "Line-Chart.png");
    }
  }, [getPiePng]);

  return (
    <div style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <LineChart
          ref={pieRef}
          width={500}
          height={300}
          data={graphData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="Title" />
          <YAxis dataKey="Averagesquarefoot" />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Averagesquarefoot"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="NoOfProjects" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <h5 align="center">{menu_title}</h5>
      <br />
      <button onClick={handlePieDownload}>
        <code>Download Line-Chart</code>
      </button>
    </div>
  );
};
export default LineChartSample;
