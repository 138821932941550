import React from "react";
import HeaderMenu from "../../../features/dashboard/HeaderMenu";
import LeftMenu from "../../../features/dashboard/LeftMenu";
import FooterMenu from "../../../features/dashboard/FooterMenu";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LoaderUI from "../loader/loader";
import { useNavigate } from 'react-router-dom';

const DefaultLayout = ({ children }) => {
  //Responsive menu toggle
  const [toggle, setToggle] = React.useState(false);

  const handleToggle = () => {
    setToggle(toggle);
  };

  const navigate = useNavigate();

  let loginUser = useSelector(state => state.login?.user?.data?.user)
  let logedinUser = useSelector(state => state.login?.isLoggedIn);

  if (loginUser.role === "OWNER"){
    logedinUser ? navigate('/owner-dashboard') : navigate('/login');
  }else if(loginUser.role === "MANUFACTURER"){
    // navigate('/manu-dashboard')
    logedinUser ? navigate('/manu-dashboard') : navigate('/login');
  }
  else if (loginUser.role === "CONTRACTOR"){
   // navigate('/contractor-dashboard')
    logedinUser ? navigate('/contractor/dashboard') : navigate('/login');
  }

  let isLoading = useSelector(state => state.loader.isLoading);
  return (
    <div className={`${toggle ? "active-sidebar" : "test"} dashboard`}>
      <HeaderMenu />
      <ToastContainer />
      <div className="dashboard-body">
        <LeftMenu />
        <div className="dashboard-main">
          {isLoading && <LoaderUI
            loader={isLoading}
            overlay={true}
            overlayRadius='rounded-10'
            FullWindow={true}
            color="danger"
            animation="border"
            customSize="lg"
          />}
          {children}
        </div>
      </div>
      <FooterMenu />
      <span className="overlay" onClick={handleToggle}></span>
    </div>
  );
};

export default DefaultLayout;
