import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from "formik";
import { failed, success } from "../../components/common/Toastify";
import { useDispatch } from "react-redux";
import { registration } from "../../services/auth.service";
import TextField from "../../components/common/formInput/TextField";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import { Get_State, Get_Countries } from "../../services/owner";
import { _roles } from "../../components/common/utils/message";
import { registerSchema } from '../../components/common/validations/Validation';
import { DragDropField } from '../../components/common/formInput/DragDropField';
import { UploadLogoField } from '../../components/common/formInput/UploadLogoField';

const Registration = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [resStates, setResStates] = useState();
    const [resCountries, setResCountries] = useState();

    useEffect(() => {
        Get_Countries(result => { setResCountries(result.data) }, dispatch);
    }, []);

    let countryData = resCountries?.map((item) => ({ value: item.name, label: item.name }));
    let stateData = resStates?.data?.states?.map((item) => ({ value: item.name, label: item.name }));

    const initialData = {
        first_name: "",
        last_name: "",
        mobile_no: "",
        phone_no: "",
        toll_free_no: "",
        email: "",
        city: "",
        state: "",
        country: "United States",
        zip_code: "",
        company_name: "",
        status: "",
        address1: "",
        address2: "",
        company_logo: "",
        role: "",
    };

    /** 
    * @description Function is for registration.
    * @param {object} data 
    */
    const _submitData = (data) => {
        let form = new FormData();
        form.append('role', data.role)
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('phone_no', data.phone_no)
        form.append('toll_free_no', data.toll_free_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        try {
            registration(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/login")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };
    
    // Function for search alphabetically......
    const customFilter = (option, searchText) => option?.data?.label?.toLowerCase().startsWith(searchText.toLowerCase()) 

    return (
        <>
            <section className='registration-section'>
                <div className="container">
                    <Formik
                        initialValues={initialData}
                        enableReinitialize
                        validationSchema={registerSchema}
                        onSubmit={(values) => { _submitData(values) }}
                    >
                        {(formik) => (
                            <Form>
                                <div className='d-card bordered m-b-25'>
                                    <div className='d-card-header'>
                                        <h5 className='d-card-heading d-card-sub-heading'>New Registration</h5>
                                        <p className='d-card-desc m-t-15'>Please fill out the form below so we can create the account.</p>
                                    </div>
                                    <div className='d-card-body p-b-0'>
                                        <div className='form-content'>
                                            <div className='f-row alignTop'>
                                                <div className='f-33'>
                                                    <SearchSelectBox
                                                        label="Customer Type"
                                                        placeholder="choose one"
                                                        name="role"
                                                        value={formik?.values?.role}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                        options={_roles}
                                                    />
                                                </div>
                                                <div className='f-33'>
                                                    <TextField
                                                        label="First Name"
                                                        placeholder="First Name"
                                                        name="first_name"
                                                        value={formik?.values?.first_name}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className='f-33'>
                                                    <TextField
                                                        label="Last Name"
                                                        placeholder="Last Name"
                                                        name="last_name"
                                                        value={formik?.values?.last_name}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='f-row alignTop'>
                                                <div className='f-33'>
                                                    <TextField
                                                        label="Email Address"
                                                        placeholder="Email Address"
                                                        name="email"
                                                        value={formik?.values?.email}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className='f-33'>
                                                    <TextField
                                                        label="Mobile Number"
                                                        placeholder="Moblie Number"
                                                        name="mobile_no"
                                                        value={formik?.values?.mobile_no}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className='f-33'>
                                                    <TextField
                                                        label="Company Name"
                                                        placeholder="Company Name"
                                                        name="company_name"
                                                        value={formik?.values?.company_name}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='f-row alignTop'>
                                                <div className="f-33">
                                                    <SearchSelectBox
                                                        label="Country"
                                                        placeholder="Select Country"
                                                        name="country"
                                                        value={formik?.values?.country}
                                                        onChange={formik.handleChange}
                                                        changeHandle={(val) => {
                                                            Get_State({ country: val }, result => {
                                                                setResStates(result);
                                                            }, dispatch)
                                                        }}
                                                        required={true}
                                                        options={countryData}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                                <div className="f-33">
                                                    <SearchSelectBox
                                                        label="State"
                                                        placeholder="Select States"
                                                        name="state"
                                                        value={formik?.values?.state}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                        options={stateData}
                                                        filterOption={customFilter}
                                                    />
                                                </div>
                                                <div className="f-33">
                                                    <TextField
                                                        label="Address Line 1"
                                                        name="address1"
                                                        placeholder="Address Line 1"
                                                        value={formik?.values?.address1}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='f-row alignTop'>
                                                <div className="f-33">
                                                    <TextField
                                                        label="Address Line 2"
                                                        name="address2"
                                                        placeholder="Address Line 2"
                                                        value={formik?.values?.address2}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="f-33">
                                                    <TextField
                                                        label="City"
                                                        name="city"
                                                        placeholder="City"
                                                        value={formik?.values?.city}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="f-33">
                                                    <TextField
                                                        label="Zip Code"
                                                        name="zip_code"
                                                        placeholder="Zip Code"
                                                        value={formik?.values?.zip_code}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className='f-row alignTop'>
                                                <div className="f-33">
                                                    <UploadLogoField
                                                        label="Upload Logo"
                                                        name="company_logo"
                                                        id="file005"
                                                        isrequired={true}
                                                    />
                                                </div>
                                                <div className="f-33">
                                                    <TextField
                                                        label="Phone Number"
                                                        placeholder="Phone Number"
                                                        name="phone_no"
                                                        value={formik?.values?.phone_no}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                                <div className="f-33">
                                                    <TextField
                                                        label="Toll Free Number"
                                                        placeholder="Toll Free Number"
                                                        name="toll_free_no"
                                                        value={formik?.values?.toll_free_no}
                                                        onChange={formik.handleChange}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className='f-row'>
                                        <div className='f-33'>
                                            <label className='f-label'>How did you hear about us?</label>
                                            <div className='f-field'>
                                                <div className='grey-dropdown'>
                                                    <select>
                                                        <option>Choose One</option>
                                                        <option>Choose One</option>
                                                        <option>Choose One</option>
                                                        <option>Choose One</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='f-66'>
                                            <label className='f-label'>Please select the states you would like to be alerted on when projects become available.</label>
                                            <div className='f-field'>
                                                <div className='grey-dropdown'>
                                                    <select>
                                                        <option>Select States</option>
                                                        <option>Select States</option>
                                                        <option>Select States</option>
                                                        <option>Select States</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                            {/* <div className='f-row'>
                                        <div className='f-33'>
                                            <label className='f-label'>We are willing to travel within</label>
                                            <div className='f-field'>
                                                <input className='f-input' placeholder='We are willing to travel within' />
                                            </div>
                                        </div>
                                        <div className='f-66'>
                                            <label className='f-label'>Do you want to receive notification when opportunities become available?</label>
                                            <div className='f-field'>
                                                <label className='checkbox radio-checkbox m-r-15' htmlFor="bidder01">
                                                    <input type="radio" id="bidder01" name='valuesOfNotification' value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="bidder02">
                                                    <input type="radio" id="bidder02" name='valuesOfNotification' value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-card-bottom">
                                    <div className="d-card-bottom-cell">
                                        <button className="btn btn-blue" type="submit">Register Now</button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </section>
        </>
    )
}

export default Registration;