import React from "react";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
import { project_details } from "../services/contractor";
import { useDispatch } from "react-redux";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";

const ProjectDescription = () => {
    const [projectData, setProjectData] = useState();
    const dispatch = useDispatch();
  let project_id = useParams();
  
  useEffect(() => {
    project_details(project_id, (data) => {
      setProjectData(data);
    })
  }, [])

    return( 
        <>
        <div className='dashboard-inner'>
       
    <ContractorProjectDescription/>

    <h2>Project Description </h2>
    {
    <p className="desc-info-heading" dangerouslySetInnerHTML={{ __html: projectData?.description }} />
    
    }
    </div>
   
    </>
    
    )
}
export default ProjectDescription;