import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPageBySlug } from '../../../services/cms.service';

const OwnerTermsAndConditions = () => {

    const dispatch = useDispatch();
    const location = useLocation()
    
    const [pageData, setPageData] = useState(null)
    
    useEffect(() => {
        getPageBySlug(location.pathname, (data) => {
          setPageData(data)
        }, dispatch)
    }, [])

    return (
        <div className='d-card'>
            {pageData && <>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>{pageData.page_title}</h5>
                </div>
                <div className='d-card-body'>
                    <div className='terms-c'>
                        <div className="terms-item">
                            <div dangerouslySetInnerHTML={{ __html: pageData.page_content }} />
                        </div>
                    </div>
                </div>
            </>}
        </div>
    )
}

export default OwnerTermsAndConditions;