import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion'
import { useDispatch } from 'react-redux';
import { list_Faqs } from '../services/manufacturer';

const Faq = () => {

    const dispatch = useDispatch();
    const [resFaq, setResFaq] = useState();

    useEffect(() => {
        list_Faqs({}, result => { setResFaq(result) }, dispatch);
    }, []);

    return (
        <div className='d-card'>
            <div className='d-card-header'>
                <h5 className='d-card-heading d-card-sub-heading'>FAQ’s</h5>
            </div>
            <div className='d-card-body'>
                <div className='accordians dark-accordians'>
                    <Accordion>
                        {resFaq?.docs?.map((item, inx) => (
                            <Accordion.Item eventKey={inx}>
                                <Accordion.Header>Q{inx + 1}. {item.faq_question}</Accordion.Header>
                                <Accordion.Body>
                                    <p className='terms-desc' dangerouslySetInnerHTML={{ __html: item.faq_answer }} />
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default Faq;