import heroBg from "../images/hero-bg.jpg";
import overallBg from "../images/overall-bg.png";
import service001 from "../images/service001.svg";
import service002 from "../images/service002.svg";
import service003 from "../images/service003.svg";
import arrow from "../images/arrow.svg";
import logo from "../images/logo.svg";
import footerLogo from "../images/footer-logo.svg";
import phone from "../images/phone.svg";
import email from "../images/email.svg";
import gallery001 from "../images/gallery001.jpg";
import gallery002 from "../images/gallery002.jpg";
import gallery003 from "../images/gallery003.jpg";
import gallery004 from "../images/gallery004.jpg";
import gallery005 from "../images/gallery005.jpg";
import graph from "../images/dashboard/graph.svg";
import hammer from "../images/dashboard/hammer.svg";
import map01 from "../images/dashboard/map01.png";
import map02 from "../images/dashboard/map02.png";
import map03 from "../images/dashboard/map03.png";
import map04 from "../images/dashboard/map04.png";
import notification from "../images/dashboard/notification.svg";
import profile from "../images/dashboard/profile.png";
import projectImg from "../images/dashboard/project-img.png";
import rocket from "../images/dashboard/rocket.svg";
import search from "../images/dashboard/search.svg";
import user from "../images/dashboard/user.svg";
import dlogo from "../images/logo.svg";
import projectBigImg from "../images/dashboard/project-big-img.png";
import pdfImage from "../images/dashboard/pdf.png";
import location from "../images/dashboard/location-icon.svg";
import timeIcon from "../images/dashboard/time-icon.svg";
import calIcon from "../images/dashboard/cal-icon.svg";
import file from "../images/dashboard/file-icon.svg";
import eye from "../images/dashboard/eye-icon.svg";
import edit from "../images/dashboard/edit-icon.svg";
import bin from "../images/dashboard/bin-icon.svg";
import backArrow from "../images/dashboard/back-arrow.svg";
import nextArrow from "../images/dashboard/next-arrow.svg";
import Owner from '../images/dashboard/owner.png';
import eyeFill from '../images/dashboard/eye-icon-filled.svg';
import gal001 from '../images/dashboard/gal001.png';
import pdf from '../images/dashboard/pdf.svg';
import Dots from '../images/dashboard/dots.svg';
import Download from '../images/dashboard/download.svg';
import Document from '../images/dashboard/document.svg';
import btnPlay from '../images/dashboard/btn-play.svg';
import btnPdf from '../images/dashboard/btn-pdf.svg';
import building from '../images/dashboard/building.png';
import libraryBg from '../images/dashboard/library-bg.jpg';
import buildingBg from '../images/dashboard/building-bg.jpg';
import roofingBg from '../images/dashboard/roofing-bg.jpg';
import invoice from '../images/dashboard/invoice.jpg';
import password from '../images/dashboard/password.svg';
import Frame2 from '../images/Frame2.jpg';
import Frame3 from '../images/Frame3.jpg';
import Frame4 from '../images/Frame4.jpg';
import overall1 from '../images/overall1.jpg';
import overall2 from '../images/overall2.jpg';
import overall3 from '../images/overall3.jpg';
import overall4 from '../images/overall4.jpeg';
import overall5 from '../images/overall5.jpg';
import overall6 from '../images/overall6.jpeg';
import overall7 from '../images/overall7.JPG';
import overall8 from '../images/overall8.JPG';
import Overall from '../images/Overall.jpg';
import removeIcon from '../images/remove-icon.png';
import minusButton from '../images/minus-button.png';
import pdfIcon from '../images/pdf.png';
import cloneIcon from '../images/clone.png';
import activationIcon from "../images/activation.png";
import bidExpiredIcon from "../images/bid-expired.png";
import changedIcon from "../images/changed.png";
import closeIcon from "../images/close.png";
import submitIcon from "../images/submit.png";


const toggleSubMenu = (id, elem) => {
  // console.log(id)

  // console.log(document.getElementById(id),'document.getElementById(id)')
  // remove debugger 
  var element = document.getElementById(id);
  let isActive = element.classList.contains("active");
  document.querySelectorAll(".sidebar-dropdown").forEach(n => n.classList.remove('active'))
  if (!isActive) {
    element.classList.add("active");

    // elem.target.closest(".sidebar-dropdown").classList.add("active");
  } else {
    // elem.target.closest(".sidebar-dropdown").classList.add("active");
    element.classList.remove("active");
  }
};

const toggleChild = (id) => {
  // console.log(id,'sdf')
  var element = document.getElementById(id);
  element.classList.remove("active");
  // console.log(element,'element.classList.add("active")')
  // document.querySelectorAll(".sidebar-item").forEach(n => n.classList.remove('activeChild'))
  // if (!elem.target.closest(".sidebar-item").classList.contains("activeChild")) {
  // elem.target.closest(".sidebar-item").classList.add("activeChild");
  // } else {
  // document.querySelectorAll(".sidebar-item").forEach(n => n.classList.remove('activeChild'))
  // }
}

// const handleToggle = (e) => {
//   console.log('sidebar add class', e)
//   var x = document.getElementsByClassName("dashboard");
//   if (x.className === "dashboard") {
//     x.className += " show";
//     console.log('sidebar add class', e)
//   } else {
//     x.className = "dashboard";
//   }
// }

const Icons = {
  toggleSubMenu,
  toggleChild,
  // handleToggle,
  heroBg,
  libraryBg,
  buildingBg,
  roofingBg,
  overallBg,
  service001,
  service002,
  service003,
  arrow,
  logo,
  footerLogo,
  phone,
  email,
  password,
  gallery001,
  gallery002,
  gallery003,
  gallery004,
  gallery005,
  graph,
  hammer,
  map01,
  map02,
  map03,
  map04,
  notification,
  profile,
  projectImg,
  rocket,
  search,
  user,
  dlogo,
  projectBigImg,
  location,
  timeIcon,
  calIcon,
  file,
  eye,
  edit,
  bin,
  backArrow,
  nextArrow,
  // Owner,
  eyeFill,
  gal001,
  pdf,
  Dots,
  Download,
  Document,
  btnPlay,
  btnPdf,
  building,
  pdfImage,
  invoice,
  Frame2,
  Frame3,
  Frame4,
  Overall,
  overall1,
  overall2,
  overall3,
  overall4,
  overall5,
  overall6,
  overall7,
  overall8,
  removeIcon,
  minusButton,
  pdfIcon,
  cloneIcon,
  activationIcon,
  bidExpiredIcon,
  changedIcon,
  closeIcon,
  submitIcon
};

export default Icons;
