import axios from "axios";
import { apiUrl, PORT } from "../../../environment";
import queryString from 'query-string';
import { loadingAction } from "../../../components/common/loader/LoaderSlice";
import { identity } from "underscore";

/**
 * @description list for owner's Project data.
 * @param {*} param 
 * @param {*} callback 
 */
export const list_Projects = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/rpc/building/get/all?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const bidden_Projects = async (callback, dispatch) => {

    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/contractor/project/myBiddenProjects`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};


export const transactionDetails = async (data, callback, dispatch) => {

    console.log("REQUEST DATA GOT PAY DETIALS====>",data)

    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/contractor/payment/receipt/details`, data, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};



export const wonProject = async (param, callback, dispatch) => {

    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/contractor/project/getWonProjects?${queryString.stringify(param)}`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                    console.log("response.data.data -----------------", response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contStripe = async (id, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.post(`${apiUrl}${PORT}/contractor/payment`, id, option)


                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);

                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
/**
* @description list for owner's project details.
* @param {*} param 
* @param {*} callback 
*/
// export const project_details = async (param, callback, dispatch) => {
//     try {
//         let token = localStorage.getItem("authentication");
//         if (token) {
//             let option = {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             };
//             dispatch(loadingAction(true))

//             axios.get(`${apiUrl}${PORT}/contractor/project/details?${queryString.stringify(param)}`, option)
//                 .then((response) => {
//                     dispatch(loadingAction(false))
//                     callback(response.data.data);
//                     console.log(response.data.data)
//                 })
//                 .catch((error) => {
//                     dispatch(loadingAction(false))
//                     callback(false);
//                 });
//         }
//     } catch (err) {
//         console.log(err);
//     }
// };

export const project_details = async (param, callback) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/details?${queryString.stringify(param)}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)
            }).catch((error) => {
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const contMediaImages = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                // callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contRoofPhotos = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contRoofDrawings = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};

export const contDetailDrawings = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contInsurance = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contVideos = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contStandardContract = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contSpecifications = async (param, type, callback) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem('authentication')


        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            axios.get(`${apiUrl}${PORT}/contractor/project/media?${queryString.stringify(param)}&type=${type}`, option).then((response) => {
                callback(response.data.data)
                // console.log(response.data.data)

            }).catch((error) => {
                callback(false)
                console.log(error)
            });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contlistEstimator = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            // axios.get(`${apiUrl}${PORT}/contractor/project/estimatorAssistant?${queryString.stringify(param)}`, option)
            axios.get(`${apiUrl}${PORT}/contractor/project/estimatorAssistant?id=${param}`, option)
                // http://54.190.192.105:9121/api/v1/contractor/project/estimatorAssistant?id=6368a56af3f6979ca885482c
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
export const contProjectNotes = async (param, callback, dispatch) => {
    try {
        console.log(queryString.stringify(param))
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            // axios.get(`${apiUrl}${PORT}/contractor/project/notes?${queryString.stringify(param)}`, option)
            axios.get(`${apiUrl}${PORT}/contractor/project/notes?id=${param}`, option)




                // http://54.190.192.105:9121/api/v1/contractor/project/notes?id=6368a56af3f6979ca885482c
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data)
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    // callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
/**
* @description  owner's Profile view.
* @param {*} param 
* @param {*} callback 
*/
export const profile_view = async (callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/user/profile/view`, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};


/**
* @description  owner's Profile view.
* @param {*} param 
* @param {*} callback 
*/
export const profile_edit = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.put(`${apiUrl}${PORT}/user/profile/update`, param, option)
                .then((response) => {
                    dispatch(loadingAction(false))
                    callback(response.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};

export const project_bids = async (id, callback, dispatch) => {
    try {
        let token = localStorage.getItem('authentication')
        if (token) {
            let option = {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
            dispatch(loadingAction(false))
            axios.post(`${apiUrl}${PORT}/rpc/bids/view/all`, id, option).then((response) => {
                dispatch(loadingAction(false))
                callback(response.data.data)
            }).catch((error) => {
                dispatch(loadingAction(false))
                callback(false)
            });
        }
    } catch (err) {
        console.log(err);
    }
};

/**
 * @description list for owner's Project data.
 * @param {*} param 
 * @param {*} callback 
 */
export const list_Faqs = async (param, callback, dispatch) => {
    try {
        let token = localStorage.getItem("authentication");
        if (token) {
            let option = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            dispatch(loadingAction(true))
            axios.get(`${apiUrl}${PORT}/admin/faq/view/all?${queryString.stringify(param)}`, option)
                .then((response) => {

                    dispatch(loadingAction(false))
                    callback(response.data.data);
                })
                .catch((error) => {
                    dispatch(loadingAction(false))
                    callback(false);
                });
        }
    } catch (err) {
        console.log(err);
    }
};
