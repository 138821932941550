import React from "react";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
const ViewChangeBid = () => {
    return <div className='dashboard-inner'>
    <ContractorProjectDescription/>
    <h5 className="d-flex align-items-center justify-content-center">No data  found!</h5>
    </div>
}
export default ViewChangeBid

