import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import CategoryList from '../CategoryList';
import SubCategoryList from "../SubCategoryList";
const CategoryManagementRoutes = [
    {
        path: `/categories`,
        layout: DefaultLayout,
        component: < CategoryList />
    },
    {
        path: `/sub-categories/:category_id`,
        layout: DefaultLayout,
        component: <  SubCategoryList />
    }

]

export default CategoryManagementRoutes;