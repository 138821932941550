import React from 'react';
import HomeLayout from '../../../components/common/layout/HomeLayout';
import AboutUs from '../AboutUs';
import BuildingOwners from '../BuildingOwners';
import LibraryOfTerms from '../LibraryOfTerms';
import MainHome from '../MainHome';
import MaterialManufacturers from '../MaterialManufacturers';
import RoofingContractors from '../RoofingContractors';
import Faq from '../Faq';
const HomePagesRoutes = [
    {
        path: `/`,
        layout: HomeLayout,
        component: <  MainHome />
    },
    {
        path: `/home`,
        layout: HomeLayout,
        component: <  MainHome />
    },
    {
        path: `/building-owners`,
        layout: HomeLayout,
        component: <  BuildingOwners />
    },
    {
        path: `/library-of-terms`,
        layout: HomeLayout,
        component: <  LibraryOfTerms />
    },
    {
        path: `/material-manufacturers`,
        layout: HomeLayout,
        component: <  MaterialManufacturers />
    },
    {
        path: `/roofing-contractors`,
        layout: HomeLayout,
        component: <  RoofingContractors />
    },
    {
        path: `/about-us`,
        layout: HomeLayout,
        component: <  AboutUs />
    },
    {
        path: `/faq`,
        layout: HomeLayout,
        component: < Faq />
    }
]

export default HomePagesRoutes;