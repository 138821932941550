import React from "react";
import ContractorProjectDescription from "../common/ContractorProjectDescription";
import _ from 'underscore';
import { API_BASEURL } from "../../../environment";
import Modal from 'react-bootstrap/Modal'
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Icons from "../../../assets/images";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import { useParams } from "react-router-dom";
import { contStandardContract } from "../services/contractor";

const Contract = () => {
    const [modalShow, setModalShow] = useState(false);
    const [modalShow1, setModalShow1] = useState(false);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [projectData, setProjectData] = useState();
    let project_id = useParams();
    let type;

    useEffect(() => {
        contStandardContract(project_id,type="standard_contract",(data) => {
    
            let newData = JSON.parse(JSON.stringify(data))
            setProjectData(newData);
          
        })
        
      }, [])
    return( 
        <>
        <div className='dashboard-inner'>
        <h2>Contract</h2>
    <ContractorProjectDescription/>
    
    <div className="desc-info">
    <h6 className="desc-info-heading">Standard Contract</h6>
    {/* <p className="desc-info-detail">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>*/}
    <div className="pdf-info">
        <p onClick={() => { setModalShow(true); setModalShow1(false) }}>Standard Contract <img className="width100" src={Icons.pdfIcon} alt="" /></p>
    </div>
</div>


<Modal
    show={modalShow}
    onHide={() => { setModalShow(false); setModalShow1(false) }}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    <Modal.Header closeButton>
        <Modal.Title>Standard Contract</Modal.Title>
        <Link className="btn-m-action float-end" title="Download" to={`${API_BASEURL}/${_.findWhere(projectData, { type: "standard_contract" })?.path}` || (Icons.pdf)} target="_blank" download>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5 8L10 13L15 8" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10 13V1" stroke="#27316F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </Link>
    </Modal.Header>
    <Modal.Body>
    <div className="f-row">
        <div className="f-50">
            <div style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '740px',
                width: '740px'
            }}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js">
                    <Viewer
                        fileUrl={`${API_BASEURL}/${_.findWhere(projectData, { type: "standard_contract" })?.path}` || (Icons.pdf)}
                        plugins={[defaultLayoutPluginInstance]}
                    />
                </Worker>
            </div>
        </div>
    </div>
</Modal.Body>
</Modal>
</div>
    </>
    )
}
export default Contract;