import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import BuildingTypeList from '../BuildingTypeList';
import BuildingTypeView from '../BuildingTypeView';
import BuildingTypeForm from '../BuildingTypeForm'

const BuildingTypeRoutes = [
    {
        path: `/building-type-list`,
        layout: DefaultLayout,
        component: <  BuildingTypeList />
    },
    {
        path: `/building-type-form`,
        layout: DefaultLayout,
        component: <  BuildingTypeForm />
    },
    {
        path: `/building-type-view/:id`,
        layout: DefaultLayout,
        component: <  BuildingTypeView />
    }
    
    
]

export default BuildingTypeRoutes ;