import React from 'react';
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBContainer,} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

export default function Breadcrumb() {
  return (
   
      <MDBContainer fluid>
        <MDBBreadcrumb>
          <MDBBreadcrumbItem>
            <Link to='/list-owner'>list-owner</Link>
          </MDBBreadcrumbItem>
          <MDBBreadcrumbItem>
            <Link to='/add-owner'>add-owner</Link>
          </MDBBreadcrumbItem>
        </MDBBreadcrumb>
      </MDBContainer>
    
  );
}