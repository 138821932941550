import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { rpcSchema } from "../../components/common/validations/Validation";
import TextField from "../../components/common/formInput/TextField";
import { options, message, _status } from "../../components/common/utils/message";
import { confirm } from "react-bootstrap-confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import { addRpc, getRPC, updateRpc } from "../../services/rpc";
import { failed, success } from "../../components/common/Toastify";
import queryString from 'query-string';
import { useDispatch } from "react-redux";
import { Get_Countries, Get_State } from "../../services/owner";
import SearchSelectBox from "../../components/common/formInput/SearchSelectBox";
import SelectBox from "../../components/common/formInput/SelectBox";
import { listRpc, DeleteRpc } from "../../services/rpc";
import {   pagination, PaginationOption } from "../../components/common/utils/message";

import Editor from "../../components/common/formInput/Editor";
import ReactDatePicker from "../../components/common/formInput/DatePicker";
import BarChartSample from "../graphs/BarChart";
import LineChartSample from "../graphs/LineChart";
import PieChartSample from "../graphs/PieChart";

const initialFormState = {
    _id: "",
    report_name: "",
    last_name: "",
    company_name: "",
    city: "",
    mobile_no: "",
    state: "",
    country: "United States",
    zip_code: "",
    address1: "",
    address2: "",
    email: "",
    status: true,
    company_logo: ""
};

const CreateGraphForm = () => {
    const [countries, setCountries] = useState();
    const [country, setCountry] = useState("United States");

    const [limit, setLimit] = useState(pagination.limit);
    const [page, setPage] = useState(pagination.page);
    const [sortBy, setSortBy] = useState()
    const [order, setOrder] = useState()
    const [searchByState, setSearchByState] = useState();
    const [searchBystatus, setSearchByStatus] = useState();
    const [resPayload, setResPayload] = useState();


    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isStartDate, setIsStartDate] = useState()
    const [isEndDate, setIsEndDate] = useState()

    const [showResults, setShowResults] = React.useState(false)
    const onClick = () => setShowResults(true)

    const [isActive, setActive] = useState(false);

    const toggleClass = () => {
        setActive(!isActive);
    };


    const rpc_id = queryString.parse(location.search);
    const [rpcManager, setRpcManager] = useState({ managerData: { ...initialFormState }, userModules: [] });

    const [states, setStates] = useState([]);
    const [resCountries, setResCountries] = useState();

    const chartType = [
        { name: 'Bar Chart', value: 'Bar Chart' },
        { name: 'Line Chart', value: 'Line Chart' },
        { name: 'Pie Chart', value: 'Pie Chart' },
    ];

    useEffect(() => {
        Get_Countries(result => {
            if (result.data) {
                const statesDropDownData = result.data && result.data.map((item) => ({ value: item.name, label: item.name }));
                setResCountries(statesDropDownData);
            }
        })


        if (rpc_id) {
            getRPC({ id: rpc_id.id }, (result) => {
                if (result.user) {
                    const get_rpc = { ...result.user };
                    const rpcManagerData = {
                        _id: get_rpc ? get_rpc._id : "",
                        report_name: get_rpc ? get_rpc.report_name : "",
                        last_name: get_rpc ? get_rpc.last_name : "",
                        company_name: get_rpc ? get_rpc.company_name : "",
                        city: get_rpc ? get_rpc.city : "",
                        mobile_no: get_rpc ? get_rpc.mobile_no : "",
                        state: get_rpc ? get_rpc.state : "",
                        country: get_rpc ? get_rpc.country : "",
                        zip_code: get_rpc ? get_rpc.zip_code : "",
                        address1: get_rpc ? get_rpc.address1 : "",
                        address2: get_rpc ? get_rpc.address2 : "",
                        email: get_rpc ? get_rpc.email : "",
                        status: get_rpc ? get_rpc.status : true,
                        company_logo: get_rpc ? get_rpc.company_logo : ""
                    };
                    setRpcManager({ managerData: rpcManagerData, userModules: [...result.usermodules.module] });
                    Get_State({ country: get_rpc.country }, result => {
                        if (result?.data?.states) {
                            setStates(result?.data?.states?.map((item) => ({ value: item.name, label: item.name })));
                        }
                    }, dispatch)
                }
            }, dispatch)
        }

    }, []);

    useEffect(() => {
    //     Get_State({ country: "United States" }, result => {
    //         if (result.data) {
    //             const citiesDropDwownData = result?.data?.states?.map((item) => ({ value: item.name, label: item.name }));
    //             setStates(citiesDropDwownData);
    //         }
    //     }, dispatch)
    // }, []);
    _getListing();
    Get_Countries(res => { setCountries(res.data) });
    _getState("United States");
}, [dispatch]);
const _getListing = () => {
    listRpc({ page, limit, sortBy, order }, (data) => {
        setResPayload(data);
    }, dispatch);
}  
const _getState = (val) => {
    Get_State({ country: val }, result => {
        setStates(result);
    }, dispatch)
}

const onStateSearch = async (filterParam) => {
    setSearchByState(filterParam);
    await listRpc({ limit, sortBy, order, fields: "state", filter: filterParam }, response => {
        setResPayload(response);
    }, dispatch)
}
const onCountySearch = async (filterParam) => {
    setSearchByState(filterParam);
    await listRpc({ limit, sortBy, order, fields: "country", filter: filterParam }, response => {
        setResPayload(response);
    }, dispatch)
}



    /**
     * @description Function is for add Rpc.
     * @param {object} data 
     */
    const _submitData = (data) => {
        let moduleArray = [...rpcManager.userModules]
        let form = new FormData();
        form.append('report_name', data.report_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        if (moduleArray) {
            for (let i = 0; i < moduleArray.length; i++) {
                form.append("module[]", moduleArray[i]);
            }
        }


        try {
            addRpc(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/list-rpc")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is for Update Owner with building.
     * @param {object} data 
     */
    const _updateData = async (data) => {
        let moduleArray = [...rpcManager.userModules]
        let form = new FormData();
        form.append('_id', data._id)
        form.append('report_name', data.report_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('address1', data.address1)
        form.append('address2', data.address2)
        form.append('company_name', data.company_name)
        form.append('mobile_no', data.mobile_no)
        form.append('city', data.city)
        form.append('state', data.state)
        form.append('country', data.country)
        form.append('zip_code', data.zip_code)
        form.append('company_logo', data.company_logo)
        form.append('status', data.status)
        if (moduleArray) {
            //form.append('module', JSON.stringify(moduleArray));

            for (let i = 0; i < moduleArray.length; i++) {
                form.append("module[]", moduleArray[i]);
            }
        }
        // let payload = {
        //     ...form,
        //     module: [...rpcManager.userModules]
        // }
        try {
            updateRpc(form, result => {
                if (result.status === "SUCCESS" && result.code === 200) {
                    success(result.message)
                    navigate("/list-rpc")
                } else if (result.status === "ERROR" && result.code === 403) {
                    failed(result.message)
                }
            }, dispatch)
        } catch (e) {
            failed(e)
        }
    };

    /**
     * @description Function is For on Cancel Confirm Box.
     */
    const onCancel = async () => {
        const result = await confirm(message.canceledRpcForm, options);
        if (result) {
            navigate("/list-rpc");
        }
    };



    return (
        <div className="dashboard-inner graph-form p-b-0">
            <Formik
                initialValues={rpcManager.managerData}
                enableReinitialize
                validationSchema={rpcSchema}
                onSubmit={(values) => rpc_id.id ? _updateData(values) : _submitData(values)}
            >
                {(formik) => (
                    <Form>
                        <div className="d-card-header border-0">
                            <h5 className="d-card-heading p-0">{rpc_id.id ? "Edit Line Chart" : "Create New Graph"}</h5>
                        </div>
                        <div className="d-card m-b-25">
                            <div className="d-card-body p-b-0">
                                <div className="form-content">
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <TextField
                                                label="Report Name"
                                                placeholder="Report Name"
                                                name="report_name"
                                                value={formik?.values?.report_name}
                                                onChange={formik.handleChange}
                                                required={true}
                                            />
                                        </div>
                                        <div className="f-33">
                                            <SearchSelectBox
                                                label="Chart Type"
                                                placeholder="Select Chart Type"
                                                name="chart type"
                                                options={chartType}
                                                value={formik?.values?.chartType}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="custom-horizontal-bar"></div>
                                    {/* <div className="f-row alignTop">
                                        <div className="f-100">
                                            <Editor
                                                label="Report Description"
                                                name="page_content"
                                                value={formik?.values?.page_content}
                                            />
                                        </div>
                                    </div> */}
                                    <div className="custom-horizontal-bar"></div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <label className='f-label f-h4'>Y - Axis</label>
                                            <div className='f-field'>
                                                <label className='checkbox radio-checkbox m-r-15' htmlFor="bidder01">
                                                    <input type="radio" id="bidder01" name='valuesOfNotification' value="total" />
                                                    <span>Total</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="bidder02">
                                                    <input type="radio" id="bidder02" name='valuesOfNotification' value="average" />
                                                    <span>Average</span>
                                                </label>
                                            </div>

                                            <div className='f-field paddleft'>
                                                <label className='checkbox radio-checkbox m-r-15' htmlFor="Cost">
                                                    <input type="radio" id="Cost" name='Cost' value="Cost" />
                                                    <span>Cost</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="Size">
                                                    <input type="radio" id="Size" name='Size' value="Size" />
                                                    <span>Size</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="Averages">
                                                    <input type="radio" id="Averages" name='Averages' value="Averages" />
                                                    <span>Averages</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-horizontal-bar"></div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <label className='f-label f-h4'>X - Axis</label>
                                            <div className='f-field'>
                                                <label className='checkbox radio-checkbox m-r-15' htmlFor="01">
                                                    <input type="radio" id="01" name='manufacture' value="manufacture" />
                                                    <span>Manufacture</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="02">
                                                    <input type="radio" id="02" name='contractor' value="contractor" />
                                                    <span>Contractor</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="03">
                                                    <input type="radio" id="03" name='winningbid' value="winningbid" />
                                                    <span>Winning Bid</span>
                                                </label>
                                                <label className='checkbox radio-checkbox' htmlFor="04">
                                                    <input type="radio" id="04" name='numberofbidder' value="numberofbidder" />
                                                    <span>Number of Bidder</span>
                                                </label>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="custom-horizontal-bar"></div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <label className="f-label f-h4">Select Criteria</label>
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-25">
                                            {/* <SearchSelectBox
                                                label="Country"
                                                placeholder="Select Country"
                                                name="country"
                                                value={formik?.values?.country}
                                                onChange={formik.handleChange}
                                                changeHandle={(val) => {
                                                    Get_State({ country: val }, result => {
                                                        if (result.data) {
                                                            const citiesDropDwownData = result?.data?.states?.map((item) => ({ value: item.name, label: item.name }));
                                                            setStates(citiesDropDwownData);
                                                        }
                                                    }, dispatch)
                                                }}
                                                options={resCountries}
                                            /> */}
                                             <SelectBox
                                        Placeholder="Select Country"
                                        label="Country"
                                        options={countries}
                                        val={country}
                                        handleVal={(val) => {
                                            setCountry(val);
                                            _getState(val);
                                            onCountySearch(val);
                                        }}
                                    />
                                        </div>
                                        <div className="f-25">
                                            {/* <SearchSelectBox
                                                label="State"
                                                placeholder="Select States"
                                                name="state"
                                                value={formik?.values?.state}
                                                onChange={formik.handleChange}
                                                options={states}
                                            /> */}
                                            <div className="nocolor-dropdown">
                                    <SelectBox
                                        Placeholder="Select State"
                                        options={states?.data?.states?.map((item) => { return item.name })}
                                        val={searchByState}
                                        handleVal={(e) => onStateSearch(e)}
                                    />
                                </div>
                                        </div>
                                        <div className="f-25">
                                            <TextField
                                                label="City"
                                                name="city"
                                                placeholder="City"
                                                value={formik?.values?.city}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                        <div className="f-25">
                                            <SearchSelectBox
                                                label="Quaters"
                                                name="status"
                                                options={_status}
                                                value={formik?.values?.status}
                                                onChange={formik.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-100">
                                            <label className="f-label p-0">Date Range</label>
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-25">
                                            <ReactDatePicker
                                                name="bid_start"
                                                placeholder="From Date"
                                                selected={isStartDate}
                                                dateFormat="yyyy/MM/dd"
                                                value={formik?.values?.bid_start}
                                                changeHandle={(date) => setIsStartDate(date)}
                                            />

                                        </div>
                                        <div className="f-25">
                                            <ReactDatePicker
                                                name="bid_start"
                                                placeholder="To Date"
                                                selected={isEndDate}
                                                dateFormat="yyyy/MM/dd"
                                                value={formik?.values?.bid_start}
                                                changeHandle={(date) => setIsEndDate(date)}
                                            />
                                        </div>
                                    </div>
                                    <div className="custom-horizontal-bar"></div>
                                    <div className="f-row alignTop">
                                        <div className="f-25">
                                            <label className="f-label f-h4">Select Application menu</label>
                                            <div className="appselectlist">
                                                <div className={isActive ? 'showapplist' : null}
                                                    onClick={toggleClass} >
                                                    <span className="appinput">Select</span>
                                                    <div className="option-list">
                                                        <h4>Select Application menu</h4>
                                                        <ul>
                                                            <li>
                                                                <div className='checkbox-row'>
                                                                    <div className='checkbox-wrap'>
                                                                        <label className='checkbox' htmlFor='check1'>
                                                                            <input type="checkbox" id="check1" />
                                                                            <span>Single Select Title</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='checkbox-row'>
                                                                    <div className='checkbox-wrap'>
                                                                        <label className='checkbox' htmlFor='check2'>
                                                                            <input type="checkbox" id="check2" />
                                                                            <span>Test</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='checkbox-row'>
                                                                    <div className='checkbox-wrap'>
                                                                        <label className='checkbox' htmlFor='check3'>
                                                                            <input type="checkbox" id="check3" />
                                                                            <span>Building Stories</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='checkbox-row'>
                                                                    <div className='checkbox-wrap'>
                                                                        <label className='checkbox' htmlFor='check4'>
                                                                            <input type="checkbox" id="check4" />
                                                                            <span>Wind Uplift</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='checkbox-row'>
                                                                    <div className='checkbox-wrap'>
                                                                        <label className='checkbox' htmlFor='check5'>
                                                                            <input type="checkbox" id="check5" />
                                                                            <span>Roof Type Approach</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <div className="btn-footer">
                                                            <button className="btn btn-blue" type="submit">Select Menu</button>
                                                            <button className="btn btn-grey" type="button">Cancel </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="f-row alignTop">
                                        <div className="f-33">
                                            <div className="showullist">
                                                <ul>
                                                    <li> <span><a className="close-wrap"><i class="bi bi-x"></i></a> Single Select Title </span> <TextField
                                                        name="value"
                                                        placeholder="Value"
                                                    />
                                                    </li>
                                                    <li> <span> <a className="close-wrap"><i class="bi bi-x"></i></a> Roof Type Approach </span> <TextField
                                                        name="value"
                                                        placeholder="Value"
                                                    />
                                                    </li>
                                                    <li> <span><a className="close-wrap"><i class="bi bi-x"></i></a> R Value </span> <TextField
                                                        name="value"
                                                        placeholder="Value"
                                                    />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="f-row">
                                        <button className="btn btn-blue generate-btn" type="button" onClick={onClick}>Generate</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showResults 
                            ?
                            <div className="d-card m-b-25">
                                <div className="d-card-header">
                                    <h5 className="d-card-heading">Bar Chart </h5>
                                </div>
                                <div className="d-card-body p-b-0">
                                    <h6 className="chart-head">Details</h6>
                                    <div className="chart-detail">
                                        <div className="left">
                                            <ul>
                                                <li>
                                                    <div className="label">Report Name</div>
                                                    <div className="data">Test Report</div>
                                                </li>
                                                <li>
                                                    <div className="label">Chart Type</div>
                                                    <div className="data">Bar Chart</div>
                                                </li>
                                            </ul>
                                            <ul>
                                                <li className="full-wrap">Select Criteria</li>
                                                <li>
                                                    <div className="label">Country</div>
                                                    <div className="data">USA</div>
                                                </li>
                                                <li>
                                                    <div className="label">States</div>
                                                    <div className="data">Arizona</div>
                                                </li>
                                                <li>
                                                    <div className="label">City</div>
                                                    <div className="data">Phoenix</div>
                                                </li>
                                                <li>
                                                    <div className="label">Quaters</div>
                                                    <div className="data">2nd</div>
                                                </li>
                                                <li>
                                                    <div className="label">Date Range</div>
                                                    <div className="data">8/15/2021  To  6/20/2022</div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="right">
                                            <ul>
                                                <li>
                                                    <div className="label">Y - Axis</div>
                                                    <div className="data">Total</div>
                                                    <div className="sub-data">Cost <br /> Size</div>
                                                </li>
                                                <li>
                                                    <div className="label">X - Axis</div>
                                                    <div className="data">Manufacture</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="chart-wrap">
                                        <BarChartSample />
                                        <LineChartSample/>
                                        <PieChartSample/>
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                        <div className="d-card-bottom">
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-blue" type="submit">Save</button>
                            </div>
                            <div className="d-card-bottom-cell">
                                <button className="btn btn-grey" type="button"> Reset </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div >
    );
};

export default CreateGraphForm;
