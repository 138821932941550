import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Accordion from "react-bootstrap/Accordion";
import Icons from '../../assets/images/index';
import { viewOneInspection } from '../../services/inspection';
import { pagination, PaginationOption } from "../../components/common/utils/message";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { options, message, _status } from "../../components/common/utils/message";
import { alert, confirm } from "react-bootstrap-confirmation";
import ProjectDetails from '../manageInspection/ProjectDetail';

const InspectionView = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [inspectionData, setinspectionData] = useState();
    const inspectionID = useParams();

    useEffect(() => {
        viewOneInspection(inspectionID, (data) => {
            setinspectionData(data)
        })
      }, [])

    /**
    * @description Function is For on Cancel Confirm Box.
    */
    const onCancel = async () => {
        const result = await confirm(message.cancelInspectionForm, options);
        if (result) {
            alert(message.cancelInspectionForm);
            navigate("/inspection-list");
        }
    };

    return (
        <div className='dashboard-inner'>
            <h4 className='dashboard-title'>Inspection</h4>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading d-card-sub-heading'>Project Details</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="f-row">
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Project Details</h6>
                                <p className="desc-info-detail">{inspectionData?.inspector_name}</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Date</h6>
                                <p className="desc-info-detail">{moment(inspectionData?.date).format('DD/MM/YYYY')}</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading"> Roof Areas</h6>
                                <p className="desc-info-detail">{inspectionData?.projectId?.square_footage}</p>
                            </div>
                        </div>
                        <div className="f-auto">
                        <div className="desc-info">
                          
                        <p className="desc-info-detail">{inspectionData?.projectId?.description}</p>
                        </div>
                    </div>
                    
                    </div>
                    
                </div>
            </div>
            {/* <div className='d-card m-b-25'>
                <div className='d-card-body p-b-0'>
                    <ul className='projects-list inspection-gallery'>
                        <li>
                            <div className='project'>
                                <span className='project-img'>
                                    <video width="320" height="240" controls>
                                        <source src="movie.mp4" type="video/mp4" />
                                        <source src="movie.ogg" type="video/ogg" />
                                        Your browser does not support the video tag.
                                    </video>
                                </span>
                                <button className='btn-play'><img src={Icons.btnPlay} alt="" /></button>
                            </div>
                        </li>
                        <li>
                            <div className='project'>
                                <span className='project-img'>
                                    <img src={Icons.projectBigImg} alt="" />
                                </span>
                            </div>
                        </li>
                        <li>
                            <div className='project'>
                                <span className='project-img'>
                                    <img src={Icons.projectBigImg} alt="" />
                                </span>
                                <button className='btn-pdf'><img src={Icons.btnPdf} alt="" /></button>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='d-card m-b-25'>
                {inspectionArray && inspectionArray
                    .map((item, index) => (
                        <div className="accordians" style={{ "background": "#f8f8f8" }}>
                            <Accordion alwaysOpen>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Inspection {index + 1}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="f-row">
                                            <div className="f-auto">
                                                <div className="desc-info">
                                                    <h6 className="desc-info-heading">Inspector Name</h6>
                                                    <p className="desc-info-detail">{item.inspector_name}</p>
                                                </div>
                                            </div>
                                            <div className="f-auto">
                                                <div className="desc-info">
                                                    <h6 className="desc-info-heading">Inpsection Date</h6>
                                                    <p className="desc-info-detail">{moment(item.date).format('DD/MM/YYYY')}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    ))}
            </div> */}
<ProjectDetails/>
            <div className="d-card-bottom">
                <div className="d-card-bottom-cell">
                    {/* <button className="btn btn-grey" onClick={onCancel} type="button">
                        Cancel
                    </button> */}
                </div>
            </div>
        </div>
    )
}

export default InspectionView

