import React from 'react';
import DefaultLayout from '../../../components/common/layout/DefaultLayout';
import LineChartSample from '../LineChart';
import PieChartSample from '../PieChart';
import BarChartSample from '../BarChart';
import AmGraph from '../Am';
import SquarefootBarChartSample from '../Squarefootbarchart';
import SquareFootLineChartSample from '../Squarefootlinechart';
import SquareFootPieChartSample from '../Squarefootpiechart';

const GraphRoutes = [
    {
        path: `/line-chart`,
        layout: DefaultLayout,
        component: <  LineChartSample />
    },
    {
        path: `/pie-chart`,
        layout: DefaultLayout,
        component: <  PieChartSample />
    },
    {
        path: `/bar-chart`,
        layout: DefaultLayout,
        component: <  BarChartSample />
    }, {
        path: `/am-chart`,
        layout: DefaultLayout,
        component: <  AmGraph />
    },
    {
        path: `/sqfootbar-chart`,
        layout: DefaultLayout,
        component: <SquarefootBarChartSample/>
    },
    {
        path: `/sqline-chart`,
        layout: DefaultLayout,
        component: <SquareFootLineChartSample/>
    },
    {
        path: `/sqpie-chart`,
        layout: DefaultLayout,
        component: <SquareFootPieChartSample/>
    }

]

export default GraphRoutes;