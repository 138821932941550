import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icons from '../../assets/images/index'
import OwlCarousel from 'react-owl-carousel2';
import { API_BASEURL } from "../../environment";
import { useDispatch } from 'react-redux';
import { getPageBySlug } from "../../services/cms.service";

const options = {
    items: 1,
    nav: false,
    rewind: true,
    autoplay: true
};

const events = {
    onDragged: function (event) { console.log(event) },
    onChanged: function (event) { console.log(event) }
};

const MainHome = () => {

    const dispatch = useDispatch();

    const [pageData, setPageData] = useState(null)

    useEffect(() => {
        getPageBySlug("/home", (data) => {
            setPageData(data)
        }, dispatch)
    }, [])

    return (
        <>
            {pageData && <>
                <section className='hero-section'>
                    <OwlCarousel options={options} events={events} >
                        {pageData.banner_images.map(banner => {
                            return (<div className='hero-section-inner' style={{ backgroundImage: `url(${API_BASEURL}/${banner.path})` }}>
                                <div className='container'>
                                    <div className='hero-inner'>
                                        <h3 className='section-heading' dangerouslySetInnerHTML={{ __html: pageData.banner_heading }} />
                                        <p className='section-desc' dangerouslySetInnerHTML={{ __html: pageData.banner_description }} />
                                    </div>
                                </div>
                            </div>)
                        })}
                    </OwlCarousel>
                </section>
                <section className='what-we-do-section'>
                    <div className='inner-container'>
                        <div className='what-we-do-inner'>
                            <h4 className='section-heading dark text-center' dangerouslySetInnerHTML={{ __html: pageData.page_content }} />
                            <p className='section-desc dark text-center'>Connect Building Owners with Roofing Contractors, Suppliers &amp; Maunfacturers</p>
                            <ul className='services-list'>
                                {pageData.blocks.map((block) => (
                                    <li>
                                        <div className="service">
                                            <span className='service-icon'>
                                                <img src={Icons.service001} alt='' />
                                            </span>
                                            <h5 className='service-type' dangerouslySetInnerHTML={{ __html: block.heading }} />
                                            <h4 className='service-benifit' dangerouslySetInnerHTML={{ __html: block.description }} />
                                            <Link to="">Learn More <span className='arrow'><img src={Icons.arrow} alt='' /></span></Link>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </section>
                <section className='overlay-section'>
                    <div className='container'>
                        <div className='overlay-inner' style={{ backgroundImage: `url(${Icons.overallBg})` }}>
                            <h4 className='section-heading text-center'>Easy to use Bidding Portal</h4>
                            <p className='section-desc text-center'>We bring the "Roof to the Roofer" through our online virtual tour that saves our Building<br /> Owners and Roofing Industry Customers both Time and Money.</p>
                            <h5 className='section-sub-heading text-center'>Spend Less &amp; Roof More</h5>
                        </div>
                    </div>
                </section>
            </>}
        </>
    )
}

export default MainHome;