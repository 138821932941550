import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Icons from '../../assets/images/index';
import { viewOneMaterialType } from '../../services/materialType';
import _ from 'underscore';
import { useDispatch } from "react-redux";

const MaterialTypeView = () => {
    const dispatch = useDispatch();
    const [materialType, setmaterialType] = useState()
    let materialType_id = useParams();


    useEffect(() => {
        viewOneMaterialType(materialType_id, (data) => {
            setmaterialType(data)
        })
    }, [dispatch])



    return (
        <div className='dashboard-inner'>
            <div className='d-card m-b-25'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading'>Material Type Management</h5>
                    <div className='d-card-actions'>
                        <label htmlFor='toggleSwitch001' className='toggle-switch m-r-10'>
                            <input type="checkbox" id='toggleSwitch001' />
                            <h6 className='deactivate'>Inactive</h6>
                            <h6 className='activate'>Active</h6>
                            <span className='toggle-bar'></span>
                        </label>
                        <Link className="dashboard-action" to="/" title="Edit">
                            <img src={Icons.edit} alt="" height="20px" />
                        </Link>
                        <button className="dashboard-action" title="Delete">
                            <img src={Icons.bin} alt="" height="20px" />
                        </button>
                    </div>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className="f-row">
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Material Name</h6>
                                <p className="desc-info-detail">{materialType && materialType.material_name}</p>
                            </div>
                        </div>
                        <div className="f-auto">
                            <div className="desc-info">
                                <h6 className="desc-info-heading">Price Per Sq Ft</h6>
                                <p className="desc-info-detail">${materialType && materialType.price} USD</p>
                            </div>
                        </div>
                    </div>
                    <div className="desc-info">
                        <p className="desc-info-detail">{materialType && materialType.description}</p>

                    </div>
                </div>
            </div>
            <div className='d-card'>
                <div className='d-card-header'>
                    <h5 className='d-card-heading'>Popular Usage</h5>
                </div>
                <div className='d-card-body p-b-0'>
                    <div className='responsive-table'>
                        <table className='table-odd-even'>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className='bid-details'>
                                            <span className='bid-icon'>
                                                <img src={Icons.projectImg} alt='' />
                                            </span>
                                            <h4>Amet minim mollit</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Date: 14/05/2022</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Price: $5599 USD</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Location: California</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='bid-details'>
                                            <span className='bid-icon'>
                                                <img src={Icons.projectImg} alt='' />
                                            </span>
                                            <h4>Amet minim mollit</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Date: 14/05/2022</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Price: $5599 USD</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Location: California</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='bid-details'>
                                            <span className='bid-icon'>
                                                <img src={Icons.projectImg} alt='' />
                                            </span>
                                            <h4>Amet minim mollit</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Date: 14/05/2022</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Price: $5599 USD</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Location: California</h5>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className='bid-details'>
                                            <span className='bid-icon'>
                                                <img src={Icons.projectImg} alt='' />
                                            </span>
                                            <h4>Amet minim mollit</h4>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Date: 14/05/2022</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Price: $5599 USD</h5>
                                    </td>
                                    <td>
                                        <h5 className='bid-closing'>Location: California</h5>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MaterialTypeView

